/**
 * Labstep
 *
 * @module grid/services/grid-toolbar
 * @desc AG Grid toolbar business logic
 */

export class GridToolBarService {
  public static showListSection = (
    labels: string[],
    searchQuery: string | null | undefined,
  ) =>
    !searchQuery ||
    searchQuery.length === 0 ||
    labels
      .map((label) =>
        label.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      .includes(true);

  public static showListItem = (
    label: string,
    searchQuery: string | null | undefined,
  ) =>
    !searchQuery ||
    searchQuery.length === 0 ||
    label.toLowerCase().includes(searchQuery.toLowerCase());
}

/**
 * Labstep
 *
 * @module core/Form/ReactForm/CustomFieldWrapper
 * @desc FIXME Missing documentation
 */

import CharMap from 'labstep-web/core/CharMap';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ICustomTextWrapperProps } from './types';

class CustomTextWrapper extends React.Component<
  ICustomTextWrapperProps,
  { open: boolean }
> {
  public myRef: any;

  public constructor(props: ICustomTextWrapperProps) {
    super(props);
    this.myRef = React.createRef();
    this.focus = this.focus.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = { open: false };
  }

  public focus(): void {
    this.myRef.current.focus();
  }

  public toggleModal(): void {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  public render(): React.ReactElement {
    const { props, focus } = this;

    return (
      <Controller
        name={props.name}
        control={props.control}
        render={({
          field: reactHookFormField,
        }): React.ReactElement => {
          const {
            onBlur,
            fieldElement: FieldElement,
            autoFocus,
            disabled,
            onKeyPress,
            placeholder,
          } = props;

          const elementProps = props.elementProps || {};

          const { withSymbols, ...rest } = elementProps;

          return (
            <div>
              <FieldElement
                data-cy="core/Form/ReactForm/CustomFieldWrapper"
                ref={this.myRef}
                value={reactHookFormField.value as any}
                name={props.name}
                {...rest}
                onChange={(e: React.ChangeEvent<any>): void => {
                  const targetValue = e.target.value;
                  const updatedValue = targetValue || null;
                  reactHookFormField.onChange(updatedValue);
                }}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                onKeyPress={onKeyPress}
                onBlur={(): void => {
                  if (onBlur && !this.state.open) {
                    onBlur();
                  }
                }}
              />

              {withSymbols && (
                <Modal
                  open={this.state.open}
                  viewComponent={() => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href="#"
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.toggleModal();
                      }}
                    >
                      + Add special character
                    </a>
                  )}
                  header="Symbols"
                  onClose={this.toggleModal}
                  content={() => (
                    <CharMap
                      onClick={(symbol) => {
                        reactHookFormField.onChange(
                          `${
                            reactHookFormField.value || ''
                          }${symbol}` as any,
                        );
                        this.toggleModal();
                        focus();
                      }}
                    />
                  )}
                />
              )}
            </div>
          );
        }}
      />
    );
  }
}

export default CustomTextWrapper;

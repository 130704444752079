/**
 * Labstep
 *
 * @module models/user-group
 * @desc Typescript export class for UserGroup
 */

import { Type } from 'class-transformer';
import {
  PERMISSION_ROLE_EDITOR,
  PERMISSION_ROLE_OWNER,
  PERMISSION_ROLE_VIEWER,
} from 'labstep-web/constants/roles';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import { PermissionType } from 'labstep-web/models/permission.model';
import { User } from 'labstep-web/models/user.model';

export const USER_GROUP_TYPE_VIEW = 'view';
export const USER_GROUP_TYPE_EDIT = 'edit';
export const USER_GROUP_TYPE_OWNER = 'owner';

export class UserGroup extends Entity {
  static readonly entityName = 'user_group';

  get entityName(): string {
    return UserGroup.entityName;
  }

  constructor(data: Partial<UserGroup> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  type!: PermissionType;

  is_home!: boolean;

  @Type(() => User)
  user!: User;

  @Type(() => Group)
  group!: Group;

  @Type(() => PermissionRole)
  permission_role!: PermissionRole;

  get permissionRoleName(): string {
    if (this.permission_role) {
      return this.permission_role.name;
    }
    if (this.type === PermissionType.view) {
      return PERMISSION_ROLE_VIEWER.name;
    }
    if (this.type === PermissionType.owner) {
      return PERMISSION_ROLE_OWNER.name;
    }
    return PERMISSION_ROLE_EDITOR.name;
  }
}

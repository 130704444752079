/**
 * Labstep
 *
 * @module core/Select/FloatingSearch
 * @desc Async Floating Search Select
 */

import { ActionComponent } from 'labstep-web/core/Action/Component';
import { SectionHeader } from 'labstep-web/core/Header';
import SearchSelect from 'labstep-web/core/Select/Search';
import React, { useRef, useState } from 'react';
import { components } from 'react-select';
import { GroupProps } from 'react-select/dist/declarations/src/components/Group';
import styles from './styles.module.scss';
import { FloatingSearchSelectProps } from './types';

export const FloatingSearchSelect: React.FC<
  FloatingSearchSelectProps
> = ({
  alignRight,
  keepMenuOpen,
  actionComponentProps,
  onChange,
  open,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={containerRef}
      className={styles.floatingSearchContainer}
    >
      <ActionComponent
        text="Add"
        icon="add"
        {...actionComponentProps}
        onClick={handleButtonClick}
      />

      {isOpen && (
        <div
          data-cy="core/Select/FloatingSearch:open"
          data-testid="floating-search-select"
          className={`${styles.dropdownContainer} ${
            alignRight ? styles.alignRight : ''
          }`}
        >
          <SearchSelect
            {...rest}
            isFloating
            keepMenuOpen={!keepMenuOpen ? undefined : true}
            autoFocus
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              GroupHeading: (props: any) =>
                props?.data?.label && (
                  <div className={styles.groupHeading}>
                    {SectionHeader({
                      label: props.data.label,
                      children: props.data.children,
                    })}
                  </div>
                ),
              Group: (props: GroupProps) => (
                <components.Group
                  {...props}
                  getClassNames={() => styles.group}
                >
                  {props.children}
                </components.Group>
              ),
            }}
            onChange={(option, input) => {
              if (onChange) {
                onChange(option, input);
              }
              if (!keepMenuOpen) {
                setIsOpen(false);
              }
            }}
            params={{ count: 5, ...rest.params }}
          />
        </div>
      )}
    </div>
  );
};

FloatingSearchSelect.defaultProps = {
  alignRight: false,
};

export default FloatingSearchSelect;

/**
 * Labstep
 *
 * @module core/Card/Select
 * @desc Selectable Card
 */

import Checkbox from 'labstep-web/core/Checkbox';
import Flex from 'labstep-web/core/Flex';
import PopupContainer from 'labstep-web/core/Popup';
import React from 'react';
import { Card, Radio } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { CardSelectProps } from './types';

const CardSelectNoCheckbox: React.FC<CardSelectProps> = ({
  content,
  isSelected,
  setIsSelected,
  label,
  isDisabled = false,
  disabledMessage,
}) =>
  isDisabled ? (
    <PopupContainer
      trigger={
        <Card
          data-testid="card-select"
          as="div"
          className={`${styles.card} ${isDisabled ? styles.disabled : ''}`}
          onClick={() => {
            if (!isDisabled) {
              setIsSelected(!isSelected);
            }
          }}
        >
          <div className={`${styles.content}`}>
            <div hidden>
              <Radio checked={isSelected} />
            </div>
            <Flex
              vAlignContent="center"
              hAlignContent="center"
              column
            >
              <>{content}</>
              <div
                className={`${styles.header} ${isDisabled && styles.disabledHeader}`}
              >
                {label}
              </div>
            </Flex>
          </div>
        </Card>
      }
      position="top center"
      content={disabledMessage}
    />
  ) : (
    <Card
      data-testid="card-select"
      as="div"
      className={`${styles.card} ${isSelected ? styles.selected : ''}`}
      onClick={() => {
        if (!isDisabled) {
          setIsSelected(!isSelected);
        }
      }}
    >
      <div className={`${styles.content}`}>
        <div hidden>
          <Radio checked={isSelected} />
        </div>
        <Flex vAlignContent="center" hAlignContent="center" column>
          <>{content}</>
          <div
            className={`${styles.header} ${isSelected && styles.selectedHeader}`}
          >
            {label}
          </div>
        </Flex>
      </div>
    </Card>
  );

const CardSelect: React.FC<CardSelectProps> = ({
  content,
  isSelected,
  setIsSelected,
  label,
  isDisabled = false,
  disabledMessage,
  noCheckbox = false,
}) =>
  noCheckbox ? (
    <CardSelectNoCheckbox
      content={content}
      isSelected={isSelected}
      setIsSelected={setIsSelected}
      label={label}
      isDisabled={isDisabled}
      disabledMessage={disabledMessage}
    />
  ) : (
    <Card
      data-testid="card-select"
      as="div"
      className={styles.card}
      onClick={() => setIsSelected(!isSelected)}
    >
      <div className={styles.content}>
        <Checkbox radio checked={isSelected} />
        {content}
      </div>
    </Card>
  );

export default CardSelect;

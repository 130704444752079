/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Lock
 * @desc Action to toggle lock for experimentWorkflow
 */

import EntityActionLock from 'labstep-web/components/Entity/Action/Lock';
import React from 'react';
import { ExperimentWorkflowActionLockProps } from './types';

export const ExperimentWorkflowActionLock: React.FC<
  ExperimentWorkflowActionLockProps
> = ({ experimentWorkflow, options = {}, actionComponentProps }) => (
  <EntityActionLock
    dataCy="components/ExperimentWorkflow/Action/Lock"
    entity={experimentWorkflow}
    options={options}
    actionComponentProps={actionComponentProps}
  />
);

export default ExperimentWorkflowActionLock;

/**
 * Labstep
 */

import ResourceItemActionDelete from 'labstep-web/components/ResourceItem/Action/Delete';
import ResourceItemActionDuplicate from 'labstep-web/components/ResourceItem/Action/Duplicate';
import colDefActionMenu from 'labstep-web/grid/Index/coldefs/Entity/actionMenu';
import { Entity } from 'labstep-web/models/entity.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';

export const colDefResourceItemActionMenu = colDefActionMenu<Entity>(
  (params) => (
    <>
      <ResourceItemActionDuplicate
        resourceItem={params.data as ResourceItem}
        noRedirect
      />
      <ResourceItemActionDelete
        resourceItem={params.data as ResourceItem}
      />
    </>
  ),
);

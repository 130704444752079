/**
 * Labstep
 *
 * @module components/Entity/Template/Breadcrumb/Show
 * @desc Breadcrumb for Template Entities on Show page
 */

import Breadcrumb from 'labstep-web/core/Breadcrumb';
import { IBreadcrumbLink } from 'labstep-web/core/Breadcrumb/types';
import React from 'react';
import { EntityTemplateBreadcrumbShowProps } from './types';

export const EntityTemplateBreadcrumbShow: React.FC<
  EntityTemplateBreadcrumbShowProps
> = ({ entity }) => {
  if (!entity.template) {
    return null;
  }
  const links: IBreadcrumbLink[] = [
    {
      icon: 'box',
      name: entity.template.displayName as string,
      route: {
        to: `${entity.entityName}_index`,
        query: {
          template_id: entity.template.guid,
        },
      },
    },
  ];

  return <Breadcrumb links={links} />;
};

export default EntityTemplateBreadcrumbShow;

/**
 * Labstep
 *
 * @module screens/GroupHomeless
 * @desc A screen to deal with the case that user has no home group
 */

import AppRoutes from 'labstep-web/components/App/Routes';
import LayoutLoadingContent from 'labstep-web/components/Layout/LoadingContent';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import Route from 'labstep-web/core/Route';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Group } from 'labstep-web/models/group.model';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { navigation } from 'labstep-web/services/navigation';
import React, { useEffect } from 'react';
import {
  Route as ReactRouterDomRoute,
  Redirect,
  Switch,
} from 'react-router-dom';
import {
  IScreensGroupHomelessProps,
  ISetActiveGroupProps,
  ISetGroupHomeProps,
  ISetHomeProps,
} from './types';

export const SetHome: React.FC<ISetHomeProps> = ({
  update,
  status,
  onFail,
}) => {
  useEffect(() => {
    update(
      { is_home: true },
      {
        onFail,
        onSuccess: () => window.location.reload(),
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutLoadingContent
      status={status}
      defaultFetching
      children={null}
    />
  );
};

const SetActiveGroup: React.FC<ISetActiveGroupProps> = ({
  group,
  setActiveGroupId,
}) => {
  useEffect(() => {
    setActiveGroupId(group.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export const SetActiveGroupContainer =
  withActiveGroup(SetActiveGroup);

export const SetGroupHome: React.FC<ISetGroupHomeProps> = (props) => (
  <ReadOnMountHOC
    type="entity"
    entityName={Group.entityName}
    id={props.match.params.id}
    loading={{ loader: 'full_screen' }}
    children={({ entity: group }) => {
      if (!group.logged_user_user_group) {
        return <SetActiveGroupContainer group={group} />;
      }
      return (
        <EntityUpdateContainer
          entityName={UserGroup.entityName}
          id={group.logged_user_user_group.id}
        >
          {({ update, status }) => (
            <SetHome
              update={update}
              status={status}
              onFail={() =>
                props.history.push(navigation.get('group_index'))
              }
            />
          )}
        </EntityUpdateContainer>
      );
    }}
  />
);

export const routes = [
  'group_index',
  'group_index_organization',
  'group_create',
  'verify_email',
  'organization_show',
  'organization_show_users',
  'organization_show_permissions',
  'organization_show_log',
  'organization_show_activity',
  'organization_show_billing',
  'organization_show_security',
  'organization_show_export',
  'organization_show_sso',
  'organization_show_settings',
  'onboarding_overview',
  'sharelink_show',
  'forgot_password',
  'reset_password',
  'signup',
  'signup_enterprise',
  'login',
  'login_saml',
  'saml_get_login_url',
  'saml_login',
  'saml_login_new',
] as const;

export const ScreensGroupHomeless: React.FC<
  IScreensGroupHomelessProps
> = ({ authenticatedUserEntity }) => (
  <Switch>
    <Route
      exact
      path={routes.map((p) => navigation.get(p))}
      component={AppRoutes}
    />
    <ReactRouterDomRoute
      path={navigation.get('group_show')}
      component={SetGroupHome}
    />
    <ReactRouterDomRoute
      render={() =>
        authenticatedUserEntity.userOrganization ? (
          <Redirect
            to={navigation.get('organization_show', {
              identifier:
                authenticatedUserEntity.userOrganization.organization
                  .identifier,
            })}
          />
        ) : (
          <Redirect to={navigation.get('group_index')} />
        )
      }
    />
  </Switch>
);

export default withAuthenticatedUserEntity(ScreensGroupHomeless);

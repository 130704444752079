/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/device/action-menu
 * @desc Action menu for the device
 */

import DeviceActionDelete from 'labstep-web/components/Device/Action/Delete';
import DeviceActionDuplicate from 'labstep-web/components/Device/Action/Duplicate';
import DeviceActionShare from 'labstep-web/components/Device/Action/Share';
import DeviceActionUpdateTemplate from 'labstep-web/components/Device/Action/UpdateTemplate';
import EntityActionTag from 'labstep-web/components/Entity/Action/Tag';
import EntityActionTransferOwnership from 'labstep-web/components/Entity/Action/TransferOwnership';
import colDefActionMenu from 'labstep-web/grid/Index/coldefs/Entity/actionMenu';
import { Device } from 'labstep-web/models/device.model';
import { Entity } from 'labstep-web/models/entity.model';

export const colDefDeviceActionMenu = colDefActionMenu<Entity>(
  (params) => (
    <>
      <EntityActionTag parentEntity={params.data as Device} />
      <DeviceActionShare device={params.data as Device} />
      <DeviceActionDuplicate device={params.data as Device} />
      {(params.data as Device).is_template && (
        <DeviceActionUpdateTemplate device={params.data as Device} />
      )}
      <EntityActionTransferOwnership entity={params.data as Device} />
      <DeviceActionDelete device={params.data as Device} />
    </>
  ),
);

/**
 * Labstep
 *
 * @module components/Entity/Template/List
 * @desc List of templates
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import EntityActionTemplateCreate from 'labstep-web/components/Entity/Action/TemplateCreate';
import EntityTemplateItem from 'labstep-web/components/Entity/Template/Item';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import ActionMenu from 'labstep-web/core/Action/Menu';
import List from 'labstep-web/core/List';
import { LocationService } from 'labstep-web/services/location.service';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { EntityTemplateListProps } from './types';

export const EntityTemplateList: React.FC<
  EntityTemplateListProps
> = ({
  location,
  groupId,
  templates,
  showUncategorised,
  showAction,
  entityName,
  entityPreview,
  history,
  indexRoute,
}) => {
  const templateId = LocationService.getQueryParameter(
    location,
    'template_id',
  );

  return (
    <List>
      {showUncategorised && (
        <EntityTemplateItem
          icon={ICONS.tag.info.untagged}
          text="Uncategorised"
          active={templateId === 'none'}
          onClick={(): void => {
            history.push(
              navigation.get(
                indexRoute,
                {
                  id: groupId,
                },
                false,
                {
                  template_id: 'none',
                },
              ),
            );
          }}
        />
      )}
      {templates
        .filter((template) => !template.deleted_at)
        .map((template) => {
          const isActive = templateId === template.guid;
          return (
            <EntityTemplateItem
              key={template.id}
              icon={ICONS.resource_template.primary}
              text={(template as any).name}
              active={isActive}
              onClick={(): void => {
                history.push(
                  navigation.get(
                    indexRoute,
                    {
                      id: groupId,
                    },
                    false,
                    {
                      template_id: template.guid,
                    },
                  ),
                );
              }}
              actionMenu={
                <ActionMenu>
                  {entityPreview({
                    entity: template,
                    modalProps: {
                      viewComponent: ({
                        toggleModal,
                      }): React.ReactElement => (
                        <ActionComponent
                          type="option"
                          icon="cog"
                          text="Settings"
                          onClick={toggleModal}
                        />
                      ),
                    },
                  })}

                  <EntityActionDelete
                    entity={template}
                    options={{
                      onSuccess: (): void => {
                        if (isActive) {
                          history.push(
                            navigation.get(indexRoute, {
                              id: groupId,
                            }),
                          );
                        }
                      },
                    }}
                  />
                </ActionMenu>
              }
            />
          );
        })}
      {showAction && (
        <EntityActionTemplateCreate
          groupId={groupId}
          entityName={entityName}
          indexRoute={indexRoute}
        />
      )}
    </List>
  );
};

export default withRouter(EntityTemplateList);

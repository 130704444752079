/**
 * Labstep
 *
 * @desc utils for processing errors from failed requests
 */

import {
  BatchError,
  BatchRequest,
  FailedRequest,
  FieldError,
} from './types';

export const getErrors = (
  requests: BatchRequest[],
  failedRequests: FailedRequest[],
): BatchError[] => {
  const result = failedRequests
    .map((failedRequest) => {
      const batchUuid = failedRequest.batchUuid;

      /* Get array of errors from failed request
       e.g.
       errors = [
        {
          property: '[0][Item1_field1]',
          message: 'This field is not expected',
        },
        {
          property: '[0][Item1_field2]',
          message: 'This field is not expected',
        },
        {
          property: '[1][Item2_field1]',
          message: 'This field is not expected',
        },
        ];
        [0] is the index of the item in the batch request
      */

      const errors: {
        property: string;
        message: string;
      }[] = failedRequest.status.error.data.message;

      const matchingRequest = requests.find(
        (request) => request.batchUuid === batchUuid,
      );
      if (!matchingRequest) {
        return null;
      }

      const itemNames: string[] = [];
      const fieldErrors: FieldError[] = [];

      errors.forEach((error) => {
        // Extract item index and field name from error property
        // e.g. '[0][Item1_field]' => ['[0][Item1_field]','0', 'Item1_field']
        const errorPropertyMatch = error.property.match(
          /\[(\d+)\]\[([^\]]+)\]/,
        );

        if (errorPropertyMatch) {
          const itemIndex = parseInt(errorPropertyMatch[1]);
          const field = errorPropertyMatch[2];
          const item = matchingRequest.items[itemIndex];

          if (item) {
            //Get the first key and value of the item object, It is usually the name of the item
            const firstKey = Object.keys(item)[0];
            const itemValue = item[firstKey];

            if (!itemNames.includes(itemValue)) {
              itemNames.push(itemValue);
            }

            fieldErrors.push({
              itemName: itemValue,
              field,
              errorMessage: error.message,
            });
          }
        }
      });

      return {
        id: batchUuid,
        itemNames,
        fieldErrors,
      };
    })
    .filter((record) => record !== null);

  return result;
};

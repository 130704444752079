/**
 * Labstep
 *
 * @module screens/Device/Index
 * @desc Device Index
 */

import GridNewIndexEntityViewLoader from 'labstep-web/grid/NewIndex/components/EntityViewLoader';
import GridNewIndexTemplateLoader from 'labstep-web/grid/NewIndex/components/TemplateLoader';
import { IndexTemplatableEntity } from 'labstep-web/models';
import { Device } from 'labstep-web/models/device.model';
import { EntityView } from 'labstep-web/models/entity-view.model';
import ScreensDeviceIndexMaster from 'labstep-web/screens/Device/Index/Master';
import { LocationService } from 'labstep-web/services/location.service';
import React from 'react';
import { withRouter } from 'react-router';
import { ScreensDeviceIndexProps } from './types';

export const ScreensDeviceIndex: React.FC<
  ScreensDeviceIndexProps
> = ({ group, location }) => (
  <GridNewIndexTemplateLoader
    entityName={Device.entityName}
    templateId={LocationService.getQueryParameter(
      location,
      'template_id',
    )}
  >
    {({ template }: { template?: IndexTemplatableEntity }) => (
      <GridNewIndexEntityViewLoader
        group={group}
        context="device_index"
        entityName={Device.entityName}
        defaultTemplate={template}
      >
        {({ entityView }: { entityView: EntityView }) => (
          <ScreensDeviceIndexMaster
            entityView={entityView}
            group={group}
          />
        )}
      </GridNewIndexEntityViewLoader>
    )}
  </GridNewIndexTemplateLoader>
);

export default withRouter(ScreensDeviceIndex);

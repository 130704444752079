/**
 * Labstep
 *
 * @module grid/Report/components/ToolPanel
 * @desc Entity DataGrid dropdown to set column options
 */

import { Column } from 'ag-grid-community';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import {
  useDataGridContext,
  useGridReportServiceFromContext,
} from 'labstep-web/contexts/grid/hook';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { toggleColumnVisible } from 'labstep-web/core/DataGrid/utils';
import Header from 'labstep-web/core/Header';
import EntityDataGridToolPanelAddColumn from 'labstep-web/grid/Report/components/ToolPanel/AddColumn';
import EntityDataGridToolPanelProtocolValue from 'labstep-web/grid/Report/components/ToolPanel/ProtocolValue';
import EntityDataGridToolPanelSection from 'labstep-web/grid/Report/components/ToolPanel/Section';
import { SectionType } from 'labstep-web/grid/Report/components/ToolPanel/Section/types';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { IEntityDataGridToolPanelProps } from './types';
import {
  filterHiddenColumnDefs,
  filterPinnedLeftColumnDefs,
  filterPinnedRightColumnDefs,
  filterShownAndNotPinnedPositionColumnDefs,
  getColIdArr,
} from './utils';

// eslint-disable-next-line no-shadow
export enum ToolPanelState {
  default = 'default',
  add_column = 'add_column',
  protocol_value = 'protocol_value',
}

export const EntityDataGridToolPanel: React.FC<
  IEntityDataGridToolPanelProps
> = ({ entityView, authenticatedUserEntity }) => {
  const gridReportService = useGridReportServiceFromContext();

  const { agGrid, columns, columnState } = useDataGridContext();
  if (!columns) {
    return null;
  }

  const colIds = gridReportService?.getColumnDefinitionIds();

  const [toolPanelState, setToolPanelState] = useState(
    ToolPanelState.default,
  );

  const [protocolValue, setProtocolValue] =
    useState<ProtocolValue | null>(null);

  /**
   * Columns by type (pinned left, pinned right, shown, hidden)
   */
  const columnsByType: Record<
    'pinnedLeft' | 'pinnedRight' | 'shown' | 'hidden',
    Column[]
  > = useMemo(
    () => ({
      pinnedLeft: filterPinnedLeftColumnDefs(columns, columnState),
      pinnedRight: filterPinnedRightColumnDefs(columns, columnState),
      shown: filterShownAndNotPinnedPositionColumnDefs(
        columns,
        columnState,
      ),
      hidden: filterHiddenColumnDefs(columns, columnState),
    }),
    [columns, columnState],
  );

  const onSortEnd = useCallback(
    (columnsSorted: Column[]) => {
      if (agGrid) {
        const allColumns = [
          ...columnsByType.pinnedLeft,
          ...columnsSorted,
          ...columnsByType.pinnedRight,
          ...columnsByType.hidden,
        ];
        const allColumnsColIdArr = getColIdArr(allColumns);
        agGrid.columnApi.applyColumnState({
          state: allColumnsColIdArr,
          applyOrder: true,
        });
      }
    },
    [agGrid],
  );

  const toggleColumnVisibleByColId = useCallback(
    (colId) => {
      if (agGrid) {
        toggleColumnVisible(agGrid, colId);
      }
    },
    [agGrid],
  );

  return (
    <div className={styles.container}>
      <div className={styles.close}>
        <ActionComponent
          type="icon"
          icon="times"
          onClick={() => agGrid?.api?.setSideBarVisible(false)}
        />
      </div>
      {toolPanelState === ToolPanelState.default && (
        <>
          <Header size="tiny" noMargin>
            Columns
          </Header>
          <EntityDataGridToolPanelSection
            section={SectionType.visible}
            columns={columnsByType.shown}
            onToggleColumnVisible={toggleColumnVisibleByColId}
            onSortColumns={onSortEnd}
          />
          {!entityView.parametersSystemFields.includes(
            'experiment.protocol.collection:guid',
          ) && (
            <ActionComponent
              icon="plus"
              type="text"
              text="Add Column"
              onClick={() =>
                setToolPanelState(ToolPanelState.add_column)
              }
            />
          )}
          {entityView.parametersSystemFields.includes(
            'experiment.protocol.collection:guid',
          ) && (
            <EntityDataGridToolPanelSection
              section={SectionType.hidden}
              columns={columnsByType.hidden}
              onToggleColumnVisible={toggleColumnVisibleByColId}
              onSortColumns={onSortEnd}
            />
          )}
        </>
      )}
      {gridReportService &&
        toolPanelState === ToolPanelState.add_column &&
        colIds && (
          <EntityDataGridToolPanelAddColumn
            authenticatedUserEntity={authenticatedUserEntity}
            colIds={colIds}
            entityView={entityView}
            gridReportService={gridReportService}
            setToolPanelState={setToolPanelState}
            setProtocolValue={setProtocolValue}
          />
        )}
      {gridReportService &&
        toolPanelState === ToolPanelState.protocol_value &&
        colIds &&
        protocolValue && (
          <EntityDataGridToolPanelProtocolValue
            colIds={colIds}
            gridReportService={gridReportService}
            setToolPanelState={setToolPanelState}
            entityView={entityView}
            protocolValue={protocolValue}
          />
        )}
    </div>
  );
};

export default withAuthenticatedUserEntity(EntityDataGridToolPanel);

/**
 * Labstep
 *
 * @module components/Filter/User/Active/Post
 * @desc Active filter component for user
 */

import RemoveLabel from 'labstep-web/components/Filter/User/Active/Label';
import { FilterPostActiveLabelProps } from 'labstep-web/components/FilterPost/Active/Label/types';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { User } from 'labstep-web/models/user.model';
import React from 'react';
import { getAuthorOrAssigneeGuid } from './utils';

const FilterUserActivePost: React.FC<FilterPostActiveLabelProps> = ({
  node,
  onRemoveClick,
}) => {
  const { guid, type } = getAuthorOrAssigneeGuid(node);
  if (!guid) {
    return null;
  }
  return (
    <EntityReadEntityContainer
      entityName={User.entityName}
      guid={guid}
    >
      {({ entity }): React.ReactElement | null =>
        entity ? (
          <RemoveLabel
            user={entity}
            onRemoveClick={onRemoveClick}
            type={type}
          />
        ) : null
      }
    </EntityReadEntityContainer>
  );
};

export default FilterUserActivePost;

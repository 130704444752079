/**
 * Labstep
 */

import { EntityView } from 'labstep-web/models/entity-view.model';
import React, { createContext, useEffect, useState } from 'react';
import { ParamsStateContextType } from './types';

export const ParamsStateContext =
  createContext<ParamsStateContextType>({
    name: undefined,
    destination: undefined,
    entityView: undefined,
    loadedEntityView: undefined,
    setEntityView: undefined,
    globalParams: undefined,
    initialSearchParams: undefined,
  });

export const ParamsStateContextProvider: React.FC<{
  value: ParamsStateContextType;
}> = ({ value, children }) => {
  const [entityView, setEntityView] = useState<EntityView | null>(
    value.entityView || null,
  );

  useEffect(() => {
    if (value.entityView !== entityView) {
      setEntityView(value.entityView || null);
    }
  }, [value.entityView]);

  return (
    <ParamsStateContext.Provider
      value={{
        ...value,
        entityView,
        setEntityView,
      }}
      children={children}
    />
  );
};

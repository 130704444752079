/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/resource-item/status
 * @desc Column Definition for resource item status
 */

import {
  EditableCallbackParams,
  ProcessCellForExportParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import FilterResourceItemStatus from 'labstep-web/components/Filter/ResourceItemStatus';
import { ResourceItemStatusFilterProps } from 'labstep-web/components/Filter/ResourceItemStatus/types';
import DataGrid from 'labstep-web/core/DataGrid';
import { suppressKeyboardEventDisableEnterOnEdit } from 'labstep-web/core/DataGrid/CellEditor/utils';
import TextWithMetadata from 'labstep-web/core/DataGrid/Text/WithMetadata';
import {
  CellEditorParams,
  CellRendererParams,
} from 'labstep-web/core/DataGrid/types';
import { setValue } from 'labstep-web/core/DataGrid/utils';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import {
  OrderRequest,
  OrderRequestStatus,
} from 'labstep-web/models/order-request.model';
import {
  ResourceItem,
  ResourceItemStatus,
  ResourceItemStatusValues,
} from 'labstep-web/models/resource-item.model';
import rules from 'labstep-web/services/validation/rules';
import styles from './styles.module.scss';

export const colDefResourceItemStatus = {
  colId: 'status',
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.options,
    label: 'Status',
    field: 'status',
    entityName: ResourceItem.entityName,
  },
  headerName: 'Availability',
  headerComponentParams: {
    icon: 'info circle',
    filter: (props: ResourceItemStatusFilterProps) => (
      <FilterResourceItemStatus {...props} />
    ),
  },
  editable: (params: EditableCallbackParams) =>
    GridNewIndexService.getEditable(params),
  suppressKeyboardEvent: suppressKeyboardEventDisableEnterOnEdit,
  processCellFromClipboard: (params: ProcessCellForExportParams) => {
    const { value } = params;
    const validStatusValues = Object.keys(ResourceItemStatusValues);

    if (validStatusValues.includes(value)) {
      return value;
    }
    return null;
  },
  valueGetter: (params: ValueGetterParams) => params.data.status,
  valueGetterExport: (params: ValueGetterParams) =>
    String(params.node?.data?.status),
  valueSetter: (params: ValueSetterParams) =>
    setValue(
      'status',
      params.newValue,
      {
        entityName: params.data.entityName,
        id: params.data.id,
      },
      rules.resource_item.status,
    ),
  cellRenderer: (
    params: CellRendererParams<ResourceItem | OrderRequest>,
  ) => {
    if (!params.data) {
      return '';
    }
    return (
      <TextWithMetadata
        text={
          <Flex className={styles.status}>
            <TextWithIcon
              icon="circle"
              iconProps={{ className: styles[params.data.status] }}
              text={
                ResourceItemStatusValues[
                  params.data
                    .status as keyof typeof ResourceItemStatusValues
                ]
              }
            />
            <Icon
              name="sort down"
              onClick={() =>
                params.api.startEditingCell({
                  rowIndex: params.rowIndex,
                  colKey: 'status',
                })
              }
            />
          </Flex>
        }
      />
    );
  },
  cellEditor: DataGrid.CellEditor.Select,
  cellEditorPopup: true,
  cellEditorParams: (
    params: CellEditorParams<ResourceItem | OrderRequest>,
  ) => ({
    props: {
      value: {
        label:
          ResourceItemStatusValues[
            params.data
              .status as keyof typeof ResourceItemStatusValues
          ],
        value: params.data.status,
      },
      options: Object.keys(ResourceItemStatusValues).map(
        (status) => ({
          label:
            ResourceItemStatusValues[
              status as keyof typeof ResourceItemStatusValues
            ],
          value: status,
        }),
      ),
      onChange: (option: {
        value: ResourceItemStatus | OrderRequestStatus;
      }) => {
        setValue('status', option.value, {
          entityName: params.data.entityName,
          id: params.data.id,
        });
        // onChange?.(params.data, option.value);
      },
    },
  }),
};

/**
 * Labstep
 *
 * @module components/ProtocolStep/Card/Detail
 * @desc Name and description of protocol step
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ProtocolStepActionStartEnd from 'labstep-web/components/ProtocolStep/Action/StartEnd';
import ThreadCountIcon from 'labstep-web/components/Thread/CountIcon';
import { ICONS } from 'labstep-web/constants/icons';
import ProtocolChildRouter from 'labstep-web/containers/Router/ProtocolChild';
import ActionComponent from 'labstep-web/core/Action/Component';
import Card from 'labstep-web/core/Card/Card';
import Icon from 'labstep-web/core/Icon';
import Popup from 'labstep-web/core/Popup';
import SubHeader from 'labstep-web/core/SubHeader';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { ICompleteActionProps, IDetailCardProps } from './types';

export const CompleteActionDisabled: React.FC = () => (
  <Popup
    trigger={
      <Icon size="big" color="grey" name={ICONS.protocol.step} />
    }
    content="You can mark steps as complete each time you run the protocol."
    inverted
  />
);

export const CompleteAction: React.FC<ICompleteActionProps> = ({
  protocol,
  protocolStep,
}) => {
  const canEdit = useHasAccess(
    protocolStep.entityName,
    protocolStep.guid,
    Action.edit,
    'ended_at',
  );
  const extraComponentProps = canEdit ? {} : { onClick: undefined };
  let content = 'Incomplete';
  if (protocolStep.ended_at) {
    content = `Completed on ${humanReadableDate(
      protocolStep.ended_at,
    )}`;
  } else if (canEdit) {
    content = 'Mark as complete';
  }

  if (!(protocol instanceof Experiment)) {
    return null;
  }
  return protocol.isTemplate ? (
    <CompleteActionDisabled />
  ) : (
    <ProtocolStepActionStartEnd
      endedAt={protocolStep.ended_at ? null : undefined}
      protocolStep={protocolStep}
      actionComponentProps={{
        type: 'icon',
        disabled: !canEdit,
        icon: ICONS.protocol.step,
        elementProps: {
          size: 'big',
          popup: {
            content,
          },
          color: protocolStep.ended_at ? 'green' : 'yellow',
          noBackground: true,
          ...extraComponentProps,
        },
      }}
    />
  );
};

export const DetailCard: React.FC<IDetailCardProps> = ({
  protocol,
  protocolStepId,
  stepNumber,
  onDelete,
  timestamp,
}) => (
  <Card
    id={`protocol-step-${protocolStepId}`}
    className={styles.card}
  >
    {!!onDelete && (
      <div className={styles.unwrapStep}>
        <ActionComponent
          type="icon"
          icon="times"
          onClick={() => onDelete(protocolStepId)}
          elementProps={{
            popup: { content: 'Unwrap Step' },
          }}
        />
      </div>
    )}
    {protocol instanceof Protocol && (
      <div contentEditable={false} className={styles.completeStep}>
        <CompleteActionDisabled />
      </div>
    )}
    <div
      contentEditable={false}
      data-cy="components/ProtocolStep/Card/Detail:step"
    >
      <SubHeader>{`STEP ${stepNumber}`}</SubHeader>
    </div>

    {protocol instanceof Experiment && (
      <Read
        entityName={ProtocolStep.entityName}
        id={protocolStepId}
        timestamp={timestamp}
        placeholder={<div />}
      >
        {({ entity }) => (
          <>
            {!protocol.isTemplate && entity && (
              <>
                <div
                  contentEditable={false}
                  className={styles.completeStep}
                >
                  <CompleteAction
                    protocol={protocol}
                    protocolStep={entity as ProtocolStep}
                  />
                </div>

                <div className={styles.actions}>
                  <ProtocolChildRouter
                    route="step_show_thread"
                    id={entity.guid}
                  >
                    {({ navigate }) => (
                      <div
                        className="protocol-step-thread"
                        onClick={navigate}
                        contentEditable={false}
                      >
                        <ThreadCountIcon
                          count={
                            (entity as ProtocolStep).thread
                              .comment_count
                          }
                        />
                      </div>
                    )}
                  </ProtocolChildRouter>
                </div>
              </>
            )}
          </>
        )}
      </Read>
    )}
  </Card>
);

export default DetailCard;

/**
 * Labstep
 *
 * @module models/permission-role-setting
 * @desc Typescript export class for PermissionRoleSetting
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { PermissionRole } from 'labstep-web/models/permission-role.model';

// eslint-disable-next-line no-shadow
export enum PermissionRoleSettingType {
  none = 'none',
  all = 'all',
  custom = 'custom',
}

// eslint-disable-next-line no-shadow
export enum PermissionRoleSettingAction {
  all = '*',
  create = 'create',
  edit = 'edit',
  delete = 'delete',
  lock = 'lock',
  unlock = 'unlock',
}

export class PermissionRoleSetting extends Entity {
  public static readonly idAttr = 'guid' as const;

  static readonly entityName = 'permission_role_setting';

  get entityName(): string {
    return PermissionRoleSetting.entityName;
  }

  constructor(data: Partial<PermissionRoleSetting> = {}) {
    super();
    Object.assign(this, data);
  }

  guid!: string;

  type!: PermissionRoleSettingType;

  entity_name!: string;

  action!: string;

  parent_entity_name!: string;

  is_template!: boolean;

  @Type(() => PermissionRole)
  permission_role!: PermissionRole;
}

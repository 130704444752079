/**
 * Labstep
 *
 * @module components/BatchRequest/Progress/Content
 * @desc ProgressContent for batch requests
 */

import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React, { useState, useEffect } from 'react';
import { ErrorTable } from '../ErrorTable';
import styles from './styles.module.scss';
import { IBatchRequestProgressProps } from './types';

export const ProgressContent: React.FC<
  IBatchRequestProgressProps
> = ({
  title = 'Creating items...',
  total,
  successCount,
  requests,
  failedRequests,
  retryRequests,
  cancelRequests,
}) => {
  const progressPercentage = (successCount / total) * 100;

  return (
    <>
      <img
        className={styles.image}
        src="/img/help/steppy.svg"
        alt=""
      />

      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.progressContainer}>
          <div
            data-testid="progressbar"
            className={styles.progressBar}
            style={{
              width: `${progressPercentage}%`,
              background:
                failedRequests.length > 0 ? '#FFC214' : '#55c15c',
            }}
          >
            <span className={styles.progressText}>
              {successCount} of {total}
            </span>
          </div>
        </div>
        {failedRequests.length > 0 && (
          <div
            onMouseDown={(e) => e.stopPropagation()}
            className={styles.failedRequests}
          >
            <ModalDefault
              header="Errors"
              size="small"
              content={({ toggleModal }) => (
                <ErrorTable
                  requests={requests}
                  failedRequests={failedRequests}
                  retryRequests={retryRequests}
                  cancelRequests={cancelRequests}
                  toggleModal={toggleModal}
                />
              )}
              contentStyle={{
                padding: '0',
              }}
              viewComponent={({ toggleModal }) => {
                return (
                  <Button
                    className={styles.errorsCount}
                    icon
                    onClick={toggleModal}
                  >
                    <Icon name="warning sign" />{' '}
                    {failedRequests.length}
                    {failedRequests.length > 1 ? ' Errors' : ' Error'}
                  </Button>
                );
              }}
            />
            <Button
              className={styles.button}
              icon
              onClick={() =>
                retryRequests(
                  failedRequests.map(
                    (failedRequest) => failedRequest.batchUuid,
                  ),
                )
              }
            >
              <Icon name="redo" /> Retry
            </Button>
            <Button
              className={styles.button}
              icon
              onClick={() =>
                cancelRequests(
                  failedRequests.map(
                    (failedRequest) => failedRequest.batchUuid,
                  ),
                )
              }
            >
              <Icon name="ban" /> Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

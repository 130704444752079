/**
 * Labstep
 *
 * @module grid/NewIndex/components/ColumnHeader/ContextMenu
 * @desc Entity DataGrid Context Menu
 */

import FilterPostNewIndexBoolean from 'labstep-web/components/FilterPost/NewIndex/Boolean';
import FilterPostNewIndexDate from 'labstep-web/components/FilterPost/NewIndex/Date';
import FilterPostNewIndexNumeric from 'labstep-web/components/FilterPost/NewIndex/Numeric';
import FilterPostNewIndexOptions from 'labstep-web/components/FilterPost/NewIndex/Options';
import FilterPostNewIndexResourceLocation from 'labstep-web/components/FilterPost/NewIndex/ResourceLocation';
import FilterPostNewIndexText from 'labstep-web/components/FilterPost/NewIndex/Text';
import { useGridNewIndexServiceFromContext } from 'labstep-web/contexts/grid/hook';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import Dropdown from 'labstep-web/core/Dropdown';
import DropdownPortal from 'labstep-web/core/Dropdown/Portal';
import Icon from 'labstep-web/core/Icon';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import {
  dateOnlyAtUTC,
  formatDateOnly,
} from 'labstep-web/services/date.service';
import React from 'react';
import { GridNewIndexColumnHeaderContextMenuProps } from './types';

export const GridNewIndexColumnHeaderContextMenu: React.FC<
  GridNewIndexColumnHeaderContextMenuProps
> = ({ columnApi, column, firstItems, lastItems, trigger }) => {
  const { addPostFilter, setParams } = useParamsContext();
  const gridNewIndexService = useGridNewIndexServiceFromContext();

  const dropdownItems: React.ReactNode[] = firstItems
    ? [firstItems]
    : [];

  const afterDividerItems = [];

  const colDef = column.getColDef() as NewIndexColDef;

  dropdownItems.push(
    <Dropdown.Item
      onClick={(): void => {
        if (colDef.postFilterSettings) {
          setParams({
            sort: colDef.postFilterSettings?.field,
          });
        }
        if (gridNewIndexService) {
          gridNewIndexService.sortColumn(
            trigger.props.column.colId,
            'asc',
          );
        }
      }}
    >
      <Icon name="sort amount up" /> Sort Ascending
    </Dropdown.Item>,
  );
  dropdownItems.push(
    <Dropdown.Item
      onClick={(): void => {
        if (colDef.postFilterSettings) {
          setParams({
            sort: `-${colDef.postFilterSettings?.field}`,
          });
        }
        if (gridNewIndexService) {
          gridNewIndexService.sortColumn(
            trigger.props.column.colId,
            'desc',
          );
        }
      }}
    >
      <Icon name="sort amount down" /> Sort Descending
    </Dropdown.Item>,
  );
  if (
    colDef.postFilterSettings?.fieldType ===
      PostFilterFieldTypes.numeric &&
    colDef.headerName &&
    colDef.postFilterSettings.field
  ) {
    dropdownItems.push(
      <FilterPostNewIndexNumeric
        label={colDef.headerName}
        path={colDef.postFilterSettings.path}
        field={colDef.postFilterSettings.field}
        addPostFilter={addPostFilter}
        defaultValues={{
          values: 0,
        }}
        multiplicator={colDef.postFilterSettings.multiplicator}
      />,
    );
  }
  if (
    colDef.postFilterSettings?.fieldType ===
      PostFilterFieldTypes.text &&
    colDef.headerName &&
    colDef.postFilterSettings.field
  ) {
    dropdownItems.push(
      <FilterPostNewIndexText
        label={colDef.headerName}
        path={colDef.postFilterSettings.path}
        field={colDef.postFilterSettings.field}
        addPostFilter={addPostFilter}
        defaultValues={{
          values: '',
        }}
      />,
    );
  }
  if (
    colDef.postFilterSettings?.fieldType ===
      PostFilterFieldTypes.date &&
    colDef.headerName &&
    colDef.postFilterSettings.field
  ) {
    dropdownItems.push(
      <FilterPostNewIndexDate
        label={colDef.headerName}
        path={colDef.postFilterSettings.path}
        field={colDef.postFilterSettings.field}
        addPostFilter={addPostFilter}
        defaultValues={{
          value: formatDateOnly(dateOnlyAtUTC(new Date())),
        }}
      />,
    );
  }
  if (
    colDef.postFilterSettings?.fieldType ===
      PostFilterFieldTypes.options &&
    colDef.headerName &&
    colDef.postFilterSettings.field
  ) {
    dropdownItems.push(
      <FilterPostNewIndexOptions
        label={colDef.headerName}
        path={colDef.postFilterSettings.path}
        field={colDef.postFilterSettings.field}
        addPostFilter={addPostFilter}
        defaultValues={{
          values: '',
        }}
        entityName={colDef.postFilterSettings.entityName || ''}
      />,
    );
  }

  if (
    colDef.postFilterSettings?.fieldType ===
      PostFilterFieldTypes.boolean &&
    colDef.headerName &&
    colDef.postFilterSettings.field
  ) {
    dropdownItems.push(
      <FilterPostNewIndexBoolean
        label={colDef.headerName}
        path={colDef.postFilterSettings.path}
        field={colDef.postFilterSettings.field}
        addPostFilter={addPostFilter}
      />,
    );
  }

  if (
    colDef.postFilterSettings?.fieldType ===
      PostFilterFieldTypes.custom &&
    colDef.headerName &&
    colDef.postFilterSettings.field &&
    colDef.colId === 'resource_location'
  ) {
    dropdownItems.push(
      <FilterPostNewIndexResourceLocation
        addPostFilter={addPostFilter}
      />,
    );
  }

  if (GridNewIndexService.isHideable(column)) {
    afterDividerItems.push(
      <Dropdown.Item
        onClick={(): void =>
          columnApi.setColumnVisible(column, false)
        }
      >
        <Icon name="eye slash" /> Hide from View
      </Dropdown.Item>,
    );
  }

  if (lastItems) {
    afterDividerItems.push(lastItems);
  }
  if (dropdownItems.length && afterDividerItems.length) {
    dropdownItems.push(<Dropdown.Divider />);
  }
  dropdownItems.push(...afterDividerItems);
  if (colDef.columnOptions) {
    dropdownItems.push(<Dropdown.Divider />);
    dropdownItems.push(colDef.columnOptions);
  }
  return dropdownItems.length ? (
    <DropdownPortal trigger={trigger}>{dropdownItems}</DropdownPortal>
  ) : (
    trigger
  );
};

export default GridNewIndexColumnHeaderContextMenu;

/**
 * Labstep
 *
 * @module components/ProtocolTimer/Modal/Content/Timer
 * @desc Timer component for protocol timer modal
 */

import cx from 'classnames';
import { pad } from 'labstep-web/core/Time';
import React from 'react';
import styles from './styles.module.scss';
import { IProtocolTimerModalContentTimerProps } from './types';

export const ProtocolTimerModalContentTimer: React.FC<
  IProtocolTimerModalContentTimerProps
> = ({
  timeRemaining: { hours, minutes, seconds, negative },
  color,
}) => (
  <div className={styles.timerContainer}>
    <div className={cx(styles.timeContainer, color)}>
      <div
        className={cx(styles.time, 'hours')}
        data-cy="components/ProtocolTimer/Modal/Content/Timer:hours"
      >
        <h1>{`${negative ? '-' : ''}${pad(hours, 2)}`}</h1>
        <div>HOURS</div>
      </div>
      <div className={cx(styles.colon, color)}>:</div>
      <div
        className={cx(styles.time, 'minutes')}
        data-cy="components/ProtocolTimer/Modal/Content/Timer:minutes"
      >
        <h1>{pad(minutes, 2)}</h1>
        <div>MINUTES</div>
      </div>
      <div className={cx(styles.colon, color)}>:</div>
      <div
        className={cx(styles.time, 'seconds')}
        data-cy="components/ProtocolTimer/Modal/Content/Timer:seconds"
      >
        <h1>{pad(seconds, 2)}</h1>
        <div>SECONDS</div>
      </div>
    </div>
  </div>
);

export default ProtocolTimerModalContentTimer;

/**
 * Labstep
 */

import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';

export const EXPERIMENT_AUTHOR_FILTER =
  'experiment.experimentWorkflow.author';

export const PostFilterAuthor = new PostFilter(
  [['guid', PostFilterComparison.eq]],
  'author',
);

export const PostFilterExperimentAuthor = new PostFilter(
  [['guid', PostFilterComparison.eq]],
  EXPERIMENT_AUTHOR_FILTER,
);

/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Delete
 * @desc Action to delete experiment workflow
 */

import DeleteAction from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { ExperimentWorkflowDeleteActionProps } from './types';

export const ExperimentWorkflowDeleteAction: React.FC<
  ExperimentWorkflowDeleteActionProps
> = ({ experimentWorkflow }) => (
  <DeleteAction
    dataCy="components/ExperimentWorkflow/Action/Delete"
    entity={experimentWorkflow}
  />
);

export default ExperimentWorkflowDeleteAction;

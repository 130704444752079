/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Options/Form
 * @desc Filter Metadata form
 */

import ReusableFormGroup from 'labstep-web/core/Form/Reusable/Group';
import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React, { useCallback, useState } from 'react';
import { getOptionsFilterFields } from './fields';
import { IFilterOptionsFormProps } from './types';

export const getPostFilterOptions = (
  field: string,
  comparison: PostFilterComparison,
  path?: string,
) => {
  return new PostFilter([[field, comparison]], path);
};

const FilterOptionsForm: React.FC<IFilterOptionsFormProps> = ({
  addPostFilter,
  onDone,
  path,
  field,
  defaultValues,
  entityName,
}) => {
  const [fields, setFields] = useState(
    getOptionsFilterFields(entityName),
  );

  const onSubmit = useCallback(
    (values): void => {
      const { value } = values.option;
      addPostFilter(
        getPostFilterOptions(
          field,
          PostFilterComparison.eq,
          path,
        ).getNode([value]),
        true,
      );
      onDone();
    },
    [path, addPostFilter],
  );

  return (
    <ReusableFormGroup
      fields={fields}
      onSubmit={onSubmit}
      onDone={onDone}
      addAnother={false}
    />
  );
};

export default FilterOptionsForm;

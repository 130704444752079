/**
 * Labstep
 *
 * @module core/Dropdown/Pill/Trigger
 * @desc Dropdown Pill component
 */

import classnames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { IDropdownPillTriggerProps } from './types';

const DropdownPillTrigger: React.FC<IDropdownPillTriggerProps> = ({
  activeLabel,
  disabled,
  triggerClassName,
  triggerIcon,
  backgroundColor,
}) => (
  <div
    className={classnames(styles.pill, triggerClassName)}
    style={backgroundColor ? { backgroundColor, color: 'white' } : {}}
    data-testid="dropdown-pill-trigger"
    data-cy="core/Dropdown/Pill/Trigger"
  >
    {triggerIcon && <Icon name={triggerIcon} />}
    <span>{activeLabel}</span>
    {!disabled && <Icon name="chevron down" />}
  </div>
);

export default DropdownPillTrigger;

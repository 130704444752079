/**
 * Labstep
 *
 * @module components/Metadata/Form/Edit
 * @desc Form to edit metadata
 */

import {
  preSubmit,
  preSubmitEdit,
} from 'labstep-web/components/Metadata/Form/preSubmit';
import {
  getOptionByType,
  loadDefaultValues,
} from 'labstep-web/components/Metadata/Form/utils';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import EditForm from 'labstep-web/core/Form/Edit';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { FieldDefinition } from 'labstep-web/models/field-definition.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { Resource } from 'labstep-web/models/resource.model';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import React, { useState } from 'react';
import {
  getFieldDefinitionFormFields,
  getMetadataFormFieldsAll,
} from '../fields';
import { MetadataFormEditProps } from './types';

export const MetadataFormEdit: React.FC<MetadataFormEditProps> = ({
  metadata,
  options,
}) => {
  const { activeGroup } = useActiveGroup();
  const fields = getMetadataFormFieldsAll(
    getOptionByType(metadata.type).value,
    metadata.is_template,
    metadata,
  );
  if (metadata.type !== MetadataType.entity_relation) {
    return (
      <EditForm
        autoFocus
        entityName={Metadata.entityName}
        entity={metadata}
        options={options}
        loadDefaultValues={loadDefaultValues}
        preSubmit={preSubmit}
        defaultValues={pick(
          metadata,
          fields.map((field) => field.name),
        )}
        fields={fields}
        confirmationModalMessage={(values: any) => {
          if (
            metadata.type === MetadataType.options &&
            metadata.is_template &&
            !!metadata.options
          ) {
            const intersectionKeys = intersection(
              Object.keys(metadata.options.values),
              values.options_values,
            );
            if (
              intersectionKeys.length !==
              Object.keys(metadata.options.values).length
            ) {
              return 'Changing default options may clear some data from every record using this template.';
            }
          }
          return false;
        }}
      />
    );
  }

  return (
    <ReadOnMountHOC
      type="entities"
      entityName={Resource.entityName}
      loading={{ loader: 'placeholder', cached: true }}
      params={{
        get_single: 1,
        id: metadata.field_definition
          ?.entity_relation_target_entity_filter.template_id,
      }}
      children={({ entity }) => (
        <EntityUpdateContainer
          entityName={Metadata.entityName}
          id={metadata.id}
        >
          {({ update }): React.ReactElement => {
            const [defaultValues, setDefaultValues] = useState<any>({
              ...pick(
                metadata.field_definition,
                fields.map((field) => field.name),
              ),
              data_type: MetadataType.entity_relation,
              entity_relation_target_entity_filter_template: entity,
              group_id: activeGroup!.guid,
            });

            return (
              <EditForm
                autoFocus
                entityName={FieldDefinition.entityName}
                entity={metadata.field_definition}
                onFormChange={(values: any) => {
                  if (
                    JSON.stringify(defaultValues) !==
                    JSON.stringify(values)
                  ) {
                    setDefaultValues(values);
                  }
                }}
                options={{
                  ...options,
                  onSuccess: (result) => {
                    options?.onSuccess?.(result);
                    if (result.response?.entities.field_definition) {
                      const fieldDefinition: any =
                        result.response.entities.field_definition[
                          `${result.response.result}`
                        ];
                      update({
                        label: fieldDefinition!.name,
                      });
                    }
                  },
                }}
                loadDefaultValues={loadDefaultValues}
                preSubmit={preSubmitEdit}
                defaultValues={defaultValues}
                fields={getFieldDefinitionFormFields(
                  getOptionByType(metadata.type).value,
                  metadata.is_template,
                  metadata,
                  defaultValues,
                )}
                confirmationModalMessage={(values: any) => false}
              />
            );
          }}
        </EntityUpdateContainer>
      )}
    />
  );
};

export default MetadataFormEdit;

/**
 * Labstep
 *
 * @module screens/Overview/MessageBoard
 * @desc Overview of a workspace - Message board
 */

import Thread from 'labstep-web/components/Thread/Item';
import { ICONS } from 'labstep-web/constants/icons';
import Card from 'labstep-web/core/Card/Card';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { MessageBoardProps } from './types';

export const MessageBoard: React.FC<MessageBoardProps> = ({
  group,
}) => (
  <Card
    header={
      <>
        <Icon name={ICONS.thread.primary} /> Message Board
      </>
    }
    size="tiny"
  >
    <Thread
      thread={group.thread}
      noCommentsMessage={
        <EmptyState
          src="/img/empty-state/comments.svg"
          title="No comments"
          explanation="Start a discussion with workspace members."
        />
      }
    />
  </Card>
);

export default MessageBoard;

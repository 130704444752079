/**
 * Labstep
 *
 * @module components/Entity/Action/AddSearch
 * @desc Action to add with search select
 */

import { EntityActionAddSearchBulkWithGroup } from 'labstep-web/components/Entity/Action/AddSearch/Bulk';
import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ActionComponent from 'labstep-web/core/Action/Component';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IEntityActionAddSearchProps } from './types';

export const EntityActionAddSearch: React.FC<
  IEntityActionAddSearchProps
> = ({
  parentEntity,
  options,
  entityName,
  actionComponentProps,
  searchSelectProps,
}) => {
  const text = `Add to ${getHumanReadableEntityName(
    entityName,
    false,
    true,
  )}`;

  return (
    <Can
      entityName={parentEntity.entityName}
      id={parentEntity.idAttr}
      action={`${entityName}:${Action.add_remove}` as Action}
    >
      <EntityActionAddSearchBulkWithGroup
        options={options}
        entityName={entityName}
        parentName={parentEntity.entityName}
        header={text}
        parentId={parentEntity.id}
        viewComponent={({ toggleModal }) => (
          <ActionComponent
            dataCy="components/Entity/Action/AddSearch"
            type="option"
            icon="plus"
            text={text}
            onClick={toggleModal}
            {...actionComponentProps}
          />
        )}
        searchSelectProps={searchSelectProps}
      />
    </Can>
  );
};

export default EntityActionAddSearch;

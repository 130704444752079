/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/resource-item/resource
 * @desc Column Definition for resource item resource
 */

import { ValueGetterParams } from 'ag-grid-community';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import MetadataPreview from 'labstep-web/components/Metadata/Preview';
import { ICONS } from 'labstep-web/constants/icons';
import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import TextLink from 'labstep-web/core/DataGrid/Text/Link';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import GridNewIndexActionToggleColumnOption from 'labstep-web/grid/NewIndex/components/ToggleColumnOption';
import { Entity } from 'labstep-web/models/entity.model';
import styles from './styles.module.scss';

export const colDefResourceItemResource = {
  colId: 'resource',
  postFilterSettings: {
    path: 'resource',
    field: 'name',
  },
  headerName: 'Resource',
  headerComponentParams: {
    icon: ICONS.resource.primary,
  },
  columnOptions: (
    <GridNewIndexActionToggleColumnOption
      colId="resource"
      columnOption="showMetadataPreview"
      columnName="Metadata Preview"
    />
  ),
  editable: false,
  autoHeight: true,
  valueGetter: (params: ValueGetterParams): number | null =>
    params.data?.resource?.guid || null,
  valueGetterExport: (params: ValueGetterParams) =>
    params.node?.data?.resource?.name || '',
  cellRenderer: (params: CellRendererParams<Entity>) => {
    const { entityView } = useParamsStateContext();
    if (!entityView) {
      return null;
    }
    if (!params.data?.resource) {
      return '';
    }
    return (
      <div>
        <TextLink
          entity={params.data.resource}
          entityPreviewProps={{
            secondaryInfo: (
              <MetadataManager entity={params.data.resource} />
            ),
          }}
        />
        {entityView.column_options.resource.showMetadataPreview && (
          <div className={styles.showMetadatas}>
            <MetadataPreview entity={params.data.resource} />
          </div>
        )}
      </div>
    );
  },
};

/**
 * Labstep
 *
 * @module screens/JupyterNotebook/Index/
 * @desc JupyterNotebook Index
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import EntitySearch from 'labstep-web/components/Entity/Search';
import { BulkAction } from 'labstep-web/components/Entity/Search/BulkActions/types';
import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { EntityTable } from 'labstep-web/components/Entity/Table';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import ActionMenu from 'labstep-web/core/Action/Menu';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';
import React, { useMemo } from 'react';
import { IJupyterNotebookIndexProps } from './types';

export const JupyterNotebookIndex: React.FC<
  IJupyterNotebookIndexProps
> = ({ group }) => {
  const params = useMemo(
    () => ({
      group_id: group.id,
    }),
    [group.id],
  );

  return (
    <EntitySearch
      action={null}
      bulkActions={[BulkAction.delete]}
      filters={[Filter.deleted, Filter.created_by]}
      entityName={JupyterNotebook.entityName}
      noResultsMessage={
        <EmptyState
          src="/img/empty-state/data-steppy.svg"
          title={`No ${JupyterNotebook.getHumanReadableEntityName(
            true,
            true,
          )}`}
          explanation="No Jupyter Notebooks found."
        />
      }
      params={params}
      tableFormat={false}
    >
      {({
        entities,
        ...rest
      }: EntitySearchContainerChildrenProps) => (
        <EntityTable
          {...rest}
          id="jupyter-table"
          entities={entities}
          actionMenu={({ entity }) => (
            <ActionMenu>
              <EntityActionDelete entity={entity} />
            </ActionMenu>
          )}
        />
      )}
    </EntitySearch>
  );
};

export default JupyterNotebookIndex;

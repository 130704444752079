/**
 * Labstep
 *
 * @module components/ResourceItem/Search/ResourceLocation
 * @desc List of resource items in a resource location
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { Action } from 'labstep-web/components/ResourceItem/Action/Menu/types';
import ResourceItemEmptyState from 'labstep-web/components/ResourceItem/Card/EmptyState';
import ResourceItemList from 'labstep-web/components/ResourceItem/List';
import { Entity } from 'labstep-web/models/entity.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { ResourceItemSearchResourceLocationProps } from './types';

const ResourceItemSearchResourceLocation: React.FC<
  ResourceItemSearchResourceLocationProps
> = ({ resourceLocation }) => (
  <EntitySearch
    entityName={ResourceItem.entityName}
    params={{ resource_location_guid: resourceLocation.guid }}
    initialSearchParams={{
      status: 'available',
    }}
    noResultsMessage={
      <ResourceItemEmptyState explanation="When you select this location for your resource items they will appear here." />
    }
    filters={[Filter.deleted, Filter.resource_item_status]}
    additionalFilters={[
      (entity: Entity) =>
        (entity instanceof ResourceItem &&
          entity.resource_location &&
          resourceLocation &&
          entity.resource_location.guid === resourceLocation.guid) ||
        false,
    ]}
  >
    {({ entities }) => (
      <ResourceItemList
        resourceItems={entities}
        actions={[Action.duplicate, Action.delete]}
      />
    )}
  </EntitySearch>
);

export default ResourceItemSearchResourceLocation;

/**
 * Labstep
 *
 * @module screens/JupyterNotebook/Show/Right
 * @desc JupyterNotebook Show Right
 */

import RightPane from 'labstep-web/components/Layout/RightPane';
import { RightPaneProps } from 'labstep-web/components/Layout/RightPane/types';
import LogList from 'labstep-web/components/Log/List';
import React from 'react';
import { IScreensJupyterNotebookShowRightProps } from './types';

const ScreensJupyterNotebookShowRight: React.FC<
  IScreensJupyterNotebookShowRightProps
> = ({ jupyterNotebook }) => {
  const subPages: RightPaneProps['subPages'] = [
    {
      name: 'Activity',
      icon: 'history',
      route: {
        to: 'jupyter_notebook_show_activity',
        params: { id: jupyterNotebook.id },
      },
      component: (
        <LogList
          entityName={jupyterNotebook.entityName}
          entityId={jupyterNotebook.id}
        />
      ),
    },
  ];

  return (
    <RightPane
      route={{
        to: 'jupyter_notebook_show',
        params: { id: jupyterNotebook.id },
      }}
      subPages={subPages}
    />
  );
};

export default ScreensJupyterNotebookShowRight;

/**
 * Labstep
 */

import { dateOnly } from 'labstep-web/services/date.service';
import { PostFilter } from 'labstep-web/services/postFilter';
import {
  PostFilterComparison,
  PostFilterNodeType,
} from 'labstep-web/services/query-parameter.service';

export const LABELS: Record<string, string> = {
  created_at: 'Creation Date',
  'experiment.experimentWorkflow:created_at':
    'Experiment Creation Date',
  'experiment.experimentWorkflow:started_at': 'Experiment Start Date',
  'experiment.experimentWorkflow:ended_at':
    'Experiment Completion Date',
  'experiment:started_at': 'Protocol Start Date',
  'experiment:ended_at': 'Protocol Completion Date',
};

export class PostFilterDate extends PostFilter<string, string> {
  /**
   * Get the human readable string for a date filter
   * @param node Post filter node
   * @returns Human readable string
   */
  public getHumanReadableString(
    node: PostFilterNodeType,
  ): string | null {
    const attributeValues = this.getAttributeValues(node);

    const field = Object.keys(attributeValues)[0];

    if (node.path) {
      return `${LABELS[`${node.path}:${field}`] || ''}`;
    }

    return LABELS[field] || '';
  }

  /**
   * Get the human readable string for a date filter
   * @param node Post filter node
   * @returns Human readable string
   */
  public getHumanReadableStringWithValue(
    node: PostFilterNodeType,
  ): string | null {
    const { comparison } = Object.values(
      this.getAttributeValues(node),
    )[0];

    if (comparison === PostFilterComparison.not_null) {
      return `Has ${this.getHumanReadableString(node)}`;
    }

    const attributeValues = this.getAttributeValues(node);
    const { value } = Object.values(attributeValues)[0];
    const date = dateOnly(value);

    if (comparison === 'gt') {
      return `${this.getHumanReadableString(node)} after ${date}`;
    }
    if (comparison === 'lt') {
      return `${this.getHumanReadableString(node)} before ${date}`;
    }

    return '';
  }
}

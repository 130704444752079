/**
 * Labstep
 *
 * @module grid/NewIndex
 * @desc DataGrid for NewIndex
 */

import { AgGridReact } from 'ag-grid-react';
import DataGrid from 'labstep-web/core/DataGrid';
import { IDataGridProps } from 'labstep-web/core/DataGrid/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import {
  useBulkSelect,
  useInfiniteScroll,
  useSideBar,
} from 'labstep-web/grid/NewIndex/hooks';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridService } from 'labstep-web/grid/services/grid.service';
import React, { useCallback, useMemo } from 'react';
import { GridNewIndexProps } from './types';

const GridNewIndex: React.FC<GridNewIndexProps> = ({
  entities,
  loadMore,
  status,
  entityView,
}) => {
  // Infinite scroll, enabled by passing a loadMore function
  const infiniteScrollProps = useInfiniteScroll(loadMore);

  // Bulk select on row selection
  const bulkSelectProps = useBulkSelect();

  // Initialise side bar
  useSideBar(entityView);

  // Set column header custom component
  const components = useMemo(
    () => ({
      agColumnHeader: GridNewIndexColumnHeader,
    }),
    [],
  );

  // Get the row id from the idAttr field - Required for AG Grid
  const getRowId = useCallback<
    NonNullable<IDataGridProps['getRowId']>
  >((params) => params.data.idAttr, []);

  return (
    <DataGrid
      key={
        entityView.id || entityView.column_definition_ids?.join('_')
      }
      columnDefs={GridNewIndexService.getColDefs(entityView)}
      getRowId={getRowId}
      rowData={entities}
      status={status}
      components={components}
      {...infiniteScrollProps}
      {...bulkSelectProps}
      onGridReady={(params): void => {
        infiniteScrollProps.onGridReady?.(params);
        const gridService = new GridService(
          params as unknown as AgGridReact,
        );
        gridService.setState(entityView.state);
      }}
    />
  );
};

export default GridNewIndex;

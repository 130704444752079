/**
 * Labstep
 *
 * @module components/FilterPost/Active
 * @desc Active Filter for Post
 */

import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { PostFilterType } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { FilterPostActiveLabel } from './Label';
import FilterPostActiveOperator from './Operator';
import {
  FilterPostActiveProps,
  FilterPostActiveWithHooksProps,
} from './types';

export const FilterPostActive: React.FC<FilterPostActiveProps> = ({
  searchParams,
  setPostFilterType,
  removePostFilter,
  clearAll,
  entityView,
}) => {
  const filter = searchParams?.filter as PostFilterType | undefined;
  if (!filter) {
    return null;
  }

  const labels: React.ReactNode[] = [];

  // We only support one level of filter nesting for now
  // filter[0] is the user-controlled filter
  const { type, predicates } = filter[0];
  predicates.forEach((predicate, i) => {
    labels.push(
      <FilterPostActiveLabel
        // eslint-disable-next-line react/no-array-index-key
        key={`label-${i}`}
        node={predicate}
        onRemoveClick={(): void => removePostFilter(i)}
        index={i}
        entityView={entityView}
      />,
    );
    const isNotLastItem = i !== predicates.length - 1;
    if (isNotLastItem) {
      labels.push(
        <FilterPostActiveOperator
          // eslint-disable-next-line react/no-array-index-key
          key={`operator-${i}`}
          type={type}
          setPostFilterType={setPostFilterType}
        />,
      );
    }
  });

  if (labels.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: '10px',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <span>Filtering by:</span>
      {labels}
      <ActionComponent
        type="text"
        text="Clear all"
        onClick={(): void => {
          clearAll();
        }}
      />
    </div>
  );
};

export const PostFilterActiveWithHooks: React.FC<
  FilterPostActiveWithHooksProps
> = ({ searchParams }) => {
  const { setPostFilterType, removePostFilter, clearAll } =
    useParamsContext();

  const { entityView } = useParamsStateContext();

  if (!entityView) {
    return null;
  }

  return (
    <FilterPostActive
      searchParams={searchParams}
      setPostFilterType={setPostFilterType}
      removePostFilter={removePostFilter}
      clearAll={clearAll}
      entityView={entityView}
    />
  );
};

export default FilterPostActive;

/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/metadata/molecule/pubchem
 * @desc Column Definition for metadata molecule pubchem fields
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import {
  createOrUpdateValue,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { colDefPubchemFieldType } from 'labstep-web/grid/Index/coldefs/Molecule/pubchem/types';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridColumnDefinitionService } from 'labstep-web/grid/services/grid-column-definition.service';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { PubchemService } from 'labstep-web/services/pubchem.service';
import { isValid } from 'labstep-web/services/validation';

export const getMetadataMoleculePubchemColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
  fieldName: string,
  field: colDefPubchemFieldType,
): NewIndexColDef => {
  return {
    colId: `metadata:${parentEntityClass}:${type}:${metadataLabel}:${field.name}`,
    postFilterSettings: {
      path: 'metadatas',
    },
    sortable: true,
    headerName: fieldName,
    headerComponent: GridNewIndexColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.type.molecule,
    },
    editable: (params: EditableCallbackParams) =>
      GridNewIndexService.getEditable(params),
    valueGetter: (
      params: ValueGetterParams,
    ): string | number | null => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata || !metadata.molecule) {
        return '';
      }
      return PubchemService.getPubchemFieldValue(
        metadata.molecule,
        field,
      );
    },
    valueSetter: (params: ValueSetterParams): boolean => {
      if (!params.node) {
        return false;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata || !metadata.molecule) {
        return false;
      }
      const molecule = metadata.molecule;
      setValue(
        'pubchem',
        PubchemService.getPubchemBody(
          molecule,
          field,
          params.newValue,
        ),
        {
          entityName: molecule.entityName,
          id: molecule.idAttr,
        },
        (value) => ({
          value,
          error: !isValid(params.newValue, field.validation),
        }),
      );
      return true;
    },
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return (
          <TextPlaceholder
            onClick={() => {
              createOrUpdateValue({
                entityName: Metadata.entityName,
                body: {
                  type,
                  label: metadataLabel,
                  metadata_thread_id:
                    params.node.data.metadata_thread.guid,
                  molecule: { name: 'Untitled structure' },
                },
                createProps: {
                  parentName:
                    params.node.data.metadata_thread.entityName,
                  parentId: params.node.data.metadata_thread.idAttr,
                },
                options:
                  GridColumnDefinitionService.getOptions(params),
              });
              return true;
            }}
          >
            Create structure
          </TextPlaceholder>
        );
      }
      return PubchemService.getPubchemFieldValue(
        metadata.molecule,
        field,
      );
    },
  };
};

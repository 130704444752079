/**
 * Labstep
 *
 * @module grid/NewIndex/components/ToolPanel/SystemFields
 * @desc List of system fields columns to add to the grid
 */

import { SectionHeader } from 'labstep-web/core/Header';
import { NEW_INDEX_COL_DEFS } from 'labstep-web/grid/NewIndex/coldefs';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import EntityDataGridToolPanelListItem from 'labstep-web/grid/NewIndex/components/ToolPanel/ListItem';
import { GridToolBarService } from 'labstep-web/grid/services/grid-toolbar.service';
import React from 'react';
import { GridNewIndexToolPanelSystemFieldsProps } from './types';

export const GridNewIndexToolPanelSystemFields: React.FC<
  GridNewIndexToolPanelSystemFieldsProps
> = ({ colIds, searchQuery, gridNewIndexService, entityView }) => (
  <>
    {GridToolBarService.showListSection(
      Object.values(NEW_INDEX_COL_DEFS[entityView.entity_name])
        .filter((colDef) => !colDef.pinned)
        .map((colDef) => colDef.headerName || ''),
      searchQuery,
    ) && <SectionHeader label="System fields" />}
    {Object.values(NEW_INDEX_COL_DEFS[entityView.entity_name])
      .filter((colDef) => !colDef.pinned)
      .filter(
        (colDef) =>
          entityView.context !== 'resource_show' ||
          colDef.colId !== 'resource',
      )
      .map((colDef: NewIndexColDef) => {
        if (
          !GridToolBarService.showListItem(
            colDef.headerName || '',
            searchQuery,
          )
        ) {
          return null;
        }
        return (
          <EntityDataGridToolPanelListItem
            icon={colDef.headerComponentParams?.icon || 'database'}
            actionIcon="eye"
            text={colDef.headerName || ''}
            onClick={() => {
              gridNewIndexService.addColumnDef(colDef);
            }}
            disabled={colIds.includes(colDef.colId || '')}
          />
        );
      })}
  </>
);

export default GridNewIndexToolPanelSystemFields;

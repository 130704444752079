/**
 * Labstep
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { Entity } from 'labstep-web/models/entity.model';
import CurrencyService from 'labstep-web/services/currency.service';
import rules from 'labstep-web/services/validation/rules';

export const colDefOrderRequestAmount = {
  colId: 'price',
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.numeric,
    field: 'amount',
    multiplicator: 100,
  },
  headerName: 'Price',
  headerComponentParams: {
    icon: ICONS.order_request.info.price,
  },
  editable: (params: EditableCallbackParams) =>
    GridNewIndexService.getEditable(params),
  valueGetter: (params: ValueGetterParams) => params.data.printAmount,
  valueSetter: (params: ValueSetterParams) => {
    const createOrUpdateProps = {
      entityName: params.data.entityName,
      id: params.data.idAttr,
    };
    let amount: unknown;
    let currency: unknown;
    if (params.newValue === null) {
      amount = null;
      currency = null;
    } else {
      amount = CurrencyService.getAmountFromAmountCurrency(
        params.newValue,
      );
      currency = CurrencyService.getCurrencyFromAmountCurrency(
        params.newValue,
      );
    }
    let amountUpdated = false;
    let currencyUpdated = false;

    if (amount !== undefined || amount === null) {
      amountUpdated = setValue(
        'amount',
        amount,
        createOrUpdateProps,
        rules.order_request.amount,
      );
    }
    if (currency !== undefined || currency === null) {
      currencyUpdated = setValue(
        'currency',
        currency,
        createOrUpdateProps,
        rules.order_request.currency,
      );
    }
    return amountUpdated || currencyUpdated;
  },

  suppressKeyboardEvent: suppressKeyboardEventClearCells,
  valueClearer: () => 0,
  cellRenderer: (params: CellRendererParams<Entity>) => {
    if (!params.data) {
      return '';
    }
    if (params.data.amount) {
      return params.data.printAmount;
    }
    return (
      <DataGridPlaceholder
        params={params}
        editable={getEditable()}
        placeholder="No price"
      />
    );
  },
};

/**
 * Labstep
 *
 * @module containers/Entity/BatchProcessCreate
 * @desc Container for creating entities with batch processing
 */

import { BatchRequestProgress } from 'labstep-web/components/BatchRequest/Progress';
import {
  selectCreateEntityStatus,
  selectCreatedEntity,
} from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import React, { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { v4 } from 'uuid';
import { IBatch, IOwnProps, IUuidContainerProps } from './types';
import { DEFAULT_MAX_BATCH_SIZE, processBatches } from './utils';

export type EntityCreateChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const Container = <P extends EntityCreateChildrenProps>({
  children,
  retry,
  refreshBatchUuids,
  batches,
  batchStatuses,
  title,
  ...rest
}: P & {
  children: (
    childrenProps: Omit<
      P,
      | 'children'
      | 'retry'
      | 'refreshBatchUuids'
      | 'batches'
      | 'batchStatuses'
      | 'title'
    >,
  ) => JSX.Element | null;
}) => {
  const retryRequests = React.useCallback(
    (requestIds: string[]) => {
      const retryBatches = batches.filter((batch) =>
        requestIds.includes(batch.batchUuid),
      );
      retry(retryBatches);
    },
    [batches],
  );

  const cancelRequests = React.useCallback((requestIds: string[]) => {
    refreshBatchUuids(requestIds);
  }, []);

  if (batchStatuses.length > 0) {
    const successfulRequests = batchStatuses.filter(
      (batchStatus) =>
        batchStatus.status !== undefined &&
        !batchStatus.status.isFetching &&
        batchStatus.status.error === null,
    );
    const failedRequests = batchStatuses.filter(
      (batchStatus) =>
        batchStatus.status !== undefined &&
        batchStatus.status.error !== null,
    );

    const totalItems = batches.reduce(
      (count, batch) => count + batch.items.length,
      0,
    );
    const successfulItemsCount = successfulRequests.reduce(
      (count, batchStatus) => {
        const batch = batches.find(
          (b) => b.batchUuid === batchStatus.batchUuid,
        );
        return count + (batch ? batch.items.length : 0);
      },
      0,
    );
    const noErrors = batchStatuses.every(
      (batchStatus) =>
        batchStatus.status !== undefined &&
        !batchStatus.status.isFetching &&
        batchStatus.status.error === null,
    );

    if (successfulItemsCount === totalItems || noErrors) {
      return children(rest);
    }

    return (
      <>
        {children(rest)}
        {createPortal(
          <BatchRequestProgress
            title={title}
            total={totalItems}
            successCount={successfulItemsCount}
            requests={batches}
            failedRequests={failedRequests}
            retryRequests={retryRequests}
            cancelRequests={cancelRequests}
          />,
          document.body,
        )}
      </>
    );
  }
  return children(rest);
};

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: IOwnProps,
) => {
  const batchStatuses = ownProps.batchUuids.map((batchUuid) => ({
    batchUuid,
    status: selectCreateEntityStatus(
      state,
      ownProps.entityName,
      batchUuid,
    ),
  }));

  const entities = ownProps.batchUuids.map((batchUuid) =>
    selectCreatedEntity(state, ownProps.entityName, batchUuid),
  );

  return {
    batchStatuses,
    entities,
    batches: ownProps.batches,
    refreshBatchUuids: ownProps.refreshBatchUuids,
    title: ownProps.title,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: IOwnProps,
) => ({
  create: async (
    body: any,
    options: any = {},
    parentName?: string,
    parentId?: number | string,
  ) => {
    const {
      maxBatchSize = DEFAULT_MAX_BATCH_SIZE,
      setBatchUuids,
      setBatches,
    } = ownProps;

    const enhancedOptions = { ...options, batch: true, toast: false };

    if (!Array.isArray(body) || body.length === 0) {
      return;
    }

    if (options.onSuccess) {
      options.onSuccess();
    }

    // Step 1: Split the body into smaller batches
    const batches: {
      batchUuid: string;
      items: any[];
      parentName?: string;
      parentId?: number | string;
    }[] = [];
    for (let i = 0; i < body.length; i += maxBatchSize) {
      batches.push({
        batchUuid: v4(),
        items: body.slice(i, i + maxBatchSize),
        parentName,
        parentId,
      });
    }
    setBatchUuids(batches.map((batch) => batch.batchUuid));
    setBatches(batches);

    processBatches(dispatch, ownProps, batches, enhancedOptions);
  },
  retry: async (batches: IBatch[]) => {
    const options = { batch: true, toast: false };

    if (!Array.isArray(batches) || batches.length === 0) {
      return;
    }

    processBatches(dispatch, ownProps, batches, options);
  },
});

const ConnectedChildren = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Container);

export const UuidContainer: React.FC<
  IUuidContainerProps & {
    children: (
      childrenProps: Omit<
        EntityCreateChildrenProps,
        | 'batches'
        | 'batchStatuses'
        | 'refreshBatchUuids'
        | 'retry'
        | 'title'
      >,
    ) => JSX.Element | null;
  }
> = ({ ...props }) => {
  const [batchUuids, setBatchUuids] = useState<string[]>([]);
  const [batches, setBatches] = useState<IBatch[]>([]);

  const refreshBatchUuids = useCallback((values: string[]) => {
    // Remove values that already exist in batchUuids
    setBatchUuids((prevBatchUuids) =>
      prevBatchUuids.filter((uuid) => !values.includes(uuid)),
    );
  }, []);

  return (
    <ConnectedChildren
      {...props}
      batchUuids={batchUuids}
      setBatchUuids={setBatchUuids}
      batches={batches}
      setBatches={setBatches}
      refreshBatchUuids={refreshBatchUuids}
    />
  );
};

export const EntityBatchProcessCreateContainer = UuidContainer;

/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/entity/name
 * @desc Column Definition for entity name
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
} from 'ag-grid-community';
import DeviceBookingManager from 'labstep-web/components/DeviceBooking/Manager';
import MetadataManager from 'labstep-web/components/Metadata/Manager';
import MetadataPreview from 'labstep-web/components/Metadata/Preview';
import { ICONS } from 'labstep-web/constants/icons';
import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import TextLink from 'labstep-web/core/DataGrid/Text/Link';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { setValue } from 'labstep-web/core/DataGrid/utils';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexActionToggleColumnOption from 'labstep-web/grid/NewIndex/components/ToggleColumnOption';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { Device } from 'labstep-web/models/device.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import OrderRequestShowCenterSecondaryInfo from 'labstep-web/screens/OrderRequest/Show/Center/SecondaryInfo';
import ResourceItemShowCenterContentSecondaryInfo from 'labstep-web/screens/ResourceItem/Show/Center/SecondaryInfo';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import styles from './styles.module.scss';

export const getColDefEntityName = (
  entityName: string,
  editable?: boolean,
): NewIndexColDef => ({
  colId: 'name',
  width: 340,
  headerName:
    entityName === OrderRequest.entityName
      ? `Request for`
      : getHumanReadableEntityName(entityName, false, true),
  pinned: 'left',
  lockPosition: true,
  headerComponentParams: {
    icon: ICONS[entityName as keyof typeof ICONS]?.primary,
  },
  columnOptions: (
    <GridNewIndexActionToggleColumnOption
      colId="name"
      columnOption="showMetadataPreview"
      columnName="Metadata Preview"
    />
  ),
  autoHeight: true,
  editable: (params: EditableCallbackParams) =>
    editable === false
      ? false
      : GridNewIndexService.getEditable(params),
  valueGetter: (params: ValueGetterParams) => params.data.name,
  valueGetterExport: (params: ValueGetterParams) =>
    params.node?.data?.name ? String(params.node?.data?.name) : '',
  valueSetter: (params: any): boolean => {
    if (editable === false) {
      return false;
    }
    const didUpdate = setValue('name', params.newValue, {
      entityName: params.data.entityName,
      id: params.data.id,
    });
    return didUpdate;
  },
  suppressKeyboardEvent: suppressKeyboardEventClearCells,
  valueClearer: () => '',
  cellRenderer: (params: CellRendererParams<Resource>) => {
    const { entityView } = useParamsStateContext();
    if (!entityView) {
      return null;
    }
    if (!params.data) {
      return '';
    }

    let entityPreviewProps: Record<string, React.ReactNode> = {
      secondaryInfo: <MetadataManager entity={params.data} />,
    };
    if (params.data.entityName === Device.entityName) {
      entityPreviewProps = {
        secondaryInfo: <MetadataManager entity={params.data} />,
        bottom: !params.data.is_template && (
          <DeviceBookingManager device={params.data as Device} />
        ),
      };
    }
    if (params.data.entityName === OrderRequest.entityName) {
      entityPreviewProps = {
        secondaryInfo: (
          <OrderRequestShowCenterSecondaryInfo
            orderRequest={params.data as OrderRequest}
            showStatusLog={false}
          />
        ),
      };
    }
    if (params.data.entityName === ResourceItem.entityName) {
      entityPreviewProps = {
        secondaryInfo: (
          <ResourceItemShowCenterContentSecondaryInfo
            resourceItem={params.data as ResourceItem}
          />
        ),
      };
    }

    return (
      <div>
        <TextLink
          entity={params.data}
          entityPreviewProps={entityPreviewProps}
        />
        {entityView.column_options.name.showMetadataPreview && (
          <div className={styles.showMetadatas}>
            <MetadataPreview
              entity={
                entityName === OrderRequest.entityName
                  ? params.data.resource
                  : params.data
              }
            />
          </div>
        )}
      </div>
    );
  },
});

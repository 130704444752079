/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/resource/available-items
 * @desc Column Definition for resource available_items
 */

import { ValueGetterParams } from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { setValue } from 'labstep-web/core/DataGrid/utils';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { Entity } from 'labstep-web/models/entity.model';
import { number } from 'yup';
export const colDefResourceAvailableItems: NewIndexColDef = {
  colId: 'available_items',
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.numeric,
    field: 'available_resource_item_count',
  },
  headerName: 'Available Items',
  headerComponentParams: {
    icon: ICONS.resource.info.available,
  },
  editable: false,
  valueGetter: (params: ValueGetterParams) =>
    params.data.available_resource_item_count,
  valueGetterExport: (params: ValueGetterParams) =>
    params.node?.data?.available_resource_item_count
      ? String(params.node?.data?.available_resource_item_count)
      : '0',
  valueSetter: (params: any): boolean => {
    const didUpdate = setValue(
      'available_resource_item_count',
      Number(params.newValue),
      {
        entityName: params.data.entityName,
        id: params.data.id,
      },
      number().required(),
    );

    return didUpdate;
  },
  cellRenderer: (params: CellRendererParams<Entity>) => {
    if (!params.data) {
      return '';
    }
    return params.data.available_resource_item_count;
  },
};

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Date
 * @desc Filter to toggle between deleted items
 */

import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import FilterDateForm from './Form';
import { FilterPostNewIndexDateProps } from './types';

const FilterPostNewIndexDate: React.FC<
  FilterPostNewIndexDateProps
> = ({
  label,
  path,
  field,
  addPostFilter,
  defaultValues,
  replace,
  ...rest
}) => (
  <Modal
    header={`Filter by ${label}`}
    viewComponent={({ toggleModal }): React.ReactNode => (
      <ActionComponent
        onClick={toggleModal}
        type="option"
        icon={ICONS.metadata.secondary}
        text="Filter by this field"
      />
    )}
    content={({ toggleModal }): React.ReactNode => (
      <FilterDateForm
        addPostFilter={addPostFilter}
        path={path}
        field={field}
        defaultValues={defaultValues}
        onDone={toggleModal}
        replace={replace}
      />
    )}
    {...rest}
  />
);

export default FilterPostNewIndexDate;

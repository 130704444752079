/**
 * Labstep
 *
 * @module components/OrderRequest/Filter/Pane
 * @desc Filter pane for order request
 */

import FilterAssignedToList from 'labstep-web/components/Filter/AssignedTo/List';
import FilterCreatedByList from 'labstep-web/components/Filter/CreatedBy/List';
import {
  FilterList,
  FilterSection,
} from 'labstep-web/components/Filter/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import FilterTagList from 'labstep-web/components/Filter/Tag/List';
import { getPostFilterBoolean } from 'labstep-web/components/FilterPost/NewIndex/Boolean/Form';
import { getPostFilterOptions } from 'labstep-web/components/FilterPost/NewIndex/Options/Form';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import {
  OrderRequest,
  OrderRequestStatusValues,
} from 'labstep-web/models/order-request.model';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import styles from './styles.module.scss';

const OrderRequestFilterPane: React.FC = () => (
  <FilterPane
    entityName={OrderRequest.entityName}
    text={'Order Requests'}
    icon={ICONS.order_request.primary}
    filterCreatedByText="Requested by"
    filters={[]}
    extraFilters={
      <div>
        <FilterList
          filters={[
            {
              icon: ICONS.order_request.info.urgent,
              text: 'Urgent',
              params: { is_urgent: 'true' },
              postFilterValues: [
                getPostFilterBoolean(
                  'is_urgent',
                  PostFilterComparison.eq,
                ),
                ['1'],
              ],
            },
          ]}
        />
        <FilterSection
          header={'Status'}
          filter={{
            entityName: OrderRequest.entityName,
            name: 'status',
          }}
          filters={Object.keys(OrderRequestStatusValues).map(
            (key) => ({
              component: (
                <Icon
                  className={styles[key]}
                  name={ICONS.order_request.info.status}
                />
              ),
              text: OrderRequestStatusValues[
                key as keyof typeof OrderRequestStatusValues
              ],
              params: { status: key },
              postFilterValues: [
                getPostFilterOptions(
                  'status',
                  PostFilterComparison.eq,
                ),
                [key],
              ],
            }),
          )}
        />
        <FilterCreatedByList
          entityName={OrderRequest.entityName}
          usePostFilter
        />
        <FilterAssignedToList
          entityName={OrderRequest.entityName}
          usePostFilter
        />
        <FilterTagList
          entityName={OrderRequest.entityName}
          usePostFilter
        />
      </div>
    }
  />
);

export default OrderRequestFilterPane;

/**
 * Labstep
 *
 * @module grid/Index
 * @desc Grid Index
 */

import DataGrid from 'labstep-web/core/DataGrid';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import { IDataGridProps } from 'labstep-web/core/DataGrid/types';
import { hasContextMenu } from 'labstep-web/grid/Index/components/ContextMenu/utils';
import GridIndexStatusBar from 'labstep-web/grid/Index/components/StatusBar';
import React, { useCallback, useMemo } from 'react';
import {
  useBulkSelect,
  useInfiniteScroll,
  useReadOrRefreshColumns,
  useRowModelUpdate,
  useSideBar,
} from './hooks';
import styles from './styles.module.scss';
import { GridIndexProps } from './types';

export const GridIndex: React.FC<GridIndexProps> = ({
  columnDefs,
  entities,
  read,
  status,
  defaultColDef: customDefaultColDef = {},
  readOn = [],
  resetColumnsOn,
  loadMore,
  statusBarChildren,
  onGridReady,
  toolPanelParams,
  ...rest
}) => {
  // Read or reset columns on prop change
  useReadOrRefreshColumns(columnDefs, read, readOn, resetColumnsOn);

  // Infinite scroll, enabled by passing a loadMore function
  const infiniteScrollProps = useInfiniteScroll(loadMore);

  // Manage row model updates
  const rowModelUpdateProps = useRowModelUpdate(entities);

  // Bulk select on row selection
  const bulkSelectProps = useBulkSelect();

  // Initialise side bar
  useSideBar(toolPanelParams);

  const statusBar = useMemo(() => {
    if (!statusBarChildren) {
      return undefined;
    }
    return {
      statusPanels: [
        {
          statusPanel: GridIndexStatusBar,
          statusPanelParams: { children: statusBarChildren },
          align: 'left',
        },
      ],
    };
  }, [statusBarChildren]);

  const defaultColDef: IDataGridProps['defaultColDef'] = useMemo(
    () => ({
      // Clear cell value on delete
      valueClearer: null,
      // Delete on backspace and delete
      suppressKeyboardEvent: suppressKeyboardEventClearCells,
      // On hover, highlight the header if it has a context menu
      headerClass: (params) =>
        hasContextMenu(params) ? styles.headerActive : undefined,
    }),
    [],
  );

  /**
   * Get the row id from the idAttr field
   * Required for AG Grid
   */
  const getRowId = useCallback<
    NonNullable<IDataGridProps['getRowId']>
  >((params) => params.data.idAttr, []);

  return (
    <DataGrid
      columnDefs={columnDefs}
      defaultColDef={{ ...defaultColDef, ...customDefaultColDef }}
      getRowId={getRowId}
      rowData={entities}
      status={status}
      statusBar={statusBar}
      {...bulkSelectProps}
      {...infiniteScrollProps}
      {...rowModelUpdateProps}
      onGridReady={(params): void => {
        infiniteScrollProps.onGridReady?.(params);
        onGridReady?.(params);
      }}
      {...rest}
    />
  );
};

export default GridIndex;

/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/metadata/numeric
 * @desc Column Definition for metadata numeric
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import {
  createOrUpdateValue,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import rules from 'labstep-web/services/validation/rules';

export const getMetadataNumericColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
): NewIndexColDef => {
  return {
    colId: `metadata:${parentEntityClass}:${type}:${metadataLabel}`,
    postFilterSettings: {
      path: 'metadatas',
      fieldType: PostFilterFieldTypes.numeric,
      field: 'number',
    },
    sortable: true,
    headerName: metadataLabel,
    headerComponent: GridNewIndexColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.type.numeric,
    },
    editable: (params: EditableCallbackParams) =>
      GridNewIndexService.getEditable(params),
    valueGetter: (params: ValueGetterParams) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return null;
      }
      return metadata.number;
    },
    valueSetter: (params: ValueSetterParams) => {
      if (!params.node) {
        return false;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        createOrUpdateValue({
          entityName: Metadata.entityName,
          body: {
            type,
            label: metadataLabel,
            metadata_thread_id: params.node.data.metadata_thread.guid,
            number: Number(params.newValue),
          },
          createProps: {
            parentName: params.node.data.metadata_thread.entityName,
            parentId: params.node.data.metadata_thread.idAttr,
          },
        });
        return true;
      }
      if (params.newValue === null) {
        setValue(
          'number',
          null,
          {
            entityName: metadata.entityName,
            id: metadata.idAttr,
          },
          rules.metadata.number,
        );
        return true;
      }

      setValue(
        'number',
        Number(params.newValue),
        {
          entityName: metadata.entityName,
          id: metadata.idAttr,
        },
        rules.metadata.number,
      );
      return true;
    },
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const editable = GridNewIndexService.getEditable(params);
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );

      if (metadata?.number !== undefined) {
        return metadata.number;
      }

      return (
        <DataGridPlaceholder
          params={params}
          editable={editable}
          placeholder="Enter"
        />
      );
    },
    suppressKeyboardEvent: suppressKeyboardEventClearCells,
    valueClearer: () => null,
  };
};

/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/metadata/datetime
 * @desc Column Definition for metadata datetime
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { isValid, parse, parseISO } from 'date-fns';
import { ICONS } from 'labstep-web/constants/icons';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import {
  createOrUpdateValue,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import FormShowEditDateToggleComponent from 'labstep-web/core/Form/ShowEdit/Date/ToggleComponent';
import { IFormShowEditDateToggleComponentProps } from 'labstep-web/core/Form/ShowEdit/Date/ToggleComponent/types';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridColumnDefinitionService } from 'labstep-web/grid/services/grid-column-definition.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import {
  formatDateOnly,
  humanReadableDate,
} from 'labstep-web/services/date.service';
import rules from 'labstep-web/services/validation/rules';

export const getMetadataDatetimeColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
): NewIndexColDef => {
  const setMetadataDate = (
    params: ValueSetterParams | CellRendererParams<Entity>,
    newValue: string | null,
  ) => {
    if (!params.node) {
      return;
    }

    const metadata = params.node.data.metadata_thread.metadatas.find(
      (m: Metadata) => m.type === type && m.label === metadataLabel,
    );
    if (!metadata) {
      createOrUpdateValue({
        entityName: Metadata.entityName,
        body: {
          type,
          label: metadataLabel,
          metadata_thread_id: params.node.data.metadata_thread.guid,
          date: newValue,
        },
        createProps: {
          parentName: params.node.data.metadata_thread.entityName,
          parentId: params.node.data.metadata_thread.idAttr,
        },
        options: GridColumnDefinitionService.getOptions(params),
      });
    } else if (newValue === null) {
      setValue(
        'date',
        null,
        {
          entityName: metadata.entityName,
          id: metadata.idAttr,
        },
        rules.metadata.date,
        GridColumnDefinitionService.getOptions(params),
      );
    } else {
      setValue(
        'date',
        newValue,
        {
          entityName: metadata.entityName,
          id: metadata.idAttr,
        },
        rules.metadata.date,
        GridColumnDefinitionService.getOptions(params),
      );
    }
  };

  return {
    colId: `metadata:${parentEntityClass}:${type}:${metadataLabel}`,
    postFilterSettings: {
      path: 'metadatas',
      fieldType: PostFilterFieldTypes.date,
      field: 'date',
    },
    sortable: true,
    headerName: metadataLabel,
    headerComponent: GridNewIndexColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.type.datetime,
    },
    editable: (params: EditableCallbackParams) =>
      GridNewIndexService.getEditable(params),
    valueGetter: (params: ValueGetterParams) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return null;
      }
      return metadata.date;
    },
    valueSetter: (params: ValueSetterParams) => {
      if (!params.node) {
        return false;
      }
      if (params.newValue === null) {
        setMetadataDate(params, null);
        return true;
      }
      const dateISO = parseISO(params.newValue);
      if (isValid(dateISO)) {
        const newValue = formatDateOnly(dateISO);
        setMetadataDate(params, newValue);
        return true;
      }

      const date = parse(
        params.newValue,
        'dd/MM/yyyy HH:mm',
        new Date(),
      );
      if (isValid(date)) {
        const newValue = formatDateOnly(date);
        setMetadataDate(params, newValue);
        return true;
      }

      return false;
    },
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata || !metadata.date) {
        return (
          <DataGridPlaceholder
            params={params}
            editable={GridNewIndexService.getEditable(params)}
            placeholder="Enter"
          />
        );
      }
      return <>{humanReadableDate(metadata.date)}</>;
    },
    cellEditorPopup: true,
    cellEditorPopupPosition: 'under',
    cellEditor: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );

      const onCloseFn: IFormShowEditDateToggleComponentProps['onClose'] =
        (date): void => {
          if (!date || date.length !== 1) {
            return;
          }

          const newValue = formatDateOnly(date[0]);

          setMetadataDate(params, newValue);
        };

      return (
        <FormShowEditDateToggleComponent
          canEdit
          dateTimePickerOnly
          viewComponent={<TextPlaceholder></TextPlaceholder>}
          defaultDate={metadata?.date || null}
          onClose={onCloseFn}
        />
      );
    },
    suppressKeyboardEvent: suppressKeyboardEventClearCells,
    valueClearer: () => null,
  };
};

/**
 * Labstep
 *
 * @module components/ResourceItem/Search/OrderRequest
 * @desc List of resource items in an order request
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import OrderRequestEmptyStateResourceItem from 'labstep-web/components/OrderRequest/Card/EmptyState/ResourceItem';
import { Action } from 'labstep-web/components/ResourceItem/Action/Menu/types';
import ResourceItemList from 'labstep-web/components/ResourceItem/List';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { ResourceItemSearchOrderRequestProps } from './types';

const ResourceItemSearchOrderRequest: React.FC<
  ResourceItemSearchOrderRequestProps
> = ({ orderRequest }) => (
  <EntitySearch
    entityName={ResourceItem.entityName}
    filters={[Filter.deleted, Filter.resource_item_status]}
    params={{ order_request_id: orderRequest.id }}
    noResultsMessage={
      <OrderRequestEmptyStateResourceItem
        orderRequest={orderRequest}
      />
    }
  >
    {({ entities }) => (
      <ResourceItemList
        resourceItems={entities}
        actions={[Action.duplicate, Action.delete]}
      />
    )}
  </EntitySearch>
);

export default ResourceItemSearchOrderRequest;

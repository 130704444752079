/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Text/Active/Post
 * @desc Active node component for date
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/FilterPost/Active/Label/types';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import FilterPostNewIndexText from '../..';
import { getPostFilterText } from '../../Form';
import { COMPARISON_OPTIONS } from '../../Form/fields';

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadable {
  lt = 'Before',
  gt = 'After',
  not_null = 'Has value',
}

const FilterTextActivePost: React.FC<FilterPostActiveLabelProps> = ({
  node,
  onRemoveClick,
  index,
}) => {
  const TEXT_FILTERS = [
    getPostFilterText('name', PostFilterComparison.eq),
    getPostFilterText('value', PostFilterComparison.eq),
  ];
  const { addPostFilter } = useParamsContext();
  const filterActive = TEXT_FILTERS.find((filter) =>
    filter.isNodeActive(node),
  );
  if (!filterActive) {
    return null;
  }

  const path = filterActive.getPath() || '';
  const attributes = filterActive.getAttributeValues(node);

  const label = 'name';
  const field = Object.keys(attributes)[0];
  const { comparison } = Object.values(attributes)[0];
  const value =
    comparison === PostFilterComparison.not_null
      ? ''
      : Object.values(attributes)[0].value;

  const defaultValues = {
    comparison: COMPARISON_OPTIONS.find(
      (option) => option.value === comparison,
    ),
    value,
  };

  const postFilterSettings = {
    label,
    path,
    field,
  };
  const name = value === '' ? 'Name not null' : value;
  return (
    <FilterPostNewIndexText
      label={label}
      path={postFilterSettings.path}
      field={postFilterSettings.field}
      addPostFilter={addPostFilter}
      defaultValues={defaultValues}
      viewComponent={({ toggleModal }) => (
        <RemoveLabel
          name={name}
          onNameClick={toggleModal}
          onRemoveClick={onRemoveClick}
        />
      )}
      replace={index}
    />
  );
};

export default FilterTextActivePost;

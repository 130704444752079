/**
 * Labstep
 *
 * @module components/Profile/Picture/Image/Initials
 * @desc Image for profile picture
 */

import cx from 'classnames';
import initials from 'initials';
import { Theme } from 'labstep-web/core/Initials/types';
import React from 'react';
import styles from './styles.module.scss';
import { IInitialsProps } from './types';

const themes: Theme[] = [
  Theme.theme1,
  Theme.theme2,
  Theme.theme3,
  Theme.theme4,
  Theme.theme5,
  Theme.theme6,
  Theme.theme7,
  Theme.theme8,
];

const getInitials = (name: string): string => {
  if (typeof name !== 'string') {
    return '';
  }
  const userInitials = initials(name) as string;
  return userInitials.substring(0, 2).toUpperCase();
};

export const getTheme = (id?: number): string => {
  return id ? themes[id % themes.length] : themes[0];
};

export const Initials: React.FC<IInitialsProps> = ({
  name,
  size = 'tiny',
  themeId,
  centered,
  circular = true,
}) => {
  const theme = getTheme(themeId);

  const classnames = cx(
    styles.initials,
    styles[theme],
    { [styles.centered]: centered },
    { [styles.square]: !circular },
    styles[size],
  );

  let content = getInitials(name);

  if (!circular) {
    content = content.replace(/\w\b/g, (char) => char.toLowerCase());
  }

  return (
    <div
      data-testid="initials"
      data-cy={`core/Initials:${name}`}
      className={classnames}
    >
      <span>{content}</span>
    </div>
  );
};

export default Initials;

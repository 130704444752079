/**
 * Labstep
 *
 * @module screens/Resource/Index/Master
 * @desc Resource Index Master
 */

import EntityActionCreateMenu from 'labstep-web/components/Entity/Action/Create/Menu';
import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import { ResourceEmptyState } from 'labstep-web/components/Resource/Card/EmptyState';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { ParamsStateContextType } from 'labstep-web/contexts/params-state/types';
import GridNewIndex from 'labstep-web/grid/NewIndex';
import {
  getSystemFilterTemplateGuid,
  systemFilterNoTemplate,
} from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridFilterService } from 'labstep-web/grid/services/grid-filter.service';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { Resource } from 'labstep-web/models/resource.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import React, { useCallback, useMemo } from 'react';
import ResourceIndexBreadcrumb from '../Breadcrumb';
import ResourceIndexLeftPane from '../LeftPane';
import { bulkActions, filters, sortOptions } from './constants';
import { ScreensResourceIndexMasterProps } from './types';

export const ScreensResourceIndexMaster: React.FC<
  ScreensResourceIndexMasterProps
> = ({ entityView, group, links }) => {
  const breadcrumb = useMemo(() => <ResourceIndexBreadcrumb />, []);
  const leftPane = useMemo(
    () => <ResourceIndexLeftPane links={links} />,
    [links],
  );
  const params = useMemo(
    () => ({ group_id: group.id, skip_total: 1, is_template: 0 }),
    [group.id],
  );
  const noResultsMessage = useCallback(
    () => (
      <ResourceEmptyState
        resourceTemplateId={entityView.templateId}
      />
    ),
    [entityView.templateId],
  );
  const action = useCallback(
    () => (
      <EntityActionCreateMenu
        entityName={Resource.entityName}
        extraOptions={
          <EntityImportModal
            targetEntityName={Resource.entityName}
            templateId={
              entityView.templateId &&
              entityView.templateId !== 'none'
                ? entityView.templateId
                : undefined
            }
          />
        }
        templateId={
          entityView.templateId && entityView.templateId !== 'none'
            ? entityView.templateId
            : undefined
        }
      />
    ),
    [entityView.templateId],
  );

  const globalParams: Record<
    string,
    string | number | Record<string, unknown>[]
  > = {
    group_id: group.id,
  };

  const paramsStateContext: ParamsStateContextType = {
    name: 'resource_index',
    globalParams,
    entityView,
    loadedEntityView: new EntityView({
      ...entityView,
    }),
  };
  if (entityView.templateId === 'none') {
    paramsStateContext.initialSearchParams =
      GridFilterService.injectSystemFilter(
        systemFilterNoTemplate,
        entityView.parameters || {},
      );
  } else if (entityView.templateId) {
    paramsStateContext.initialSearchParams =
      GridFilterService.injectSystemFilter(
        getSystemFilterTemplateGuid(entityView.templateId),
        entityView.parameters || {},
      );
  }
  if (paramsStateContext.loadedEntityView) {
    paramsStateContext.loadedEntityView.parameters =
      paramsStateContext.initialSearchParams;
  }

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <MasterIndex
        entityName={Resource.entityName}
        breadcrumb={breadcrumb}
        leftPane={leftPane}
        filters={filters}
        params={params}
        noResultsMessage={noResultsMessage}
        action={action}
        bulkActions={bulkActions}
        sortOptions={sortOptions}
        useAllPages
        usePostFilter
        tableFormat={false}
      >
        {({
          entities,
          readNextPage,
          status,
        }: EntitySearchContainerChildrenProps) => (
          <GridNewIndex
            entityName={Resource.entityName}
            group={group}
            entities={entities}
            entityView={entityView}
            status={status}
            loadMore={readNextPage}
          />
        )}
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

export default ScreensResourceIndexMaster;

/**
 * Labstep
 *
 * @module grid/NewIndex/components/TemplateLoader
 * @desc Load a template if any
 */

import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import React from 'react';
import { withRouter } from 'react-router';
import { GridNewIndexTemplateLoaderProps } from './types';

const GridNewIndexTemplateLoader: React.FC<
  GridNewIndexTemplateLoaderProps
> = ({ entityName, templateId, children, ...rest }) => {
  if (!templateId || !entityName) {
    return children({
      template: undefined,
      ...rest,
    });
  }
  return (
    <ReadOnMountHOC
      key={templateId}
      type="entities"
      entityName={entityName}
      params={{
        get_single: 1,
        id: templateId,
      }}
      loading={false}
      children={({ entity }) => {
        return children({
          template: entity,
          ...rest,
        });
      }}
    />
  );
};

export default withRouter(GridNewIndexTemplateLoader);

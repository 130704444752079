/**
 * Labstep
 *
 * @module models/entity-state
 * @desc Typescript export class for EntityState
 */

import { Type } from 'class-transformer';
import { Automation } from 'labstep-web/models/automation.model';
import { EntityState } from 'labstep-web/models/entity-state.model';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { SignatureRequirement } from 'labstep-web/models/signature-requirement.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export class EntityUserRoleRequirement extends Entity {
  public static readonly entityName = 'entity_user_role_requirement';

  public static readonly entityClass =
    'entity_user_role_requirement' as const;

  public get entityName(): string {
    return EntityUserRoleRequirement.entityName;
  }

  public constructor(data: Partial<EntityUserRoleRequirement> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: string;

  public number_required!: number;

  public entity_state_id!: number;

  public entity_user_role_id!: number;

  public signature_requirement_id!: number;

  public owner_id!: number;

  public author_id!: number;

  @Type(() => EntityState)
  public entity_state!: EntityState;

  @Type(() => EntityUserRole)
  public entity_user_role!: EntityUserRole;

  @Type(() => Automation)
  public automation!: Automation;

  @Type(() => Group)
  public owner!: Group;

  @Type(() => User)
  public author!: User;

  @Type(() => SignatureRequirement)
  public _signature_requirement!: SignatureRequirement | null;

  public get signature_requirement(): SignatureRequirement | null {
    if (this._signature_requirement?.deleted_at) {
      return null;
    }
    return this._signature_requirement;
  }

  public set signature_requirement(value: SignatureRequirement | null) {
    this._signature_requirement = value;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}

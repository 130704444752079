/**
 * Labstep
 *
 * @module components/EntitySnapshot/EntitySnapshotLogLegacyList
 * @desc List of legacy logs
 */

import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { EntitySnapshot } from 'labstep-web/models/entity-snapshot.model';
import { Log } from 'labstep-web/models/log.model';
import React from 'react';
import { v4 } from 'uuid';
import styles from './styles.module.scss';
import {
  EntitySnapshotLogLegacyListContainerProps,
  EntitySnapshotLogLegacyListProps,
} from './types';

export const EntitySnapshotLogLegacyList: React.FC<
  EntitySnapshotLogLegacyListProps
> = ({ logs, setSelectedEntitySnapshot }) => {
  if (logs.length === 0) {
    return null;
  }

  return (
    <div className={styles.list}>
      {logs.map((log: Log) => {
        return (
          <div
            onClick={() => {
              const tempEntitySnapshot = new EntitySnapshot({
                id: v4(),
                created_at: log.ended_at
                  ? log.ended_at
                  : log.created_at,
                object_id: log.object_id,
                object_class: EntitySnapshot.entityNameToObjectClass(
                  log.objectClassToEntityName,
                ),
              });
              setSelectedEntitySnapshot(tempEntitySnapshot);
            }}
          >
            {log.created_at}
          </div>
        );
      })}
    </div>
  );
};

export const EntitySnapshotLogLegacyListContainer: React.FC<
  EntitySnapshotLogLegacyListContainerProps
> = ({ log, extraParams, count = 10, ...rest }) => (
  <ReadOnMountHOC
    type="cursor"
    pagination={{
      infinite: false,
    }}
    entityName={Log.entityName}
    params={{
      [`${log.objectClassToEntityName}_id`]:
        log.log_entry_object_id || log.object_id,
      log_entry_object_class:
        log.log_entry_object_class || log.objectClassToEntityName,
      count,
      has_request_trace: false,
      ...extraParams,
    }}
    children={({ entities }) => (
      <EntitySnapshotLogLegacyList logs={entities} {...rest} />
    )}
  />
);

export default EntitySnapshotLogLegacyListContainer;

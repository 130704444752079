/**
 * Labstep
 *
 * @module grid/NewIndex/components/ToolPanel/AddColumn/Device
 * @desc Lists for Device
 */

import EntityTemplateSectionItem from 'labstep-web/components/Entity/Template/SectionItem';
import MetadataActionCreateModalDropdown from 'labstep-web/components/Metadata/Action/Create/Modal/Dropdown';
import { ICONS } from 'labstep-web/constants/icons';
import { SectionHeader } from 'labstep-web/core/Header';
import EntityDataGridToolPanelListItem from 'labstep-web/grid/NewIndex/components/ToolPanel/ListItem';
import { IndexTemplatableEntity } from 'labstep-web/models';
import { Device } from 'labstep-web/models/device.model';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { Metadata } from 'labstep-web/models/metadata';
import {
  MetadataParent,
  MetadataType,
} from 'labstep-web/models/metadata/types';

export const getDeviceLists = (
  entityView: EntityView,
  selectMetadata: (
    metadata: Metadata,
    parentEntityClass: string,
  ) => void,
  colIds: string[],
  template?: IndexTemplatableEntity,
) => [
  {
    key: Metadata.entityName,
    entityName: Metadata.entityName,
    searchField: 'label_like',
    params: template
      ? {
          metadata_thread_id: template.metadata_thread.id,
          count: 3,
        }
      : {
          permission_class: Device.entityName,
          group_id: entityView.group.id,
          group_by_label: 1,
          sort: 'label',
          count: 3,
        },
    header: (
      <SectionHeader label="Metadata Fields">
        {template && (
          <EntityTemplateSectionItem template={template} />
        )}
      </SectionHeader>
    ),
    listItem: (entity: object) => (
      <EntityDataGridToolPanelListItem
        icon={
          ICONS.metadata.type[
            (entity as Metadata)
              .type as keyof typeof ICONS.metadata.type
          ] || 'database'
        }
        actionIcon={
          (entity as Metadata).type === MetadataType.molecule
            ? 'arrow right'
            : 'eye'
        }
        text={(entity as Metadata).label || 'Untitled data field'}
        onClick={() =>
          selectMetadata(entity as Metadata, Device.entityName)
        }
        disabled={colIds.includes((entity as Metadata).colId)}
      />
    ),
    bottomAction: template ? (
      <MetadataActionCreateModalDropdown
        isTemplate={true}
        entity={template as MetadataParent}
      />
    ) : undefined,
  },
];

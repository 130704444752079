/**
 * Labstep
 *
 * @module screens/ResourceItem/Index
 * @desc ResourceItem Index
 */

import GridNewIndexEntityViewLoader from 'labstep-web/grid/NewIndex/components/EntityViewLoader';
import GridNewIndexTemplateLoader from 'labstep-web/grid/NewIndex/components/TemplateLoader';
import { IndexTemplatableEntity } from 'labstep-web/models';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import ScreensResourceItemIndexMaster from 'labstep-web/screens/ResourceItem/Index/Master';
import { LocationService } from 'labstep-web/services/location.service';
import React from 'react';
import { withRouter } from 'react-router';
import { ScreensResourceItemIndexProps } from './types';

export const ScreensResourceItemIndex: React.FC<
  ScreensResourceItemIndexProps
> = ({ group, links, location }) => (
  <GridNewIndexTemplateLoader
    entityName={Resource.entityName}
    templateId={LocationService.getQueryParameter(
      location,
      'template_id',
    )}
  >
    {({ template }: { template?: IndexTemplatableEntity }) => (
      <GridNewIndexEntityViewLoader
        group={group}
        context="resource_item_index"
        entityName={ResourceItem.entityName}
        defaultTemplate={template}
      >
        {({ entityView }: { entityView: EntityView }) => (
          <ScreensResourceItemIndexMaster
            entityView={entityView}
            resourceTemplate={template as Resource}
            group={group}
            links={links}
          />
        )}
      </GridNewIndexEntityViewLoader>
    )}
  </GridNewIndexTemplateLoader>
);

export default withRouter(ScreensResourceItemIndex);

/**
 * Labstep
 *
 * @module containers/Entity/Create
 * @desc Container for creating entities
 */

import LoadingLoadingEntityCreate from 'labstep-web/components/Layout/LoadingEntityCreate';
import { UserGroup } from 'labstep-web/models/user-group.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { createEntity } from 'labstep-web/state/actions/entity';
import {
  selectCreateEntityStatus,
  selectCreatedEntity,
} from 'labstep-web/state/selectors/entity';
import { LabstepReduxState } from 'labstep-web/state/types';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { v4 } from 'uuid';
import { IOwnProps, IUuidContainerProps } from './types';

const Container = <P extends EntityCreateChildrenProps>({
  children,
  loadingProps,
  ...rest
}: P & {
  children: (
    childrenProps: Omit<P, 'children' | 'loadingProps'>,
  ) => JSX.Element | null;
}) => {
  if (
    rest.status?.isFetching &&
    loadingProps.showLoaderWhileCreating
  ) {
    return (
      <>
        {createPortal(
          <LoadingLoadingEntityCreate
            defaultFetching={rest.status?.isFetching ?? false}
            status={rest.status ?? { isFetching: false }}
            message={
              (rest as any).entityName === UserGroup.entityName
                ? `Joining ${loadingProps.entityName}...`
                : `Creating ${loadingProps.entityName}...`
            }
          >
            <div />
          </LoadingLoadingEntityCreate>,
          document.body,
        )}
      </>
    );
  }
  return children(rest);
};

export type EntityCreateChildrenProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (
  state: LabstepReduxState,
  ownProps: IOwnProps,
) => {
  const status = selectCreateEntityStatus(
    state,
    ownProps.entityName,
    ownProps.uuid,
  );

  const entity = selectCreatedEntity(
    state,
    ownProps.entityName,
    ownProps.uuid,
  );

  const loadingProps = {
    entityName: getHumanReadableEntityName(
      ownProps.entityName,
      false,
      false,
      ownProps.isTemplate,
    ),
    showLoaderWhileCreating: ownProps.showLoaderWhileCreating,
  };

  return {
    status,
    entity,
    loadingProps,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: IOwnProps,
) => ({
  /**
   * Fucntion to create entity
   * @param  {object} body - Form data
   * @param  {Object} options
   */
  create(
    body: any,
    options: any = {},
    parentName?: string,
    parentId?: number | string,
  ): void {
    let enhancedOptions = {
      ...options,
      onSuccess: (...args: Parameters<typeof options.onSuccess>) => {
        ownProps.refreshUuid();
        if (options.onSuccess) {
          options.onSuccess(...args);
        }
      },
    };
    if (ownProps.batch) {
      enhancedOptions = { ...enhancedOptions, batch: true };
    }

    dispatch(
      createEntity(
        ownProps.entityName,
        body,
        parentName || ownProps.parentName,
        parentId || ownProps.parentId,
        ownProps.uuid,
        enhancedOptions,
        ownProps.childKeyName,
      ),
    );
  },
});

const ConnectedChildren = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Container);

interface IState {
  uuid: string;
}

/**
 * Generate uuid
 */
export class UuidContainer extends React.Component<
  IUuidContainerProps & {
    children: (
      childrenProps: EntityCreateChildrenProps & {
        refreshUuid: VoidFunction;
        uuid: string;
      },
    ) => JSX.Element | null;
  },
  IState
> {
  constructor(
    props: IUuidContainerProps & {
      children: (
        childrenProps: EntityCreateChildrenProps & {
          refreshUuid: VoidFunction;
          uuid: string;
        },
      ) => JSX.Element | null;
    },
  ) {
    super(props);
    this.state = {
      uuid: v4(),
    };
    this.refreshUuid = this.refreshUuid.bind(this);
  }

  refreshUuid() {
    this.setState({ uuid: v4() });
  }

  render() {
    const uuid = this.props.uuid || this.state.uuid;

    return (
      <ConnectedChildren
        {...this.props}
        refreshUuid={this.refreshUuid}
        uuid={uuid}
      />
    );
  }
}

export const EntityCreateContainer = UuidContainer;

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Text/Active/Post
 * @desc Active node component for date
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/FilterPost/Active/Label/types';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import FilterPostNewIndexOptions from '../..';
import { getPostFilterOptions } from '../../Form';

const FilterOptionsActivePost: React.FC<
  FilterPostActiveLabelProps
> = ({ node, onRemoveClick, entityView }) => {
  const TEXT_FILTERS = [
    getPostFilterOptions('availability', PostFilterComparison.eq),
    getPostFilterOptions('status', PostFilterComparison.eq),
  ];
  const { addPostFilter } = useParamsContext();
  const filterActive = TEXT_FILTERS.find((filter) =>
    filter.isNodeActive(node),
  );
  if (!filterActive) {
    return null;
  }

  const path = filterActive.getPath() || '';
  const attributes = filterActive.getAttributeValues(node);
  const label = node.predicates[0].attribute;
  const field = Object.keys(attributes)[0];
  const value = node.predicates[0].value;

  const postFilterSettings = {
    label,
    path,
    field,
  };

  return (
    <FilterPostNewIndexOptions
      label={label}
      path={postFilterSettings.path}
      field={postFilterSettings.field}
      addPostFilter={addPostFilter}
      viewComponent={({ toggleModal }) => (
        <RemoveLabel
          name={`${label} = ${value}`}
          onNameClick={toggleModal}
          onRemoveClick={onRemoveClick}
        />
      )}
      entityName={entityView ? entityView.entity_name : ''}
    />
  );
};

export default FilterOptionsActivePost;

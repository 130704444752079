/**
 * Labstep
 */

import { ICONS } from 'labstep-web/constants/icons';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { setValue } from 'labstep-web/core/DataGrid/utils';
import { Entity } from 'labstep-web/models/entity.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import {
  validateNumeric,
  validateUnit,
} from 'labstep-web/services/amount-unit.service';
import { isValid } from 'labstep-web/services/validation';
import rules from 'labstep-web/services/validation/rules';
import { colDefAmountParametersType } from './types';

const RULE_AMOUNT = rules.protocol_value.amount;
const RULE_UNIT = rules.protocol_value.unit;

export const getHeaderParams = (
  isInput: boolean,
): Pick<
  ColDef<ProtocolValue>,
  'headerName' | 'headerComponentParams'
> => ({
  headerName: isInput ? 'Amount Used' : 'Initial Amount',
  headerComponentParams: {
    icon: isInput
      ? ICONS.protocol_value.info.amount_used
      : ICONS.protocol_value.info.amount_created,
  },
});

export const getValueSetter =
  (
    getEntity: colDefAmountParametersType<Entity>['getNestedEntity'],
    updateUnit?: boolean,
  ) =>
  (params: any): boolean => {
    const entity = getEntity?.(params);
    if (!entity) {
      return false;
    }
    const createOrUpdateProps = {
      entityName: entity.entityName,
      id: entity.idAttr,
    };
    let didUpdate = setValue(
      'amount',
      params.newValue,
      createOrUpdateProps,
      (newValue) => {
        const value = validateNumeric(newValue);
        return {
          value,
          error: value === false || !isValid(value, RULE_AMOUNT),
        };
      },
    );
    if (updateUnit) {
      didUpdate =
        setValue(
          'unit',
          params.newValue,
          createOrUpdateProps,
          (newValue) => {
            const unit = validateUnit(newValue);
            return {
              value: unit,
              error: unit === false || !isValid(unit, RULE_UNIT),
            };
          },
        ) || didUpdate;
    }
    return didUpdate;
  };

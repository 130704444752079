/**
 * Labstep
 *
 * @module screens/Overview/LogBoard
 * @desc Overview of a workspace - Log board
 */

import LogList from 'labstep-web/components/Log/List';
import Card from 'labstep-web/core/Card/Card';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { LogBoardProps } from './types';

export const LogBoard: React.FC<LogBoardProps> = ({ group }) => (
  <Card
    header={
      <>
        <Icon name="history" /> Activity Log
      </>
    }
    size="tiny"
  >
    <LogList
      entityName={group.entityName}
      entityId={group.id}
      count={5}
    />
  </Card>
);

export default LogBoard;

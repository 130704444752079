/**
 * Labstep
 *
 * @module components/ProtocolCondition/Explorer
 * @desc Data Explorer
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { ProtocolConditionExplorerEmptyState } from 'labstep-web/components/ProtocolCondition/Explorer/EmptyState';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { ParamsStateContextType } from 'labstep-web/contexts/params-state/types';
import GridReport from 'labstep-web/grid/Report';
import {
  getProtocolValueFieldColumnDef,
  getProtocolValueResourceItemNameColumnDef,
  getProtocolValueResourceLocationPathColumnDef,
  getProtocolValueResourceNameColumnDef,
} from 'labstep-web/grid/Report/coldefs';
import { ReportingColDef } from 'labstep-web/grid/Report/coldefs/types';
import {
  GridReportService,
  SYSTEM_FILTER_EXPERIMENT_WORKFLOW_IS_TEMPLATE_FALSE,
} from 'labstep-web/grid/Report/services/grid-report.service';
import { GridFilterService } from 'labstep-web/grid/services/grid-filter.service';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import {
  PostFilterComparison,
  PostFilterOperator,
} from 'labstep-web/services/query-parameter.service';
import React, { useEffect } from 'react';
import {
  ProtocolConditionExplorerContainerProps,
  ProtocolConditionExplorerProps,
} from './types';

export const ProtocolConditionExplorer: React.FC<
  ProtocolConditionExplorerProps
> = ({ entityView, group }) => {
  const noResultsMessage = <ProtocolConditionExplorerEmptyState />;

  const globalParams = {
    group_id: group.guid,
    serializerGroups: 'protocol_condition_reporting,paginator',
    sort: '-created_at',
  };

  const paramsStateContext: ParamsStateContextType = {
    name: 'protocol_condition_index',
    globalParams,
    initialSearchParams: entityView.parameters || {},
    entityView,
    loadedEntityView: new EntityView({
      ...entityView,
    }),
  };

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <EntitySearch
        entityName={ProtocolCondition.entityName}
        noResultsMessage={noResultsMessage}
        tableFormat={false}
        hideFilters
        usePostFilter
        useAllPages
      >
        {({
          entities,
          readNextPage,
          status,
        }: EntitySearchContainerChildrenProps) => (
          <GridReport
            entityView={entityView}
            protocolConditions={entities}
            loadMore={readNextPage}
            status={status}
          />
        )}
      </EntitySearch>
    </ParamsStateContextProvider>
  );
};

const ProtocolConditionExplorerContainer: React.FC<
  ProtocolConditionExplorerContainerProps
> = ({ group, protocolCollection, metadata, protocolValue }) => {
  const [entityView, setEntityView] =
    React.useState<EntityView | null>(null);

  useEffect(() => {
    if (!entityView) {
      if (protocolCollection && protocolCollection.last_version) {
        const newEntityView = new EntityView({
          group,
          entity_name: 'protocol_condition',
          name: `Comparing runs of ${protocolCollection.name}`,
          column_definition_ids: GridReportService.getProtocolColDefs(
            protocolCollection.last_version,
          ).map((colDef: ReportingColDef) => colDef.colId || ''),
          parameters: GridFilterService.injectSystemFilter(
            SYSTEM_FILTER_EXPERIMENT_WORKFLOW_IS_TEMPLATE_FALSE,
            {
              filter: [
                {
                  type: PostFilterOperator.and,
                  predicates: [
                    {
                      type: PostFilterOperator.and,
                      path: 'experiment.protocol.collection',
                      predicates: [
                        {
                          attribute: 'guid',
                          comparison: PostFilterComparison.eq,
                          value: protocolCollection.guid,
                        },
                      ],
                    },
                  ],
                },
                {
                  type: PostFilterOperator.and,
                  predicates: [],
                },
              ],
            },
          ),
        });
        setEntityView(newEntityView);
      }
      if (metadata) {
        const newEntityView = new EntityView({
          group,
          entity_name: 'protocol_condition',
          name: `Comparing ${metadata.label}`,
          column_definition_ids: [
            `metadata:experiment:${metadata.type}:${
              metadata.label || ''
            }`,
            'experiment_name',
            'experiment_author',
            'protocol_name',
            'experiment_started_at',
            'experiment_ended_at',
          ],
          parameters: GridFilterService.injectSystemFilter(
            SYSTEM_FILTER_EXPERIMENT_WORKFLOW_IS_TEMPLATE_FALSE,
            {
              filter: [
                {
                  type: PostFilterOperator.and,
                  predicates: [
                    {
                      type: PostFilterOperator.and,
                      path: 'metadatas',
                      predicates: [
                        {
                          attribute: 'label',
                          comparison: PostFilterComparison.eq,
                          value: metadata.label || '',
                        },
                        {
                          attribute: 'value',
                          comparison: PostFilterComparison.not_null,
                        },
                      ],
                    },
                  ],
                },
                {
                  type: PostFilterOperator.and,
                  predicates: [],
                },
              ],
            },
          ),
        });
        setEntityView(newEntityView);
      }
      if (protocolValue && protocolValue.name) {
        const newEntityView = new EntityView({
          group,
          entity_name: 'protocol_condition',
          name: `Comparing ${protocolValue.reportingName}`,
          column_definition_ids: [
            getProtocolValueFieldColumnDef(
              protocolValue.is_input,
              protocolValue.name,
              'amount',
            ).colId || '',
            getProtocolValueResourceNameColumnDef(
              protocolValue.is_input,
              protocolValue.name,
            ).colId || '',
            getProtocolValueResourceItemNameColumnDef(
              protocolValue.is_input,
              protocolValue.name,
            ).colId || '',
            getProtocolValueResourceLocationPathColumnDef(
              protocolValue.is_input,
              protocolValue.name,
            ).colId || '',
            'experiment_name',
            'experiment_author',
            'protocol_name',
            'experiment_started_at',
            'experiment_ended_at',
          ],
          parameters: GridFilterService.injectSystemFilter(
            SYSTEM_FILTER_EXPERIMENT_WORKFLOW_IS_TEMPLATE_FALSE,
            {
              filter: [
                {
                  type: PostFilterOperator.and,
                  predicates: [],
                },
                {
                  type: PostFilterOperator.and,
                  predicates: [],
                },
              ],
            },
          ),
        });
        setEntityView(newEntityView);
      }
    }
  }, [group]);

  return entityView ? (
    <ProtocolConditionExplorer
      key={entityView.guid}
      group={group}
      entityView={entityView}
    />
  ) : null;
};

export default ProtocolConditionExplorerContainer;

/**
 * Labstep
 *
 * @module core/TextLink
 * @desc Text with Metadata
 */

import classNames from 'classnames';
import EntityLink from 'labstep-web/components/Entity/Link';
import EntityPreview from 'labstep-web/components/Entity/Preview';
import Icon from 'labstep-web/core/Icon';
import { EntityLinkEntity } from 'labstep-web/models';
import React from 'react';
import styles from './styles.module.scss';
import { ITextLinkProps } from './types';

export const TextLink: React.FC<ITextLinkProps> = ({
  entity,
  disabled,
  entityPreviewProps,
  actionIconProps,
}) => (
  <div
    className={classNames(styles.text, {
      [styles.textPosition]: actionIconProps,
    })}
  >
    <EntityLink
      entity={entity as EntityLinkEntity}
      disabled={disabled}
    />
    {entityPreviewProps && (
      <span
        className={classNames(styles.preview, {
          [styles.previewPosition]: actionIconProps,
        })}
      >
        <EntityPreview
          entity={entity}
          type="icon"
          {...entityPreviewProps}
        />
      </span>
    )}
    {actionIconProps && (
      <span className={styles.actionIcon}>
        <Icon {...actionIconProps} />
      </span>
    )}
  </div>
);

export default TextLink;

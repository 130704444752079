/**
 * Labstep
 *
 * @module grid/NewIndex/components/ToolPanel/AddColumn/OrderRequest
 * @desc Lists for OrderRequest
 */

import { ICONS } from 'labstep-web/constants/icons';
import { SectionHeader } from 'labstep-web/core/Header';
import EntityDataGridToolPanelListItem from 'labstep-web/grid/NewIndex/components/ToolPanel/ListItem';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { Resource } from 'labstep-web/models/resource.model';

export const getOrderRequestLists = (
  entityView: EntityView,
  selectMetadata: (
    metadata: Metadata,
    parentEntityClass: string,
  ) => void,
  colIds: string[],
) => [
  {
    key: Metadata.entityName,
    entityName: Metadata.entityName,
    searchField: 'label_like',
    params: {
      permission_class: Resource.entityName,
      group_id: entityView.group.id,
      group_by_label: 1,
      sort: 'label',
      count: 3,
    },
    header: <SectionHeader label="Resource Metadata Fields" />,
    listItem: (entity: object) => (
      <EntityDataGridToolPanelListItem
        icon={
          ICONS.metadata.type[
            (entity as Metadata)
              .type as keyof typeof ICONS.metadata.type
          ] || 'database'
        }
        actionIcon={
          (entity as Metadata).type === MetadataType.molecule
            ? 'arrow right'
            : 'eye'
        }
        text={(entity as Metadata).label || 'Untitled data field'}
        onClick={() =>
          selectMetadata(entity as Metadata, Resource.entityName)
        }
        disabled={colIds.includes((entity as Metadata).colId)}
      />
    ),
  },
  {
    key: Metadata.entityName,
    entityName: Metadata.entityName,
    searchField: 'label_like',
    params: {
      permission_class: OrderRequest.entityName,
      group_id: entityView.group.id,
      group_by_label: 1,
      sort: 'label',
      count: 3,
    },
    header: <SectionHeader label="Request Metadata Fields" />,
    listItem: (entity: object) => (
      <EntityDataGridToolPanelListItem
        icon={
          ICONS.metadata.type[
            (entity as Metadata)
              .type as keyof typeof ICONS.metadata.type
          ] || 'database'
        }
        actionIcon={
          (entity as Metadata).type === MetadataType.molecule
            ? 'arrow right'
            : 'eye'
        }
        text={(entity as Metadata).label || 'Untitled data field'}
        onClick={() =>
          selectMetadata(entity as Metadata, OrderRequest.entityName)
        }
        disabled={colIds.includes((entity as Metadata).colId)}
      />
    ),
  },
  {
    key: Metadata.entityName,
    entityName: Metadata.entityName,
    searchField: 'label_like',
    params: {
      permission_class: PurchaseOrder.entityName,
      group_id: entityView.group.id,
      group_by_label: 1,
      sort: 'label',
      count: 3,
    },
    header: <SectionHeader label="Order Metadata Fields" />,
    listItem: (entity: object) => (
      <EntityDataGridToolPanelListItem
        icon={
          ICONS.metadata.type[
            (entity as Metadata)
              .type as keyof typeof ICONS.metadata.type
          ] || 'database'
        }
        actionIcon={
          (entity as Metadata).type === MetadataType.molecule
            ? 'arrow right'
            : 'eye'
        }
        text={(entity as Metadata).label || 'Untitled data field'}
        onClick={() =>
          selectMetadata(entity as Metadata, PurchaseOrder.entityName)
        }
        disabled={colIds.includes((entity as Metadata).colId)}
      />
    ),
  },
];

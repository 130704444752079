/**
 * Labstep
 *
 * @module screens/JupyterNotebook/Show/Center
 * @desc JupyterNotebook Show Center
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import JupyterNotebookItem from 'labstep-web/components/JupyterNotebook/Item';
import ActionMenu from 'labstep-web/core/Action/Menu';
import InformationCard from 'labstep-web/core/Card/Information';
import React from 'react';
import styles from './styles.module.scss';
import { IJupyterNotebookShowCenterProps } from './types';

export const JupyterNotebookShowCenter: React.FC<
  IJupyterNotebookShowCenterProps
> = ({ jupyterNotebook }) => {
  return (
    <div
      id="jupyter-notebook-show-screen"
      className={styles.container}
    >
      <InformationCard
        actionMenu={
          <ActionMenu>
            <EntityActionDelete entity={jupyterNotebook} />
          </ActionMenu>
        }
        primaryInfo={<EntityPrimaryInfo entity={jupyterNotebook} />}
        bottom={
          <JupyterNotebookItem
            jupyterNotebook={jupyterNotebook}
            startExpanded
          />
        }
      />
    </div>
  );
};

export default JupyterNotebookShowCenter;

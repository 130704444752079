/**
 * Labstep
 *
 * @module components/ProtocolCondition/Action/ToggleVariable
 * @desc Set isVariable action
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import PremiumFeatureToggle from 'labstep-web/components/Organization/PremiumFeatureToggle';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import React from 'react';
import { useProtocolConditionModalContext } from '../../Modal/context';
import { IProtocolConditionActionToggleIsVariableProps } from './types';

export const ProtocolConditionActionToggleIsVariable: React.FC<
  IProtocolConditionActionToggleIsVariableProps
> = ({ entity, toggle = true, protocol }) => {
  const { setIsOpen } = useProtocolConditionModalContext();

  if (!useHasFeatureFlagEnabled('multiplexing')) {
    return <PremiumFeatureToggle premiumFeature="multiplexing" />;
  }
  return (
    <EntityActionUpdate
      entityName={entity.entityName}
      id={entity.idAttr}
      body={{ is_variable: !entity.is_variable }}
      actionComponentProps={{
        dataTestId: 'action-toggle-is-variable',
        dataCy: 'components/ProtocolCondition/Action/ToggleVariable',
        type: 'checkbox',
        elementProps: {
          toggle,
          checked: entity.is_variable,
        },
      }}
      options={{
        onSuccess: (): void => {
          if (!entity.is_variable && protocol.n_protocol_conditions) {
            setIsOpen(protocol);
          }
        },
      }}
    />
  );
};

export default ProtocolConditionActionToggleIsVariable;

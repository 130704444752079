/**
 * Labstep
 *
 * @module grid/NewIndex/components/ToggleColumnOption
 * @desc Toggle Column Option
 */

import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import Checkbox from 'labstep-web/core/Checkbox';
import Dropdown from 'labstep-web/core/Dropdown';
import {
  EntityView,
  EntityViewColumnOptions,
} from 'labstep-web/models/entity-view.model';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

export const GridNewIndexActionToggleColumnOption: <
  T extends keyof EntityViewColumnOptions,
>(props: {
  colId: T;
  columnOption: keyof EntityViewColumnOptions[T];
  columnName: string;
}) => JSX.Element | null = ({ colId, columnOption, columnName }) => {
  const { entityView, setEntityView } = useParamsStateContext();

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (entityView?.column_options?.[colId]?.[columnOption]) {
      setIsChecked(!!entityView.column_options[colId][columnOption]);
    }
  }, [entityView, colId, columnOption]);

  if (!entityView) {
    return null;
  }

  const handleChange = () => {
    const newCheckedValue = !isChecked;
    setIsChecked(newCheckedValue);

    if (setEntityView) {
      const newEntityView = new EntityView({
        ...entityView,
        column_options: {
          ...entityView.column_options,
          [colId]: {
            ...entityView.column_options[colId],
            [columnOption]: newCheckedValue,
          },
        },
      });
      setEntityView(newEntityView);
    }
  };

  return (
    <Dropdown.Item className={styles.option}>
      <div>Show {columnName}</div>
      <Checkbox toggle checked={isChecked} onChange={handleChange} />
    </Dropdown.Item>
  );
};

export default GridNewIndexActionToggleColumnOption;

/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/entity/template
 * @desc Column Definition for entity template
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import EntityActionUpdateTemplate from 'labstep-web/components/Entity/Action/UpdateTemplate';
import FilterTemplate from 'labstep-web/components/Filter/Template';
import { FilterTemplateProps } from 'labstep-web/components/Filter/Template/types';
import { ICONS } from 'labstep-web/constants/icons';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import {
  CellEditorParams,
  CellRendererParams,
} from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { Device } from 'labstep-web/models/device.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Resource } from 'labstep-web/models/resource.model';

export const getColDefEntityTemplate = (
  entityName: string,
): NewIndexColDef => ({
  colId: 'template',
  headerName: 'Category',
  headerComponentParams: {
    icon: ICONS.resource_template.primary,
    filter: (props: FilterTemplateProps) => (
      <FilterTemplate
        {...props}
        entityName={entityName as 'device' | 'resource'}
      />
    ),
  },
  editable: (params: EditableCallbackParams) =>
    GridNewIndexService.getEditable(params),
  valueGetter: (params: ValueGetterParams) =>
    params.data?.template?.idAttr || null,
  valueGetterExport: (params: ValueGetterParams) =>
    params.node?.data?.template?.name || 'Untitled Category',
  valueSetter: (params: ValueSetterParams) => {
    setValue('template_id', params.newValue, {
      entityName: params.data?.entityName,
      id: params.data?.idAttr,
    });
    return true;
  },
  cellRenderer: (params: CellRendererParams<Entity>) => {
    const entity = params.data?.template;
    if (!entity) {
      return (
        <DataGridPlaceholder
          params={params}
          editable={getEditable()}
          children="Specify category"
        />
      );
    }
    return null;
  },
  cellEditorPopup: true,
  cellEditor: (params: CellEditorParams<Entity>) => {
    return (
      <ParamsStateContextProvider value={{}}>
        <EntityActionUpdateTemplate
          entity={params.data as Resource | Device}
          options={{ onSuccess: () => stopEditingAndFocus(params) }}
          modalProps={{
            open: true,
            onClose: () => stopEditingAndFocus(params),
            viewComponent: null,
          }}
        />
      </ParamsStateContextProvider>
    );
  },
});

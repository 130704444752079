/**
 * Labstep
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { setValue } from 'labstep-web/core/DataGrid/utils';
import Icon from 'labstep-web/core/Icon';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { Entity } from 'labstep-web/models/entity.model';
import rules from 'labstep-web/services/validation/rules';
import styles from './styles.module.scss';

export const colDefOrderRequestIsUrgent = {
  colId: 'is_urgent',
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.boolean,
    field: 'is_urgent',
  },
  headerName: 'Is Urgent',
  headerComponentParams: {
    icon: ICONS.order_request.info.urgent,
  },
  editable: (params: EditableCallbackParams) =>
    GridNewIndexService.getEditable(params),
  valueGetter: (params: ValueGetterParams) => params.data.is_urgent,
  valueSetter: (params: ValueSetterParams) =>
    setValue(
      'is_urgent',
      params.newValue,
      {
        entityName: params.data.entityName,
        id: params.data.id,
      },
      rules.order_request.is_urgent,
    ),
  cellRenderer: (params: CellRendererParams<Entity>) => {
    if (!params.data) {
      return '';
    }
    return (
      <Icon
        name={ICONS.order_request.info.urgent}
        className={styles[`${params.data.is_urgent}`]}
        onClick={() =>
          setValue(
            'is_urgent',
            !params.data.is_urgent,
            {
              entityName: params.data.entityName,
              id: params.data.id,
            },
            rules.order_request.is_urgent,
          )
        }
      />
    );
  },
  suppressKeyboardEvent: suppressKeyboardEventClearCells,
  valueClearer: () => false,
  cellEditorPopup: true,
  // only editing through copy/paste and cellRenderer
  cellEditor: () => null,
};

/**
 * Labstep
 *
 * @module components/FilterPost/Report/MetadataHasValue/Active/Post
 * @desc Active node component for metadata has value filter
 */

import { ICONS } from 'labstep-web/constants/icons';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { getPostFilterMetadatasHasValue } from 'labstep-web/services/postFilter/filters/metadatas';
import React from 'react';
import { IFilterMetadataHasValueActivePostProps } from './types';

const PATHS = [
  'metadatas',
  'protocolValues.resourceItem.resource.metadatas',
  'protocolValues.resourceItem.metadatas',
];

const FILTERS = PATHS.flatMap((path) => [
  getPostFilterMetadatasHasValue(path),
]);

const FilterMetadataHasValueActivePost: React.FC<
  IFilterMetadataHasValueActivePostProps
> = ({ node, onRemoveClick }) => {
  const filterActive = FILTERS.find((filter) =>
    filter.isNodeActive(node),
  );
  if (!filterActive) {
    return null;
  }
  const attributes = filterActive.getAttributeValues(node);

  return (
    <RemoveLabel
      name={`${attributes.label.value} has any value`}
      icon={
        filterActive.getPath()?.includes('resource')
          ? ICONS.metadata.secondary
          : ICONS.metadata.primary
      }
      onRemoveClick={onRemoveClick}
    />
  );
};

export default FilterMetadataHasValueActivePost;

/**
 * Labstep
 *
 * @module components/FilterPost/Report/Date/Form
 * @desc Filter Metadata form
 */

import ReusableFormGroup from 'labstep-web/core/Form/Reusable/Group';
import { PostFilterDate } from 'labstep-web/services/postFilter/date';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React, { useCallback, useState } from 'react';
import {
  COMPARISON_OPTIONS,
  fieldComparison,
  fieldDate,
} from './fields';
import { IFilterDateFormProps } from './types';

export const getPostFilterDate = (
  field: string,
  comparison: PostFilterComparison,
  path?: string,
) => {
  if (comparison === PostFilterComparison.not_null) {
    return new PostFilterDate([[field, comparison]], path);
  }

  return new PostFilterDate([[field, comparison]], path);
};

const FilterDateForm: React.FC<IFilterDateFormProps> = ({
  addPostFilter,
  onDone,
  path,
  field,
  defaultValues,
  replace,
}) => {
  const [fields, setFields] = useState([fieldComparison]);

  const onSubmit = useCallback(
    (values): void => {
      const { comparison } = values;
      let { value } = values;
      if (comparison.value === PostFilterComparison.not_null) {
        value = undefined;
      }
      addPostFilter(
        getPostFilterDate(field, comparison.value, path).getNode([
          value,
        ]),
        true,
      );
      onDone();
    },
    [path, addPostFilter],
  );

  return (
    <ReusableFormGroup
      fields={fields}
      onSubmit={onSubmit}
      onDone={onDone}
      defaultValues={{
        comparison: COMPARISON_OPTIONS[0],
        ...defaultValues,
      }}
      onFormChange={({ comparison }): void => {
        if (!comparison) {
          return;
        }
        const newFields =
          comparison.value === PostFilterComparison.not_null
            ? [fieldComparison]
            : [fieldComparison, fieldDate];
        if (fields.length !== newFields.length) {
          setFields(newFields);
        }
      }}
      addAnother={false}
    />
  );
};

export default FilterDateForm;

/**
 * Labstep
 *
 * @module containers/UiPersistent
 * @desc Container for ui
 */

import {
  reset,
  setUiCountPerPage,
  setUiExperimentWorkflowView,
  setUiSearchParams,
  toggleUiFilter,
} from 'labstep-web/state/actions/ui-persistent';
import { selectUiPersistent } from 'labstep-web/state/selectors/ui-persistent';
import { LabstepReduxState } from 'labstep-web/state/types';
import React from 'react';
import { connect } from 'react-redux';

export type UiPersistentChildrenType = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: LabstepReduxState) => ({
  uiPersistent: selectUiPersistent(state),
});

const mapDispatchToProps = {
  toggleUiFilter,
  setUiExperimentWorkflowView,
  setUiSearchParams,
  reset,
  setUiCountPerPage,
};

export const withUiPersistent = <P, S>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<P, Exclude<keyof P, keyof UiPersistentChildrenType>>
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);

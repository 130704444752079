/**
 * Labstep
 *
 * @module hoc/Params
 * @desc HOC that enhances component with params
 * capabilities. Allows for params to be pushed
 * to url.
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { withUiPersistent } from 'labstep-web/containers/UiPersistent';
import { ParamsContextProvider } from 'labstep-web/contexts/params';
import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import { PostFilter } from 'labstep-web/services/postFilter';
import {
  PostFilterBranchType,
  PostFilterNodeType,
  PostFilterOperator,
  PostFilterType,
  QueryParameterService,
} from 'labstep-web/services/query-parameter.service';
import {
  makeSelectParams,
  selectParams,
  setParams,
} from 'labstep-web/state/slices/paramsHoc';
import store from 'labstep-web/state/store';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import qs from 'query-string';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import {
  IParamsContainerProps,
  IParamsContainerState,
  IParamsHOCContainerProps,
} from './types';

export const POST_FILTER_PAGE_SIZE = 10;

const ignoreUiSearchParams = [
  'search_query',
  'page',
  'serializerGroups',
];
const ignoreHistoryParams = ['m'];

/** List of parameter fields supported with filter */
export const filterParams = [
  'search_query',
  'page',
  'filter',
  'group_id',
  'sort',
  'skip_total',
  'at',
];

export class ParamsContainer extends React.Component<
  IParamsContainerProps,
  IParamsContainerState
> {
  public constructor(props: IParamsContainerProps) {
    super(props);
    this.setParams = this.setParams.bind(this);
    this.addPostFilter = this.addPostFilter.bind(this);
    this.removePostFilter = this.removePostFilter.bind(this);
    this.clearParams = this.clearParams.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.getCurrentSearchParams =
      this.getCurrentSearchParams.bind(this);
    this.setPostFilterType = this.setPostFilterType.bind(this);

    const { location, historyAction, initialParams } = props;

    if (this.props.paramsStateContext?.name) {
      this.state = {
        ready: true,
        searchParams:
          this.props.paramsStateContext?.initialSearchParams || {},
      };
      store.dispatch(
        setParams({
          name: this.props.paramsStateContext.name,
          params:
            this.props.paramsStateContext.initialSearchParams || {},
        }),
      );
    } else if (historyAction) {
      this.state = {
        ready: false,
      };
      if (
        initialParams &&
        Object.keys(qs.parse(location.search)).length === 0
      ) {
        this.addParamsToHistory(initialParams, historyAction);
      }
    } else {
      this.state = {
        ready: true,
        searchParams:
          QueryParameterService.sanitizeParams(initialParams),
      };
    }
  }

  public componentDidMount(): void {
    this.setState({ ready: true });
  }

  /**
   * Updates search params. If a param is passed with an undefined value
   * it will be removed from the params.
   * @param  {object} params - object of key values of params to be updated
   */
  public setParams(params: Record<string, any>): void {
    const searchParams = this.getCurrentSearchParams();

    const updatedParams = { ...searchParams, ...params };

    // Pagination specific
    const paramsKeys = Object.keys(params);
    if (!(paramsKeys.length === 1 && paramsKeys[0] === 'page')) {
      updatedParams.page = undefined;
    }

    // Removes undefined params
    const cleanedUpdateParams =
      QueryParameterService.sanitizeParams(updatedParams);

    this.updateParams(cleanedUpdateParams);
  }

  public getCurrentSearchParams() {
    const { historyAction, location, initialParams } = this.props;
    const { ready } = this.state;

    if (this.props.paramsStateContext?.name) {
      return { ...this.props.reduxSearchParams };
    }

    let params = { ...this.state.searchParams };

    if (historyAction) {
      params = qs.parse(location.search);
      params = omit(params, ignoreHistoryParams);
      if (initialParams && !ready) {
        // https://github.com/Labstep/labstep/issues/7672
        // Need to pass initialParams so that AsyncInput gets the correct
        // defaultValue from search_query on mount
        params = { ...params, ...initialParams };
      }
    }

    return QueryParameterService.sanitizeParams(params);
  }

  public setPostFilterType(
    type: PostFilterOperator,
    branch: PostFilterBranchType = 0,
  ): void {
    const searchParams = this.getCurrentSearchParams();
    const postFilter = searchParams.filter as PostFilterType;
    if (!postFilter) {
      return;
    }

    const updatedParams = {
      ...searchParams,
      filter: [
        branch === 0 ? { ...postFilter[0], type } : postFilter[0],
        branch === 1 ? { ...postFilter[1], type } : postFilter[1],
      ],
    };

    const cleanedUpdateParams =
      QueryParameterService.sanitizeParams(updatedParams);
    this.updateParams(cleanedUpdateParams);
  }

  private addParamsToHistory = (
    params: Record<string, unknown>,
    historyAction: 'replace',
  ): void => {
    const { location, history } = this.props;
    const currentParams = qs.parse(location.search);
    const ignoredParams = pick(currentParams, ignoreHistoryParams);
    const search = `?${qs.stringify({
      ...ignoredParams,
      ...params,
    })}`;
    history[historyAction]({
      pathname: location.pathname,
      search,
    });
  };

  /**
   * Add a filter node to the post filter of the current search params
   * @param filterNode Filter node to add
   * @param replace Whether to replace an existing filter node with the same path and attribute
   * @param branch 0 or 1, which branch of the post filter to add the filter node to.
   * 0 is the user-controlled branch, 1 is the system-controlled branch
   */
  public addPostFilter(
    filterNode: PostFilterNodeType,
    replace: boolean | number = true,
    branch: PostFilterBranchType = 0,
  ): void {
    const searchParams = this.props.reduxSearchParams || {};
    const updatedSearchParams = { ...searchParams };

    const filter: PostFilterType =
      (updatedSearchParams.filter as PostFilterType) || [
        {
          type: PostFilterOperator.and,
          predicates: [],
        },
        {
          type: PostFilterOperator.and,
          predicates: [],
        },
      ];
    let newPredicates = [...filter[branch].predicates];

    if (replace !== undefined && replace !== false) {
      if (typeof replace === 'number') {
        newPredicates.splice(replace, 1, filterNode);
      } else {
        const index = newPredicates.findIndex((node) => {
          return PostFilter.compareNodes(node, filterNode);
        });
        if (index !== -1) {
          newPredicates = [
            ...newPredicates.slice(0, index),
            filterNode,
            ...newPredicates.slice(index + 1),
          ];
        } else {
          newPredicates.push(filterNode);
        }
      }
    }
    const updatedBranch = {
      ...filter[branch],
      predicates:
        replace !== undefined && replace !== false
          ? newPredicates
          : [...newPredicates, filterNode],
    };
    const updatedFilter = [
      branch === 0 ? updatedBranch : filter[0],
      branch === 1 ? updatedBranch : filter[1],
    ];
    updatedSearchParams.filter = updatedFilter;
    const sanitizedParams = QueryParameterService.sanitizeParams(
      updatedSearchParams,
    );
    this.updateParams(sanitizedParams);
  }

  /**
   * Remove filter node
   * @param index Index of filter node to remove
   * @param branch Which branch to remove the node from. 0 is the user-controlled branch, 1 is the system-controlled branch.
   */
  public removePostFilter(
    index: number,
    branch: PostFilterBranchType = 0,
  ): void {
    const searchParams = this.props.reduxSearchParams || {};
    const updatedParams = { ...searchParams };
    const filter = searchParams.filter as PostFilterType;
    if (!filter) {
      return;
    }
    const predicates = [...filter[branch].predicates];
    predicates.splice(index, 1);
    const updatedFilterBranch = { ...filter[branch], predicates };
    const updatedFilter = [
      branch === 0 ? updatedFilterBranch : filter[0],
      branch === 1 ? updatedFilterBranch : filter[1],
    ];

    updatedParams.filter = updatedFilter;
    const sanitizedParams =
      QueryParameterService.sanitizeParams(updatedParams);
    this.updateParams(sanitizedParams);
  }

  /**
   * Clears all search params
   */
  public clearAll(
    ignore: string[] = [],
    ignoreSystemFilters = false,
  ): void {
    let params: any = {};
    const searchParams = this.getCurrentSearchParams();
    if (Array.isArray(ignore)) {
      params = pick(searchParams, ignore);
    }
    if (ignoreSystemFilters) {
      if (searchParams.filter) {
        params.filter = [
          {
            type: PostFilterOperator.and,
            predicates: [],
          },
          (searchParams.filter as Record<number, unknown>)[1],
        ];
      }
    }
    this.updateParams(params);
  }

  /**
   * Clears params
   * @param  {array} params - array of keys to be cleared
   */
  public clearParams(params: string[]): void {
    const searchParams = this.getCurrentSearchParams();
    const updatedParams = omit(searchParams, params);
    this.updateParams(updatedParams);
  }

  public updateParams(params: Record<string, any>): void {
    const { location, historyAction, setUiSearchParams } = this.props;
    if (
      Object.keys(params).length !== 0 &&
      !('search_query' in params) &&
      this.props.paramsStateContext?.destination
    ) {
      store.dispatch(
        setParams({
          name: this.props.paramsStateContext.destination,
          params,
        }),
      );

      return;
    }
    if (this.props.paramsStateContext?.name) {
      store.dispatch(
        setParams({
          name: this.props.paramsStateContext.name,
          params,
        }),
      );

      return;
    }
    if (historyAction) {
      this.addParamsToHistory(params, historyAction);
      const uiSearchParams = omit(params, ignoreUiSearchParams);
      setUiSearchParams(location.pathname, uiSearchParams);
    } else {
      this.setState({ searchParams: params });
    }
  }

  public render() {
    return (
      <ParamsContextProvider
        value={{
          globalParams: this.props.paramsStateContext?.globalParams,
          initialSearchParams:
            this.props.paramsStateContext?.initialSearchParams || {},
          searchParams: this.getCurrentSearchParams(),
          setParams: this.setParams,
          clearAll: this.clearAll,
          clearParams: this.clearParams,
          addPostFilter: this.addPostFilter,
          setPostFilterType: this.setPostFilterType,
          removePostFilter: this.removePostFilter,
        }}
        children={this.props.children}
      />
    );
  }
}

export const ParamsHOCChildren = withActiveGroup(
  withUiPersistent(withRouter(ParamsContainer)),
);

export const ParamsHOC: React.FC<IParamsHOCContainerProps> = (
  props,
) => {
  const paramsStateContext = useParamsStateContext();

  const reduxSearchParams = useSelector(
    makeSelectParams(
      paramsStateContext?.destination || paramsStateContext?.name,
    ),
  );

  return (
    <ParamsHOCChildren
      {...props}
      paramsStateContext={paramsStateContext}
      reduxSearchParams={
        reduxSearchParams as Record<string, unknown> | null
      }
    />
  );
};

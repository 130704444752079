/**
 * Labstep
 *
 * @module components/Entity/Search/Multi
 * @desc Generic component for rendering a search bar and multiple lists of entities
 */

import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import Flex from 'labstep-web/core/Flex';
import Input from 'labstep-web/core/Input';
import Paginator from 'labstep-web/core/Paginator';
import { SearchHOC } from 'labstep-web/hoc/Search';
import React, { useEffect, useState } from 'react';
import { IEntitySearchMultiProps } from './types';

export const EntitySearchMulti: React.FC<IEntitySearchMultiProps> = ({
  lists,
  staticLists,
}) => {
  const [searchQuery, setSearchQuery] = useState<
    string | null | undefined
  >(null);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<
    string | null | undefined
  >(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchQuery(debouncedSearchQuery);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedSearchQuery]);

  return (
    <Flex grow column spacing="gap">
      <Input
        icon="search"
        iconPosition="left"
        placeholder="Search"
        onChange={(event) =>
          setDebouncedSearchQuery(event.target.value)
        }
        fluid
      />
      <Flex
        grow
        spacing="gap"
        column
        style={{ overflowY: 'auto' }}
        height={'100%'}
      >
        {staticLists && <div>{staticLists(searchQuery)}</div>}
        {lists.map((list) => (
          <Flex
            grow
            column
            key={list.key}
            style={{ minHeight: 'auto' }}
          >
            {list.header && <div>{list.header}</div>}
            <ParamsStateContextProvider value={{}}>
              <SearchHOC
                entityName={list.entityName}
                params={
                  searchQuery && searchQuery.length > 0
                    ? {
                        ...list.params,
                        [list.searchField]: searchQuery,
                      }
                    : list.params
                }
                isCursor
              >
                {({
                  entities,
                  total,
                  nextCursor,
                  currentCursor,
                  read,
                  status,
                }) => {
                  return (
                    <Paginator
                      read={read}
                      status={status}
                      nextCursor={nextCursor}
                      currentCursor={currentCursor}
                      total={total}
                    >
                      {entities.map((entity) =>
                        list.listItem(entity),
                      )}
                    </Paginator>
                  );
                }}
              </SearchHOC>
            </ParamsStateContextProvider>
            {list.bottomAction && <div>{list.bottomAction}</div>}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default EntitySearchMulti;

/**
 * Labstep
 *
 * @module components/FilterPost/Active/Label
 * @desc Filter Post Active Label
 */

import FilterDeletedActivePost from 'labstep-web/components/Filter/Deleted/Active/Post';
import FilterMetadataActivePost from 'labstep-web/components/Filter/Metadata/Active/Post';
import TagFilterActivePost from 'labstep-web/components/Filter/Tag/Active/Post';
import FilterUserActivePost from 'labstep-web/components/Filter/User/Active/Post';
import FilterPostNewIndexDateActivePost from 'labstep-web/components/FilterPost/NewIndex/Date/Active/Post';
import FilterPostNewIndexTextActivePost from 'labstep-web/components/FilterPost/NewIndex/Text/Active/Post';
import FilterPostReportDateActivePost from 'labstep-web/components/FilterPost/Report/Date/Active/Post';
import FilterPostReportExperimentAuthorActivePost from 'labstep-web/components/FilterPost/Report/ExperimentAuthor/Active/Post';
import FilterPostReportMetadataActivePost from 'labstep-web/components/FilterPost/Report/Metadata/Active/Post';
import FilterPostReportMetadataHasValueActivePost from 'labstep-web/components/FilterPost/Report/MetadataHasValue/Active/Post';
import FilterPostReportProtocolCollectionActivePost from 'labstep-web/components/FilterPost/Report/ProtocolCollection/Active/Post';
import FilterMoleculeActivePost from 'labstep-web/components/Molecule/Filter/Active/Post';
import FilterResourceLocationActivePost from 'labstep-web/components/ResourceLocation/Filter/Active/Post';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import React from 'react';
import FilterBooleanActivePost from '../../NewIndex/Boolean/Active/Post';
import FilterNumericActivePost from '../../NewIndex/Numeric/Active/Post';
import FilterOptionsActivePost from '../../NewIndex/Options/Active/Post';
import { FilterPostActiveLabelProps } from './types';

export const FilterPostActiveLabel: React.FC<
  FilterPostActiveLabelProps
> = (props): React.ReactElement | null =>
  props.entityView &&
  props.entityView.entity_name === ProtocolCondition.entityName ? (
    <>
      <FilterPostReportDateActivePost {...props} />
      <FilterPostReportMetadataActivePost {...props} />
      <FilterPostReportMetadataHasValueActivePost {...props} />
      <FilterPostReportProtocolCollectionActivePost {...props} />
      <FilterPostReportExperimentAuthorActivePost {...props} />
    </>
  ) : (
    <>
      <FilterBooleanActivePost {...props} />
      <FilterOptionsActivePost {...props} />
      <FilterPostNewIndexDateActivePost {...props} />
      <FilterPostNewIndexTextActivePost {...props} />
      <FilterNumericActivePost {...props} />
      <FilterUserActivePost {...props} />
      <FilterResourceLocationActivePost {...props} />
      <FilterUserActivePost {...props} />
      <FilterMetadataActivePost {...props} />
      {/* <FilterResourceItemStatusActivePost {...props} /> */}
      <FilterDeletedActivePost {...props} />
      <FilterMoleculeActivePost {...props} />
      {/* <FilterAvailableResourceItemCountActivePost {...props} /> */}
      <TagFilterActivePost {...props} />
    </>
  );

/**
 * Labstep
 */

import {
  ColDef,
  ColGroupDef,
  Column,
  ColumnApi,
  GridApi,
  ValueSetterParams,
} from 'ag-grid-enterprise';
import { selectHasAccess } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { APICallOptions } from 'labstep-web/models';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import { isValid } from 'labstep-web/services/validation';
import {
  createEntity,
  updateEntity,
} from 'labstep-web/state/actions/entity';
import { makeSelectHasAccessCreate } from 'labstep-web/state/new/authorization';
import store from 'labstep-web/state/store';
import { AnySchema } from 'yup';
import {
  ColDefParams,
  EditableCallback,
  createBatchType,
  createOrUpdateValueParametersType,
  createOrUpdateValueType,
  getPropOrFallbackType,
} from './types';

/**
 * Set value of entity in grid
 * @param fieldName Field name
 * @param newValue New value
 * @param createOrUpdateProps Props to pass to create/update fn
 * @param validate Rule string or fn to validate value
 * @returns Boolean indicating if update was successful
 */
export const setValue = (
  fieldName: string,
  newValue: unknown,
  createOrUpdateProps: Omit<
    createOrUpdateValueParametersType,
    'body'
  >,
  validate?:
    | AnySchema
    | ((value: unknown) => { value: unknown; error: boolean }),
  options?: APICallOptions,
): boolean => {
  let value = newValue;
  if (validate) {
    if (typeof validate !== 'function') {
      const validation = isValid(value, validate);
      if (!validation) {
        return false;
      }
    } else {
      const validation = validate(newValue);
      if (validation.error) {
        return false;
      }
      value = validation.value;
    }
  }
  createOrUpdateValue({
    body: { [fieldName]: value },
    ...createOrUpdateProps,
    options,
  });
  return true;
};

/**
 * Create bulk values in grid
 * @param props Create batch props
 */
export const createBatch: createBatchType = ({
  entityName,
  body,
  options,
  createProps,
}) => {
  const action = createEntity(
    entityName,
    body as any,
    createProps?.parentName,
    createProps?.parentId,
    undefined,
    {
      ...options,
      batch: true,
    },
  );
  store.dispatch(action);
};

/**
 * Create or update value in grid
 * @param props Create/update props
 */
export const createOrUpdateValue: createOrUpdateValueType = ({
  entityName,
  body,
  id,
  options,
  createProps,
}) => {
  let action;
  if (id && !createProps) {
    action = updateEntity(entityName, id, body, {
      optimistic: true,
      ...options,
    });
  } else {
    action = createEntity(
      entityName,
      {
        ...body,
        ...createProps?.createBody,
      },
      createProps?.parentName,
      createProps?.parentId,
      undefined,
      options as any,
    );
  }
  store.dispatch(action);
};

/**
 * Generic updateValue function
 * @param entity Entity
 * @param fieldName Field name
 * @param value Value
 * @param options Options
 */
export const updateValue = (
  entity:
    | Metadata
    | ProtocolValue
    | { entityName: string; id: number },
  fieldName: string,
  value: unknown,
  options?: Record<string, unknown>,
): void => {
  store.dispatch(
    updateEntity(
      entity.entityName,
      entity[
        getIdAttribute(entity.entityName) as keyof typeof entity
      ],
      {
        [fieldName]: value,
      },
      { optimistic: true, ...options },
    ),
  );
};

/**
 * Stop editing and refocus cell
 * @param params Column definition parameters
 */
export const stopEditingAndFocus = (
  params:
    | ValueSetterParams
    | CellRendererParams<Entity>
    | (ColDefParams<Entity> & { rowIndex: number }),
): void => {
  params.api.stopEditing();
  params.api.clearRangeSelection();

  const rowIndex = 'rowIndex' in params ? params.rowIndex : null;
  if (rowIndex !== null) {
    params.api.setFocusedCell(rowIndex + 1, params.column);
  }
};

/**
 * Start editing active cell
 * @param params Column definition parameters
 */
export const startEditingActiveCell = (
  params: ColDefParams<Entity> & { rowIndex: number },
): void => {
  params.api.startEditingCell({
    rowIndex: params.rowIndex,
    colKey: params.column,
  });
};

/** Set a fiedl for all given column definitions */
export const setColumnDefsField = <T extends keyof ColDef>(
  columnDefs: (ColGroupDef | ColDef)[],
  field: T,
  value: ColDef[T],
): (ColGroupDef | ColDef)[] =>
  columnDefs.map((columnDef): ColGroupDef | ColDef => {
    if ('children' in columnDef) {
      return {
        ...columnDef,
        children: setColumnDefsField(
          columnDef.children,
          field,
          value,
        ),
      };
    }
    return {
      ...columnDef,
      [field]: value,
    };
  });

export const getEntityDefault = <T extends Entity>(
  params: ColDefParams<T>,
): T => params.data;

/**
 * Helper fn to get the nested entity from the row data
 * Or fallback if the entity is not found
 * @param getNestedEntity Custom fn to get nested entity from row data
 * @returns Result of passed fn
 */
export const getPropOrFallback: getPropOrFallbackType =
  (getNestedEntity) =>
  (params, fn, fallback = null) => {
    const entity = getNestedEntity
      ? getNestedEntity(params)
      : params.data;
    return entity ? fn(entity) : fallback;
  };

/**
 * Toggle column visibility
 * @param params Column parameters
 * @param colId Column id. If not passed, use active column
 */
export const toggleColumnVisible = (
  params: { columnApi: ColumnApi; column?: Column },
  colId?: string,
): void => {
  const column = colId
    ? params.columnApi.getColumn(colId)
    : params.column;
  if (!column) {
    return;
  }
  params.columnApi.setColumnVisible(column, !column.isVisible());
};

/**
 * Check if user has edit access to entity
 * @param getNestedEntity Custom fn to get nested entity from row data
 * @returns Editable callback
 */
export const getEditable =
  (
    getNestedEntity?: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params: ColDefParams<any>,
    ) => (Entity & { entityName: string }) | null | undefined,
    fields?: (entity: any) => string | string[],
  ): EditableCallback =>
  (params: any) => {
    const entity =
      getNestedEntity?.(params) || getEntityDefault(params);
    let hasAccess = false;

    if (
      entity.entityName === 'metadata' &&
      (entity as Metadata).is_template
    ) {
      const selectHasAccessCreate = makeSelectHasAccessCreate();
      hasAccess = selectHasAccessCreate(
        store.getState() as any,
        'metadata',
        params.data.metadata_thread.entityName,
        params.data.metadata_thread.id,
      );
    } else {
      hasAccess =
        params.context?.editable !== false &&
        !!selectHasAccess(
          store.getState(),
          entity.entityName,
          entity.idAttr,
          Action.edit,
          fields?.(entity),
        );
    }
    return hasAccess;
  };

/**
 * Get total height of displayd rows
 * @param params Column parameters
 * @param withHeader Add header height
 * @returns
 */
export const getHeightOfRows = (
  params: { api: GridApi },
  withHeader?: boolean,
): number => {
  const { rowHeight, headerHeight } =
    params.api.getSizesForCurrentTheme();
  const totalHeight = rowHeight * params.api.getDisplayedRowCount();
  if (withHeader && headerHeight) {
    return totalHeight + headerHeight;
  }
  return totalHeight;
};

/**
 * Reset column definitions and apply previous column state
 * @param params Column parameters
 * @param columnDefs Column definitions
 */
export const refreshColumns = (
  params: { api: GridApi; columnApi: ColumnApi },
  columnDefs: (ColDef | ColGroupDef)[],
): void => {
  const state = params.columnApi?.getColumnState();
  params.api.setColumnDefs(columnDefs);
  if (state) {
    params.columnApi.applyColumnState({
      state,
      applyOrder: true,
    });
  }
};

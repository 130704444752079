/**
 * Labstep
 *
 * @module models/entity-snapshot
 * @desc Typescript export class for EntitySnapshot
 */

import { Entity } from 'labstep-web/models/entity.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { Protocol } from 'labstep-web/models/protocol.model';

export class EntitySnapshot extends Entity {
  static readonly entityName = 'entity_snapshot';

  get entityName(): string {
    return EntitySnapshot.entityName;
  }

  constructor(data: Partial<EntitySnapshot> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  created_at!: string;

  object_id!: number;

  object_class!: string;

  get objectClassToEntityName(): string {
    if (this.object_class === 'Protocol') {
      return Protocol.entityName;
    }
    if (this.object_class === 'Experiment') {
      return Experiment.entityName;
    }
    if (this.object_class === 'ProtocolTable') {
      return ProtocolTable.entityName;
    }
    return '';
  }

  static entityNameToObjectClass(entityName: string): string {
    if (entityName === Protocol.entityName) {
      return 'Protocol';
    }
    if (entityName === Experiment.entityName) {
      return 'Experiment';
    }
    if (entityName === ProtocolTable.entityName) {
      return 'ProtocolTable';
    }
    return '';
  }
}

/**
 * Labstep
 *
 * @module components/Folder/Breadcrumb
 * @desc Breadcrumb for Folder
 */

import { ParamsContext } from 'labstep-web/contexts/params';
import DropdownPortalHover from 'labstep-web/core/Dropdown/Portal/Hover';
import Icon from 'labstep-web/core/Icon';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import SubHeader from 'labstep-web/core/SubHeader';
import { ParamsHOC } from 'labstep-web/hoc/Params';
import React from 'react';
import styles from './styles.module.scss';
import { IScreensIndexBreadcrumbProps } from './types';

export const ScreensIndexBreadcrumb: React.FC<
  IScreensIndexBreadcrumbProps
> = ({
  text,
  onClick,
  right,
  icon,
  dropdownContent,
  rightMobileIcon,
}) => (
  <ParamsHOC historyAction="replace">
    <ParamsContext.Consumer>
      {({ searchParams }) => {
        const Right = right && right({ searchParams });

        const ViewComponent = () => (
          <SubHeader className={styles.subHeader}>
            <div className={styles.container}>
              <div onClick={onClick}>
                <Icon name={icon} />
                {text}
              </div>
              {Right && (
                <div className={styles.right}>
                  <Icon name="chevron right" size="small" />
                  {Right}
                </div>
              )}
            </div>
          </SubHeader>
        );

        return (
          <Desktop>
            {({ match }) => {
              if (dropdownContent && !match) {
                return (
                  <DropdownPortalHover
                    trigger={
                      <SubHeader className={styles.subHeader}>
                        {Right ? (
                          <div className={styles.mobile}>
                            <Icon name={rightMobileIcon} />
                            {Right}
                            <Icon name="chevron down" />
                          </div>
                        ) : (
                          <div>
                            <Icon name={icon} />
                            {text}
                            <Icon name="chevron down" />
                          </div>
                        )}
                      </SubHeader>
                    }
                    content={dropdownContent({
                      header: (
                        <div onClick={onClick}>
                          <Icon name={icon} />
                          {text}
                        </div>
                      ),
                    })}
                  />
                );
              }
              return <ViewComponent />;
            }}
          </Desktop>
        );
      }}
    </ParamsContext.Consumer>
  </ParamsHOC>
);

export default ScreensIndexBreadcrumb;

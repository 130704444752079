/**
 * Labstep
 *
 * @module screens/OrderRequest/Index/LeftPane
 * @desc OrderRequest Index LeftPane
 */

import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import OrderRequestFilterPane from 'labstep-web/components/OrderRequest/Filter/Pane';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import React from 'react';
import { OrderRequestIndexLeftPaneProps } from './types';

export const OrderRequestIndexLeftPane: React.FC<
  OrderRequestIndexLeftPaneProps
> = ({ links }) => (
  <LayoutLeftPane links={links}>
    <ParamsStateContextProvider
      value={{ destination: 'order_request_index' }}
    >
      <OrderRequestFilterPane />
    </ParamsStateContextProvider>
  </LayoutLeftPane>
);

export default OrderRequestIndexLeftPane;

/**
 * Labstep
 *
 * @module screens/ResourceItem/Index/LeftPane
 * @desc ResourceItem Index LeftPane
 */

import EntityTemplatePane from 'labstep-web/components/Entity/Template/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import ResourceItemFilterPane from 'labstep-web/components/ResourceItem/Filter/Pane';
import ResourceLocationPane from 'labstep-web/components/ResourceLocation/Pane';
import ResourceTemplatePreview from 'labstep-web/components/ResourceTemplate/Preview';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { IResourceItemIndexLeftPaneProps } from './types';

export const ResourceItemIndexLeftPane: React.FC<
  IResourceItemIndexLeftPaneProps
> = ({ links }) => (
  <LayoutLeftPane links={links}>
    <ParamsStateContextProvider value={{}}>
      <EntityTemplatePane
        entityPreview={({ entity, ...rest }): React.ReactElement => (
          <ResourceTemplatePreview
            resource={entity as Resource}
            {...rest}
          />
        )}
        entityName={Resource.entityName}
        showUncategorised={false}
        indexRoute="group_resource_items"
      />
    </ParamsStateContextProvider>
    <ParamsStateContextProvider
      value={{ destination: 'resource_item_index' }}
    >
      <ResourceLocationPane usePostFilter />
      <ResourceItemFilterPane />
    </ParamsStateContextProvider>
  </LayoutLeftPane>
);

export default ResourceItemIndexLeftPane;

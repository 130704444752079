/**
 * Labstep
 *
 * @module components/EntityView/Form/ProtocolCollection
 * @desc Select ProtocolCondition to initialize report
 */

import EntitySearchMulti from 'labstep-web/components/Entity/Search/Multi';
import EntityViewFormCreateListItem from 'labstep-web/components/EntityView/Form/Create/ListItem';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { navigation } from 'labstep-web/services/navigation';
import {
  PostFilterComparison,
  PostFilterOperator,
} from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { withRouter } from 'react-router';
import { EntityViewFormCreateMetadataProps } from './types';

export const EntityViewFormCreateMetadata: React.FC<
  EntityViewFormCreateMetadataProps
> = ({ group, folderId, onSuccess, history }) => (
  <EntityCreateContainer entityName={EntityView.entityName}>
    {({ create }) => (
      <EntitySearchMulti
        lists={[
          {
            key: Metadata.entityName,
            entityName: Metadata.entityName,
            searchField: 'label_like',
            params: {
              count: 5,
              group_id: group.guid,
              serializerGroups:
                'protocol_condition_reporting,paginator',
              permission_class: 'experiment',
              group_by_label: 1,
              sort: 'label',
              skip_total: 1,
            },
            header: '',
            listItem: (entity: object) => (
              <ReadOnMountHOC
                type="count"
                entityName={Metadata.entityName}
                params={{
                  count: 1,
                  group_id: group.guid,
                  serializerGroups: 'paginator',
                  permission_class: 'experiment',
                  label: (entity as Metadata).label,
                }}
                children={({ total }) => {
                  return (
                    <EntityViewFormCreateListItem
                      icon={ICONS.metadata.primary}
                      text={(entity as Metadata).label || ''}
                      count={total}
                      onClick={() => {
                        create(
                          {
                            entity_name: ProtocolCondition.entityName,
                            group_id: group.guid,
                            folder_id: folderId,
                            name: `Comparing ${
                              (entity as Metadata).label
                            }`,
                            column_definition_ids: [
                              (entity as Metadata).colId,
                              'experiment_name',
                              'protocol_name',
                              'experiment_started_at',
                              'experiment_ended_at',
                            ],
                            parameters: {
                              filter: [
                                {
                                  type: PostFilterOperator.and,
                                  predicates: [
                                    {
                                      type: PostFilterOperator.and,
                                      path: 'metadatas',
                                      predicates: [
                                        {
                                          attribute: 'label',
                                          comparison:
                                            PostFilterComparison.eq,
                                          value: (entity as Metadata)
                                            .label,
                                        },
                                        {
                                          attribute: 'value',
                                          comparison:
                                            PostFilterComparison.not_null,
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  type: PostFilterOperator.and,
                                  predicates: [],
                                },
                              ],
                            },
                          },
                          {
                            onSuccess: ({ response }) => {
                              onSuccess();
                              history.push(
                                navigation.get('entity_view_show', {
                                  id: group.id,
                                  entityViewId: response.result,
                                }),
                              );
                            },
                          },
                        );
                      }}
                    />
                  );
                }}
              />
            ),
          },
        ]}
      />
    )}
  </EntityCreateContainer>
);

export default withRouter(EntityViewFormCreateMetadata);

/**
 * Labstep
 *
 * @module report/services/grid-filter
 * @desc Grid Service to inject/remove filter
 */

import { EntityViewParameters } from 'labstep-web/models/entity-view.model';
import {
  PostFilterNodeType,
  PostFilterOperator,
} from 'labstep-web/services/query-parameter.service';
import { cloneDeep } from 'lodash';

export enum PostFilterFieldTypes {
  date = 'date',
  text = 'text',
  numeric = 'numeric',
  boolean = 'boolean',
  options = 'options',
  custom = 'custom',
}

export interface PostFilterSettings {
  fieldType?: PostFilterFieldTypes;
  field?: string;
  path?: string;
  multiplicator?: number;
  entityName?: string;
}

export class GridFilterService {
  public static injectUserFilter = (
    node: PostFilterNodeType,
    parameters?: EntityViewParameters | null,
  ): EntityViewParameters => {
    if (
      parameters &&
      parameters.filter &&
      parameters.filter.length === 2 &&
      parameters.filter[0].predicates.length === 0
    ) {
      const newParameters = cloneDeep(parameters);
      if (
        newParameters &&
        newParameters.filter &&
        newParameters.filter.length === 2 &&
        newParameters.filter[0].predicates.length === 0
      ) {
        newParameters.filter[0].predicates.push(node);
      }
      return newParameters;
    }
    return {
      filter: [
        {
          type: PostFilterOperator.and,
          predicates: [node],
        },
        {
          type: PostFilterOperator.and,
          predicates: [],
        },
      ],
    };
  };

  public static injectSystemFilter = (
    node: PostFilterNodeType,
    parameters?: EntityViewParameters | null,
  ): EntityViewParameters => {
    if (
      parameters &&
      parameters.filter &&
      parameters.filter.length === 2 &&
      parameters.filter[1].predicates.length === 0
    ) {
      const newParameters = cloneDeep(parameters);
      if (
        newParameters &&
        newParameters.filter &&
        newParameters.filter.length === 2 &&
        newParameters.filter[1].predicates.length === 0
      ) {
        newParameters.filter[1].predicates.push(node);
      }
      return newParameters;
    }
    return {
      filter: [
        {
          type: PostFilterOperator.and,
          predicates: [],
        },
        {
          type: PostFilterOperator.and,
          predicates: [node],
        },
      ],
    };
  };

  public static removeAllSystemFilters = (
    parameters: EntityViewParameters | null,
  ): EntityViewParameters | null => {
    if (
      parameters &&
      parameters.filter &&
      parameters.filter.length === 2 &&
      parameters.filter[1].predicates.length === 1
    ) {
      const newParameters = cloneDeep(parameters);
      if (newParameters.filter) {
        newParameters.filter[1].predicates = [];
      }
      return newParameters;
    }
    return parameters;
  };
}

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/ResourceLocation/Form
 * @desc Filter ResourceLocation form
 */

import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import SearchSelect from 'labstep-web/core/Select/Search';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { PostFilterResourceLocation } from 'labstep-web/services/postFilter/filters/resourceLocation';
import React from 'react';
import { IFilterResourceLocationFormProps } from './types';

const FilterResourceLocationForm: React.FC<
  IFilterResourceLocationFormProps
> = ({ addPostFilter, onDone, activeGroup }) => {
  if (!activeGroup) {
    return null;
  }

  return (
    <ParamsStateContextProvider value={{}}>
      <SearchSelect
        entityName={ResourceLocation.entityName}
        isClearable
        params={{
          group_id: activeGroup?.id,
        }}
        onChange={(selectedOption) => {
          if (addPostFilter) {
            addPostFilter(
              PostFilterResourceLocation.getNode([
                selectedOption?.guid,
              ]),
            );
          }
          onDone();
        }}
      />
    </ParamsStateContextProvider>
  );
};

export default withActiveGroup(FilterResourceLocationForm);

/**
 * Labstep
 *
 * @module state/slices/paramsHoc
 * @desc Slice for ParamsHOC
 */

import {
  PayloadAction,
  type SliceCaseReducers,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { ParamsState, SetParamsPayload } from './types';

export const paramsHocSlice = createSlice<
  ParamsState,
  SliceCaseReducers<ParamsState>
>({
  name: 'paramsHoc',
  initialState: {},
  reducers: {
    setParams(state, action: PayloadAction<SetParamsPayload>) {
      const { name, params } = action.payload;

      if (!name) {
        return state;
      }

      return {
        ...state,
        [name]: params,
      };
    },
  },
});

// Base selector
const selectParamsState = (state: any) => state.paramsHoc;

/**
 * Creates a memoized selector for params by context name
 * @param name - The context name to select params for
 * @returns A selector function that returns params for the given context
 */
export const makeSelectParams = (name?: string) =>
  createSelector([selectParamsState], (paramsHoc) =>
    name ? paramsHoc[name] || {} : {},
  );

/**
 * Selects params for a given context name
 * @deprecated Use makeSelectParams instead for better memoization
 */
export const selectParams = (state: any, name?: string) =>
  makeSelectParams(name)(state);

export const { setParams } = paramsHocSlice.actions;

export default paramsHocSlice.reducer;

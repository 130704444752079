/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/metadata/molecule/safety
 * @desc Column Definition for metadata molecule safety
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import MoleculeSafety from 'labstep-web/components/Molecule/Safety';
import Pictograms from 'labstep-web/components/Molecule/Safety/Pictograms';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import {
  CellEditorParams,
  CellRendererParams,
} from 'labstep-web/core/DataGrid/types';
import {
  createOrUpdateValue,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridColumnDefinitionService } from 'labstep-web/grid/services/grid-column-definition.service';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';

export const getMetadataMoleculeSafetyColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
): NewIndexColDef => {
  return {
    colId: `metadata:${parentEntityClass}:${type}:${metadataLabel}:safety`,
    postFilterSettings: {
      path: 'metadatas',
    },
    sortable: true,
    headerName: 'Safety',
    headerComponent: GridNewIndexColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.type.molecule,
    },
    editable: (params: EditableCallbackParams) =>
      GridNewIndexService.getEditable(params),
    valueGetter: (params: ValueGetterParams): string | null => {
      // const entity = getEntity(params);
      // if (entity instanceof Molecule && entity.properties) {
      //   return JSON.stringify(entity.properties.Safety);
      // }
      return null;
    },
    valueSetter: (params: ValueSetterParams): boolean => {
      // FIXME
      return false;
    },
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return (
          <TextPlaceholder
            onClick={() => {
              createOrUpdateValue({
                entityName: Metadata.entityName,
                body: {
                  type,
                  label: metadataLabel,
                  metadata_thread_id:
                    params.node.data.metadata_thread.guid,
                  molecule: { name: 'Untitled structure' },
                },
                createProps: {
                  parentName:
                    params.node.data.metadata_thread.entityName,
                  parentId: params.node.data.metadata_thread.idAttr,
                },
                options:
                  GridColumnDefinitionService.getOptions(params),
              });
              return true;
            }}
          >
            Create structure
          </TextPlaceholder>
        );
      }

      if (!metadata.molecule.properties) {
        return (
          <DataGridPlaceholder
            params={params}
            editable={GridNewIndexService.getEditable(params)}
            placeholder="Enter"
          />
        );
      }

      if (
        metadata.molecule.properties.Safety.pictograms?.length === 0
      ) {
        return (
          <DataGridPlaceholder
            params={params}
            editable={GridNewIndexService.getEditable(params)}
            placeholder="Enter"
          />
        );
      }

      return (
        <Pictograms
          pubChemMetadata={metadata.molecule.properties}
          isInline
          size="mini"
        />
      );
    },
    cellEditorPopup: true,
    cellEditor: (params: CellEditorParams<Entity>) => {
      if (!params.node) {
        return '';
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return null;
      }
      return (
        <MoleculeSafety
          parent={metadata.molecule}
          pubChemMetadata={metadata.molecule.properties}
          modalProps={{
            open: true,
            onClose: () => stopEditingAndFocus(params),
            viewComponent: null,
          }}
        />
      );
    },
  };
};

/**
 * Labstep
 *
 * @module components/ProtocolValue/Detail/Settings
 * @desc ProtocolValue Detail Settings
 */

import EntityDetailTable from 'labstep-web/components/Entity/Detail/Table';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import Collapsible from 'labstep-web/core/Collapsible';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { IProtocolValueDetailSettingsProps } from './types';
import { getSettingsRows } from './utils';

export const ProtocolValueDetailSettings: React.FC<
  IProtocolValueDetailSettingsProps
> = ({ protocolValue }) => {
  const { isOpen } = useProtocolConditionModalContext();
  const { state } = useLocation();
  const settings = (
    <EntityDetailTable
      dataCy="components/ProtocolValue/Detail/Settings/Table"
      columns={[protocolValue]}
      rows={getSettingsRows(protocolValue)}
    />
  );
  return isOpen ? (
    settings
  ) : (
    <Collapsible
      dataCy="components/ProtocolValue/Detail/Settings"
      label={(expanded): React.ReactElement => (
        <span>
          <Icon name="cog" />
          {`${expanded ? 'Hide' : 'Show'} Settings`}
        </span>
      )}
      component={settings}
      // true when experimentValue first created
      initialExpandedState={(state as any)?.expanded}
      withChevron={false}
    />
  );
};

export default ProtocolValueDetailSettings;

/**
 * Labstep
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import rules from 'labstep-web/services/validation/rules';
import { string } from 'yup';

export const COMPARISON_OPTIONS = [
  {
    label: 'Has value',
    value: PostFilterComparison.not_null,
  },
  {
    label: 'Exact match',
    value: PostFilterComparison.eq,
  },
];

export const fieldComparison: FieldWithoutActionProps = {
  fieldLabel: 'Comparison',
  name: 'comparison',
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: COMPARISON_OPTIONS.map((option) => ({
      value: option.value,
      label: option.label,
    })),
  },
  validation: rules.metadata.comparison,
};

export const fieldText: FieldWithoutActionProps = {
  fieldLabel: 'Text',
  name: 'value',
  fieldType: FieldType.Text,
  validation: string().required(),
};

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Date/Active/Post
 * @desc Active node component for date
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/FilterPost/Active/Label/types';
import FilterPostNewIndexDate from 'labstep-web/components/FilterPost/NewIndex/Date';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { dateOnly } from 'labstep-web/services/date.service';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { getPostFilterDate } from '../../Form';
import { COMPARISON_OPTIONS } from '../../Form/fields';

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadable {
  lt = 'Before',
  gt = 'After',
  not_null = 'Has value',
}

const FilterPostNewIndexDateActivePost: React.FC<
  FilterPostActiveLabelProps
> = ({ node, onRemoveClick, index }) => {
  const DATE_FILTERS = [
    getPostFilterDate('created_at', PostFilterComparison.not_null),
  ];
  const { addPostFilter } = useParamsContext();

  const filterActive = DATE_FILTERS.find((filter) => {
    return filter.isNodeActive(node);
  });
  if (!filterActive) {
    return null;
  }

  const path = filterActive.getPath() || '';
  const attributes = filterActive.getAttributeValues(node);

  const label = filterActive.getHumanReadableString(node) || '';
  const field = Object.keys(attributes)[0];
  const { comparison } = Object.values(attributes)[0];
  let value =
    comparison === PostFilterComparison.not_null
      ? ''
      : Object.values(attributes)[0].value;

  if (node.predicates.length > 1) {
    value = node.predicates
      .map((predicate) => `${dateOnly(predicate.value as string)}`)
      .join(' - ');
  }

  const defaultValues = {
    comparison: COMPARISON_OPTIONS.find(
      (option) => option.value === comparison,
    ),
    value,
  };

  const postFilterSettings = {
    label,
    path,
    field,
  };

  return (
    <FilterPostNewIndexDate
      label={label}
      path={postFilterSettings.path}
      field={postFilterSettings.field}
      addPostFilter={addPostFilter}
      defaultValues={defaultValues}
      viewComponent={({ toggleModal }) => (
        <RemoveLabel
          name={
            filterActive.getHumanReadableStringWithValue(node) ||
            `${label} within ${value}`
          }
          onNameClick={toggleModal}
          onRemoveClick={onRemoveClick}
        />
      )}
      replace={index}
    />
  );
};

export default FilterPostNewIndexDateActivePost;

/**
 * Labstep
 *
 * @module screens/EntityView/Show
 * @desc EntityView Show
 */

import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { ProtocolConditionExplorerEmptyState } from 'labstep-web/components/ProtocolCondition/Explorer/EmptyState';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { ParamsStateContextType } from 'labstep-web/contexts/params-state/types';
import GridReport from 'labstep-web/grid/Report';
import { SYSTEM_FILTER_EXPERIMENT_WORKFLOW_IS_TEMPLATE_FALSE } from 'labstep-web/grid/Report/services/grid-report.service';
import { GridFilterService } from 'labstep-web/grid/services/grid-filter.service';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import EntityViewShowBreadcrumb from 'labstep-web/screens/EntityView/Show/Breadcrumb';
import MasterIndex from 'labstep-web/screens/Master/Index';
import React from 'react';
import {
  EntityViewShowContainerProps,
  IProtocolConditionShowProps,
} from './types';

export const ProtocolConditionShow: React.FC<
  IProtocolConditionShowProps
> = ({ entityView, groupId }) => {
  const breadcrumb = (
    <ParamsStateContextProvider value={{}}>
      <EntityViewShowBreadcrumb
        entityView={entityView}
        groupId={groupId}
      />
    </ParamsStateContextProvider>
  );
  const noResultsMessage = <ProtocolConditionExplorerEmptyState />;

  const globalParams: Record<
    string,
    string | number | Record<string, unknown>[]
  > = {
    group_id: groupId,
    serializerGroups: 'protocol_condition_reporting,paginator',
    sort: '-created_at',
    count: 50,
  };

  const paramsStateContext: ParamsStateContextType = {
    name: 'entity_view_show',
    globalParams,
    initialSearchParams:
      GridFilterService.injectSystemFilter(
        SYSTEM_FILTER_EXPERIMENT_WORKFLOW_IS_TEMPLATE_FALSE,
        entityView.parameters,
      ) || {},
    entityView,
    loadedEntityView: new EntityView({
      ...entityView,
    }),
  };

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <MasterIndex
        breadcrumb={breadcrumb}
        entityName={ProtocolCondition.entityName}
        noResultsMessage={noResultsMessage}
        tableFormat={false}
        hideSearch
        usePostFilter
        useAllPages
      >
        {({
          entities,
          readNextPage,
          status,
        }: EntitySearchContainerChildrenProps) => (
          <GridReport
            entityView={entityView}
            protocolConditions={entities}
            loadMore={readNextPage}
            status={status}
          />
        )}
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

const EntityViewShowContainer: React.FC<
  EntityViewShowContainerProps
> = ({ match }) => (
  <ReadOnMountHOC
    type="entities"
    entityName={EntityView.entityName}
    params={{
      get_single: 1,
      group_id: match.params.id,
      id: match.params.entityViewId,
      entity_name: 'protocol_condition',
      context: 'reporting',
      serializerGroups: 'entity_view_reporting',
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }: { entity: EntityView }) => {
      return (
        <ProtocolConditionShow
          groupId={match.params.id}
          entityView={entity}
        />
      );
    }}
  />
);

export default EntityViewShowContainer;

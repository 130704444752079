/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/entity/entity-users
 * @desc Column Definition for entity entity_users
 */

import { ValueGetterParams } from 'ag-grid-community';
import EntityUserActionManage from 'labstep-web/components/EntityUser/Action/Manage';
import EntityUserActionManageList from 'labstep-web/components/EntityUser/Action/Manage/List';
import { ICONS } from 'labstep-web/constants/icons';
import {
  CellEditorParams,
  CellRendererParams,
} from 'labstep-web/core/DataGrid/types';
import { stopEditingAndFocus } from 'labstep-web/core/DataGrid/utils';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import {
  EntityUser,
  EntityUserEntities,
} from 'labstep-web/models/entity-user.model';

export const colDefEntityEntityUsers = {
  colId: 'entity_users',
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.text,
    field: 'username',
    path: 'assigned_to',
  },
  headerName: 'Collaborators',
  headerComponentParams: {
    icon: ICONS.user.secondary,
  },
  editable: false,
  cellStyle: { display: 'flex', alignItems: 'center' },
  valueGetter: (params: ValueGetterParams): number =>
    params.data.entity_users_count,
  valueGetterExport: (params: ValueGetterParams) =>
    params.node?.data && params.node?.data?.entity_users_preview
      ? params.node?.data?.entity_users_preview
          .map(
            (entityUser: EntityUser) => entityUser.user?.name || '',
          )
          .join(', ')
      : '',
  cellRenderer: (params: CellRendererParams<EntityUserEntities>) => {
    if (!params.data) {
      return '';
    }
    return <EntityUserActionManageList entity={params.data} />;
  },
  cellEditorPopup: true,
  cellEditor: (params: CellEditorParams<EntityUserEntities>) => {
    const { data } = params;
    return (
      <EntityUserActionManage
        entity={data}
        viewComponent={null}
        open
        onClose={(): void => stopEditingAndFocus(params)}
      />
    );
  },
};

/**
 * Labstep
 *
 * @module components/Log/List
 * @desc List of logs
 */

import classnames from 'classnames';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import Icon from 'labstep-web/core/Icon';
import { Size } from 'labstep-web/core/Initials/types';
import Loader from 'labstep-web/core/Loader';
import Popup from 'labstep-web/core/Popup';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Log } from 'labstep-web/models/log.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  LogLegacyListContainerProps,
  LogLegacyListProps,
} from './types';

export const LogLegacyList: React.FC<LogLegacyListProps> = ({
  logs,
  getOnClickFunction,
  activeLogId,
  showExternalIcon = true,
}) => {
  return (
    <div className={styles.list}>
      {logs.map((log: Log) => {
        let onClick = getOnClickFunction
          ? getOnClickFunction(log)
          : undefined;
        let action = null;

        if (log.is_log_entry_snapshot_available === false) {
          action = (
            <Popup
              trigger={<Loader size="tiny" inline active />}
              content="Processing..."
              size="mini"
              position="top left"
            />
          );
          onClick = undefined;
        } else if (!!onClick && showExternalIcon) {
          action = <Icon name="external alternate" />;
        }

        return (
          <div
            key={log.id}
            onClick={onClick}
            className={classnames(styles.item, {
              [styles.active]: activeLogId === log.id,
              [styles.selectable]: !!onClick,
            })}
          >
            <ProfilePicture
              size={Size.mini}
              entity={log.author}
              linkable={false}
            />
            <div className={styles.message}>
              <span>{humanReadableDate(log.created_at)}</span>
              <div>{`${log.author.name} ${log.message}`}</div>
            </div>
            {action}
          </div>
        );
      })}
    </div>
  );
};

export const LogLegacyListContainer: React.FC<
  LogLegacyListContainerProps
> = ({ entity, extraParams, ...rest }) => (
  <ReadOnMountHOC
    type="cursor"
    pagination={{
      infinite: false,
    }}
    entityName="log"
    params={{
      [`${entity.entityName}_id`]: entity.id,
      has_request_trace: false,
      ...extraParams,
    }}
    children={({ entities }) => (
      <LogLegacyList logs={entities} {...rest} />
    )}
  />
);

export default LogLegacyListContainer;

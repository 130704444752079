/**
 * Labstep
 *
 * @module screens/JupyterNotebook/Show
 * @desc JupyterNotebook Show Screen
 */

import DocumentHead from 'labstep-web/core/DocumentHead';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';
import MasterShow from 'labstep-web/screens/Master/Show';
import React from 'react';
import Center from './Center';
import Right from './Right';
import {
  IJupyterNotebookShowScreenContainerProps,
  IJupyterNotebookShowScreenProps,
} from './types';

export const JupyterNotebookShowScreen: React.FC<
  IJupyterNotebookShowScreenProps
> = ({ jupyterNotebook }) => (
  <>
    <DocumentHead title={jupyterNotebook.name} />
    <MasterShow
      entity={jupyterNotebook}
      center={<Center jupyterNotebook={jupyterNotebook} />}
      right={<Right jupyterNotebook={jupyterNotebook} />}
    />
  </>
);

const JupyterNotebookShowScreenContainer: React.FC<
  IJupyterNotebookShowScreenContainerProps
> = ({ match }) => (
  <ReadOnMountHOC
    type="entities"
    entityName={JupyterNotebook.entityName}
    params={{
      get_single: 1,
      is_deleted: 'both',
      id: match.params.id,
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }) => (
      <JupyterNotebookShowScreen jupyterNotebook={entity} />
    )}
  />
);

export default JupyterNotebookShowScreenContainer;

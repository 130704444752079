/**
 * Labstep
 *
 * @module core/Dropdown/Pill
 * @desc Dropdown Pill component
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Dropdown from 'labstep-web/core/Dropdown';
import React from 'react';
import DropdownPillTrigger from './Trigger';
import { DropdownPillProps } from './types';

const DropdownPill: React.FC<DropdownPillProps> = ({
  items,
  disabled,
  ...rest
}) => {
  const trigger = (
    <DropdownPillTrigger {...rest} disabled={disabled} />
  );
  return disabled ? (
    trigger
  ) : (
    <Dropdown.Dropdown
      trigger={trigger}
      icon={null}
      data-testid="dropdown-pill"
      data-cy="core/Dropdown/Pill"
    >
      <Dropdown.Menu>
        {items.map((item) => (
          <Dropdown.Item
            key={item.label}
            onClick={item.onClick}
            data-testid={item.label}
          >
            <ActionComponent
              dataCy={`core/Dropdown/Pill:${item.label}`}
              type="option"
              premiumFeature={item.premiumItem?.premiumFeature}
              text={item.label}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown.Dropdown>
  );
};

export default DropdownPill;

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/ResourceLocation
 * @desc ResourceLocation filter
 */

import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React from 'react';
import FilterResourceLocationForm from './Form';
import { FilterPostNewIndexResourceLocationProps } from './types';

const metadataNameCapitalised =
  ResourceLocation.getHumanReadableEntityName(false, true);

const FilterPostNewIndexResourceLocation: React.FC<
  FilterPostNewIndexResourceLocationProps
> = ({ addPostFilter, ...rest }) => (
  <Modal
    header={`Filter by ${metadataNameCapitalised}`}
    viewComponent={({ toggleModal }): React.ReactNode => (
      <ActionComponent
        onClick={toggleModal}
        type="option"
        icon={ICONS.metadata.secondary}
        text="Filter by this field"
      />
    )}
    content={({ toggleModal }): React.ReactNode => (
      <FilterResourceLocationForm
        addPostFilter={addPostFilter}
        onDone={toggleModal}
      />
    )}
    {...rest}
  />
);

export default FilterPostNewIndexResourceLocation;

/**
 * Labstep
 *
 * @module components/Log/List
 * @desc Display a Log in a list
 */

import classnames from 'classnames';
import EntitySnapshotList from 'labstep-web/components/EntitySnapshot/List';
import EntitySnapshotModal from 'labstep-web/components/EntitySnapshot/Modal';
import LogBreadcrumb from 'labstep-web/components/Log/Breadcrumb';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import UserShowProfile from 'labstep-web/components/User/Show/Profile';
import Icon from 'labstep-web/core/Icon';
import { Size } from 'labstep-web/core/Initials/types';
import Popup from 'labstep-web/core/Popup';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import { LogListItemProps } from './types';

export const LogListItem: React.FC<LogListItemProps> = ({
  log,
  entityName,
  selectedEntitySnapshot,
  setSelectedEntitySnapshot,
}) => {
  const [showSnapshots, setShowSnapshots] = React.useState(false);

  return (
    <>
      <div
        key={log.id}
        className={classnames(styles.item)}
        onClick={
          setSelectedEntitySnapshot &&
          (() => setShowSnapshots(!showSnapshots))
        }
      >
        <Popup
          size="small"
          trigger={
            <div>
              <ProfilePicture
                size={Size.mini}
                entity={log.author}
                linkable={false}
              />
            </div>
          }
          content={`${log.author.name} - ${log.author.username} - ${log.clientDisplayLabel} - ${log.clientIp}`}
        />
        <div className={styles.message}>
          <span>{humanReadableDate(log.created_at)}</span>
          {entityName && (
            <LogBreadcrumb log={log} entityName={entityName} />
          )}
          <div className={styles.messageText}>
            <UserShowProfile
              user={log.author}
              viewComponent={
                <a style={{ cursor: 'pointer' }}>{log.author.name}</a>
              }
            />{' '}
            {log.message}
          </div>
        </div>
        {log.action === 'update' && !setSelectedEntitySnapshot && (
          <Popup
            size="small"
            position="left center"
            trigger={<EntitySnapshotModal log={log} />}
            content={log.diff}
          />
        )}
        {setSelectedEntitySnapshot && (
          <Icon
            name={showSnapshots ? 'chevron up' : 'chevron down'}
          />
        )}
      </div>
      {setSelectedEntitySnapshot && showSnapshots && (
        <div className={styles.snapshots}>
          <EntitySnapshotList
            log={log}
            selectedEntitySnapshot={selectedEntitySnapshot}
            setSelectedEntitySnapshot={setSelectedEntitySnapshot}
          />
        </div>
      )}
    </>
  );
};

export default LogListItem;

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Numeric/Form
 * @desc Filter Metadata form
 */

import {
  COMPARISON_OPTIONS,
  fieldComparison,
} from 'labstep-web/components/FilterPost/NewIndex/Numeric/Form/fields';
import ReusableFormGroup from 'labstep-web/core/Form/Reusable/Group';
import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React, { useCallback, useState } from 'react';
import { fieldNumber } from './fields';
import { IFilterNumericFormProps } from './types';

export const getPostFilterNumeric = (
  field: string,
  comparison: PostFilterComparison,
  path?: string,
) => {
  return new PostFilter([[field, comparison]], path);
};

const FilterNumericForm: React.FC<IFilterNumericFormProps> = ({
  addPostFilter,
  onDone,
  path,
  field,
  defaultValues,
  multiplicator,
}) => {
  const [fields, setFields] = useState([fieldComparison]);
  const onSubmit = useCallback(
    (values): void => {
      const { comparison } = values;
      let { value } = values;
      if (comparison.value === PostFilterComparison.not_null) {
        value = undefined;
      } else {
        value = multiplicator ? value * multiplicator : value;
      }
      addPostFilter(
        getPostFilterNumeric(field, comparison.value, path).getNode([
          value,
        ]),
        true,
      );
      onDone();
    },
    [path, addPostFilter],
  );

  return (
    <ReusableFormGroup
      fields={fields}
      onSubmit={onSubmit}
      onDone={onDone}
      defaultValues={{
        comparison: COMPARISON_OPTIONS[0],
        ...defaultValues,
      }}
      onFormChange={({ comparison }): void => {
        if (!comparison) {
          return;
        }
        const newFields =
          comparison.value === PostFilterComparison.not_null
            ? [fieldComparison]
            : [fieldComparison, fieldNumber];
        if (fields.length !== newFields.length) {
          setFields(newFields);
        }
      }}
      addAnother={false}
    />
  );
};

export default FilterNumericForm;

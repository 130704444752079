/**
 * Labstep
 *
 * @module report/services/grid-column-definition
 * @desc Helpers for column definitions
 */

import { ValueSetterParams } from 'ag-grid-community';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { stopEditingAndFocus } from 'labstep-web/core/DataGrid/utils';
import { APICallOptions } from 'labstep-web/models';
import { Entity } from 'labstep-web/models/entity.model';

export class GridColumnDefinitionService {
  public static refreshCell = (
    params: ValueSetterParams | CellRendererParams<Entity>,
  ): void => {
    if (!params.node) {
      return;
    }
    params.api.refreshCells({
      rowNodes: [params.node],
      columns: [params.column],
      force: true,
    });
  };

  public static getOptions = (
    params: ValueSetterParams | CellRendererParams<Entity>,
  ): APICallOptions => {
    return {
      onSuccess: (): void => {
        this.refreshCell(params);
        stopEditingAndFocus(params);
      },
    };
  };
}

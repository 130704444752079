/**
 * Labstep
 *
 * @module grid/Report
 * @desc Grid for Report
 */

import {
  ColDef,
  ColumnEverythingChangedEvent,
  ColumnMovedEvent,
  ColumnVisibleEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import DataGrid from 'labstep-web/core/DataGrid';
import { IDataGridProps } from 'labstep-web/core/DataGrid/types';
import GridReportColumnHeader from 'labstep-web/grid/Report/components/ColumnHeader';
import {
  useInfiniteScroll,
  useSideBar,
} from 'labstep-web/grid/Report/hooks';
import { GridReportService } from 'labstep-web/grid/Report/services/grid-report.service';
import { GridService } from 'labstep-web/grid/services/grid.service';
import { EntityView } from 'labstep-web/models/entity-view.model';
import React, { useCallback, useMemo } from 'react';
import { GridReportProps } from './types';

const GridReport: React.FC<GridReportProps> = ({
  entityView,
  protocolConditions,
  loadMore,
  status,
}) => {
  const [columnState, setColumnState] = React.useState<
    EntityView['state'] | undefined
  >(undefined);

  React.useEffect(() => {
    setColumnState(entityView.state);
  }, [entityView.id]);

  // Store entityView columns in context
  const paramsStateContext = useParamsStateContext();

  // Generate column definitions
  const columnDefs: ColDef[] =
    GridReportService.getColDefs(entityView);

  // Infinite scroll, enabled by passing a loadMore function
  const infiniteScrollProps = useInfiniteScroll(loadMore);

  // Initialise side bar
  useSideBar(entityView);

  // Set column header custom component
  const components = useMemo(
    () => ({
      agColumnHeader: GridReportColumnHeader,
    }),
    [],
  );

  // Get the row id from the idAttr field - Required for AG Grid
  const getRowId = useCallback<
    NonNullable<IDataGridProps['getRowId']>
  >((params) => params.data.idAttr, []);

  const storeState = (
    params:
      | ColumnEverythingChangedEvent
      | ColumnMovedEvent
      | ColumnVisibleEvent,
  ) => {
    if (paramsStateContext?.setEntityView) {
      const gridReportService = new GridReportService(
        params as unknown as AgGridReact,
      );
      const newState = gridReportService.getState();
      const newColumnDefinitionIds =
        gridReportService.getColumnDefinitionIds();

      entityView.state = newState;
      entityView.column_definition_ids = newColumnDefinitionIds;
      paramsStateContext.setEntityView(entityView);
    }
  };

  return (
    <DataGrid
      columnDefs={columnDefs}
      columnState={columnState || undefined}
      getRowId={getRowId}
      rowData={protocolConditions}
      status={status}
      components={components}
      {...infiniteScrollProps}
      onColumnEverythingChanged={storeState}
      onColumnMoved={storeState}
      onColumnVisible={storeState}
      onGridReady={(params): void => {
        infiniteScrollProps.onGridReady?.(params);
        const gridService = new GridService(
          params as unknown as AgGridReact,
        );
        gridService.setState(entityView.state);
      }}
    />
  );
};

export default GridReport;

/**
 * Labstep
 *
 * @module models/protocol-collection
 * @desc Typescript export class for ProtocolCollection
 */

import { Type } from 'class-transformer';
import { PermissionActions } from 'labstep-web/models';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { PermaLink } from 'labstep-web/models/perma-link.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { ShareLink } from 'labstep-web/models/share-link.model';
import { Tag } from 'labstep-web/models/tag.model';
import { Thread } from 'labstep-web/models/thread.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export class ProtocolCollection extends Entity {
  static readonly entityName = 'protocol_collection';

  get entityName(): typeof ProtocolCollection.entityName {
    return ProtocolCollection.entityName;
  }

  constructor(data: Partial<ProtocolCollection> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  name!: string;

  last_version_all_experiment_count!: number;

  entity_users_count!: number;

  last_version_id!: number;

  allowed_actions!: PermissionActions[];

  allowed_actions_lock!: PermissionActions[];

  public readonly all_versions_count!: number;

  @Type(() => Group)
  owner!: Group;

  @Type(() => User)
  author!: User;

  @Type(() => Protocol)
  last_version!: Protocol | null;

  @Type(() => Protocol)
  draft_version!: Protocol | null;

  @Type(() => Protocol)
  parent_protocol!: Protocol;

  @Type(() => Protocol)
  versions!: Protocol[];

  @Type(() => Tag)
  tags!: Tag[];

  @Type(() => Thread)
  thread!: Thread;

  @Type(() => ShareLink)
  share_link!: ShareLink;

  @Type(() => PermaLink)
  perma_link!: PermaLink;

  @Type(() => EntityUser)
  entity_users_preview!: EntityUser[];

  default_experiment_allowed_actions_lock!: PermissionActions[];

  get new_version_required(): boolean {
    return this.last_version_all_experiment_count > 0;
  }

  get last_or_draft_version(): Protocol {
    return (
      this.draft_version ? this.draft_version : this.last_version
    ) as Protocol;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}

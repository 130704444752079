/**
 * Labstep
 *
 * @module components/Entity/Template/Item
 * @desc An item to display a template in the template list
 */

import classnames from 'classnames';
import FilterActionToggleCollapse from 'labstep-web/components/Filter/Action/ToggleCollapse';
import FilterSectionContent from 'labstep-web/components/Filter/List/Content';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { EntityTemplateItemProps } from './types';

const EntityTemplateItem: React.FC<EntityTemplateItemProps> = ({
  component,
  icon,
  text,
  active,
  onClick,
  actionShortcuts,
  actionMenu,
  subFilters,
}) => (
  <>
    <div
      data-testid="filter-item"
      className={classnames(styles.container, {
        [styles.active]: active,
      })}
      data-cy={`components/Filter/Item:${text}`}
    >
      <div className={styles.innerContainer}>
        <div
          onClick={onClick}
          data-cy={`components/Filter/Item:name-container-${text}`}
        >
          <div>{component || <Icon name={icon} />}</div>
          <div className={styles.text}>
            <span>{text}</span>
          </div>
        </div>
        <div>{actionShortcuts}</div>
        {subFilters && subFilters.filters.length > 0 && (
          <FilterActionToggleCollapse filter={subFilters.filter} />
        )}
      </div>
      {actionMenu}
    </div>
    {subFilters && subFilters.filters.length > 0 && (
      <div className={styles.subFilter} data-testid="sub-filter">
        <FilterSectionContent
          filters={subFilters.filters}
          filter={subFilters.filter}
        />
      </div>
    )}
  </>
);

export default EntityTemplateItem;

/**
 * Labstep
 */

import ResourceItemResourceLocationModal from 'labstep-web/components/ResourceItem/ResourceLocationModal';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { ColDefParams } from 'labstep-web/core/DataGrid/types';
import { stopEditingAndFocus } from 'labstep-web/core/DataGrid/utils';
import { Entity } from 'labstep-web/models/entity.model';
import React from 'react';

export const getResourceLocationModal = (
  params: ColDefParams<Entity> & { rowIndex: number },
  editing: boolean,
): React.ReactElement => (
  <EntityReadEntityContainer
    entityName={params.data.entityName}
    id={params.data.id}
  >
    {({ entity: resourceItem }): JSX.Element => {
      const props = editing
        ? {
            hideMap: true,
            modalProps: {
              open: true,
              viewComponent: null,
              onClose: (): void => stopEditingAndFocus(params),
            },
          }
        : {};
      return (
        <ResourceItemResourceLocationModal
          resourceItem={resourceItem}
          {...props}
        />
      );
    }}
  </EntityReadEntityContainer>
);

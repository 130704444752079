/**
 * Labstep
 *
 * @module screens/ResourceLocation/Show/Right
 * @desc ResourceLocation Show Right
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import RightPane from 'labstep-web/components/Layout/RightPane';
import LogList from 'labstep-web/components/Log/List';
import ResourceItemEmptyState from 'labstep-web/components/ResourceItem/Card/EmptyState';
import ResourceItemList from 'labstep-web/components/ResourceItem/List';
import ResourceItemSearchResourceLocation from 'labstep-web/components/ResourceItem/Search/ResourceLocation';
import ResourceLocationManager from 'labstep-web/components/ResourceLocation/Manager';
import ThreadForm from 'labstep-web/components/Thread/Form';
import { ICONS } from 'labstep-web/constants/icons';
import { Entity } from 'labstep-web/models/entity.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';
import { IScreensResourceLocationShowRightProps } from './types';

const ScreensResourceLocationShowRight: React.FC<
  IScreensResourceLocationShowRightProps
> = ({ resourceLocation }) => (
  <RightPane
    route={{
      to: 'resource_location_show',
      params: { id: resourceLocation.guid },
    }}
    subPages={[
      {
        name: 'Sub-Locations',
        icon: ICONS.resource_location.primary,
        route: {
          to: 'resource_location_show_sublocations',
          params: { id: resourceLocation.guid },
        },
        component: (
          <ResourceLocationManager
            resourceLocation={resourceLocation}
          />
        ),
      },
      {
        name: 'Items',
        icon: ICONS.resource_item.primary,
        route: {
          to: 'resource_location_show_resource_items',
          params: { id: resourceLocation.guid },
        },
        component: (
          <ResourceItemSearchResourceLocation
            resourceLocation={resourceLocation}
          />
        ),
      },
      {
        name: 'Notes',
        icon: ICONS.thread.primary,
        count: resourceLocation.thread.comment_count,
        route: {
          to: 'resource_location_show_thread',
          params: { id: resourceLocation.guid },
        },
        component: (
          <ThreadForm
            createThreadId={resourceLocation.thread.id}
            readThreadIds={resourceLocation.thread.id}
            entityNameLabel="notes"
          />
        ),
      },
      {
        name: 'Activity',
        icon: 'history',
        route: {
          to: 'resource_location_show_activity',
          params: { id: resourceLocation.guid },
        },
        component: (
          <LogList
            entityName={resourceLocation.entityName}
            entityId={resourceLocation.id}
          />
        ),
      },
    ]}
  />
);

export default ScreensResourceLocationShowRight;

/**
 * Labstep
 *
 * @module components/FilterPost/Report/Metadata/Active/Post
 * @desc Active node component for metadata
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/FilterPost/Active/Label/types';
import GridReportFilterMetadata from 'labstep-web/components/FilterPost/Report/Metadata';
import { ICONS } from 'labstep-web/constants/icons';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import {
  MetadataType,
  MetadataTypeValues,
} from 'labstep-web/models/metadata/types';
import {
  getPostFilterMetadatasDate,
  getPostFilterMetadatasDefault,
  getPostFilterMetadatasNumeric,
  getPostFilterMetadatasOptions,
} from 'labstep-web/services/postFilter/filters/metadatas';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { getComparisonLabelDict } from '../../Form/fields';
import { FilterMetadataType } from '../../Form/types';

const PATHS = [
  'metadatas',
  'protocolValues.resourceItem.resource.metadatas',
  'protocolValues.resourceItem.metadatas',
];

const FILTERS = PATHS.flatMap((path) => [
  getPostFilterMetadatasDate(path),
  getPostFilterMetadatasDefault(path),
  getPostFilterMetadatasNumeric(path),
  getPostFilterMetadatasOptions(path),
]);

const FilterMetadataActivePost: React.FC<
  FilterPostActiveLabelProps
> = ({ node, onRemoveClick, index }) => {
  const { addPostFilter } = useParamsContext();
  const filterActive = FILTERS.find((filter) =>
    filter.isNodeActive(node),
  );
  if (!filterActive) {
    return null;
  }
  const attributes = filterActive.getAttributeValues(node);

  if (
    attributes?.value?.comparison === PostFilterComparison.not_null
  ) {
    return null;
  }

  let type: FilterMetadataType;
  let comparison: PostFilterComparison;
  let value: string;
  if (attributes.options) {
    type = MetadataType.options;
    comparison = attributes.options.comparison;
    value = attributes.options.value;
  } else if (attributes.date) {
    type = MetadataType.date;
    comparison = attributes.date.comparison;
    value = attributes.date.value;
  } else if (attributes.number) {
    type = MetadataType.numeric;
    comparison = attributes.number.comparison;
    value = attributes.number.value;
  } else {
    type = MetadataType.default;
    comparison = attributes.value.comparison;
    value = attributes.value.value;
  }

  const defaultValues = {
    label: attributes.label.value,
    type: {
      value: type,
      label:
        MetadataTypeValues[type as keyof typeof MetadataTypeValues],
    },
    comparison: {
      value: comparison,
      label: getComparisonLabelDict(type)[comparison],
    },
    value,
  };

  return (
    <GridReportFilterMetadata
      path={filterActive.getPath() || ''}
      addPostFilter={addPostFilter}
      defaultValues={defaultValues}
      viewComponent={({ toggleModal }) => (
        <RemoveLabel
          name={filterActive.getHumanReadableString(node)}
          icon={
            filterActive.getPath()?.includes('resource')
              ? ICONS.metadata.secondary
              : ICONS.metadata.primary
          }
          onNameClick={toggleModal}
          onRemoveClick={onRemoveClick}
        />
      )}
      replace={index}
    />
  );
};

export default FilterMetadataActivePost;

/**
 * Labstep
 *
 * @module screens/OrderRequest/Index
 * @desc OrderRequest Index
 */

import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import OrderRequestActionCreate from 'labstep-web/components/OrderRequest/Action/Create';
import { OrderRequestEmptyState } from 'labstep-web/components/OrderRequest/Card/EmptyState';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { ParamsStateContextType } from 'labstep-web/contexts/params-state/types';
import GridNewIndex from 'labstep-web/grid/NewIndex';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import OrderRequestIndexBreadcrumb from 'labstep-web/screens/OrderRequest/Index/Breadcrumb';
import OrderRequestIndexLeftPane from 'labstep-web/screens/OrderRequest/Index/LeftPane';
import React, { useMemo } from 'react';
import { bulkActions, filters, sortOptions } from './constants';
import { ScreensOrderRequestIndexMasterProps } from './types';

export const ScreensOrderRequestIndexMaster: React.FC<
  ScreensOrderRequestIndexMasterProps
> = ({ entityView, group, links }) => {
  const breadcrumb = useMemo(
    () => <OrderRequestIndexBreadcrumb />,
    [],
  );
  const leftPane = useMemo(
    () => <OrderRequestIndexLeftPane links={links} />,
    [links],
  );
  const noResultsMessage = useMemo(
    () => <OrderRequestEmptyState />,
    [],
  );
  const action = useMemo(() => <OrderRequestActionCreate />, []);

  const globalParams: Record<
    string,
    string | number | Record<string, unknown>[]
  > = {
    group_id: group.id,
  };

  const paramsStateContext: ParamsStateContextType = {
    name: 'order_request_index',
    globalParams,
    entityView,
    loadedEntityView: new EntityView({
      ...entityView,
    }),
  };

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <MasterIndex
        entityName={OrderRequest.entityName}
        breadcrumb={breadcrumb}
        leftPane={leftPane}
        filters={filters}
        noResultsMessage={noResultsMessage}
        action={action}
        bulkActions={bulkActions}
        sortOptions={sortOptions}
        useAllPages
        usePostFilter
      >
        {({
          entities,
          readNextPage,
          status,
        }: EntitySearchContainerChildrenProps) => (
          <GridNewIndex
            entityName={OrderRequest.entityName}
            group={group}
            entities={entities}
            entityView={entityView}
            status={status}
            loadMore={readNextPage}
          />
        )}
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

export default ScreensOrderRequestIndexMaster;

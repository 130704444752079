/**
 * Labstep
 *
 * @module grid/NewIndex/components/ColumnHeader/Trigger
 * @desc Default DataGrid ColumnHeader
 */

import classnames from 'classnames';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import React, { useRef } from 'react';
import styles from './styles.module.scss';
import { GridNewIndexColumnHeaderTriggerProps } from './types';

export const GridNewIndexColumnHeaderTrigger: React.FC<
  GridNewIndexColumnHeaderTriggerProps
> = ({
  onClick,
  displayName,
  icon,
  cornerIcon,
  action,
  column,
  secondaryHeader,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={ref}
      className={classnames(styles.defaultContainer, {
        [styles.clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      <div className={styles.title}>
        {icon && <Icon name={icon} cornerIcon={cornerIcon} />}{' '}
        <Flex column>
          {displayName}
          {secondaryHeader && (
            <span className={styles.secondaryHeader}>
              {secondaryHeader}
            </span>
          )}
        </Flex>
        <div className={styles.sort}>
          {column.getSort() === 'asc' && (
            <Icon name="long arrow alternate up" />
          )}
          {column.getSort() === 'desc' && (
            <Icon name="long arrow alternate down" />
          )}
        </div>
      </div>
      {action}
    </div>
  );
};

export default GridNewIndexColumnHeaderTrigger;

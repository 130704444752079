/**
 * Labstep
 *
 * @module grid/Index/coldefs/bulkSelect
 * @desc Column Definition for bulk select
 */

import styles from './styles.module.scss';

export const colDefBulkSelect = {
  colId: 'bulk_select',
  width: 20,
  pinned: 'left',
  checkboxSelection: true,
  headerCheckboxSelection: true,
  resizable: false,
  cellClass: styles.cell,
  lockPosition: true,
  valueGetter: () => '',
} as const;

export const colDefBulkSelectNoHeader = {
  ...colDefBulkSelect,
  headerCheckboxSelection: false,
} as const;

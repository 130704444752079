/**
 * Labstep
 *
 * @module components/BatchRequest/Progress
 * @desc Progress bar for batch requests
 */

import React, { useState, useEffect } from 'react';
import { ProgressContent } from './Content';
import { IBatchRequestProgressProps } from './Content/types';
import styles from './styles.module.scss';

export const BatchRequestProgress: React.FC<
  IBatchRequestProgressProps
> = (props) => {
  const [position, setPosition] = useState({ top: 10, right: 10 });
  const [dragging, setDragging] = useState(false);
  const [startMouse, setStartMouse] = useState({ x: 0, y: 0 });
  const [startPos, setStartPos] = useState({ top: 10, right: 10 });

  // Mouse down event for starting the drag
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
    setStartMouse({ x: e.clientX, y: e.clientY });
    setStartPos(position);
  };

  // Mouse move event to handle the dragging
  const handleMouseMove = (e: MouseEvent) => {
    if (!dragging) {
      return;
    }

    const deltaX = e.clientX - startMouse.x;
    const deltaY = e.clientY - startMouse.y;

    setPosition({
      top: startPos.top + deltaY,
      right: startPos.right - deltaX,
    });
  };

  // Mouse up event to stop dragging
  const handleMouseUp = () => {
    setDragging(false);
  };

  // UseEffect to handle the mouse move and mouse up globally
  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    // Clean up event listeners
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);

  return (
    <div
      onMouseDown={handleMouseDown}
      style={{
        right: `${position.right}px`,
        top: `${position.top}px`,
      }}
      className={styles.progress}
    >
      <ProgressContent {...props} />
    </div>
  );
};

import { IDeviceCalendarProps } from 'labstep-web/components/Device/Calendar/types';
import DeviceBookingModal from 'labstep-web/components/DeviceBooking/Modal';
import {
  useHasAccessCreate,
  useHasAccessCreateMultiple,
  useHasAccessMultiple,
} from 'labstep-web/components/Entity/Can/hooks';
import EntityLink from 'labstep-web/components/Entity/Link';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { EntityUpdateAnyContainer } from 'labstep-web/containers/Entity/Update/Any';
import Timeline from 'labstep-web/core/Timeline';
import { SearchHOC } from 'labstep-web/hoc/Search';
import { APIResponse } from 'labstep-web/models';
import { DeviceBooking } from 'labstep-web/models/device-booking.model';
import { Device } from 'labstep-web/models/device.model';
import { dateRange, format } from 'labstep-web/services/date.service';
import React from 'react';

const DeviceCalendar: React.FC<IDeviceCalendarProps> = ({
  activeEditModal,
  devices,
  setActiveEditModal,
}) => {
  // Store only the date range in state
  const [dateRange, setDateRange] = React.useState({
    start: Date.now(),
    end: Date.now(),
    viewType: 'resourceTimelineDay',
  });
  const resourceLabelContent = (props) => {
    const device = props.resource.extendedProps.device;
    return <EntityLink entity={device} />;
  };

  const handleDatesSet = (info) => {
    setDateRange({
      start: info.start,
      end: info.end,
      viewType: info.view.type,
    });
  };

  const canCreateDeviceBooking = useHasAccessCreateMultiple(
    DeviceBooking.entityName,
    DeviceBooking.entityName,
    devices.map((device) => device.id),
  );

  return (
    <>
      <SearchHOC
        entityName={DeviceBooking.entityName}
        params={{
          device_id: devices.map((device) => device.id).join(','),
          count: 100,
          started_at_within: format(dateRange.start),
          ended_at_within: format(dateRange.end),
        }}
      >
        {({
          entities: deviceBookings,
        }: { entities: DeviceBooking[] }) => {
          const events = deviceBookings.map((deviceBooking) => ({
            id: deviceBooking.id.toString(),
            start: deviceBooking.started_at,
            end: deviceBooking.ended_at,
            extendedProps: { deviceBooking },
            resourceIds: [deviceBooking.device.id.toString()],
            title: deviceBooking.author
              ? deviceBooking.author.name
              : '',
          }));

          return (
            <EntityUpdateAnyContainer
              entityName={DeviceBooking.entityName}
            >
              {({ statuses: updateStatuses, update }) => (
                <EntityCreateContainer
                  entityName={DeviceBooking.entityName}
                >
                  {({ create, status }) => {
                    const isCreating = status && status.isFetching;
                    const isUpdating = updateStatuses.some(
                      (updateStatus) =>
                        updateStatus?.isFetching || false,
                    );
                    const isLoading = isCreating || isUpdating;
                    return (
                      <Timeline
                        datesSet={handleDatesSet}
                        initialView="resourceTimelineDay"
                        slotLabelFormat={undefined}
                        events={events}
                        isTimeline
                        resourceAreaHeaderContent="Devices"
                        resources={devices.map((device) => ({
                          id: device.id.toString(),
                          extendedProps: { device },
                        }))}
                        resourceLabelContent={resourceLabelContent}
                        eventStartEditable={false}
                        eventChange={(props) => {
                          props.revert();
                          // props.revert();
                          // const { event } = props;
                          // setTimeout(() => {
                          //   update(
                          //     event.id,
                          //     {
                          //       started_at: format(event.start),
                          //       ended_at: format(event.end),
                          //     },
                          //     {
                          //       optimistic: true,
                          //     },
                          //   );
                          // }, 10);
                        }}
                        select={(event) => {
                          const resourceId = event.resource
                            ?.id as string;
                          if (
                            !canCreateDeviceBooking[
                              Number(resourceId)
                            ] ||
                            isLoading
                          ) {
                            return;
                          }
                          create(
                            {
                              started_at: format(event.start),
                              ended_at: format(event.end),
                              device_id: resourceId,
                            },
                            {
                              onSuccess: ({
                                response,
                              }: {
                                response: APIResponse;
                              }) =>
                                setActiveEditModal({
                                  entityName:
                                    DeviceBooking.entityName,
                                  id: response.result,
                                }),
                            },
                          );
                        }}
                        eventClick={({ event }) => {
                          setActiveEditModal({
                            entityName: DeviceBooking.entityName,
                            id: event.extendedProps.deviceBooking.id,
                          });
                        }}
                        // eventContent={({ event }) => (
                        //   <Event
                        //     deviceBooking={event.extendedProps.deviceBooking}
                        //     disabled={isLoading}
                        //   />
                        // )}
                        eventOverlap={false}
                        selectOverlap={false}
                        // selectable={canCreateDeviceBooking && !isLoading}
                        // editable={canEdit && !isLoading}
                      />
                    );
                  }}
                </EntityCreateContainer>
              )}
            </EntityUpdateAnyContainer>
          );
        }}
      </SearchHOC>

      {
        // Need to separate out the modal in case time grid refreshes
        activeEditModal &&
          activeEditModal.entityName === DeviceBooking.entityName && (
            <DeviceBookingModal
              deviceBookingId={activeEditModal.id as number}
              onClose={() => setActiveEditModal(null)}
            />
          )
      }
    </>
  );
};

export default withActiveEditModal(DeviceCalendar);

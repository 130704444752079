/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/resource-item/amount
 * @desc Column Definition for resource item amount
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { setValue } from 'labstep-web/core/DataGrid/utils';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { Entity } from 'labstep-web/models/entity.model';
import { number } from 'yup';

export const colDefResourceItemAmount = {
  colId: 'amount',
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.numeric,
    field: 'amount',
  },
  headerName: 'Amount Remaining',
  headerComponentParams: {
    icon: ICONS.resource_item.info.quantity,
  },
  editable: (params: EditableCallbackParams) =>
    GridNewIndexService.getEditable(params),
  valueGetter: (params: ValueGetterParams) => {
    return params.data.amount || 0;
  },
  valueGetterExport: (params: ValueGetterParams) =>
    params.node?.data?.amount
      ? String(params.node?.data?.amount)
      : '0',
  valueSetter: (params: any): boolean => {
    return setValue(
      'amount',
      params.newValue,
      {
        entityName: params.data.entityName,
        id: params.data.id,
      },
      number().required('Field must be a numeric value'),
    );
  },
  suppressKeyboardEvent: suppressKeyboardEventClearCells,
  valueClearer: () => '0',
  cellRenderer: (params: CellRendererParams<Entity>) => {
    if (!params.data) {
      return 0;
    }
    return params.data.amount || 0;
  },
};

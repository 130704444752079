/**
 * Labstep
 *
 * @module grid/NewIndex/components/EntityViewLoader
 * @desc Load an entity view based on the category id
 */

import { getDefaultColDefs } from 'labstep-web/grid/NewIndex/coldefs';
import { ReportingColDef } from 'labstep-web/grid/Report/coldefs/types';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import {
  ENTITY_VIEW_DEFAULT_PARAMETERS,
  EntityView,
} from 'labstep-web/models/entity-view.model';
import { LocationService } from 'labstep-web/services/location.service';
import React from 'react';
import { withRouter } from 'react-router';
import { GridNewIndexEntityViewLoaderProps } from './types';

const GridNewIndexEntityViewLoader: React.FC<
  GridNewIndexEntityViewLoaderProps
> = ({
  group,
  context,
  entityName,
  location,
  children,
  templateId,
  defaultTemplate,
  ...rest
}) => {
  const paramTemplateId =
    templateId ||
    LocationService.getQueryParameter(location, 'template_id');

  const columnDefs = getDefaultColDefs(
    context,
    entityName,
    defaultTemplate,
    paramTemplateId === 'none',
  );

  const defaultEntityView = new EntityView({
    group,
    context:
      context.includes('_index') && !paramTemplateId
        ? `${context}_all`
        : context,
    entity_name: entityName,
    templateId: paramTemplateId,
    column_definition_ids: columnDefs.map(
      (colDef: ReportingColDef) => colDef.colId || '',
    ),
    parameters: ENTITY_VIEW_DEFAULT_PARAMETERS,
  });

  const params: Record<string, unknown> = {
    group_id: group.guid,
    context:
      context.includes('_index') && !paramTemplateId
        ? `${context}_all`
        : context,
    entity_name: entityName,
    get_single: 1,
  };
  if (paramTemplateId && paramTemplateId !== 'none') {
    params[`${entityName}_template_id`] = paramTemplateId;
  } else {
    params.has_template = 0;
  }

  return (
    <ReadOnMountHOC
      key={paramTemplateId}
      type="entities"
      entityName={EntityView.entityName}
      params={params}
      loading={false}
      children={({ entity }) => {
        return children({
          entityView: entity || defaultEntityView,
          ...rest,
        });
      }}
    />
  );
};

export default withRouter(GridNewIndexEntityViewLoader);

/**
 * Labstep
 *
 * @module services/location
 * @desc Service to manipulate URL
 */

import { RouteComponentProps } from 'react-router';

export class LocationService {
  static getQueryParameter(
    location: RouteComponentProps['location'],
    queryParameter: string,
  ): string | undefined {
    const params = new URLSearchParams(location.search);

    return params.get(queryParameter) || undefined;
  }

  static addQueryParameter(
    history: RouteComponentProps['history'],
    location: RouteComponentProps['location'],
    queryParameter: string,
    value: string,
  ): void {
    const params = new URLSearchParams(location.search);
    params.set(queryParameter, value);
    const newSearch = params.toString();
    history.push({
      ...location,
      search: newSearch,
    });
  }

  static removeQueryParameter(
    history: RouteComponentProps['history'],
    location: RouteComponentProps['location'],
    queryParameter: string,
  ): void {
    const params = new URLSearchParams(location.search);
    params.delete(queryParameter);
    const newSearch = params.toString();
    history.push({
      ...location,
      search: newSearch,
    });
  }
}

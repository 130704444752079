import EntityLink from 'labstep-web/components/Entity/Link';
import { EntityRelationSearchSelect } from 'labstep-web/components/EntityRelation/SearchSelect';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import Flex from 'labstep-web/core/Flex';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { EntityRelation } from 'labstep-web/models/entity-relation.model';
import { colDefMetadataEntityRelationType } from './types';

export const colDefMetadataEntityRelation: colDefMetadataEntityRelationType =
  (metadataTemplate, entityName) => {
    const { activeGroup } = useActiveGroup();
    return {
      colId: `metadata:${metadataTemplate.guid}`,
      headerName: metadataTemplate.field_definition!.name,
      headerComponentParams: {
        icon: ICONS.metadata.secondary,
        cornerIcon: ICONS[entityName].primary,
      },
      valueGetter: (params) => {
        if (
          !params.data ||
          !params.data.getEntityRelationsByFieldDefinition
        ) {
          return '';
        }
        return params.data
          .getEntityRelationsByFieldDefinition(
            metadataTemplate.field_definition,
          )
          .filter((entity: EntityRelation) => entity.target_resource)
          .map(
            (entity: EntityRelation) => entity.target_resource.name,
          );
      },
      cellEditorPopup: true,
      editable: true,
      cellRenderer: (params) => {
        if (
          !params.data ||
          !params.data.getEntityRelationsByFieldDefinition
        ) {
          return '';
        }
        return (
          <Flex
            spacing="gap"
            hAlignContent="center"
            vAlignContent="center"
            data-cy={`grid/Index/coldefs/Resource/metadatas/entityRelation:CellRenderer-${params.data.name}`}
          >
            {params.data
              .getEntityRelationsByFieldDefinition(
                metadataTemplate.field_definition,
              )
              .map((entity: EntityRelation) => {
                if (!entity.target) {
                  return null;
                }
                return (
                  <div>
                    <EntityLink
                      key={entity.target.guid}
                      entity={entity.target}
                      showIcon
                    />
                  </div>
                );
              })}
          </Flex>
        );
      },
      cellEditorPopupPosition: 'under',
      cellEditor: (params: any) => {
        return (
          <Flex width={'100%'}>
            <EntityCreateContainer
              entityName={EntityRelation.entityName}
            >
              {({ create }): React.ReactElement => (
                <EntityRelationSearchSelect
                  fieldDefinition={metadataTemplate.field_definition!}
                  sourceEntity={params.data}
                  createEntityRelation={create}
                  stopEdit={() => params.api.stopEditing()}
                />
              )}
            </EntityCreateContainer>
          </Flex>
        );
      },
    };
  };

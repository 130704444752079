/**
 * Labstep
 */

import MetadataManager from 'labstep-web/components/Metadata/Manager';
import colDefName from 'labstep-web/grid/Index/coldefs/Resource/name';
import { Resource } from 'labstep-web/models/resource.model';

export const colDefResourceNameNoLink = colDefName(
  Resource.entityName,
  {
    getEntityPreviewProps: (resource) => ({
      secondaryInfo: (
        <MetadataManager entity={resource as Resource} />
      ),
    }),
    noLink: true,
  },
);

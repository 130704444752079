/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Commands/Modal/HtmlTable
 * @desc Modal to create HTML table
 */

import FormReusable from 'labstep-web/core/Form/Reusable';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import {
  DEFAULT_COLUMNS_VALUE,
  DEFAULT_ROWS_VALUE,
  fieldHtmlTableColumns,
  fieldHtmlTableRows,
} from './fields';
import { IMenuCommandsModalHtmlTableProps } from './types';

export const defaultValues: any = {
  rows: DEFAULT_ROWS_VALUE,
  columns: DEFAULT_COLUMNS_VALUE,
};

export const MenuCommandsModalHtmlTable: React.FC<
  IMenuCommandsModalHtmlTableProps
> = ({ onSubmit, modalProps }) => (
  <Modal
    usePortal
    header="Add Table"
    content={() => (
      <FormReusable
        autoFocus
        defaultValues={defaultValues}
        fields={[fieldHtmlTableRows, fieldHtmlTableColumns]}
        onSubmit={onSubmit}
      />
    )}
    {...modalProps}
  />
);

export default MenuCommandsModalHtmlTable;

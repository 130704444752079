/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/Print
 * @desc Action to print an experiment workflow
 */

import EntityActionPrint from 'labstep-web/components/Entity/Action/Print';
import React from 'react';
import { ExperimentWorkflowEntityActionPrintProps } from './types';

export const ExperimentWorkflowEntityActionPrint: React.FC<
  ExperimentWorkflowEntityActionPrintProps
> = ({ experimentWorkflow }) => (
  <EntityActionPrint entity={experimentWorkflow} />
);

export default ExperimentWorkflowEntityActionPrint;

/**
 * Labstep
 */

import { ValueGetterParams } from 'ag-grid-community';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { OrderRequestStatus } from 'labstep-web/models/order-request.model';
import { dateOnly } from 'labstep-web/services/date.service';

const TOOL_PANEL_NAMES: Record<string, string> = {
  [OrderRequestStatus.approved]: 'Date requested',
  [OrderRequestStatus.ordered]: 'Date ordered',
  [OrderRequestStatus.received]: 'Date received',
};

export const getColDefOrderRequestLogCreatedAt = (
  status: OrderRequestStatus,
): NewIndexColDef => ({
  colId: `${status}_log_created_at`,
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.date,
    field: 'created_at',
    path: `${status}Log`,
  },
  headerName: TOOL_PANEL_NAMES[status],
  headerComponentParams: {
    icon: 'calendar alternate outline',
  },
  editable: false,
  valueGetter: (params: ValueGetterParams) =>
    params.data.getStatusLog(status)?.created_at || null,
  valueFormatter: (params) => {
    const date = params.data.getStatusLog(status)?.created_at;
    return date ? dateOnly(date) : '';
  },
});

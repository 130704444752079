/**
 * Labstep
 *
 * @module grid/NewIndex/components/ToolPanel/AddColumn
 * @desc Wizard to add a column
 */

import EntitySearchMulti from 'labstep-web/components/Entity/Search/Multi';
import { IEntitySearchMultiList } from 'labstep-web/components/Entity/Search/Multi/types';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import GridNewIndexTemplateLoader from 'labstep-web/grid/NewIndex/components/TemplateLoader';
import { ToolPanelState } from 'labstep-web/grid/NewIndex/components/ToolPanel';
import { getDeviceLists } from 'labstep-web/grid/NewIndex/components/ToolPanel/AddColumn/Device';
import { getOrderRequestLists } from 'labstep-web/grid/NewIndex/components/ToolPanel/AddColumn/OrderRequest';
import { getResourceLists } from 'labstep-web/grid/NewIndex/components/ToolPanel/AddColumn/Resource';
import { getResourceItemLists } from 'labstep-web/grid/NewIndex/components/ToolPanel/AddColumn/ResourceItem';
import GridNewIndexToolPanelSystemFields from 'labstep-web/grid/NewIndex/components/ToolPanel/SystemFields';
import { IndexTemplatableEntity } from 'labstep-web/models';
import { Device } from 'labstep-web/models/device.model';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { Metadata } from 'labstep-web/models/metadata';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { IEntityDataGridToolPanelAddColumnProps } from './types';

export const getLists = (
  entityView: EntityView,
  selectMetadata: (
    metadata: Metadata,
    parentEntityClass: string,
  ) => void,
  colIds: string[],
  template?: IndexTemplatableEntity,
): IEntitySearchMultiList[] => {
  if (entityView.entity_name === Device.entityName) {
    return getDeviceLists(
      entityView,
      selectMetadata,
      colIds,
      template,
    );
  }
  if (entityView.entity_name === OrderRequest.entityName) {
    return getOrderRequestLists(entityView, selectMetadata, colIds);
  }
  if (entityView.entity_name === Resource.entityName) {
    return getResourceLists(
      entityView,
      selectMetadata,
      colIds,
      template,
    );
  }
  if (entityView.entity_name === ResourceItem.entityName) {
    return getResourceItemLists(
      entityView,
      selectMetadata,
      colIds,
      template,
    );
  }
  return [];
};

export const EntityDataGridToolPanelAddColumn: React.FC<
  IEntityDataGridToolPanelAddColumnProps
> = ({
  colIds,
  entityView,
  gridNewIndexService,
  setToolPanelState,
  selectMetadata,
}) => (
  <GridNewIndexTemplateLoader
    entityName={entityView.templateEntityName}
    templateId={entityView.templateId}
  >
    {({ template }: { template?: IndexTemplatableEntity }) => (
      <div>
        <Flex vAlignContent="center" spacing="gap" shrink={0}>
          <ActionComponent
            type="icon"
            icon="arrow left"
            text="Go Back"
            onClick={() => setToolPanelState(ToolPanelState.default)}
          />
          <Header size="tiny" noMargin>
            Add Column
          </Header>
        </Flex>
        <small>
          First you must select the field that you wish to add as a
          column
        </small>
        <EntitySearchMulti
          staticLists={(searchQuery) => [
            <GridNewIndexToolPanelSystemFields
              colIds={colIds}
              entityView={entityView}
              gridNewIndexService={gridNewIndexService}
              searchQuery={searchQuery}
            />,
          ]}
          lists={getLists(
            entityView,
            selectMetadata,
            colIds,
            template,
          )}
        />
      </div>
    )}
  </GridNewIndexTemplateLoader>
);

export default EntityDataGridToolPanelAddColumn;

/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/metadata/options
 * @desc Column Definition for metadata options
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { getFieldsOptions } from 'labstep-web/components/Metadata/Form/fields';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import {
  suppressKeyboardEventClearCells,
  suppressKeyboardEventDisableEnterOnEdit,
} from 'labstep-web/core/DataGrid/CellEditor/utils';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import {
  createOrUpdateValue,
  setValue,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import FormShowEditSelectOptions from 'labstep-web/core/Form/ShowEdit/SelectOptions';
import Label from 'labstep-web/core/Label';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridColumnDefinitionService } from 'labstep-web/grid/services/grid-column-definition.service';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import rules from 'labstep-web/services/validation/rules';
import styles from './styles.module.scss';

export const getMetadataOptionsColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
): NewIndexColDef => {
  return {
    colId: `metadata:${parentEntityClass}:${type}:${metadataLabel}`,
    postFilterSettings: {
      path: 'metadatas',
    },
    sortable: true,
    headerName: metadataLabel,
    headerComponent: GridNewIndexColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.type.options,
    },
    editable: (params: EditableCallbackParams) =>
      GridNewIndexService.getEditable(params),
    suppressKeyboardEvent: (params: any): boolean =>
      suppressKeyboardEventDisableEnterOnEdit(params) ||
      suppressKeyboardEventClearCells(params),
    valueClearer: () => null,
    valueGetter: (params: ValueGetterParams) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return null;
      }
      return JSON.stringify(metadata.options);
    },
    valueSetter: (params: ValueSetterParams) => {
      if (!params.node) {
        return false;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );

      if (params.newValue === null) {
        setValue(
          'options',
          null,
          {
            entityName: metadata.entityName,
            id: metadata.idAttr,
          },
          rules.metadata.options,
          GridColumnDefinitionService.getOptions(params),
        );
        return true;
      }
      let newValue = params.newValue;
      if (typeof newValue === 'string') {
        try {
          newValue = JSON.parse(newValue);
        } catch {
          return false;
        }
      }

      if (!metadata) {
        createOrUpdateValue({
          entityName: Metadata.entityName,
          body: {
            type,
            label: metadataLabel,
            metadata_thread_id: params.node.data.metadata_thread.guid,
            options: newValue,
          },
          createProps: {
            parentName: params.node.data.metadata_thread.entityName,
            parentId: params.node.data.metadata_thread.idAttr,
          },
          options: GridColumnDefinitionService.getOptions(params),
        });
        return true;
      }
      setValue(
        'options',
        newValue,
        {
          entityName: metadata.entityName,
          id: metadata.idAttr,
        },
        rules.metadata.options,
        GridColumnDefinitionService.getOptions(params),
      );
      return true;
    },
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        const metadataTemplate = params.node.data.template
          ? params.node.data.template.metadata_thread.metadatas.find(
              (m: Metadata) =>
                m.type === type && m.label === metadataLabel,
            )
          : null;

        return (
          <EntityCreateContainer entityName={Metadata.entityName}>
            {({ create, status }) => {
              if (status && status.isFetching) {
                return null;
              }
              return (
                <TextPlaceholder
                  onClick={() =>
                    create(
                      {
                        label: metadataLabel,
                        metadata_thread_id:
                          params.node.data.metadata_thread.guid,
                        options: {
                          values: [],
                          is_allow_multiple: metadataTemplate
                            ? metadataTemplate.options
                                .is_allow_multiple
                            : true,
                          is_allow_add: metadataTemplate
                            ? metadataTemplate.options.is_allow_add
                            : true,
                        },
                      },
                      {
                        onSuccess: () => {
                          stopEditingAndFocus(params);
                          GridColumnDefinitionService.refreshCell(
                            params,
                          );
                          if (params.node.rowIndex) {
                            params.api.startEditingCell({
                              rowIndex: params.node.rowIndex,
                              colKey: params.column.getColId(),
                            });
                          }
                        },
                      },
                      params.node.data.metadata_thread.entityName,
                      params.node.data.metadata_thread.idAttr,
                    )
                  }
                >
                  Select
                </TextPlaceholder>
              );
            }}
          </EntityCreateContainer>
        );
      }
      return (
        <>
          {metadata.optionsSelectedValues.length ? (
            metadata.optionsSelectedValues.map((value: string) => (
              <Label key={value} circular>
                {value}
              </Label>
            ))
          ) : (
            <DataGridPlaceholder
              children="Select"
              params={params}
              editable
            />
          )}
        </>
      );
    },
    cellEditorPopup: true,
    cellEditor: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return '';
      }
      return (
        <div
          className={styles.container}
          style={{
            width: params.column.getActualWidth(),
          }}
        >
          <FormShowEditSelectOptions
            canEdit
            children={null}
            initialEditingState
            onToggle={(): void => stopEditingAndFocus(params)}
            options={GridColumnDefinitionService.getOptions(params)}
            field={
              getFieldsOptions(
                metadata,
                true,
                metadata.is_template,
                true,
              )[0]
            }
            entity={metadata}
            entityName={Metadata.entityName}
          />
        </div>
      );
    },
  };
};

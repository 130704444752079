/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Numeric/Active/Post
 * @desc Active node component for numbers
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/FilterPost/Active/Label/types';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import {
  PostFilterComparison,
  PostFilterComparisonHumanReadableShort,
} from 'labstep-web/services/query-parameter.service';
import React from 'react';
import FilterPostNewIndexNumeric from '../..';
import { getPostFilterNumeric } from '../../Form';
import { COMPARISON_OPTIONS } from '../../Form/fields';

// eslint-disable-next-line no-shadow
export enum PostFilterComparisonHumanReadable {
  eq = 'equals',
  lte = 'less than or equal to',
  lt = 'less than',
  gte = 'greater than or equal to',
  gt = 'greater than',
}

const FilterNumericActivePost: React.FC<
  FilterPostActiveLabelProps
> = ({ node, onRemoveClick }) => {
  const NUMERIC_FILTERS = [
    getPostFilterNumeric(
      'available_resource_item_count',
      PostFilterComparison.eq,
    ),
    getPostFilterNumeric('amount', PostFilterComparison.eq),
    getPostFilterNumeric('number', PostFilterComparison.eq),
  ];
  const { addPostFilter } = useParamsContext();
  const filterActive = NUMERIC_FILTERS.find((filter) =>
    filter.isNodeActive(node),
  );
  if (!filterActive) {
    return null;
  }

  const path = filterActive.getPath() || '';
  const attributes = filterActive.getAttributeValues(node);

  const label = 'available_resource_item_count';
  const field = Object.keys(attributes)[0];
  const { comparison } = Object.values(attributes)[0];
  const value =
    comparison === PostFilterComparison.not_null
      ? ''
      : Object.values(attributes)[0].value;

  const defaultValues = {
    comparison: COMPARISON_OPTIONS.find(
      (option) => option.value === comparison,
    ),
    value,
  };

  const postFilterSettings = {
    label,
    path,
    field,
  };

  const comparaisonReadable =
    PostFilterComparisonHumanReadableShort[
      comparison as keyof typeof PostFilterComparisonHumanReadableShort
    ];

  return (
    <FilterPostNewIndexNumeric
      label={label}
      path={postFilterSettings.path}
      field={postFilterSettings.field}
      addPostFilter={addPostFilter}
      defaultValues={defaultValues}
      viewComponent={({ toggleModal }) => (
        <RemoveLabel
          name={`${field} ${comparaisonReadable} ${value}`}
          onNameClick={toggleModal}
          onRemoveClick={onRemoveClick}
        />
      )}
    />
  );
};

export default FilterNumericActivePost;

/**
 * Labstep
 *
 * @module components/ProtocolValue/List
 * @desc Protocol Value list
 */

import EntityDraggable from 'labstep-web/components/Entity/Draggable';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import ProtocolValueActionCreate from 'labstep-web/components/ProtocolValue/Action/Create';
import { ProtocolValueInventoryFieldActions } from 'labstep-web/components/ProtocolValue/Action/UpdateAmountBulk';
import ProtocolValueItem from 'labstep-web/components/ProtocolValue/Item';
import { ICONS } from 'labstep-web/constants/icons';
import { getChildRoute } from 'labstep-web/containers/Router/ProtocolChild';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import List from 'labstep-web/core/List';
import ListCollapsible from 'labstep-web/core/List/Collapsible';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import React from 'react';
import styles from './styles.module.scss';
import {
  IExperimentProtocolValueListContainerProps,
  IInvertoyFieldEmptyStateProps,
  IProtocolValueCardProps,
  IProtocolValueListContainerProps,
  IProtocolValueListProps,
  IProtocolValuesProps,
} from './types';

const ProtocolValuesContainer: React.FC<IProtocolValuesProps> = ({
  protocol,
  isInput = false,
}) => {
  const { entityName } = ProtocolValue;
  return (
    <ReadOnMountHOC
      type="cursor"
      loading={{ loader: 'placeholder', cached: false }}
      entityName={entityName}
      params={{
        [protocol instanceof Experiment
          ? 'experiment_id'
          : 'protocol_id']: protocol.id,
        has_variable_template: false,
        is_input: isInput,
        is_output: !isInput,
      }}
      children={({ entities: protocolValues }) => {
        return (
          <ProtocolValueList
            protocolValues={protocolValues}
            isInput={isInput}
          />
        );
      }}
    />
  );
};

const ExperimentProtocolValueListContainer: React.FC<
  IExperimentProtocolValueListContainerProps
> = ({ experimentWorkflow, isInput }) => {
  return (
    <ReadOnMountHOC
      type="cursor"
      loading={{ loader: 'placeholder', cached: false }}
      entityName={Experiment.entityName}
      params={{
        is_root: false,
        experiment_workflow_id: experimentWorkflow.id,
      }}
      children={({ entities }: { entities: Experiment[] }) => {
        const experiments = entities.filter((e) => !e.deleted_at);

        return (
          <ListCollapsible
            sections={experiments.map((experiment) => ({
              icon: ICONS.experiment.primary,
              text: experiment.name,
              count: isInput
                ? experiment.protocol_value_input_count
                : experiment.protocol_value_output_count,
              component: (
                <ProtocolValuesContainer
                  protocol={experiment}
                  isInput={isInput}
                />
              ),
            }))}
          />
        );
      }}
    />
  );
};

const ProtocolValueCard: React.FC<IProtocolValueCardProps> = ({
  protocolValue,
  isInput,
}) => {
  return (
    <EntityDraggable
      key={protocolValue.id}
      entityId={protocolValue.id}
      entityGuid={protocolValue.guid}
      entityName={protocolValue.entityName}
    >
      <ProtocolValueItem
        protocolValue={protocolValue}
        isInput={isInput}
      />
    </EntityDraggable>
  );
};

const InvertoyFieldEmptyState: React.FC<
  IInvertoyFieldEmptyStateProps
> = ({ protocol, isOutput = false }) => {
  const outputFiledExplanation =
    'Output inventory fields are for items that will be added to inventory as part of this experiment. Creating items from output inventory fields allows the origin experiment and lineage of the item to be tracked automatically.';
  const inputFieldExplanation =
    'Input inventory fields allow you to specify which resources are required and how much, and allow you to track which specific items were used and deduct the amount from your inventory automatically.';

  return (
    <EmptyState
      action={
        <ProtocolValueActionCreate
          protocol={protocol}
          isOutput={isOutput}
          actionComponentProps={
            {
              elementProps: {
                fluid: false,
              },
            } as any
          }
        />
      }
      src="/img/empty-state/inventory.svg"
      explanation={
        isOutput ? outputFiledExplanation : inputFieldExplanation
      }
    />
  );
};

export const ProtocolValueList: React.FC<IProtocolValueListProps> = ({
  protocolValues,
  isInput = false,
}) => (
  <List id="protocol-value-list">
    {protocolValues.map((value) => {
      if (
        value.is_variable &&
        value.is_input &&
        !value.variable_template &&
        value.protocol_value_template_children.length > 0
      ) {
        return (
          <ReadOnMountHOC
            type="cursor"
            loading={{ loader: 'placeholder', cached: false }}
            entityName={ProtocolValue.entityName}
            params={{
              variable_template_guid: value.guid,
              is_input: true,
              count: 100,
            }}
            children={() => {
              return (
                <ProtocolValueCard
                  protocolValue={value}
                  isInput={isInput}
                />
              );
            }}
          />
        );
      }
      return (
        <ProtocolValueCard protocolValue={value} isInput={isInput} />
      );
    })}
  </List>
);

export const ProtocolValueListContainer: React.FC<
  IProtocolValueListContainerProps
> = ({ experimentWorkflow, protocol }) => {
  const inputRoute = getChildRoute('values')!;
  const outputRoute = getChildRoute('values_outputs')!;
  const [refreshKey, setRefreshKey] = React.useState(0);

  return (
    <LayoutLinks
      links={[
        {
          children: 'Inputs',
          icon: 'sign in',
          render: () => {
            let count = 0;
            if (experimentWorkflow) {
              count = experimentWorkflow.protocol_value_input_count;
            } else if (protocol instanceof Experiment) {
              count = protocol.protocol_value_input_count;
            } else {
              count = protocol.protocol_values.filter(
                (p) =>
                  !p.deleted_at &&
                  !p.variable_template_guid &&
                  p.is_input,
              ).length;
            }

            if (count === 0) {
              return <InvertoyFieldEmptyState protocol={protocol} />;
            }
            return (
              <div key={refreshKey} className={styles.container}>
                <div className={styles.scrollableContent}>
                  <ProtocolValuesContainer
                    protocol={protocol}
                    isInput
                  />
                  {experimentWorkflow && (
                    <ExperimentProtocolValueListContainer
                      experimentWorkflow={experimentWorkflow}
                      isInput
                    />
                  )}
                </div>

                <div className={styles.barrier}></div>
                <ProtocolValueInventoryFieldActions
                  protocol={protocol}
                  protocolValues={protocol.protocol_values}
                  showBulkUpdate={
                    !experimentWorkflow &&
                    protocol instanceof Experiment
                  }
                  setRefreshKey={() => {
                    setRefreshKey((prevKey) => prevKey + 1);
                  }}
                />
              </div>
            );
          },
          route: {
            to: inputRoute.name,
            params: { id: inputRoute.params.id },
          },
        },
        {
          children: 'Outputs',
          icon: 'sign out',
          route: {
            to: outputRoute.name,
            params: { id: outputRoute.params.id },
          },
          render: () => {
            let count = 0;
            if (experimentWorkflow) {
              count = experimentWorkflow.protocol_value_output_count;
            } else if (protocol instanceof Experiment) {
              count = protocol.protocol_value_output_count;
            } else {
              count = protocol.protocol_values.filter(
                (p) =>
                  !p.deleted_at &&
                  !p.variable_template_guid &&
                  p.is_output,
              ).length;
            }

            if (count === 0) {
              return (
                <InvertoyFieldEmptyState
                  protocol={protocol}
                  isOutput
                />
              );
            }
            return (
              <div className={styles.container}>
                <div className={styles.scrollableContent}>
                  <ProtocolValuesContainer protocol={protocol} />
                  {experimentWorkflow && (
                    <ExperimentProtocolValueListContainer
                      experimentWorkflow={experimentWorkflow}
                      isInput={false}
                    />
                  )}
                </div>
                <div className={styles.barrier}></div>
                <ProtocolValueActionCreate
                  protocol={protocol}
                  isOutput
                />
              </div>
            );
          },
        },
      ]}
      stretch
      inverted
    />
  );
};

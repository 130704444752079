/**
 * Labstep
 *
 * @module components/Resource/Action/Delete
 * @desc Action to delete
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { IResourceDeleteActionProps } from './types';

export const ResourceDeleteAction: React.FC<
  IResourceDeleteActionProps
> = ({ resource, ...rest }) => (
  <EntityActionDelete
    dataCy="components/Resource/Action/Delete"
    {...rest}
    entity={resource}
  />
);

export default ResourceDeleteAction;

/**
 * Labstep
 *
 * @module components/Device/Filter/Pane
 * @desc Filter pane for device
 */

import FilterAssignedToList from 'labstep-web/components/Filter/AssignedTo/List';
import FilterCreatedByList from 'labstep-web/components/Filter/CreatedBy/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import FilterTagList from 'labstep-web/components/Filter/Tag/List';
import { ICONS } from 'labstep-web/constants/icons';
import { Device } from 'labstep-web/models/device.model';
import React from 'react';

const DeviceFilterPane: React.FC = () => (
  <FilterPane
    entityName={Device.entityName}
    text={'Devices'}
    icon={ICONS.device.primary}
    filters={[]}
    extraFilters={
      <>
        <FilterCreatedByList
          entityName={Device.entityName}
          usePostFilter
        />
        <FilterAssignedToList
          entityName={Device.entityName}
          usePostFilter
        />
        <FilterTagList entityName={Device.entityName} usePostFilter />
      </>
    }
  />
);

export default DeviceFilterPane;

/**
 * Labstep
 *
 * @module grid/NewIndex/components/ToolPanel/MetadataMolecule
 * @desc Select a metadata molecule field to add as a column
 */

import { ActionComponent } from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import Header from 'labstep-web/core/Header';
import { getMetadataMoleculeColDefs } from 'labstep-web/grid/NewIndex/coldefs';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { ToolPanelState } from 'labstep-web/grid/NewIndex/components/ToolPanel';
import EntityDataGridToolPanelListItem from 'labstep-web/grid/Report/components/ToolPanel/ListItem';
import React from 'react';
import { EntityDataGridToolPanelMetadataMoleculeProps } from './types';

export const EntityDataGridToolPanelMetadataMolecule: React.FC<
  EntityDataGridToolPanelMetadataMoleculeProps
> = ({
  colIds,
  setToolPanelState,
  gridNewIndexService,
  metadata,
}) => (
  <div>
    <Flex vAlignContent="center" spacing="gap" shrink={0}>
      <ActionComponent
        type="icon"
        icon="arrow left"
        text="Go Back"
        onClick={() => setToolPanelState(ToolPanelState.default)}
      />
      <Header size="tiny" noMargin>
        Add Structure Column
      </Header>
    </Flex>
    <small>
      You must select the field that you wish to add as a column
    </small>
    {getMetadataMoleculeColDefs(metadata).map(
      (colDef: NewIndexColDef) => {
        return (
          <EntityDataGridToolPanelListItem
            icon={colDef.headerComponentParams?.icon || 'database'}
            actionIcon="eye"
            text={colDef.headerName || ''}
            onClick={() => {
              gridNewIndexService.addColumnDef(colDef);
            }}
            disabled={colIds.includes(colDef.colId || '')}
          />
        );
      },
    )}
  </div>
);

export default EntityDataGridToolPanelMetadataMolecule;

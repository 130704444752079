/**
 * Labstep
 *
 * @module components/EntitySnapshot/List
 * @desc List of EntitySnapshots
 */

import classNames from 'classnames';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { EntitySnapshot } from 'labstep-web/models/entity-snapshot.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  EntitySnapshotListContainerProps,
  EntitySnapshotListProps,
} from './types';

export const EntitySnapshotList: React.FC<
  EntitySnapshotListProps
> = ({
  entitySnapshots,
  selectedEntitySnapshot,
  setSelectedEntitySnapshot,
}) => {
  return (
    <ul
      className={styles.list}
      data-cy="components/EntitySnapshot/List"
    >
      {entitySnapshots.map((entitySnapshot: EntitySnapshot) => {
        return (
          <li
            data-cy="components/EntitySnapshot/List:item"
            onClick={() => setSelectedEntitySnapshot(entitySnapshot)}
            style={{ cursor: 'pointer' }}
            key={entitySnapshot.id}
            className={classNames(styles.item, {
              [styles.selected]:
                entitySnapshot.id === selectedEntitySnapshot?.id,
            })}
          >
            Autosaved at{' '}
            {humanReadableDate(entitySnapshot.created_at)}
          </li>
        );
      })}
    </ul>
  );
};

export const EntitySnapshotListContainer: React.FC<
  EntitySnapshotListContainerProps
> = ({ log, extraParams, count = 10, ...rest }) => (
  <ReadOnMountHOC
    type="cursor"
    pagination={{
      infinite: false,
    }}
    entityName={EntitySnapshot.entityName}
    params={{
      object_id: log.object_id,
      object_class: log.object_class,
      created_at_from: log.created_at,
      created_at_to: log.ended_at ? log.ended_at : log.created_at,
      count,
      ...extraParams,
    }}
    children={({ entities }) => (
      <EntitySnapshotList entitySnapshots={entities} {...rest} />
    )}
  />
);

export default EntitySnapshotListContainer;

/**
 * Labstep
 *
 * @module models/protocol-device
 * @desc Typescript export class for ProtocolDevice
 */

import { Type } from 'class-transformer';
import { Device } from 'labstep-web/models/device.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import { Protocol } from 'labstep-web/models/protocol.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export class ProtocolDevice extends Entity {
  public static readonly idAttr = 'id' as const;

  static readonly entityName = 'protocol_device';

  get entityName(): string {
    return ProtocolDevice.entityName;
  }

  constructor(data: Partial<ProtocolDevice> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: string;

  name!: string;

  @Type(() => Experiment)
  experiment!: Experiment;

  @Type(() => Protocol)
  protocol!: Protocol;

  @Type(() => Device)
  device!: Device;

  @Type(() => Metadata)
  metadatas!: Metadata[];

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}

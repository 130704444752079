/**
 * Labstep
 *
 * @module grid/Report/components/ColumnHeader
 * @desc Protocol Condition DataGrid Header
 */

import GridReportColumnHeaderContextMenu from 'labstep-web/grid/Report/components/ColumnHeader/ContextMenu';
import GridReportColumnHeaderTrigger from 'labstep-web/grid/Report/components/ColumnHeader/Trigger';
import React from 'react';
import { GridReportColumnHeaderProps } from './types';

export const GridReportColumnHeader: React.FC<
  GridReportColumnHeaderProps
> = ({ sort, filter, ...rest }) => {
  const { column, displayName, icon, cornerIcon, action, ...params } =
    rest;
  return (
    <GridReportColumnHeaderContextMenu
      trigger={<GridReportColumnHeaderTrigger {...rest} />}
      sort={sort}
      filter={filter}
      {...params}
      {...rest}
    />
  );
};

export default GridReportColumnHeader;

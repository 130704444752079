/**
 * Labstep
 *
 * @module models/entity-user
 * @desc Typescript user class for EntityUser
 */

import { Type } from 'class-transformer';
import { Device } from 'labstep-web/models/device.model';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { PurchaseOrder } from 'labstep-web/models/purchase-order.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import { Resource } from 'labstep-web/models/resource.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export type EntityUserEntities =
  | EntityStateWorkflow
  | ExperimentWorkflow
  | ProtocolCollection
  | Resource
  | ResourceItem
  | ResourceLocation
  | OrderRequest
  | PurchaseOrder
  | Device;

export class EntityUser extends Entity {
  static readonly entityName = 'entity_user';

  get entityName(): string {
    return EntityUser.entityName;
  }

  constructor(data: Partial<EntityUser> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  created_at!: string;

  is_assigned!: boolean;

  is_contributor!: boolean;

  entity_user_role_id!: number;

  @Type(() => User)
  user!: User;

  @Type(() => EntityUserRole)
  entity_user_role!: EntityUserRole | null;

  getIsAuthor(entityAuthor: User): boolean {
    return this.user.id === entityAuthor.id;
  }

  getRole(entityAuthor: User): string {
    let role = '';
    if (this.is_contributor) {
      role = 'Contributor';
    }
    if (this.is_assigned) {
      role = 'Assigned';
    }
    if (this.getIsAuthor(entityAuthor)) {
      if (this.is_assigned) {
        role = 'Creator - Assigned';
      } else {
        role = `Creator`;
      }
    }

    return role;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}

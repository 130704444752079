import { useHasAccess } from 'labstep-web/components/Entity/Can';
import CardSelect from 'labstep-web/core/Card/Select';
import Flex from 'labstep-web/core/Flex';
import Radio from 'labstep-web/core/Radio';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { RadioGroupProps, RadioItemWithAccessProps } from './types';

const RadioItemWithAccess: React.FC<RadioItemWithAccessProps> = ({
  item,
  selected,
  onChange,
  isCustom,
  disabled,
  isDisabled,
  disabledMessage,
  ...rest
}) => {
  const itemDisabled =
    isDisabled ||
    (item.disableException === false &&
      disabled.editProps !== undefined &&
      !useHasAccess(
        disabled!.editProps!.entityName,
        disabled!.editProps!.entityId,
        disabled!.editProps!.action,
      ));

  if (isCustom) {
    return (
      <Flex style={{ marginTop: '10px' }}>
        <CardSelect
          data-testid={`card-select-${item.value}`}
          isSelected={item.value === selected}
          setIsSelected={() => onChange(item.value)}
          content={item.component}
          noCheckbox
          label={item.label}
          isDisabled={itemDisabled}
          disabledMessage={disabledMessage}
        />
      </Flex>
    );
  }

  return (
    <Radio
      {...item}
      data-testid={`radio-${item.value}`}
      disabled={itemDisabled}
      checked={item.value === selected}
      {...rest}
      onChange={() => onChange(item.value)}
    />
  );
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  elementProps,
  control,
  name,
  autoFocus,
  onKeyPress,
  ...rest
}) => {
  const [selected, setSelected] = useState(
    elementProps?.defaultValue,
  );
  const disabledMessage = elementProps?.disabled?.message;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): React.ReactElement => {
        const { onChange } = field;
        const { value } = field;

        useEffect(() => {
          if (elementProps?.defaultValue) {
            field.onChange(elementProps.defaultValue);
          }

          if (value) {
            setSelected(value);
          }
        }, []);

        if (elementProps?.disabled?.editProps === undefined) {
          setSelected(elementProps?.defaultValue);
        }

        return (
          <Flex spacing="gap">
            {elementProps?.items.map((item) => {
              const isDisabled =
                elementProps?.disabled?.isDisabled === true &&
                item.disableException !== true;

              return (
                <RadioItemWithAccess
                  key={item.value}
                  item={item}
                  selected={selected}
                  onChange={(value: any) => {
                    onChange(value as any);
                    setSelected(value);
                  }}
                  isCustom={elementProps?.isCustom}
                  isDisabled={isDisabled}
                  disabledMessage={disabledMessage}
                  {...rest}
                  disabled={elementProps?.disabled!}
                />
              );
            })}
          </Flex>
        );
      }}
    />
  );
};

export default RadioGroup;

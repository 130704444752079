/**
 * Labstep
 *
 * @module components/ResourceItem/FilterPane
 * @desc Filter pane for resource item
 */

import FilterAssignedToList from 'labstep-web/components/Filter/AssignedTo/List';
import FilterCreatedByList from 'labstep-web/components/Filter/CreatedBy/List';
import { FilterList } from 'labstep-web/components/Filter/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import { ICONS } from 'labstep-web/constants/icons';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { PostFilterStatus } from 'labstep-web/services/postFilter/filters/status';
import React from 'react';
import { IResourceItemFilterPaneProps } from './types';

const ResourceItemFilterPane: React.FC<
  IResourceItemFilterPaneProps
> = () => (
  <FilterPane
    entityName={ResourceItem.entityName}
    text={'Items'}
    icon={ICONS.resource_item.primary}
    filters={[]}
    extraFilters={
      <>
        <FilterList
          filters={[
            {
              icon: ICONS.resource.info.available,
              text: 'Available',
              params: { status: 'available' },
              postFilterValues: [PostFilterStatus, ['available']],
            },
            {
              icon: ICONS.resource.info.unavailable,
              text: 'Unavailable',
              params: { status: 'unavailable' },
              postFilterValues: [PostFilterStatus, ['unavailable']],
            },
          ]}
        />
        <FilterCreatedByList
          entityName={ResourceItem.entityName}
          usePostFilter
        />
        <FilterAssignedToList
          entityName={ResourceItem.entityName}
          usePostFilter
        />
      </>
    }
  />
);

export default ResourceItemFilterPane;

/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/InputOutput
 * @desc ProtocolValue field type input/output
 */

import EntityFormShowEditInputOutput from 'labstep-web/components/Entity/Form/ShowEdit/InputOutput';
import React from 'react';
import { IProtocolValueShowEditInputOutputProps } from './types';

export const ProtocolValueShowEditInputOutput: React.FC<
  IProtocolValueShowEditInputOutputProps
> = ({ protocolValue }) => (
  <EntityFormShowEditInputOutput
    data-cy="components/ProtocolValue/Form/ShowEdit/InputOutput"
    entity={protocolValue}
  />
);

export default ProtocolValueShowEditInputOutput;

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Boolean/Active/Post
 * @desc Active node component for date
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/FilterPost/Active/Label/types';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import FilterPostNewIndexBoolean from '../..';
import { getPostFilterBoolean } from '../../Form';

const FilterBooleanActivePost: React.FC<
  FilterPostActiveLabelProps
> = ({ node, onRemoveClick, entityView }) => {
  const FILTERS = [
    getPostFilterBoolean('is_urgent', PostFilterComparison.eq),
  ];
  const { addPostFilter } = useParamsContext();
  const filterActive = FILTERS.find((filter) =>
    filter.isNodeActive(node),
  );
  if (!filterActive) {
    return null;
  }

  const path = filterActive.getPath() || '';
  const attributes = filterActive.getAttributeValues(node);

  const label = node.predicates[0].attribute;
  const field = Object.keys(attributes)[0];
  const value = node.predicates[0].value;

  const postFilterSettings = {
    label,
    path,
    field,
  };

  return (
    <FilterPostNewIndexBoolean
      label={label}
      path={postFilterSettings.path}
      field={postFilterSettings.field}
      addPostFilter={addPostFilter}
      viewComponent={({ toggleModal }) => (
        <RemoveLabel
          name={`${label} is ${value}`}
          onNameClick={toggleModal}
          onRemoveClick={onRemoveClick}
        />
      )}
    />
  );
};

export default FilterBooleanActivePost;

/**
 * Labstep
 *
 * @module core/DataGrid/ColumnHeader/EntityImport/Metadata/Action/Create
 * @desc DataGrid Header for EntityImport
 */

import MetadataActionCreateModal from 'labstep-web/components/Metadata/Action/Create/Modal';
import ResourceItemActionCreateTemplateMetadata from 'labstep-web/components/ResourceItem/Action/Create/Template/Metadata';
import { ICONS } from 'labstep-web/constants/icons';
import { useGridEntityImportServiceFromContext } from 'labstep-web/contexts/grid/hook';
import { ActionComponentProps } from 'labstep-web/core/Action/Component/types';
import Dropdown from 'labstep-web/core/Dropdown';
import { Metadata } from 'labstep-web/models/metadata';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React, { useCallback } from 'react';
import { IDataGridColumnHeaderEntityImportMetadataActionCreateProps } from './types';

export const DataGridColumnHeaderEntityImportMetadataActionCreate: React.FC<
  IDataGridColumnHeaderEntityImportMetadataActionCreateProps
> = ({ column, template, targetEntityName }) => {
  const gridEntityImportService =
    useGridEntityImportServiceFromContext();

  const getActionComponentProps = useCallback<
    (entityName: string) => ActionComponentProps
  >(
    (entityName) => ({
      type: 'option',
      icon: ICONS.metadata.secondary,
      text: `${getHumanReadableEntityName(
        entityName,
        false,
        true,
      )} Metadata`,
    }),
    [],
  );

  if (!gridEntityImportService) {
    return null;
  }

  const createProps = {
    defaultValues: {
      label: column.getColDef().headerName,
      options_values: gridEntityImportService.getUniqueValues(
        column.getColId(),
      ),
    },

    noValue: true,
    hideLinkedTypes: true,
  };

  return (
    <>
      <Dropdown.Header>Add Template Field...</Dropdown.Header>
      {targetEntityName === ResourceItem.entityName && (
        <ResourceItemActionCreateTemplateMetadata
          resource={template as Resource}
          actionComponentProps={getActionComponentProps(
            targetEntityName,
          )}
          options={{
            onSuccess: ({ response }) => {
              if (!response) {
                return;
              }
              const colId = column.getColId();
              const metadata =
                response.entities.metadata[response.result as number];
              gridEntityImportService.convertCustomColDefToMetadataColDef(
                colId,
                new Metadata(metadata as Metadata),
                targetEntityName,
              );
            },
          }}
          {...createProps}
        />
      )}
      <MetadataActionCreateModal
        entity={template}
        entityName={template.entityName}
        actionComponentProps={getActionComponentProps(
          template.entityName,
        )}
        disableAddAnother
        options={{
          onSuccess: ({ response }) => {
            if (!response) {
              return;
            }
            const colId = column.getColId();
            const metadata =
              response.entities.metadata[response.result as number];
            gridEntityImportService.convertCustomColDefToMetadataColDef(
              colId,
              new Metadata(metadata as Metadata),
              template.entityName,
            );
          },
        }}
        {...createProps}
      />
    </>
  );
};

export default DataGridColumnHeaderEntityImportMetadataActionCreate;

/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/entity/metadata
 * @desc Column Definition for entity metadata
 */

import { getMetadataDateColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/date';
import { getMetadataDatetimeColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/datetime';
import { getMetadataDefaultColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/default';
import { getMetadataEntityRelationColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/entity-relation';
import { getMetadataFileColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/file';
import { getMetadataNumericColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/numeric';
import { getMetadataOptionsColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/options';
import { getMetadataSequenceColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/sequence';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { MetadataType } from 'labstep-web/models/metadata/types';

export const getMetadataColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
): NewIndexColDef => {
  switch (type) {
    case MetadataType.numeric:
      return getMetadataNumericColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
    case MetadataType.date:
      return getMetadataDateColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
    case MetadataType.datetime:
      return getMetadataDatetimeColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
    case MetadataType.file:
      return getMetadataFileColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
    case MetadataType.options:
      return getMetadataOptionsColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
    case MetadataType.sequence:
      return getMetadataSequenceColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
    case MetadataType.entity_relation:
      return getMetadataEntityRelationColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
    default:
      return getMetadataDefaultColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
  }
};

/**
 * Labstep
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import {
  PostFilterComparison,
  PostFilterComparisonHumanReadable,
} from 'labstep-web/services/query-parameter.service';
import rules from 'labstep-web/services/validation/rules';
import { number } from 'yup';

export const COMPARISON_OPTIONS = [
  {
    label: PostFilterComparisonHumanReadable.eq,
    value: PostFilterComparison.eq,
  },
  {
    label: PostFilterComparisonHumanReadable.gt,
    value: PostFilterComparison.gt,
  },
  {
    label: PostFilterComparisonHumanReadable.gte,
    value: PostFilterComparison.gte,
  },
  {
    label: PostFilterComparisonHumanReadable.lt,
    value: PostFilterComparison.lt,
  },
  {
    label: PostFilterComparisonHumanReadable.lte,
    value: PostFilterComparison.lte,
  },
];

export const fieldComparison: FieldWithoutActionProps = {
  fieldLabel: 'Comparison',
  name: 'comparison',
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: COMPARISON_OPTIONS.map((option) => ({
      value: option.value,
      label: option.label,
    })),
  },
  validation: rules.metadata.comparison,
};

export const fieldNumber: FieldWithoutActionProps = {
  fieldLabel: 'Value',
  name: 'value',
  placeholder: 'Enter value',
  fieldType: FieldType.Text,
  validation: number().required(),
};

/**
 * Labstep
 */

import { NAME_MAPPPINGS } from 'labstep-web/components/PubChemMetadata/Form/fields';
import {} from 'labstep-web/containers/PubChem/types';
import { colDefPubchemFieldType } from 'labstep-web/grid/Index/coldefs/Molecule/pubchem/types';
import { Molecule } from 'labstep-web/models/molecule.model';
import { showToast } from 'labstep-web/state/actions/ui';
import store from 'labstep-web/state/store';

export interface PubChemMetadata {
  IUPACName: string | null;
  MolecularFormula: string | null;
  CAS: string | null;
  InChI?: string | null;
  SMILES?: string | null;
  Density: string;
  MolecularWeight: string | null;
  Safety: {
    ghs_hazard_statements: string[];
    precautionary: {
      General: string[];
      Prevention: string[];
      Response: string[];
      Storage: string[];
      Disposal: string[];
    };
    pictograms: string[];
  };
}

export type DataInformation = {
  ReferenceNumber: string;
  Name: string;
  Value: {
    Number: number[];
    StringWithMarkup: {
      String: string;
      Markup: { URL: string }[];
    }[];
  };
};

export interface Data {
  Record: {
    RecordType: string;
    RecordNumber: number;
    RecordTitle: string;
    Section: Data['Record'][];
    Information: DataInformation[];
    TOCHeading: string;
  };
}

export class PubchemService {
  static getPubchemBody(
    entity: Molecule,
    field: colDefPubchemFieldType,
    value: string | number,
  ): Molecule['pubchem'] {
    return {
      products: entity.pubchem?.products || [],
      reactants: [
        {
          ...(entity.properties || Molecule.metadataDefault),
          [NAME_MAPPPINGS[field.name]]: value,
        },
      ],
    };
  }

  static getPubchemFieldValue(
    entity: Molecule,
    field: colDefPubchemFieldType,
  ): string | number | null {
    return entity.properties?.[NAME_MAPPPINGS[field.name]] || null;
  }

  static extractData(
    record: Data['Record'],
    sections: string[],
    mode: 'number' | 'string' | 'markup',
  ):
    | null
    | DataInformation['Value']['Number'][0]
    | DataInformation['Value']['StringWithMarkup'][0]['String']
    | DataInformation['Value']['StringWithMarkup'][0]['Markup'] {
    try {
      const section = sections.reduce(
        (currentSection, nextSection) => {
          return currentSection.Section.find(
            (h) => h.TOCHeading === nextSection,
          );
        },
        record,
      );

      switch (mode) {
        case 'string':
          return section.Information[0].Value.StringWithMarkup[0]
            .String;
        case 'number':
          return section.Information[0].Value.Number[0];
        case 'markup':
          return section.Information[0].Value.StringWithMarkup[0]
            .Markup;
        default:
          return null;
      }
    } catch (e) {
      return null;
    }
  }

  static extractChemicalSafety(
    record: Data['Record'],
  ): PubChemMetadata['Safety'] {
    const codes: PubChemMetadata['Safety'] =
      Molecule.metadataDefault.Safety;
    try {
      const { Information } = record.Section.find(
        (s) => s.TOCHeading === 'Safety and Hazards',
      )
        .Section.find(
          (s) => s.TOCHeading === 'Hazards Identification',
        )
        .Section.find((s) => s.TOCHeading === 'GHS Classification');

      const referenceNo = Information[0].ReferenceNumber;

      const precautionaryCodesSection = Information.find(
        (s) =>
          s.ReferenceNumber === referenceNo &&
          s.Name === 'Precautionary Statement Codes',
      );
      if (precautionaryCodesSection) {
        const precautionaryCodes =
          precautionaryCodesSection.Value.StringWithMarkup[0].String.split(
            ', ',
          ).map((s) => s.replace('and ', ''));

        codes.precautionary = {
          General: precautionaryCodes.filter((s) => s[1] === '1'),
          Prevention: precautionaryCodes.filter((s) => s[1] === '2'),
          Response: precautionaryCodes.filter((s) => s[1] === '3'),
          Storage: precautionaryCodes.filter((s) => s[1] === '4'),
          Disposal: precautionaryCodes.filter((s) => s[1] === '5'),
        };
      }
      const ghsHazardStatementsSection = Information.find(
        (s) =>
          s.ReferenceNumber === referenceNo &&
          s.Name === 'GHS Hazard Statements',
      );
      if (ghsHazardStatementsSection) {
        codes.ghs_hazard_statements =
          ghsHazardStatementsSection.Value.StringWithMarkup.map((s) =>
            s.String.split(':')[0]
              .replaceAll(' ', '')
              .replaceAll('*', ''),
          );
      }

      const pictogramsCodesSection = Information.find(
        (s) =>
          s.ReferenceNumber === referenceNo &&
          s.Name === 'Pictogram(s)',
      );
      if (pictogramsCodesSection) {
        codes.pictograms =
          pictogramsCodesSection.Value.StringWithMarkup[0].Markup.map(
            (s) => s.URL.split('/').pop().split('.')[0],
          );
      }
      return codes;
    } catch {
      return codes;
    }
  }

  static async extractMetadata(
    cid: number,
  ): Promise<PubChemMetadata | null> {
    const metadata = await fetch(
      `https://pubchem.ncbi.nlm.nih.gov/rest/pug_view/data/compound/${cid}/JSON`,
    )
      .then((resp) => resp.json())
      .then((data: Data) => {
        const record = data.Record;
        const IUPACName = this.extractData(
          record,
          [
            'Names and Identifiers',
            'Computed Descriptors',
            'IUPAC Name',
          ],
          'string',
        );
        const InChI = this.extractData(
          record,
          ['Names and Identifiers', 'Computed Descriptors', 'InChI'],
          'string',
        );
        const MolecularFormula = this.extractData(
          record,
          ['Names and Identifiers', 'Molecular Formula'],
          'string',
        );

        const CAS = this.extractData(
          record,
          ['Names and Identifiers', 'Other Identifiers', 'CAS'],
          'string',
        );

        const MolecularWeight =
          this.extractData(
            record,
            [
              'Chemical and Physical Properties',
              'Computed Properties',
              'Molecular Weight',
            ],
            'number',
          ) ||
          this.extractData(
            record,
            [
              'Chemical and Physical Properties',
              'Computed Properties',
              'Molecular Weight',
            ],
            'string',
          );

        const Density = this.extractData(
          record,
          [
            'Chemical and Physical Properties',
            'Experimental Properties',
            'Density',
          ],
          'string',
        );

        const Safety = this.extractChemicalSafety(record);

        const SMILES = this.extractData(
          record,
          ['Names and Identifiers', 'Computed Descriptors', 'SMILES'],
          'string',
        );

        return {
          IUPACName,
          InChI,
          MolecularFormula,
          CAS,
          Density,
          MolecularWeight,
          Safety,
          SMILES,
        };
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('extractMetadataError=', error);
        return null;
      });

    return metadata as PubChemMetadata | null;
  }

  static async getCID(url: string): Promise<number> {
    const cid = await fetch(url)
      .then((resp) => resp.json())
      .then(
        (data) =>
          data.IdentifierList &&
          data.IdentifierList.CID &&
          data.IdentifierList.CID[0],
      )
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('getCIDError=', error);
        return null;
      });
    return cid;
  }

  static async getSearchResults(
    value: string,
    limit = 15,
  ): Promise<{ label: string; value: string }[]> {
    const searchResults = await fetch(
      `https://pubchem.ncbi.nlm.nih.gov/rest/autocomplete/compound/${value}/json?limit=${limit}`,
    )
      .then((resp) => resp.json())
      .then(
        (data) =>
          data.dictionary_terms &&
          data.dictionary_terms.compound &&
          data.dictionary_terms.compound.map((result: string) => ({
            label: result,
            value: result,
          })),
      )
      .catch(() => ({}));

    if (!searchResults) {
      return [];
    }

    return searchResults;
  }

  static async getPubChemMetadataFromText(
    text: string,
  ): Promise<PubChemMetadata | null> {
    const cid = await PubchemService.getCID(
      `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/name/${text}/cids/json`,
    );

    if (!cid) {
      return null;
    }

    return PubchemService.extractMetadata(cid);
  }

  static async getPubChemMetadataFromSmiles(
    smiles: string,
  ): Promise<PubChemMetadata | null | null> {
    if (!smiles) {
      return null;
    }

    const defaultMetadata = {
      ...Molecule.metadataDefault,
      SMILES: smiles,
    };

    const cid = await PubchemService.getCID(
      `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/smiles/cids/JSON?smiles=${encodeURIComponent(
        smiles,
      )}`,
    );

    if (!cid) {
      return defaultMetadata;
    }

    const metadata = PubchemService.extractMetadata(cid);
    if (!metadata) {
      return defaultMetadata;
    }

    return metadata;
  }

  /**
   * Adds value to top of results
   * @param results array
   * @param value string
   * @returns array
   */
  static addValueToResults = (
    results: { label: string; value: string }[],
    value: string,
  ): { label: string; value: string }[] => {
    const valueOption = { label: value, value };
    const resultsWithoutValue = results.filter(
      (result) => result.value !== value,
    );
    resultsWithoutValue.unshift(valueOption);
    return resultsWithoutValue;
  };

  /**
   * Show toast with error
   */
  static showNoResultsError = (): void => {
    store.dispatch(
      showToast({
        type: 'error',
        message: 'Compound not found.',
      }),
    );
  };
}

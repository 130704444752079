/**
 * Labstep
 *
 * @module core/Header
 * @desc Core Header component. Includes 7 sizes.
 */

import cx from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { IHeaderProps } from './types';

export const SectionHeader: React.FC<{
  label: string;
}> = (props) => {
  return (
    <div>
      <div className={styles.sectionHeader}>
        <div>{props.label}</div>
        <hr />
        {props.children}
      </div>
    </div>
  );
};

export const Header: React.FC<IHeaderProps> = ({
  children,
  size,
  color,
  noMargin,
  className,
  onClick,
  isDataPublic = true,
}) => {
  const sizeClass = styles[`${size}Size`];
  const cxArgs: cx.ArgumentArray = [
    sizeClass,
    { [styles.noMargin]: noMargin },
  ];
  if (className) {
    cxArgs.push({ [className]: className });
  }
  if (color) {
    cxArgs.push({ [styles[color]]: color });
  }
  const classes = cx(...cxArgs);

  return (
    <span
      className={classes}
      onClick={onClick}
      {...(isDataPublic ? { 'data-public': true } : {})}
    >
      {children}
    </span>
  );
};

Header.defaultProps = {
  size: 'medium',
};

export default Header;

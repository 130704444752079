/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/Reference
 * @desc Renders an Reference node
 */

import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { ProtocolTimer } from 'labstep-web/models/protocol-timer.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import Read from 'labstep-web/prosemirror/components/NodeView/Read';
import { useReactNodeView } from 'labstep-web/prosemirror/components/ReactNodeView';
import React from 'react';
import Content from './Content';
import { ReferenceNodeProps } from './types';

const mapping = {
  experiment_timer: ProtocolTimer.entityName,
  experiment_table: ProtocolTable.entityName,
  experiment_value: ProtocolValue.entityName,
  experiment_step: ProtocolStep.entityName,
};
export class ReferenceNode extends React.Component<ReferenceNodeProps> {
  shouldComponentUpdate() {
    return !this;
  }

  render() {
    const { id, entityName, parentEntity, timestamp } = this.props;

    return (
      <Read id={id} entityName={entityName} timestamp={timestamp}>
        {({ entity }) => (
          <Content
            entity={entity as any}
            parentEntity={parentEntity}
          />
        )}
      </Read>
    );
  }
}

const ReferenceNodeView = ({ entity, timestamp }: any) => {
  const context = useReactNodeView();
  const { entityName, id, guid } = (context.node as any).attrs;
  const finalEntityName = (mapping as any)[entityName] || entityName;
  return (
    <ReferenceNode
      id={id || guid}
      entityName={finalEntityName}
      parentEntity={entity}
      timestamp={timestamp}
    />
  );
};

export default ReferenceNodeView;

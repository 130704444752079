/**
 * Labstep
 *
 * @module components/SecurityLog/Table
 * @desc A multilist listing user organizations
 */

import TableSimple from 'labstep-web/core/Table/Simple';
import { Log } from 'labstep-web/models/log.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { ISecurityLogTableProps } from './types';

const SecurityLogTable: React.FC<ISecurityLogTableProps> = ({
  securityLogs,
}) => {
  const columns = [
    {
      header: 'Date',
      content: (securityLog: Log) =>
        humanReadableDate(securityLog.created_at),
      cellProps: { flex: 1 },
    },
    {
      header: 'Type',
      content: (securityLog: Log) => securityLog.type.toUpperCase(),
      cellProps: { style: { width: 120 } },
    },
    {
      header: 'User',
      content: (securityLog: Log) => securityLog.author.username,
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (securityLog: Log) => securityLog.clientIp,
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (securityLog: Log) => securityLog.browser.name,
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (securityLog: Log) => securityLog.printDeviceType,
      cellProps: { flex: 1 },
    },
    {
      header: '',
      content: (securityLog: Log) => securityLog.os.name,
      cellProps: { flex: 1 },
    },
  ];
  return <TableSimple rows={securityLogs} columns={columns} />;
};

export default SecurityLogTable;

/**
 * Labstep
 *
 * @module components/Filter/AvailableResourceItemCount
 * @desc AvailableResourceItemCount min/max filter
 */

import Container from 'labstep-web/components/Layout/Container';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import Modal from 'labstep-web/core/Modal/Default';
import ReactSelect from 'labstep-web/core/Select';
import { ReactSelectOption } from 'labstep-web/core/Select/types';
import { PostFilterAvailableItems } from 'labstep-web/services/postFilter/filters/availableItems';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import styles from './styles.module.scss';
import { AvailableResourceItemCountFilterProps } from './types';

/* @var Options for minimum */
export const MIN_OPTIONS: (string | number | boolean)[] = [
  'No minimum',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
];

/* @var Options for maximum */
export const MAX_OPTIONS: (string | number | boolean)[] = [
  'No maximum',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
];

const AvailableResourceItemCountFilter: React.FC<
  AvailableResourceItemCountFilterProps
> = ({
  searchParams,
  setParams,
  addPostFilter,
  actionComponentProps,
}) => {
  let defaultValueMin = {
    value: MIN_OPTIONS[0],
    label: MIN_OPTIONS[0],
  };
  let defaultValueMax = {
    value: MAX_OPTIONS[0],
    label: MAX_OPTIONS[0],
  };
  if (searchParams.available_resource_item_count_min) {
    defaultValueMin = {
      value: searchParams.available_resource_item_count_min as string,
      label:
        MIN_OPTIONS[
          parseInt(
            searchParams.available_resource_item_count_min as string,
            10,
          ) + 1
        ],
    };
  }
  if (searchParams.available_resource_item_count_max) {
    defaultValueMax = {
      value: searchParams.available_resource_item_count_max as string,
      label:
        MAX_OPTIONS[
          parseInt(
            searchParams.available_resource_item_count_max as string,
            10,
          ) + 1
        ],
    };
  }
  if (searchParams.filter) {
    const { predicates: filterPredicates } = searchParams.filter[0];
    if (filterPredicates) {
      filterPredicates.forEach(({ predicates }) => {
        const { attribute, comparison, value } = predicates[0];
        if (value && attribute === 'available_resource_item_count') {
          if (comparison === PostFilterComparison.gte) {
            defaultValueMin = {
              value,
              label:
                typeof value === 'string'
                  ? MIN_OPTIONS[parseInt(value, 10)]
                  : MIN_OPTIONS[0],
            };
          } else if (comparison === PostFilterComparison.lte) {
            defaultValueMax = {
              value,
              label:
                typeof value === 'string'
                  ? MAX_OPTIONS[parseInt(value, 10)]
                  : MAX_OPTIONS[0],
            };
          }
        }
      });
    }
  }

  return (
    <Modal
      header="Filter by Available Items"
      content={({ toggleModal }) => (
        <div>
          <Container>
            <Flex>
              <ReactSelect
                className={styles.reactSelect}
                isClearable
                placeholder="Select minimum"
                defaultValue={defaultValueMin}
                options={Object.keys(MIN_OPTIONS).map((key) => ({
                  value: MIN_OPTIONS[Number(key)],
                  label: MIN_OPTIONS[Number(key)],
                }))}
                onChange={(selectedOption: ReactSelectOption) => {
                  if (addPostFilter) {
                    addPostFilter(
                      PostFilterAvailableItems.getNode([
                        [
                          selectedOption.value,
                          PostFilterComparison.gte,
                        ],
                      ]),
                      true,
                    );
                  } else {
                    setParams({
                      available_resource_item_count_min:
                        selectedOption &&
                        selectedOption.value !== MIN_OPTIONS[0]
                          ? selectedOption.value
                          : undefined,
                    });
                  }
                }}
              />
              <ReactSelect
                className={styles.reactSelect}
                isClearable
                placeholder="Select maximum"
                defaultValue={defaultValueMax}
                options={Object.keys(MAX_OPTIONS).map((key) => ({
                  value: MAX_OPTIONS[Number(key)],
                  label: MAX_OPTIONS[Number(key)],
                }))}
                onChange={(selectedOption: ReactSelectOption) => {
                  if (addPostFilter) {
                    addPostFilter(
                      PostFilterAvailableItems.getNode([
                        [
                          selectedOption.value,
                          PostFilterComparison.lte,
                        ],
                      ]),
                      true,
                    );
                  } else {
                    setParams({
                      available_resource_item_count_max:
                        selectedOption &&
                        selectedOption.value !== MAX_OPTIONS[0]
                          ? selectedOption.value
                          : undefined,
                    });
                  }
                }}
              />
            </Flex>
          </Container>
          <LayoutContainerActionButtons>
            <ActionComponent
              type="button"
              text="Done"
              onClick={toggleModal}
            />
          </LayoutContainerActionButtons>
        </div>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={ICONS.resource_item.primary}
          text="Available Items"
          onClick={toggleModal}
          {...actionComponentProps}
        />
      )}
    />
  );
};

export default AvailableResourceItemCountFilter;

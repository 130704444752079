/**
 * Labstep
 *
 * @module components/Metadata/Form/ShowEdit/EntityRelation
 * @desc ShowEdit for EntityRelation
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { EntityLink } from 'labstep-web/components/Entity/Link';
import EntityListCreate from 'labstep-web/components/Entity/ListCreate';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import Flex from 'labstep-web/core/Flex';
import Hoverable from 'labstep-web/core/Hoverable';
import { EntityRelation } from 'labstep-web/models/entity-relation.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { MetadataFormShowEditEntityRelationProps } from './types';

export const MetadataFormShowEditEntityRelation: React.FC<
  MetadataFormShowEditEntityRelationProps
> = ({ metadata, entity, activeGroup }) => {
  if (
    !metadata.field_definition ||
    !activeGroup ||
    (entity as Resource).is_template
  ) {
    return null;
  }

  const entityRelations =
    entity && (entity as Resource).getEntityRelationsByFieldDefinition
      ? (entity as Resource).getEntityRelationsByFieldDefinition(
          metadata.field_definition,
        )
      : [];

  const disableActionButton =
    !metadata.field_definition.is_multiple_entity_relations_allowed &&
    entityRelations.length !== 0;

  return (
    <EntityListCreate
      disableAddButton={disableActionButton}
      group={activeGroup}
      entities={entityRelations}
      entityName={EntityRelation.entityName}
      searchEntityName={
        metadata.field_definition.entity_relation_target_entity_class
      }
      searchParams={
        metadata.field_definition.entity_relation_target_entity_filter
          ? {
              ...metadata.field_definition
                .entity_relation_target_entity_filter,
              group_id: activeGroup.id,
              count: 20,
            }
          : {
              group_id: activeGroup.id,
              count: 20,
            }
      }
      // placeholder={`Search ${metadata.field_definition.entity_relation_target_entity_class} in this workspace`}
      // actionMessageIcon={ICONS.metadata.type.entity_relation}
      onChangeCreateProps={(selectedOption: Entity) => {
        return {
          field_definition_id: metadata.field_definition!.id,
          [`source_${metadata.field_definition!.entity_relation_target_entity_class}_id`]:
            entity.guid,
          [`target_${metadata.field_definition!.entity_relation_target_entity_class}_id`]:
            selectedOption.guid,
        };
      }}
      list={({ entities }) => {
        return (
          <Flex>
            {entities.map((entityRelation: EntityRelation) => {
              if (!entityRelation.target || !entityRelation.source) {
                return null;
              }
              const relationEntityLinked =
                entityRelation.source.guid === entity.guid
                  ? entityRelation.target
                  : entityRelation.source;
              return (
                <Flex grow>
                  <Hoverable
                    hoverable={
                      <EntityLink
                        key={relationEntityLinked.guid}
                        entity={relationEntityLinked}
                        showIcon
                      />
                    }
                    component={
                      <EntityActionDelete
                        entity={entityRelation}
                        actionComponentProps={{
                          type: 'icon',
                          icon: 'times',
                          elementProps: {
                            popup: { content: 'Remove' },
                          },
                        }}
                        skipConfirmation
                      />
                    }
                  />
                </Flex>
              );
            })}
          </Flex>
        );
      }}
    />
  );
};

export default withActiveGroup(MetadataFormShowEditEntityRelation);

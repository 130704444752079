/**
 * Labstep
 *
 * @module components/Entity/ListCreate/Item
 * @desc Entity List Create Item
 */

import classnames from 'classnames';
import ActionComponent from 'labstep-web/core/Action/Component';
import Icon from 'labstep-web/core/Icon';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import styles from './styles.module.scss';
import { EntityListCreateItemProps } from './types';

export const EntityListCreateItem: React.FC<
  EntityListCreateItemProps
> = ({
  toggle,
  toggled,
  text,
  select,
  entities,
  entityName,
  childKeyName,
  list,
  actionMessageIcon,
  additionalActions,
  createPermission,
  prepend,
  disableAddButton = false,
}) => (
  <div
    className={classnames(styles.toggleContainer, {
      active: entities.length > 0,
    })}
  >
    {actionMessageIcon && (
      <div>
        <Icon name={actionMessageIcon} />
      </div>
    )}
    {prepend}
    <div className={styles.listContainer}>
      {list && entities.length !== 0
        ? list({ entities })
        : list &&
          !createPermission && (
            <div>{`No ${getHumanReadableEntityName(
              entityName,
              true,
            )}`}</div>
          )}
    </div>
    {createPermission && (
      <div className={styles.actionContainer}>
        {additionalActions}
        {!toggled
          ? !disableAddButton && (
              <ActionComponent
                dataCy="components/Entity/ListCreate/Item"
                text={
                  text ||
                  `Add ${getHumanReadableEntityName(
                    childKeyName || entityName,
                  )}(s)`
                }
                onClick={toggle}
                {...(entities.length
                  ? {
                      type: 'icon',
                      icon: 'plus',
                      elementProps: {
                        popup: { content: 'Add another' },
                      },
                    }
                  : {
                      type: 'text',
                      elementProps: {
                        placeholder: true,
                      },
                    })}
              />
            )
          : select()}
      </div>
    )}
  </div>
);

export default EntityListCreateItem;

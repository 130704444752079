/**
 * Labstep
 */

import Papa from 'papaparse';
import { ExcelIOData } from './excel-io/types';

export class PapaParseService {
  public config;

  public constructor(
    private onImport: (data: unknown[], fields: string[]) => void,
  ) {
    this.config = {
      header: true,
      skipEmptyLines: true,
      complete: this.onComplete.bind(this),
    };
    this.onImport = onImport;
  }

  public onComplete(results: Papa.ParseResult<unknown>): void {
    const { data, errors, meta } = results;
    if (errors.length > 0) {
      PapaParseService.onError(errors);
    } else if (data.length && meta.fields?.length) {
      this.onImport(data, meta.fields);
    } else {
      PapaParseService.onError(new Error('No data found'));
    }
  }

  public static onError(errors: Papa.ParseError[] | Error): void {
    // eslint-disable-next-line no-console
    console.error(errors);
  }

  public parse(file: File): void {
    Papa.parse(file, this.config);
  }

  /**
   * Convert Papa data to ExcelIO data.
   * @param data Papa data
   * @param fields Papa column fields
   * @returns ExcelIO data
   */
  public static convertPapaDataToExcelIOData(
    data: Record<string, string>[],
    fields: string[],
  ): ExcelIOData {
    const dataTable: ExcelIOData = {};
    fields.forEach((field, fieldIndex) => {
      dataTable[0] = {
        ...dataTable[0],
        [fieldIndex]: {
          value: field,
        },
      };
    });
    data.forEach((row, rowIndex) => {
      fields.forEach((field, fieldIndex) => {
        dataTable[rowIndex + 1] = {
          ...dataTable[rowIndex + 1],
          [fieldIndex]: {
            value: row[field],
          },
        };
      });
    });

    return dataTable;
  }
}

export default PapaParseService;

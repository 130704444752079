/**
 * Labstep
 *
 * @module components/Filter/Active
 * @desc Active Filters
 */

import FilterDeviceActive from 'labstep-web/components/Device/Filter/Active';
import ExperimentWorkflowFilterProtocolActive from 'labstep-web/components/ExperimentWorkflow/Filter/Protocol/Active';
import ExperimentWorkflowFilterResourceActive from 'labstep-web/components/ExperimentWorkflow/Filter/Resource/Active';
import ExperimentWorkflowFilterSignedActive from 'labstep-web/components/ExperimentWorkflow/Filter/Signed/Active';
import ExperimentWorkflowFilterStatusActive from 'labstep-web/components/ExperimentWorkflow/Filter/Status/Active';
import ExperimentWorkflowFilterStatusTypeActive from 'labstep-web/components/ExperimentWorkflow/Filter/StatusType/Active';
import FileFilterExtensionActive from 'labstep-web/components/File/Filter/Extension/Active';
import FilterDeletedActive from 'labstep-web/components/Filter/Deleted/Active';
import FilterResourceItemStatusActive from 'labstep-web/components/Filter/ResourceItemStatus/Active';
import FilterSearchActive from 'labstep-web/components/Filter/Search/Active';
import FilterTagActive from 'labstep-web/components/Filter/Tag/Active';
import FilterUserActive from 'labstep-web/components/Filter/User/Active';
import FilterPostActive from 'labstep-web/components/FilterPost/Active';
import MoleculeFilterActive from 'labstep-web/components/Molecule/Filter/Active';
import ProtocolCollectionFilterStatusActive from 'labstep-web/components/ProtocolCollection/Filter/Status/Active';
import ResourceLocationFilterActive from 'labstep-web/components/ResourceLocation/Filter/Active';
import TagFilterTypeActive from 'labstep-web/components/Tag/Filter/Type/Active';
import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { dateOnly } from 'labstep-web/services/date.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  DateRangeActiveFilterProps,
  IActiveFilterProps,
  IActiveFilterWithHooksProps,
} from './types';
import { getHasActiveFilter } from './utils';

export const DateRangeActiveFilter: React.FC<
  DateRangeActiveFilterProps
> = ({ dateFrom, dateTo, clearParams }) => {
  if (!dateTo || !dateFrom) {
    return null;
  }

  const toText = dateOnly(dateTo);
  const fromText = dateOnly(dateFrom);

  return (
    <RemoveLabel
      name={`${fromText} - ${toText}`}
      icon="calendar alternate"
      onRemoveClick={() =>
        clearParams &&
        clearParams(['created_at_from', 'created_at_to'])
      }
    />
  );
};

export const ActiveFilterWithHooks: React.FC<
  IActiveFilterWithHooksProps
> = ({ searchParams }) => {
  const {
    setParams,
    clearParams,
    setPostFilterType,
    removePostFilter,
    clearAll,
  } = useParamsContext();
  const { entityView } = useParamsStateContext();

  return (
    <ActiveFilter
      setParams={setParams}
      searchParams={searchParams}
      clearParams={clearParams}
      setPostFilterType={setPostFilterType}
      removePostFilter={removePostFilter}
      entityView={entityView}
      clearAll={clearAll}
    />
  );
};

const ActiveFilter: React.FC<IActiveFilterProps> = ({
  searchParams,
  setParams,
  clearParams,
  showSearchQuery,
  setPostFilterType,
  removePostFilter,
  clearAll,
  entityView,
}) => {
  if (!searchParams || !clearParams || !setParams) {
    return null;
  }
  const hasParams = getHasActiveFilter(searchParams, showSearchQuery);
  if (!hasParams) {
    return null;
  }
  return (
    <div data-testid="filter-active" className={styles.container}>
      <span>Filtering by:</span>
      {setPostFilterType &&
        removePostFilter &&
        clearAll &&
        entityView && (
          <FilterPostActive
            searchParams={searchParams}
            setPostFilterType={setPostFilterType}
            removePostFilter={removePostFilter}
            clearAll={clearAll}
            entityView={entityView}
          />
        )}
      <DateRangeActiveFilter
        clearParams={clearParams}
        dateFrom={searchParams.created_at_from as string}
        dateTo={searchParams.created_at_to as string}
      />
      <FilterUserActive
        searchParams={searchParams}
        clearParams={clearParams}
        type="author"
      />
      <FilterTagActive
        setParams={setParams}
        searchParams={searchParams}
      />
      <TagFilterTypeActive
        clearParams={clearParams}
        searchParams={searchParams}
      />
      <FilterResourceItemStatusActive
        clearParams={clearParams}
        searchParams={searchParams}
      />
      <ExperimentWorkflowFilterStatusTypeActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <ProtocolCollectionFilterStatusActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <ExperimentWorkflowFilterStatusActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <FileFilterExtensionActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <ExperimentWorkflowFilterSignedActive
        clearParams={clearParams}
        searchParams={searchParams}
      />
      <ExperimentWorkflowFilterProtocolActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <ExperimentWorkflowFilterResourceActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <FilterDeviceActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <FilterDeletedActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <FilterUserActive
        searchParams={searchParams}
        clearParams={clearParams}
        type="assignee"
      />
      <ResourceLocationFilterActive
        searchParams={searchParams}
        clearParams={clearParams}
      />
      <MoleculeFilterActive
        searchParams={searchParams}
        clearParams={clearParams}
        setParams={setParams}
      />
      {showSearchQuery && (
        <FilterSearchActive
          searchParams={searchParams}
          clearParams={clearParams}
        />
      )}
    </div>
  );
};

export default ActiveFilter;

/**
 * Labstep
 *
 * @module components/Organization/UserManager
 */

import EntitySearch from 'labstep-web/components/Entity/Search';
import SecurityLogTable from 'labstep-web/components/SecurityLog/Table';
import { Log } from 'labstep-web/models/log.model';
import React from 'react';
import { ISecurityLogManagerProps } from './types';

const SecurityLogManager: React.FC<ISecurityLogManagerProps> = ({
  organization,
}) => (
  <EntitySearch
    entityName={Log.entityName}
    params={{
      organization_id: organization.id,
      count: 10,
      action: 'security',
      has_request_trace: true,
    }}
    hideFilters
  >
    {({ entities }) => <SecurityLogTable securityLogs={entities} />}
  </EntitySearch>
);

export default SecurityLogManager;

/**
 * Labstep
 *
 * @module components/Entity/Template/Pane/Manager
 * @desc Manager for templates
 */

import EntityActionTemplateCreate from 'labstep-web/components/Entity/Action/TemplateCreate';
import EntitySearchCursor from 'labstep-web/components/Entity/Search/Cursor';
import EntityTemplateList from 'labstep-web/components/Entity/Template/List';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { sortByName } from 'labstep-web/core/Tree/utils';
import { TreeEntity } from 'labstep-web/models';
import React from 'react';
import { EntityTemplatePaneManagerContainerProps } from './types';

export const params: Record<string, unknown> = {
  template_id: undefined,
  has_template: 'false',
};

export const EntityTemplatePaneManagerContainer: React.FC<
  EntityTemplatePaneManagerContainerProps
> = ({
  activeGroup,
  showUncategorised = true,
  searchBarProps,
  entityName,
  entityPreview,
  indexRoute,
}) => (
  <EntitySearchCursor
    entityName={entityName}
    params={{
      group_id: activeGroup!.id,
      is_template: 1,
      sort: 'name',
    }}
    emptyStateAction={
      <EntityActionTemplateCreate
        groupId={activeGroup!.id}
        entityName={entityName}
        indexRoute={indexRoute}
      />
    }
    showEmptyStateOnSearchOnly
    searchBarProps={{
      placeholder: 'Search categories',
      ...searchBarProps,
    }}
  >
    {({ entities, searchParams }): React.ReactElement => {
      const searchActive = !!searchParams.search_query;
      return (
        <EntityTemplateList
          groupId={activeGroup!.id}
          entityPreview={entityPreview}
          templates={
            searchActive
              ? (entities as TreeEntity[])
              : (sortByName(entities as TreeEntity[]) as any)
          }
          showUncategorised={!searchActive && showUncategorised}
          showAction={!searchActive}
          entityName={entityName}
          indexRoute={indexRoute}
        />
      );
    }}
  </EntitySearchCursor>
);

export default withActiveGroup(EntityTemplatePaneManagerContainer);

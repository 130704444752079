/**
 * Labstep
 *
 * @module constants/organization-identifiers
 * @desc List of hardcoded organization identifiers
 */

// eslint-disable-next-line no-shadow
export enum ORGANIZATION_IDENTIFIERS {
  djsantibodies = 'djsantibodies',
  labstep = 'labstep',
  ochrebio = 'ochrebio',
  miravista = 'miravista_diagnostics_llc',
}

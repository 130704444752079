/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/metadata/file
 * @desc Column Definition for metadata file
 */

import {
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import FileActionCreate from 'labstep-web/components/File/Action/Create';
import FileActionRemove from 'labstep-web/components/File/Action/Remove';
import FilePreview from 'labstep-web/components/File/Preview';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import {
  createOrUpdateValue,
  setValue,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import Label from 'labstep-web/core/Label';
import Modal from 'labstep-web/core/Modal/Default';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import { GridColumnDefinitionService } from 'labstep-web/grid/services/grid-column-definition.service';
import { Entity } from 'labstep-web/models/entity.model';
import { File } from 'labstep-web/models/file.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { generateNewDateString } from 'labstep-web/services/date.service';
import rules from 'labstep-web/services/validation/rules';
import { mixed } from 'yup';

export const getMetadataFileColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
): NewIndexColDef => {
  return {
    colId: `metadata:${parentEntityClass}:${type}:${metadataLabel}`,
    postFilterSettings: {
      path: 'metadatas',
    },
    sortable: true,
    headerName: metadataLabel,
    headerComponent: GridNewIndexColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.type.file,
    },
    // editable: (params: EditableCallbackParams) =>
    //   GridNewIndexService.getEditable(params),

    editable: true,
    valueGetter: (params: ValueGetterParams) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return null;
      }
      return metadata.value;
    },
    valueSetter: (params: ValueSetterParams) => {
      if (!params.node) {
        return false;
      }

      return false;
    },
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return (
          <TextPlaceholder
            onClick={() => {
              createOrUpdateValue({
                entityName: Metadata.entityName,
                body: {
                  type,
                  label: metadataLabel,
                  metadata_thread_id:
                    params.node.data.metadata_thread.guid,
                },
                createProps: {
                  parentName:
                    params.node.data.metadata_thread.entityName,
                  parentId: params.node.data.metadata_thread.idAttr,
                },
                options: {
                  onSuccess: () => {
                    stopEditingAndFocus(params);
                    GridColumnDefinitionService.refreshCell(params);
                    if (params.node.rowIndex) {
                      params.api.startEditingCell({
                        rowIndex: params.node.rowIndex,
                        colKey: params.column.getColId(),
                      });
                    }
                  },
                },
              });
            }}
          >
            Upload files
          </TextPlaceholder>
        );
      }

      if (metadata?.files?.length > 1) {
        return (
          <Modal
            size="small"
            header="Files"
            viewComponent={({ toggleModal }) => (
              <TextPlaceholder onClick={toggleModal}>
                Multiple files
              </TextPlaceholder>
            )}
            content={() => (
              <div>
                {metadata.files.map((file: File) => (
                  <Label key={file.id}>
                    <FilePreview
                      file={file}
                      text={file.name}
                      noDiv
                      withDefault
                    />
                    <FileActionRemove
                      file={file}
                      actionComponentProps={{
                        type: 'icon',
                        icon: 'close',
                        elementProps: { noDiv: true },
                      }}
                      options={GridColumnDefinitionService.getOptions(
                        params,
                      )}
                    />
                  </Label>
                ))}
              </div>
            )}
          />
        );
      } else if (metadata?.files?.length === 1) {
        const file = metadata.files[0];
        return (
          <Label key={file.id}>
            <FilePreview
              file={file}
              text={file.name}
              noDiv
              withDefault
            />
            <FileActionRemove
              file={file}
              actionComponentProps={{
                type: 'icon',
                icon: 'close',
                elementProps: { noDiv: true },
              }}
              options={GridColumnDefinitionService.getOptions(params)}
            />
          </Label>
        );
      }

      return (
        <TextPlaceholder
          onClick={() => {
            if (params.node.rowIndex) {
              params.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: params.column.getColId(),
              });
            }
          }}
        >
          Upload files
        </TextPlaceholder>
      );
    },
    cellEditor: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return null;
      }

      return (
        <FileActionCreate
          entityType={metadata.entityName}
          entityId={metadata.id}
          onFolderWatcherSelect={({ file }): void => {
            setValue('file_id', file.id, {
              entityName: metadata.entityName,
              id: metadata.id,
              createProps: {
                parentName:
                  params.node.data.metadata_thread.entityName,
                parentId: params.node.data.metadata_thread.idAttr,
              },
              options: GridColumnDefinitionService.getOptions(params),
            });
            stopEditingAndFocus(params);
          }}
          options={{
            onSuccess: () => {
              stopEditingAndFocus(params);
            },
          }}
          modalProps={{
            onClose: (): void => stopEditingAndFocus(params),
            open: true,
            viewComponent: null,
          }}
        />
      );
    },
    suppressKeyboardEvent: suppressKeyboardEventClearCells,
    valueClearer: (params: any) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (metadata) {
        setValue(
          'deleted_at',
          generateNewDateString(),
          {
            entityName: metadata.entityName,
            id: metadata.idAttr,
          },
          rules.metadata.date,
          GridColumnDefinitionService.getOptions(params),
        );
      }
      return null;
    },
  };
};

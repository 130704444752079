/**
 * Labstep
 *
 * @module components/FilterPost/Report/Metadata
 * @desc Metadata filter
 */

import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import { Metadata } from 'labstep-web/models/metadata';
import React from 'react';
import FilterMetadataForm from './Form';
import { GridReportFilterMetadataProps } from './types';

const metadataNameCapitalised = Metadata.getHumanReadableEntityName(
  false,
  true,
);

const GridReportFilterMetadata: React.FC<
  GridReportFilterMetadataProps
> = ({ path, addPostFilter, defaultValues, replace, ...rest }) => (
  <Modal
    header={`Filter by ${metadataNameCapitalised}`}
    viewComponent={({ toggleModal }): React.ReactNode => (
      <ActionComponent
        onClick={toggleModal}
        type="option"
        icon={ICONS.metadata.secondary}
        text="Filter by this field"
      />
    )}
    content={({ toggleModal }): React.ReactNode => (
      <FilterMetadataForm
        addPostFilter={addPostFilter}
        path={path}
        onDone={toggleModal}
        defaultValues={defaultValues}
        replace={replace}
      />
    )}
    {...rest}
  />
);

export default GridReportFilterMetadata;

/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/CreateFromTemplate
 * @desc Create experiment workflow from a template
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ExperimentWorkflowActionMenu from 'labstep-web/components/ExperimentWorkflow/Action/Menu';
import { Action } from 'labstep-web/components/ExperimentWorkflow/Action/Menu/types';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Icon from 'labstep-web/core/Icon';
import Link from 'labstep-web/core/Link';
import FloatingSearchSelect from 'labstep-web/core/Select/FloatingSearch';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import {
  ExperimentWorkflowActionCreateFromTemplateContainerProps,
  ExperimentWorkflowActionCreateFromTemplateWithActiveGroupProps,
} from './types';

export const ExperimentWorkflowActionCreateFromTemplateWithActiveGroup: React.FC<
  ExperimentWorkflowActionCreateFromTemplateWithActiveGroupProps
> = ({ create, history, activeGroup, body = {}, ...rest }) => (
  <div className="button icon">
    <FloatingSearchSelect
      actionComponentProps={{
        type: 'children',
        children: ({ onClick }) => (
          <div
            style={{ padding: '0px 5px' }}
            onClick={onClick}
            data-cy="core/Select/FloatingSearch"
          >
            <Icon name="caret down" style={{ padding: '0px 5px' }} />
          </div>
        ),
      }}
      alignRight
      entityName={ExperimentWorkflow.entityName}
      placeholder="Search templates..."
      keepMenuOpen
      autoFocus
      isClearable={false}
      params={{ is_template: true, group_id: activeGroup?.id }}
      setOptions={(options) => {
        options.forEach((option) => {
          option.icon = 'copy';
        });
        return [
          {
            label: 'Create from template',
            children: (
              <Link unstyled to="experiment_workflow_template_index">
                <ActionComponent
                  dataCy="components/ExperimentWorkflow/Action/CreateFromTemplate:cog"
                  type="icon"
                  icon="cog"
                  elementProps={{
                    popup: { content: 'Manage Templates' },
                  }}
                />
              </Link>
            ),
            options,
          },
          {
            name: 'New Experiment Template',
            value: 'new',
            icon: 'add',
          },
        ];
      }}
      onChange={(option, input) => {
        const payload = body;
        if (option.value === 'new') {
          payload.is_template = true;
          payload.name = input || null;
        } else {
          payload.source_id = option.id;
        }
        create(payload, {
          onSuccess: ({ response }) => {
            history.push(
              navigation.get('experiment_workflow_show', {
                id: response.result,
              }),
            );
          },
        });
      }}
      getOptionActions={(option) => (
        <ExperimentWorkflowActionMenu
          experimentWorkflow={option as ExperimentWorkflow}
          actions={[Action.view, Action.duplicate, Action.delete]}
          usePortal
        />
      )}
    />
  </div>
);

export const ExperimentWorkflowActionCreateFromTemplate =
  withActiveGroup(
    ExperimentWorkflowActionCreateFromTemplateWithActiveGroup,
  );

export const ExperimentWorkflowActionCreateFromTemplateContainer: React.FC<
  ExperimentWorkflowActionCreateFromTemplateContainerProps
> = (props) => (
  <CanCreate entityName="experiment_workflow">
    <EntityCreateContainer
      entityName={ExperimentWorkflow.entityName}
      showLoaderWhileCreating
      isTemplate
    >
      {({ create, status }) => (
        <ExperimentWorkflowActionCreateFromTemplate
          create={create}
          status={status}
          {...props}
        />
      )}
    </EntityCreateContainer>
  </CanCreate>
);

export default withRouter(
  ExperimentWorkflowActionCreateFromTemplateContainer,
);

/**
 * Labstep
 */

import { ValueGetterParams } from 'ag-grid-community';
import UserPreview from 'labstep-web/components/User/Preview';
import { ICONS } from 'labstep-web/constants/icons';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { Entity } from 'labstep-web/models/entity.model';
import { OrderRequestStatus } from 'labstep-web/models/order-request.model';

const TOOL_PANEL_NAMES: Record<string, string> = {
  [OrderRequestStatus.approved]: 'Requested by',
  [OrderRequestStatus.ordered]: 'Ordered by',
  [OrderRequestStatus.received]: 'Received by',
};

export const getColDefOrderRequestLogAuthor = (
  status: OrderRequestStatus,
): NewIndexColDef => ({
  colId: `${status}_log_author`,
  headerName: TOOL_PANEL_NAMES[status],
  headerComponentParams: {
    icon: ICONS.user.primary,
  },
  editable: false,
  valueGetter: (params: ValueGetterParams) =>
    params.data.getStatusLog(status)?.author?.idAttr || null,
  cellRenderer: (params: CellRendererParams<Entity>) => {
    if (!params.data) {
      return '';
    }
    const user = params.data.getStatusLog(status)?.author;
    return user ? <UserPreview user={user} /> : null;
  },
});

/**
 * Labstep
 *
 * @module models/request-trace
 * @desc Typescript export class for Log
 */

import { Entity } from 'labstep-web/models/entity.model';
import { UAParser } from 'ua-parser-js';

export class RequestTrace extends Entity {
  static readonly entityName = 'request_trace';

  get entityName(): string {
    return RequestTrace.entityName;
  }

  constructor(data: Partial<RequestTrace> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  created_at!: string;

  client_ip!: string;

  client_user_agent!: string;

  client_uuid!: string;

  get userAgentObject(): UAParser.IResult {
    const parser = new UAParser();

    parser.setUA(this.client_user_agent);

    return parser.getResult();
  }
}

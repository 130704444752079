/**
 * Labstep
 *
 * @module grid/NewIndex/components/ToolPanel/Section/Item
 * @desc Draggable item for Entity DataGrid column options section
 */

import { HeaderComponentParamsProps } from 'labstep-web/core/DataGrid/types';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import SortableItemDragHandle from 'labstep-web/core/Sortable/Item/DragHandle';
import SortableItemRef from 'labstep-web/core/Sortable/Item/Ref';
import { SectionType } from 'labstep-web/grid/NewIndex/components/ToolPanel/Section/types';
import { IEntityDataGridToolPanelSectionItemProps } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';
import styles from './styles.module.scss';

export const EntityDataGridToolPanelSectionItem: React.FC<
  IEntityDataGridToolPanelSectionItemProps
> = ({ column, section, onToggleColumnVisible }) => {
  const { headerComponentParams, headerName } = column.getColDef();
  const colId = column.getId();
  const isVisible = section === SectionType.visible;

  const params: HeaderComponentParamsProps = {
    column,
  };
  return (
    <SortableItemRef id={colId} key={colId}>
      {({ style }): React.ReactElement => (
        <Flex data-testid="column-option" style={style} shrink={0}>
          {isVisible && <SortableItemDragHandle />}
          <Flex grow className={styles.item}>
            <div className={styles.name}>
              <Icon
                name={
                  objectOrFunction(headerComponentParams, params)
                    ?.icon
                }
                cornerIcon={
                  objectOrFunction(headerComponentParams, params)
                    ?.cornerIcon
                }
              />
              {headerName}
              {headerComponentParams?.secondaryHeader
                ? ` - ${headerComponentParams?.secondaryHeader}`
                : ''}
            </div>
            <div
              className={styles.iconContainer}
              onClick={(): void => onToggleColumnVisible(colId)}
            >
              <Icon
                popup={{ content: 'Hide Column' }}
                className={styles.icon}
                color="grey"
                name={'eye slash'}
              />
            </div>
          </Flex>
        </Flex>
      )}
    </SortableItemRef>
  );
};

export default EntityDataGridToolPanelSectionItem;

/**
 * Labstep
 *
 * @module components/Folder/Action/Add/Bulk
 * @desc Action to add parentEntity to folder
 */

import EntityActionAddSearchBulk from 'labstep-web/components/Entity/Action/AddSearch/Bulk';
import { useHasAccessMultiple } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ICONS } from 'labstep-web/constants/icons';
import { Folder } from 'labstep-web/models/folder.model';
import React from 'react';
import { FolderActionAddBulkProps } from './types';

export const FolderActionAddBulk: React.FC<
  FolderActionAddBulkProps
> = ({ entityName, parentEntities, options }) => {
  const parentEntityIdsAccessObject = useHasAccessMultiple(
    entityName,
    parentEntities.map((e) => e.id),
    Action.folder_add_remove,
  );

  if (parentEntities.length === 0) {
    return null;
  }

  const parentEntitiesWithPermission = parentEntities.filter(
    (e) => parentEntityIdsAccessObject[e.id],
  );

  return (
    <EntityActionAddSearchBulk
      dataCy="components/Folder/Action/Add/Bulk"
      entityName={Folder.entityName}
      parentEntities={parentEntities}
      parentEntitiesWithPermission={parentEntitiesWithPermission}
      actionText={`Add to ${Folder.getHumanReadableEntityName()}`}
      icon={ICONS.folder.primary}
      options={options}
      searchSelectProps={{
        labelKey: 'folder_path',
        extraParams: { count: 50 },
      }}
    />
  );
};

export default FolderActionAddBulk;

/**
 * Labstep
 *
 * @module components/Entity/Link/Component
 * @desc Child component for EntityLink
 */

import classnames from 'classnames';
import {
  ICONS,
  IEntityIconsProps,
} from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { EntityLinkComponentProps } from './types';

export const EntityLinkComponent: React.FC<
  EntityLinkComponentProps
> = ({
  secondary,
  displayName,
  showIcon,
  entity,
  onClick,
  disabled,
}) => {
  const className = classnames(styles.entityLink, {
    [styles.primary]: !secondary,
    [styles.enabled]: !disabled,
  });

  const finalDisplayName = displayName || entity.displayName;

  return (
    <span
      className={className}
      onClick={onClick}
      data-cy="components/Entity/Link/Component"
    >
      {showIcon && (
        <Icon
          name={
            (ICONS[entity.entityName] as IEntityIconsProps).primary
          }
        />
      )}
      {finalDisplayName}
    </span>
  );
};

export default EntityLinkComponent;

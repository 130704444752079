/**
 * Labstep
 */

import { Column } from 'ag-grid-community';
import { IEntityDataGridToolPanelSectionItemProps } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';

// eslint-disable-next-line no-shadow
export enum SectionType {
  visible = 'visible',
  hidden = 'hidden',
}
export interface IEntityDataGridToolPanelSectionProps {
  columns: Column[];
  section: SectionType;
  onToggleColumnVisible: IEntityDataGridToolPanelSectionItemProps['onToggleColumnVisible'];
  onSortColumns: (columns: Column[]) => void;
}

export type ColumnWithId = Column & {
  getId: () => string;
};

export type ItemWithColumn = {
  id: string;
  column: ColumnWithId;
};

/**
 * Labstep
 *
 * @module components/Filter/Tag/Active/Post
 * @desc Active filter component for tags in postFilter
 */

import { FilterPostActiveLabelProps } from 'labstep-web/components/FilterPost/Active/Label/types';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { Tag } from 'labstep-web/models/tag.model';
import { PostFilterTag } from 'labstep-web/services/postFilter/filters/tag';
import React from 'react';

const TagFilterActivePost: React.FC<FilterPostActiveLabelProps> = ({
  node,
  onRemoveClick,
}) => {
  const { id: guidAttribute } =
    PostFilterTag.getAttributeValues(node);
  const guid = guidAttribute?.value;
  if (!guid) {
    return null;
  }
  return (
    <EntityReadEntityContainer entityName={Tag.entityName} id={guid}>
      {({ entity }): React.ReactElement | null =>
        entity ? (
          <RemoveLabel
            icon={ICONS.tag.primary}
            name={entity.name}
            onRemoveClick={onRemoveClick}
          />
        ) : null
      }
    </EntityReadEntityContainer>
  );
};

export default TagFilterActivePost;

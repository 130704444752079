/**
 * Labstep
 *
 * @module components/Metadata/CardList
 * @desc List of metadatas
 */

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import LayoutLinks from 'labstep-web/components/Layout/Links';
import MetadataActionCreateDataModal from 'labstep-web/components/Metadata/Action/Create/DataModal';
import MetadataActionCreateFile from 'labstep-web/components/Metadata/Action/Create/File';
import MetadataCard from 'labstep-web/components/Metadata/Card';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityUpdateAnyContainer } from 'labstep-web/containers/Entity/Update/Any';
import { getChildRoute } from 'labstep-web/containers/Router/ProtocolChild';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import List from 'labstep-web/core/List';
import ListCollapsible from 'labstep-web/core/List/Collapsible';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Metadata } from 'labstep-web/models/metadata';
import { PositionService } from 'labstep-web/services/position.service';
import React from 'react';
import styles from './styles.module.scss';
import {
  IExperimentMetadataCardListContainerProps,
  IInvertoyFieldEmptyStateProps,
  IMetadataCardListContainerProps,
  IMetadataCardListProps,
} from './types';

const MetadataFieldEmptyState: React.FC<
  IInvertoyFieldEmptyStateProps
> = ({ entity, isInput }) => {
  const explanation = isInput
    ? 'Use input data fields to capture variable input parameters, such as incubation times and temperatures or instrument settings. Capturing parameters in structured way using data fields allows their impact to be compared across experiments.'
    : 'Use output data fields to capture any raw data, along with other measurements / results such as yield or concentration. Capturing data in structured way using data fields allows you to compare results across multiple experiments.';
  return (
    <MetadataActionCreateFile
      metadataThreadId={entity.metadata_thread.id}
    >
      <EmptyState
        src="/img/empty-state/data.svg"
        explanation={explanation}
        action={
          <MetadataActionCreateDataModal
            entity={entity}
            isInput={isInput}
          />
        }
      />
    </MetadataActionCreateFile>
  );
};

const MetadataCardListContainer: React.FC<
  IMetadataCardListContainerProps
> = ({ entity, draggable = true, hideActions, isInput }) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.id,
    Action.edit,
  );

  return (
    <MetadataActionCreateFile
      metadataThreadId={entity.metadata_thread.id}
    >
      <ReadOnMountHOC
        type="cursor"
        loading={{ loader: 'placeholder', cached: false }}
        entityName={Metadata.entityName}
        params={{
          metadata_thread_id: entity.metadata_thread.id,
          has_variable_template: false,
          is_input: isInput,
        }}
        children={({ entities }) => (
          <EntityUpdateAnyContainer entityName={Metadata.entityName}>
            {({ update }) => (
              <DragDropContext
                onDragEnd={(result: DropResult) =>
                  PositionService.onDragEnd(result, entities, update)
                }
              >
                <Droppable
                  droppableId="metadata-list"
                  isDropDisabled={!canEdit || !draggable}
                >
                  {(provided) => (
                    <div id="metadata-list" ref={provided.innerRef}>
                      <List>
                        {entities
                          .sort(
                            (a: Metadata, b: Metadata) =>
                              a.position - b.position,
                          )
                          .map(
                            (metadata: Metadata, index: number) => (
                              <Draggable
                                key={`metadata-${metadata.id}`}
                                draggableId={`metadata:${metadata.guid}`}
                                index={index}
                              >
                                {(draggableProvided) => (
                                  <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    <MetadataCard
                                      entity={entity}
                                      metadata={metadata as Metadata}
                                      hideActions={hideActions}
                                      isInput={isInput}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ),
                          )}
                      </List>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </EntityUpdateAnyContainer>
        )}
      />
    </MetadataActionCreateFile>
  );
};

const ExperimentMetadataCardListContainer: React.FC<
  IExperimentMetadataCardListContainerProps
> = ({ experimentWorkflow, isInput, draggable, hideActions }) => {
  return (
    <ReadOnMountHOC
      type="cursor"
      loading={{ loader: 'placeholder', cached: false }}
      entityName={Experiment.entityName}
      params={{
        is_root: false,
        experiment_workflow_id: experimentWorkflow.id,
      }}
      children={({ entities }: { entities: Experiment[] }) => {
        const experiments = entities.filter((e) => !e.deleted_at);

        return (
          <ListCollapsible
            sections={experiments.map((experiment) => ({
              icon: ICONS.experiment.primary,
              text: experiment.name,
              count: isInput
                ? experiment.metadata_input_count
                : experiment.metadata_output_count,
              component: (
                <MetadataCardListContainer
                  entity={experiment}
                  draggable={draggable}
                  hideActions={hideActions}
                  isInput={isInput}
                />
              ),
            }))}
          />
        );
      }}
    />
  );
};

export const MetadataCardList: React.FC<IMetadataCardListProps> = ({
  entity,
  experimentWorkflow,
  draggable = true,
  hideActions,
}) => {
  const inputRoute = getChildRoute('metadata')!;
  const outputRoute = getChildRoute('metadata_outputs')!;

  let metadataInputCount = entity.metadata_input_count;
  let metadataOutputCount = entity.metadata_output_count;
  if (experimentWorkflow) {
    metadataInputCount +=
      experimentWorkflow.metadata_input_count +
      experimentWorkflow.root_experiment.metadata_input_count;
    metadataOutputCount +=
      experimentWorkflow.metadata_output_count +
      experimentWorkflow.root_experiment.metadata_output_count;
  }

  return (
    <LayoutLinks
      links={[
        {
          children: 'Inputs',
          icon: 'sign in',
          render: () => {
            if (metadataInputCount === 0) {
              return (
                <MetadataFieldEmptyState
                  entity={
                    experimentWorkflow?.root_experiment || entity
                  }
                  isInput
                />
              );
            }
            return (
              <div className={styles.container}>
                <div className={styles.scrollableContent}>
                  <MetadataCardListContainer
                    entity={entity}
                    draggable={draggable}
                    hideActions={hideActions}
                    isInput
                  />
                  {experimentWorkflow && (
                    <ExperimentMetadataCardListContainer
                      experimentWorkflow={experimentWorkflow}
                      isInput
                      draggable={draggable}
                      hideActions={hideActions}
                    />
                  )}
                </div>
                <div className={styles.barrier} />
                <MetadataActionCreateDataModal
                  entity={
                    experimentWorkflow?.root_experiment || entity
                  }
                  isInput
                />
              </div>
            );
          },
          route: {
            to: inputRoute.name,
            params: { id: inputRoute.params.id },
          },
        },
        {
          children: 'Outputs',
          icon: 'sign out',
          route: {
            to: outputRoute.name,
            params: { id: outputRoute.params.id },
          },
          render: () => {
            if (metadataOutputCount === 0) {
              return (
                <MetadataFieldEmptyState
                  entity={
                    experimentWorkflow?.root_experiment || entity
                  }
                  isInput={false}
                />
              );
            }
            return (
              <div className={styles.container}>
                <div className={styles.scrollableContent}>
                  <MetadataCardListContainer
                    entity={entity}
                    draggable={draggable}
                    hideActions={hideActions}
                    isInput={false}
                  />
                  {experimentWorkflow && (
                    <ExperimentMetadataCardListContainer
                      experimentWorkflow={experimentWorkflow}
                      isInput={false}
                      draggable={draggable}
                      hideActions={hideActions}
                    />
                  )}
                </div>
                <div className={styles.barrier} />
                <MetadataActionCreateDataModal
                  entity={
                    experimentWorkflow?.root_experiment || entity
                  }
                  isInput={false}
                />
              </div>
            );
          },
        },
      ]}
      stretch
      inverted
    />
  );
};

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Boolean/Form
 * @desc Filter Metadata form
 */

import ReusableFormGroup from 'labstep-web/core/Form/Reusable/Group';
import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React, { useCallback, useState } from 'react';
import { fieldBoolean } from './fields';
import { IFilterBooleanFormProps } from './types';

export const getPostFilterBoolean = (
  field: string,
  comparison: PostFilterComparison,
  path?: string,
) => {
  return new PostFilter([[field, comparison]], path);
};

const FilterBooleanForm: React.FC<IFilterBooleanFormProps> = ({
  addPostFilter,
  onDone,
  path,
  field,
  defaultValues,
}) => {
  const [fields, setFields] = useState([fieldBoolean]);
  const onSubmit = useCallback(
    (values): void => {
      const { value } = values.bool;
      const postFilter = getPostFilterBoolean(
        field,
        PostFilterComparison.eq,
        path,
      ).getNode([value]);

      addPostFilter(postFilter, true);
      onDone();
    },
    [path, addPostFilter],
  );

  return (
    <ReusableFormGroup
      fields={fields}
      onSubmit={onSubmit}
      onDone={onDone}
      defaultValues={defaultValues}
      addAnother={false}
    />
  );
};

export default FilterBooleanForm;

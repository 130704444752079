/**
 * Labstep
 *
 * @module prosemirror/extensions/paste
 * @desc Handle paste
 */

import { Experiment } from 'labstep-web/models/experiment.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import NodeFile from 'labstep-web/prosemirror/nodes/file';
import { uploadFile } from 'labstep-web/state/actions/file';
import store from 'labstep-web/state/store';
import { EditorState } from 'prosemirror-state';

export const onCreateFilesSuccess = (
  state: EditorState,
  dispatch,
  fileIds: number[],
) => {
  const { tr } = state;
  tr.setStoredMarks([]);
  let currentPosition = tr.selection.$to.pos;
  fileIds.forEach((id) => {
    const node = state.schema.nodes[NodeFile.key].create({
      id,
    });
    tr.insert(currentPosition, node);
    currentPosition = tr.selection.$to.pos;
  });

  dispatch(tr);
};

export const createFiles = (
  state: EditorState,
  dispatch,
  entity: Experiment | Protocol,
  files,
): void => {
  const fileIds: number[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    store.dispatch(
      uploadFile(
        {
          file,
          entityType: entity.entityName,
          entityId: entity.id,
        },
        {
          onSuccess: ({ response }) => {
            const id = (response as any).result[0];
            fileIds.push(id);
            if (fileIds.length === files.length) {
              onCreateFilesSuccess(state, dispatch, fileIds);
            }
          },
        },
      ),
    );
  }
};

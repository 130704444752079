/**
 * Labstep
 *
 * @module models/log
 * @desc Typescript export class for Log
 */

import { Type } from 'class-transformer';
import { LoggablePartial } from 'labstep-web/models';
import { Entity } from 'labstep-web/models/entity.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { RequestTrace } from 'labstep-web/models/request-trace.model';
import { User } from 'labstep-web/models/user.model';
import { UAParser } from 'ua-parser-js';

export class Log extends Entity {
  static readonly entityName = 'log';

  get entityName(): string {
    return Log.entityName;
  }

  constructor(data: Partial<Log> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: string;

  created_at!: string;

  ended_at!: string;

  message!: string;

  action!: string;

  type!: string;

  previous_value!: unknown;

  new_value!: unknown;

  is_log_entry_snapshot_available!: boolean;

  object_id!: number;

  object_class!: string;

  log_entry_object_class!: string;

  log_entry_object_id!: number;

  log_entry_client_user_agent!: string;

  log_entry_client_uuid!: string;

  loggable_parents: LoggablePartial[] = [];

  @Type(() => RequestTrace)
  request_trace!: RequestTrace;

  @Type(() => User)
  author!: User;

  get objectClassToEntityName(): string {
    if (this.object_class === 'Protocol') {
      return Protocol.entityName;
    }
    if (this.object_class === 'Experiment') {
      return Experiment.entityName;
    }
    if (this.object_class === 'ProtocolTable') {
      return ProtocolTable.entityName;
    }
    return '';
  }

  get diff(): string | undefined {
    if (this.action === 'update') {
      return JSON.stringify({
        previous_value: this.previous_value,
        new_value: this.new_value,
      });
    }
  }

  get clientIp(): string {
    return this.request_trace?.client_ip || '';
  }

  get client_user_agent(): string {
    return (
      this.log_entry_client_user_agent ||
      this.request_trace?.client_user_agent ||
      ''
    );
  }

  get userAgentObject(): UAParser.IResult {
    const parser = new UAParser();

    parser.setUA(this.client_user_agent);

    return parser.getResult();
  }

  get clientDisplayLabel(): string {
    return `${this.browser.name} ${this.printDeviceType} ${this.os.name}`;
  }

  get browser(): UAParser.IBrowser {
    return this.userAgentObject.browser;
  }

  get device(): UAParser.IDevice {
    return this.userAgentObject.device;
  }

  get os(): UAParser.IOS {
    return this.userAgentObject.os;
  }

  get printDeviceType(): string {
    const { device } = this.userAgentObject;
    return device.type || 'desktop';
  }
}

/**
 * Labstep
 *
 * @desc Entities validation rules
 * @see https://github.com/skaterdav85/validatorjs
 */

import { OrderRequestStatus } from 'labstep-web/models/order-request.model';
import { array, boolean, mixed, number, object, string } from 'yup';

/** We've added this so that we send a number as string to the backend. If we chose
 * number() as the rule then we would send a number as a number to the backend.
 */
export const numericString = string()
  .matches(/^\d+(\.\d+)?$/, 'Field must be a numeric value')
  .nullable();

const accessKey = {
  name: string().required(),
  expires_at: string().nullable(),
};

const profile = {
  website: string().nullable(),
  location: string().nullable(),
  description: string().max(2000),
};

const deviceBooking = {
  description: string(),
};

const group = {
  name: string().required().max(255),
  description: string().nullable(),
  default_signature_statement: string().nullable(),
  default_signature_request_message: string().nullable(),
};

const entityState = {
  name: string().required().max(255),
  description: string().nullable(),
  target_allowed_actions_lock: object().nullable(),
};

const entityUserRoleRequirement = {
  number_required: number().min(1).max(100).integer().required(),
  entity_user_role: mixed().required(),
  automation: object().nullable(),
};

const entityUser = {
  entity_user_role: mixed().required(),
};

const entityUserRole = {
  entity_user_role: mixed().required(),
  description: string().nullable(),
};

const signatureRequirement = {
  reject_entity_state: mixed().required(),
  statement: string().nullable(),
  days_to_sign: number().nullable().min(1).max(100).integer(),
};

const notificationAlert = {
  message: string().nullable().max(255),
  minutes_before: number().required().integer().min(0),
};

const groupAutoSharing = {
  entity_type: object().required(),
  target_group: mixed().required(),
  permission_type: object().required(),
  propagate: string().nullable(),
};

const organization = {
  name: string().required().max(255),
  description: string().nullable(),
  whitelist_ip: mixed().required(),
};

export interface OrganizationSAMLRules {
  attribute_email: string | null;
  attribute_first_name: string | null;
  attribute_last_name: string | null;
  idp_entity_id: string | null;
  idp_sign_on_url: string | null;
  idp_logout_url: string | null;
  idp_certificate: string | null;
}

const organizationSAML = {
  attribute_email: string().nullable(),
  attribute_first_name: string().nullable(),
  attribute_last_name: string().nullable(),
  idp_entity_id: string().nullable(),
  idp_sign_on_url: string().nullable(),
  idp_logout_url: string().nullable(),
  idp_certificate: string().nullable(),
};

const folder = {
  name: string().required().max(255),
  description: string().nullable(),
};

const permissionRole = {
  name: string().required().max(255),
};

const user = {
  first_name: string().required().max(255),
  last_name: string().required().max(255),
  biography: string().nullable(),
};

const comment = {
  body: string().nullable(),
};

const richText = {
  description: string().required(),
};

const orderRequest = {
  name: string().required().max(255),
  quantity: number().required().max(100000000).min(1),
  amount: number().required().max(100000000).min(0),
  resource: mixed().required(),
  is_urgent: boolean().nullable(),
  currency: object().required(),
  status: string()
    .oneOf(Object.values(OrderRequestStatus))
    .required(),
};

const protocolCollection = {
  name: string().required().max(255),
};

const protocolCondition = {
  quantity: number().required().max(100).min(1),
};

const protocol = {
  name: string().required().max(255),
  changes: string().nullable(),
  additional_information: string().required(),
  protocol_collection_draft_id: string().nullable(),
  group: mixed().required(),
};

const protocolStep = {
  name: string().required(),
  description: string().nullable(),
};

const protocolValue = {
  inputOutputType: object().nullable(),
  is_lineage: string().nullable(),
  name: string().nullable().max(255),
  resource: mixed().nullable(),
  resource_item: mixed().nullable(),
  amount: numericString,
  unit: mixed().nullable(),
};

const protocolTimer = {
  name: string().required(),
  hours: number().integer().nullable().min(0).max(99),
  minutes: number().integer().nullable().min(0).max(59),
  seconds: number().integer().nullable().min(0).max(59),
};

const metadata = {
  label: string().max(190).required(),
  type: object().required(),
  value: string().nullable(),
  date: string().nullable(),
  datetime: string().nullable(),
  number: number().nullable(),
  unit: string().nullable().max(32),
  sequence: mixed().nullable(),
  options: mixed().nullable(),
  options_values: mixed().nullable(),
  options_is_allow_multiple: boolean(),
  options_is_allow_add: boolean(),
  is_required: boolean(),
  file: mixed().nullable(),
  files: mixed().nullable(),
  device: mixed().required(),
  device_data: mixed().nullable(),
  comparison: mixed().required(),
};

const tag = {
  name: string().required().max(50),
  type: object().required(),
};

const resourceLocation = {
  name: string().required().max(255),
  map_rows: number().required().integer().max(26).min(1),
  map_columns: number().required().integer().max(100).min(1),
};

const resourceItem = {
  name: string().nullable().max(255),
  amount: numericString,
  amount_alert_threshold: numericString,
  unit: object().nullable(),
  resource: mixed().required(),
  status: string().oneOf(['available', 'unavailable']).required(),
};

const purchaseOrder = {
  name: string().required().max(255),
  handling_amount: number().nullable().max(100000000).min(0),
  discount_amount: number().nullable().max(100000000).min(0),
  tax_rate: number().min(0).max(100).nullable(),
  currency: object().nullable(),
};

const resource = {
  name: string().required().max(255),
  status: string().required(),
  resource_location: mixed().required(),
  resource_template: mixed().nullable(),
  default_unit: string().nullable().max(32),
  default_amount: number().nullable(),
  generate_resource_item_count: number()
    .nullable()
    .min(0)
    .max(0)
    .integer(),
  available_resource_item_count_alert_threshold: number()
    .nullable()
    .min(0)
    .max(10000000000)
    .integer(),
};

const customIdentifierSet = {
  prefix: string().required().max(30),
  zero_padding: number().nullable().integer().min(0).max(10),
};

const chemical = {
  purity: number().nullable().min(0).max(1),
  equivalence: numericString,
  molar_amount: numericString,
};

const pubChemMetadata = {
  name: string().nullable().max(500),
  molecular_formula: string().nullable().max(500),
  cas_number: string().nullable().max(500),
  molecular_weight: number().required().min(0),
  density: number().required().min(0),
};

const device = {
  name: string().required().max(255),
};

const experiment = {
  name: string().required().max(255),
  description: string().nullable(),
};

const entityStateWorkflow = {
  name: string().required().max(255),
};

const experimentWorkflow = {
  name: string().required().max(255),
  description: string().nullable(),
  state: string().nullable(),
  planned_at: array().of(string()).nullable(),
  template: mixed().nullable(),
};

const fieldDefinition = {
  is_multiple_entity_relations_allowed: boolean(),
  relation_type: mixed().required(),
  inverse_relation: string().required().max(255),
  entity_relation_target_entity_filter_template: object().required(),
};

const jupyterNotebook = {
  name: string().nullable().max(255),
  automation: object().nullable(),
};

const table = {
  name: string().required().max(255),
};

const htmlTable = {
  rows: number().integer().required().max(25).min(1),
  columns: number().integer().required().max(25).min(1),
};

const signatureRequest = {
  message: string().nullable().max(10000),
  user: object().required(),
};

const signature = {
  statement: string().nullable().max(10000).required(),
};

const stripeCustomerAddress = {
  line1: string().required(),
  line2: string().nullable(),
  postal_code: string().required(),
  city: string().required(),
  state: string().nullable(),
  country: object().required(),
};

const entity = {
  name: string().required().max(255),
  name_nulable: string().nullable().max(255),
  description: string().nullable(),
};

const entityView = {
  name: string().required().max(255),
};

const rules = {
  access_key: accessKey,
  entity,
  chemical,
  rich_text: richText,
  jupyter_notebook: jupyterNotebook,
  resource_location: resourceLocation,
  entity_view: entityView,
  entity_state: entityState,
  entity_user: entityUser,
  entity_user_role_requirement: entityUserRoleRequirement,
  entity_user_role: entityUserRole,
  signature_requirement: signatureRequirement,
  experiment,
  entity_state_workflow: entityStateWorkflow,
  experiment_workflow: experimentWorkflow,
  field_definition: fieldDefinition,
  tag,
  profile,
  group,
  group_auto_sharing: groupAutoSharing,
  comment,
  protocol,
  protocol_value: protocolValue,
  resource,
  reagent: {},
  protocol_collection: protocolCollection,
  protocol_condition: protocolCondition,
  protocol_step: protocolStep,
  protocol_timer: protocolTimer,
  user,
  purchase_order: purchaseOrder,
  email: string().email().required(),
  username: string().email().required(),
  password: string().required().min(8),
  password_strong: string()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
      'Field must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character excluding _',
    ),
  organisation: string().required().min(3),
  signup_password: string()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
      'Field must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character excluding _',
    ),
  signup_password_confirmation: string()
    .required()
    .test(
      'passwords-match',
      'Passwords must match',
      // eslint-disable-next-line func-names
      function (value) {
        return (
          this.parent.password === value ||
          this.parent.new_password === value
        );
      },
    ),
  metadata,
  order_request: orderRequest,
  resource_item: resourceItem,
  table,
  htmlTable,
  signature,
  signature_request: signatureRequest,
  folder,
  permission_role: permissionRole,
  device,
  organization,
  organization_saml: organizationSAML,
  stripe_customer_address: stripeCustomerAddress,
  custom_identifier_set: customIdentifierSet,
  notification_alert: notificationAlert,
  pub_chem_metadata: pubChemMetadata,
  device_booking: deviceBooking,
  six_digit_code: string().max(6).required(),
};

export default rules;

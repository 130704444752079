/**
 * Labstep
 *
 * @module components/ExperimentWorkflow/Action/CreateMenu
 * @desc Create Action Menu for experiment workflow
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import ExperimentWorkflowActionCreate from 'labstep-web/components/ExperimentWorkflow/Action/Create';
import ExperimentWorkflowActionCreateFromTemplate from 'labstep-web/components/ExperimentWorkflow/Action/CreateFromTemplate';
import Button from 'labstep-web/core/Button';
import React from 'react';
import { ExperimentWorkflowActionCreateMenuProps } from './types';

export const ExperimentWorkflowActionCreateMenu: React.FC<
  ExperimentWorkflowActionCreateMenuProps
> = (props) => (
  <CanCreate entityName="experiment_workflow">
    <Button.Group color="blue">
      <ExperimentWorkflowActionCreate {...props} />
      <ExperimentWorkflowActionCreateFromTemplate {...props} />
    </Button.Group>
  </CanCreate>
);

export default ExperimentWorkflowActionCreateMenu;

/**
 * Labstep
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import { setValue } from 'labstep-web/core/DataGrid/utils';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import rules from 'labstep-web/services/validation/rules';

export const colDefOrderRequestQuantity: NewIndexColDef = {
  colId: 'quantity',
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.numeric,
    field: 'quantity',
  },
  headerName: '# of items',
  headerComponentParams: {
    icon: ICONS.resource_item.primary,
  },
  editable: (params: EditableCallbackParams) =>
    GridNewIndexService.getEditable(params),
  valueGetter: (params: ValueGetterParams) => params.data.quantity,
  valueSetter: (params) =>
    setValue(
      'quantity',
      Number(params.newValue),
      {
        entityName: params.data.entityName,
        id: params.data.id,
      },
      rules.order_request.quantity,
    ),
  suppressKeyboardEvent: suppressKeyboardEventClearCells,
  valueClearer: () => 1,
};

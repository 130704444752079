/**
 * Labstep
 *
 * @module models/organization
 * @desc Typescript export class for Organization
 */

import { Type } from 'class-transformer';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { PermissionRoleSections } from 'labstep-web/constants/roles';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { OrganizationSAML } from 'labstep-web/models/organization-saml.model';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import { UserOrganization } from 'labstep-web/models/user-organization.model';
import { User } from 'labstep-web/models/user.model';
import { CurrencyISO } from 'labstep-web/services/currency.service';
import { daysDifference } from 'labstep-web/services/date.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export type Source =
  | 'filters'
  | 'metadata'
  | 'bulk_metadata'
  | 'metadata_detail_table'
  | 'categories'
  | 'referencing_menu_categories'
  | 'conditions_table'
  | 'global_search'
  | 'slash_commands'
  | 'sub_links'
  | 'order_request_action';

export type PremiumKey =
  | Filter
  | MetadataType
  | PermissionRoleSectionKey
  | string;

type FeatureConfig = {
  premiumKeys: PremiumKey[];
  show: Source[];
  hide: Source[];
};

export type PremiumFeatures = Partial<
  Record<PremiumFeature, FeatureConfig>
>;

export type OptionWithConfig<T> = T & {
  premiumFeature?: PremiumFeature;
  show: boolean;
  premiumFeatureEnabled: boolean;
};

type PermissionRoleSectionKey = keyof typeof PermissionRoleSections;

export const PermissionRoleSectionKeys: Record<
  PermissionRoleSectionKey,
  string
> = Object.keys(PermissionRoleSections).reduce(
  (acc, key) => {
    acc[key as PermissionRoleSectionKey] = key;
    return acc;
  },
  {} as Record<PermissionRoleSectionKey, string>,
);

export const PAYMENT_TYPE_BACS = 'bacs_debit';

export const PAYMENT_TYPE_CARD = 'card';

export const PAYMENT_TYPE_SEPA = 'sepa_debit';

export interface StripeAddress {
  line1: string;
  line2: string;
  postal_code: string;
  city: string;
  state: string;
  country: string;
}

export type PremiumFeature =
  | 'ip_whitelist'
  | '2fa'
  | 'security_log'
  | 'custom_permissions'
  | 'time_machine'
  | 'timeline'
  | 'jupyter'
  | 'jupyter_automation'
  | 'chemistry'
  | 'auto_sharing'
  | 'sso'
  | 'multiplexing'
  | 'marvinjs'
  | 'entity_import'
  | 'lock_settings'
  | 'signature_workflow'
  | 'inventory'
  | 'order_management'
  | 'devices'
  | 'molecular_biology'
  | 'export_logs'
  | 'custom_identifier'
  | 'completion_checklist'
  | 'export'
  | 'spreadsheet_designer'
  | 'custom_identifier'
  | 'reporting'
  | 'entity_relation'
  | 'log_overview';

export class Organization extends Entity {
  static readonly entityName = 'organization';

  public get entityName(): string {
    return Organization.entityName;
  }

  public constructor(data: Partial<Organization>) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public name!: string;

  public whitelist_ip!: string[];

  public logo_url!: string;

  public has_metabase_id!: boolean;

  public metabase_url!: string;

  public two_factor_authentication!: boolean;

  public identifier!: string;

  public is_allow_member_create_group!: boolean;

  public is_stripe_subscription_set!: boolean;

  public group_count!: number;

  public free_seats!: number;

  public tier!:
    | 'academic_free'
    | 'academic_plus'
    | 'academic_institute'
    | 'startup'
    | 'pro'
    | 'enterprise'
    | 'labstep'
    | 'basic'
    | 'ultimate';

  public stripe_customer_id!: string;

  public currency!: CurrencyISO | null;

  public stripe_payment_method_type!:
    | 'bacs_debit'
    | 'card'
    | 'sepa_debit';

  public trial_ends_at!: string;

  public premium_features?: PremiumFeature[];

  public is_saml_enabled!: boolean;

  public is_sync_stripe_enabled!: boolean;

  public is_trialling!: boolean;

  public is_payment_overdue!: boolean;

  public seats!: number | null;

  public max_seats!: number;

  public admin_email!: string;

  @Type(() => OrganizationSAML)
  public organization_saml!: OrganizationSAML | null;

  @Type(() => UserOrganization)
  public logged_user_user_organization!: UserOrganization;

  @Type(() => User)
  public author!: User;

  @Type(() => Group)
  public groups!: Group[];

  @Type(() => PermissionRole)
  public permission_roles!: PermissionRole[];

  public get printDaysRemaining(): string {
    const difference = daysDifference(
      this.trial_ends_at,
      new Date().toDateString(),
    );
    return `${difference} days remaining`;
  }

  public get noSeatsLeft(): boolean {
    if (!this.max_seats) {
      return false;
    }
    return (this.seats || 0) >= this.max_seats;
  }

  public get availableSeats(): number | null {
    if (this.max_seats) {
      return this.max_seats - (this.seats || 0);
    }
    return null;
  }

  public hasFeatureEnabled(feature: PremiumFeature): boolean {
    return this.premium_features
      ? this.premium_features.indexOf(feature) > -1
      : false;
  }

  public get hasTrialEnded(): boolean {
    if (!this.trial_ends_at || !this.is_trialling) {
      return false;
    }
    const expiryDate = new Date(this.trial_ends_at);
    return new Date().getTime() >= expiryDate.getTime();
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}

/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/metadata/entity-relation
 * @desc Column Definition for metadata entity relation
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import {
  createOrUpdateValue,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { generateNewDateString } from 'labstep-web/services/date.service';
import rules from 'labstep-web/services/validation/rules';

export const getMetadataEntityRelationColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
): NewIndexColDef => {
  return {
    colId: `metadata:${parentEntityClass}:${type}:${metadataLabel}`,
    postFilterSettings: {
      path: 'metadatas',
    },
    sortable: true,
    headerName: metadataLabel,
    headerComponent: GridNewIndexColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.type.entity_relation,
    },
    editable: (params: EditableCallbackParams) =>
      GridNewIndexService.getEditable(params),
    valueGetter: (params: ValueGetterParams) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return null;
      }
      return metadata.value;
    },
    valueSetter: (params: ValueSetterParams) => {
      if (!params.node) {
        return false;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        createOrUpdateValue({
          entityName: Metadata.entityName,
          body: {
            type,
            label: metadataLabel,
            metadata_thread_id: params.node.data.metadata_thread.guid,
            value: params.newValue,
          },
          createProps: {
            parentName: params.node.data.metadata_thread.entityName,
            parentId: params.node.data.metadata_thread.idAttr,
          },
        });
        return true;
      }

      if (params.newValue === null) {
        setValue(
          'deleted_at',
          generateNewDateString(),
          {
            entityName: metadata.entityName,
            id: metadata.idAttr,
          },
          rules.metadata.date,
        );
        return true;
      }

      setValue('value', params.newValue, {
        entityName: metadata.entityName,
        id: metadata.idAttr,
      });
      return true;
    },
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return '';
      }
      return <>{metadata.value}</>;
    },
    suppressKeyboardEvent: suppressKeyboardEventClearCells,
    valueClearer: () => null,
  };
};

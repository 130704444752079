/**
 * Labstep
 *
 * @module models/entity-state-workflow
 * @desc Typescript export class for EntityStateWorkflow
 *
 */

import { Type } from 'class-transformer';
import {
  EntityState,
  EntityStateType,
} from 'labstep-web/models/entity-state.model';
import { EntityUser } from 'labstep-web/models/entity-user.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

// eslint-disable-next-line no-shadow
export enum WorkflowType {
  experiment_workflow = 'experiment workflow',
  protocol = 'protocol',
}

export class EntityStateWorkflow extends Entity {
  static readonly entityName = 'entity_state_workflow';

  public static readonly entityClass =
    'entity_state_workflow' as const;

  get entityName(): typeof EntityStateWorkflow.entityName {
    return EntityStateWorkflow.entityName;
  }

  constructor(data: Partial<EntityStateWorkflow> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: string;

  public name!: string;

  public is_template!: boolean;

  public author_id!: number;

  public owner_id!: number;

  public entity_type!: WorkflowType;

  public entity_state_round!: number;

  public experiment_workflow_template_count!: number;

  entity_users_count!: number;

  @Type(() => Group)
  public owner!: Group;

  @Type(() => User)
  public author!: User;

  @Type(() => EntityUser)
  entity_users_preview!: EntityUser[];

  @Type(() => EntityState)
  public _entity_states!: EntityState[];

  public get entity_states(): EntityState[] {
    return EntityStateWorkflow.sortEntityStatesByPosition(
      this._entity_states.filter((e) => !e.deleted_at),
    );
  }

  public set entity_states(entityStates: EntityState[]) {
    this._entity_states = entityStates;
  }

  static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }

  public getStatesWithType(type: EntityStateType) {
    const states = this.entity_states.filter(
      (entityState: EntityState) =>
        entityState.type === type.toLowerCase(),
    );
    return states;
  }

  public getStateCurrentRounds(
    experimentWorkflowEntityStateRound: number,
  ) {
    const newStateCurrentRounds: { [key: string]: number } = {};
    this.entity_states.forEach((entityState: EntityState) => {
      newStateCurrentRounds[entityState.name] =
        experimentWorkflowEntityStateRound;
    });
    return newStateCurrentRounds;
  }

  public hasSignatureRequirements() {
    return this.entity_states.some(
      (entityState: EntityState) =>
        entityState.signature_requirements.length > 0,
    );
  }

  get perma_link() {
    return {
      url: this.guid,
    };
  }

  public static sortEntityStatesByPosition(
    entityStates: EntityState[],
  ) {
    return entityStates.sort((a, b) => {
      return a.position - b.position;
    });
  }
}

/**
 * Labstep
 *
 * @module components/Entity/Action/Duplicate
 * @desc Button to duplicate entity
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { TemplatableEntity } from 'labstep-web/models';
import { IRouting } from 'labstep-web/services/navigation.types';
import React from 'react';
import { EntityActionDuplicateProps } from './types';

export const EntityActionDuplicate: React.FC<
  EntityActionDuplicateProps
> = ({
  entity,
  actionComponentProps,
  body = {},
  noRedirect,
  dataCy,
  ...rest
}) => {
  let bodyWithExtraParams: Record<string, unknown> = {
    source_id: entity.id,
  };
  if ((entity as TemplatableEntity).is_template) {
    bodyWithExtraParams.is_template = true;
  }
  // TODO Refactor
  // We spread the body after bodyWithExtraParams for experiment workflow case
  // where even though is_template is true we really want to pass false.
  bodyWithExtraParams = { ...bodyWithExtraParams, ...body };
  return (
    <EntityActionCreate
      dataCy={dataCy}
      entityName={entity.entityName}
      actionComponentProps={{
        type: 'option',
        text: 'Duplicate',
        icon: 'clone',
        ...actionComponentProps,
      }}
      redirectRoute={
        noRedirect
          ? undefined
          : ({ response }) => ({
              to: `${entity.entityName}_show` as keyof IRouting,
              params: { id: response.result },
            })
      }
      body={bodyWithExtraParams}
      {...rest}
    />
  );
};

export default EntityActionDuplicate;

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Text
 * @desc Filter to toggle between deleted items
 */

import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import FilterNumericForm from './Form';
import { FilterPostNewIndexNumericProps } from './types';

const FilterPostNewIndexNumeric: React.FC<
  FilterPostNewIndexNumericProps
> = ({
  label,
  path,
  field,
  addPostFilter,
  defaultValues,
  multiplicator,
  ...rest
}) => (
  <Modal
    header={`Filter by ${label}`}
    viewComponent={({ toggleModal }): React.ReactNode => (
      <ActionComponent
        onClick={toggleModal}
        type="option"
        icon={ICONS.metadata.secondary}
        text="Filter by this field"
      />
    )}
    content={({ toggleModal }): React.ReactNode => (
      <FilterNumericForm
        addPostFilter={addPostFilter}
        path={path}
        field={field}
        defaultValues={defaultValues}
        onDone={toggleModal}
        multiplicator={multiplicator}
      />
    )}
    {...rest}
  />
);

export default FilterPostNewIndexNumeric;

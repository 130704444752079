/**
 * Labstep
 *
 * @module components/Entity/TimeMachine/List
 * @desc List of entities loaded for the time machine
 */

import TableCard from 'labstep-web/components/Table/Card';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import ProseMirrorReadOnly from 'labstep-web/prosemirror/ReadOnly';
import React from 'react';
import { EntitySnapshotListItemEntityProps } from './types';

const EntitySnapshotListItemEntity: React.FC<
  EntitySnapshotListItemEntityProps
> = ({ entitySnapshot }) => (
  <ReadOnMountHOC
    type="entities"
    entityName={entitySnapshot.objectClassToEntityName}
    params={{
      id: entitySnapshot.object_id,
      at: entitySnapshot.created_at,
      get_single: 1,
    }}
    loading={{ loader: 'main_content', cached: true }}
    children={({ entity }) => {
      switch (entitySnapshot.objectClassToEntityName) {
        case Protocol.entityName:
        case Experiment.entityName:
          return (
            <ProseMirrorReadOnly
              key={entitySnapshot.id}
              initialState={entity.state}
              entity={entity}
              timestamp={entitySnapshot.created_at}
            />
          );
        case ProtocolTable.entityName:
          return (
            <TableCard
              key={entitySnapshot.id}
              name={entity.name}
              data={entity.data}
              debouncingStatus={{ isFetching: false }}
              onChange={() => {}}
              onError={() => {}}
              readOnly
            />
          );
        default:
          return null;
      }
    }}
  />
);

export default EntitySnapshotListItemEntity;

/**
 * Labstep
 *
 * @module components/EntityView/Action/Save
 * @desc Action to save EntityView
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { useGridServiceFromContext } from 'labstep-web/contexts/grid/hook';
import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { PINNED_COL_IDS } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridFilterService } from 'labstep-web/grid/services/grid-filter.service';
import {
  ENTITY_VIEW_DEFAULT_PARAMETERS,
  EntityView,
  EntityViewParameters,
} from 'labstep-web/models/entity-view.model';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { navigation } from 'labstep-web/services/navigation';
import { cloneDeep, isEqual } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router';
import { EntityViewActionCreateProps } from './types';

/**
 * Cleanup search parameters
 * - Set default parameters if empty
 * - Remove system filters from the parameters
 * - Remove search_query
 */
const cleanupSearchParams = (searchParams: any) => {
  let parameters = cloneDeep(searchParams);

  if (!parameters || Object.keys(parameters).length === 0) {
    parameters = ENTITY_VIEW_DEFAULT_PARAMETERS;
  }

  if (
    parameters.filter &&
    Object.keys(parameters.filter).length === 2
  ) {
    parameters.filter[1] = ENTITY_VIEW_DEFAULT_PARAMETERS.filter![1];
  }

  delete parameters.search_query;

  return parameters;
};

export const EntityViewActionSave: React.FC<
  EntityViewActionCreateProps
> = ({ history }) => {
  const { searchParams } = useParamsContext();

  const paramsStateContext = useParamsStateContext();

  const gridService = useGridServiceFromContext();

  if (!paramsStateContext) {
    return null;
  }

  const { entityView, loadedEntityView } = paramsStateContext;

  if (!gridService || !loadedEntityView || !entityView) {
    return null;
  }

  const newState = gridService.getState();
  const newColumnDefinitionIds = gridService.getColumnDefinitionIds();

  if (!newState) {
    return null;
  }

  if (
    (newState || []).filter(
      (colDef) => !PINNED_COL_IDS.includes(colDef.colId),
    ).length === 0
  ) {
    return null;
  }

  const paramsNotChanged = isEqual(
    cleanupSearchParams(loadedEntityView.parameters),
    cleanupSearchParams(searchParams),
  );

  const stateNotChanged =
    !loadedEntityView.state ||
    isEqual(
      (loadedEntityView.state || []).filter(
        (colDef) => !PINNED_COL_IDS.includes(colDef.colId),
      ),
      (newState || []).filter(
        (colDef) => !PINNED_COL_IDS.includes(colDef.colId),
      ),
    );

  const columnDefinitionsNotChanged = isEqual(
    (loadedEntityView.column_definition_ids || []).filter(
      (colId) => !PINNED_COL_IDS.includes(colId),
    ),
    (newColumnDefinitionIds || []).filter(
      (colId) => !PINNED_COL_IDS.includes(colId),
    ),
  );

  const columnOptionsNotChanged = isEqual(
    loadedEntityView.column_options,
    entityView.column_options,
  );

  if (
    paramsNotChanged &&
    stateNotChanged &&
    columnDefinitionsNotChanged &&
    columnOptionsNotChanged
  ) {
    return null;
  }

  const searchParamsToSave = GridFilterService.removeAllSystemFilters(
    cloneDeep(searchParams) as EntityViewParameters,
  );

  const columnDefinitionsToSave = cloneDeep(
    newColumnDefinitionIds,
  ).filter(
    (colId: string) =>
      entityView.entity_name === ProtocolCondition.entityName ||
      !PINNED_COL_IDS.includes(colId),
  );

  if (!entityView.id) {
    return (
      <EntityCreateContainer entityName={EntityView.entityName}>
        {({ create, status }) => (
          <ActionComponent
            type="button"
            icon="save"
            showIcon
            elementProps={{ basic: true }}
            text={
              entityView.entity_name === ProtocolCondition.entityName
                ? 'Save as Report'
                : 'Save View'
            }
            status={status}
            onClick={() => {
              if (gridService && create) {
                create(
                  {
                    context: entityView.context,
                    entity_name: entityView.entity_name,
                    name: entityView.name,
                    [`${entityView.entity_name}_template_id`]:
                      entityView.templateId !== 'none'
                        ? entityView.templateId
                        : null,
                    group_id: entityView.group.guid,
                    parameters: searchParamsToSave,
                    state: newState,
                    column_definition_ids: columnDefinitionsToSave,
                    column_options: entityView.column_options,
                  },
                  {
                    onSuccess: ({
                      response,
                    }: {
                      response: {
                        result: string;
                      };
                    }) => {
                      if (
                        entityView.entity_name ===
                        ProtocolCondition.entityName
                      ) {
                        history.push(
                          navigation.get('entity_view_show', {
                            id: entityView.group.id,
                            entityViewId: response.result,
                          }),
                        );
                      }
                    },
                  },
                );
              }
            }}
          />
        )}
      </EntityCreateContainer>
    );
  }

  return (
    <EntityUpdateContainer
      entityName={EntityView.entityName}
      id={entityView.guid}
    >
      {({ update, status }) => (
        <ActionComponent
          type="button"
          text="Save View"
          icon="save"
          showIcon
          status={status}
          elementProps={{ basic: true }}
          onClick={() => {
            if (gridService && update) {
              update(
                {
                  parameters: searchParamsToSave,
                  state: newState,
                  column_definition_ids: columnDefinitionsToSave,
                  column_options: entityView.column_options,
                },
                {},
              );
            }
          }}
        />
      )}
    </EntityUpdateContainer>
  );
};

export default withRouter(EntityViewActionSave);

/**
 * Labstep
 *
 * @module containers/ProseMirror/ReferencingSearch
 * @desc Container for reading entities from referencing search
 */

import { Metadata } from 'labstep-web/models/metadata';
import { readEntitiesPage } from 'labstep-web/state/actions/entity';
import { setProseMirrorReferencingSearchAction } from 'labstep-web/state/actions/prosemirror-referencing-search';
import {
  selectReadPageEntities,
  selectReadPageStatus,
  selectReadPageTotal,
} from 'labstep-web/state/selectors/entity';
import { selectProseMirrorReferencingSearch } from 'labstep-web/state/selectors/prosemirror-referencing-search';
import { connect } from 'react-redux';
import { IWithProseMirrorReferencingSearchInjectedProps } from './types';

export const SEARCH_COUNT = 3;

export const mapStateToProps = (state: any) => {
  let entities = [];
  let status = false;
  let total = false;
  let creatableEntities = [];

  const proseMirrorReferencingSearch =
    selectProseMirrorReferencingSearch(state);

  if (proseMirrorReferencingSearch) {
    const {
      searchQuery,
      experimentWorkflowId,
      experimentNotId,
      entityName,
      groupId,
      creatableEntityName,
      has_last_version,
    } = proseMirrorReferencingSearch;

    let params: any = {
      experiment_workflow_id: experimentWorkflowId,
      experiment_not_id: experimentNotId,
      count: SEARCH_COUNT,
    };
    if (searchQuery) {
      if (entityName === Metadata.entityName) {
        params.label_like = searchQuery;
      } else {
        params.search_query = searchQuery;
      }
    }
    if (has_last_version) {
      params.has_last_version = has_last_version;
    }

    entities = selectReadPageEntities(state, entityName, params, 1);
    status = selectReadPageStatus(state, entityName, params, 1);
    total = selectReadPageTotal(state, entityName, params);

    if (creatableEntityName) {
      params = {
        group_id: groupId,
        count: SEARCH_COUNT,
      };
      if (searchQuery) {
        if (creatableEntityName === Metadata.entityName) {
          params.label_like = searchQuery;
        } else {
          params.search_query = searchQuery;
        }
      }
      if (has_last_version) {
        params.has_last_version = has_last_version;
      }

      creatableEntities = selectReadPageEntities(
        state,
        creatableEntityName,
        params,
        1,
      );
    }
  }

  return {
    entities,
    status,
    total,
    creatableEntities,
  };
};

export const mapDispatchToProps = (
  dispatch: (...args: any[]) => void,
) => ({
  setSearchParams({
    experimentWorkflowId,
    experimentNotId,
    entityName,
    searchQuery,
    groupId,
    creatableEntityName,
    has_last_version,
  }) {
    const params: any = {
      experimentWorkflowId,
      experimentNotId,
      entityName,
      searchQuery,
      groupId,
      creatableEntityName,
    };
    if (has_last_version) {
      params.has_last_version = has_last_version;
    }
    dispatch(setProseMirrorReferencingSearchAction(params));
  },
  searchSubExperiments({
    experimentWorkflowId,
    experimentNotId,
    entityName,
    searchQuery,
  }) {
    const params: any = {
      experiment_workflow_id: experimentWorkflowId,
      experiment_not_id: experimentNotId,
      count: SEARCH_COUNT,
    };
    if (searchQuery) {
      if (entityName === Metadata.entityName) {
        params.label_like = searchQuery;
      } else {
        params.search_query = searchQuery;
      }
    }

    dispatch(readEntitiesPage(entityName, params, 1));
  },
  searchCreatable({
    searchQuery,
    groupId,
    creatableEntityName,
    has_last_version,
  }) {
    const params: any = {
      group_id: groupId,
      count: SEARCH_COUNT,
    };
    if (searchQuery) {
      if (creatableEntityName === Metadata.entityName) {
        params.label_like = searchQuery;
      } else {
        params.search_query = searchQuery;
      }
    }
    if (has_last_version) {
      params.has_last_version = has_last_version;
    }
    dispatch(readEntitiesPage(creatableEntityName, params, 1));
  },
});

export const withProseMirrorReferencingSearch = <
  P extends IWithProseMirrorReferencingSearchInjectedProps,
  S,
>(
  Component: React.FC<P> | React.ComponentClass<P, S>,
): React.FC<
  Pick<
    P,
    Exclude<
      keyof P,
      keyof IWithProseMirrorReferencingSearchInjectedProps
    >
  >
> => connect(mapStateToProps, mapDispatchToProps)(Component as any);

/**
 * Labstep
 *
 * @module components/Metadata/Manager
 * @desc Metadata list and empty state
 */

import MetadataActionCreateModalDropdown from 'labstep-web/components/Metadata/Action/Create/Modal/Dropdown';
import MetadataTable from 'labstep-web/components/Metadata/Table';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { useToggle } from 'labstep-web/hooks/toggle';
import { Device } from 'labstep-web/models/device.model';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { User } from 'labstep-web/models/user.model';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import {
  MetadataManagerContainerProps,
  MetadataManagerProps,
} from './types';
import { INITIAL_METADATA_SHOWN, getMetadatas } from './utils';

export const MetadataManager: React.FC<MetadataManagerProps> = ({
  entity,
  secondaryAction,
  tertiaryAction,
  isTemplate,
  templateMetadataThread,
  extraRows,
}) => {
  const [showAll, toggleShowAll] = useToggle();

  const hasMetadataTemplate = !!templateMetadataThread;

  const [
    baseMetadatas,
    metadatasNotMatchingTemplateThread,
    totalFieldsCount,
  ] = getMetadatas(entity, templateMetadataThread, showAll);

  useEffect(() => {
    if (!showAll && totalFieldsCount <= INITIAL_METADATA_SHOWN) {
      toggleShowAll();
    }
  }, [showAll, totalFieldsCount, toggleShowAll]);

  return (
    <>
      <MetadataTable
        actions={['edit', 'delete']}
        replacementMetadatas={
          hasMetadataTemplate ? entity.metadata_thread.metadatas : []
        }
        baseMetadatas={baseMetadatas}
        entity={entity}
        isTemplate={isTemplate}
        extraRows={extraRows}
      />
      {hasMetadataTemplate &&
        metadatasNotMatchingTemplateThread.length > 0 && (
          <>
            <MetadataTable
              replacementMetadatas={
                metadatasNotMatchingTemplateThread
              }
              baseMetadatas={metadatasNotMatchingTemplateThread}
              entity={entity}
              actions={['edit', 'delete']}
            />
          </>
        )}

      <div className={styles.actionCreateContainer}>
        {totalFieldsCount > INITIAL_METADATA_SHOWN && (
          <div>
            <ActionComponent
              type="text"
              icon={!showAll ? 'chevron down' : 'chevron up'}
              text={
                !showAll
                  ? `Show ${
                      totalFieldsCount - INITIAL_METADATA_SHOWN
                    } more`
                  : 'Show less'
              }
              onClick={toggleShowAll}
            />
          </div>
        )}
        {tertiaryAction}
        <MetadataActionCreateModalDropdown
          isTemplate={isTemplate}
          entity={entity}
          options={{
            onSuccess: (): void => {
              if (!showAll) {
                toggleShowAll();
              }
            },
          }}
        />
        {secondaryAction}
      </div>
    </>
  );
};

export const MetadataManagerContainer: React.FC<
  MetadataManagerContainerProps
> = ({ entity, ...rest }) => {
  const params: {
    id: number;
    get_single: boolean | 0 | 1;
    serializerGroups: string;
    is_deleted?: boolean | 'both';
  } = {
    id: entity.id,
    get_single: 1,
    serializerGroups: `default,${entity.entityName}_show,${entity.entityName}_metadatas`,
  };
  if (!(entity instanceof User)) {
    // is_deleted is not used for user
    params.is_deleted = 'both';
  }

  return (
    <ParamsStateContextProvider value={{}}>
      <ReadOnMountHOC
        type="entities"
        loading={{ loader: 'placeholder' }}
        entityName={entity.entityName}
        params={params}
        children={({ entity: entityWithMetadatas }) => {
          let templateMetadataThread: MetadataThread | null = null;
          if (
            entityWithMetadatas instanceof Resource ||
            entityWithMetadatas instanceof Device ||
            entityWithMetadatas instanceof OrderRequest
          ) {
            templateMetadataThread =
              entityWithMetadatas.nonDeletedTemplate &&
              entityWithMetadatas.nonDeletedTemplate.metadata_thread;
          } else if (entityWithMetadatas instanceof ResourceItem) {
            if (
              !entityWithMetadatas.is_template &&
              entityWithMetadatas.nonDeletedTemplate
            ) {
              templateMetadataThread =
                entityWithMetadatas.nonDeletedTemplate
                  .metadata_thread;
            }
          }

          return (
            <MetadataManager
              entity={entityWithMetadatas}
              templateMetadataThread={templateMetadataThread}
              {...rest}
            />
          );
        }}
      />
    </ParamsStateContextProvider>
  );
};

export default MetadataManagerContainer;

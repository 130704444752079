/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs
 * @desc Grid NewIndex Column Definitions
 */

import {
  fieldCAS,
  fieldMolecularFormula,
  fieldMolecularWeight,
  fieldName,
} from 'labstep-web/components/PubChemMetadata/Form/fields';
import { colDefBulkSelect } from 'labstep-web/grid/NewIndex/coldefs/bulk-select';
import { colDefDeviceActionMenu } from 'labstep-web/grid/NewIndex/coldefs/device/action-menu';
import { colDefEntityCreatedAt } from 'labstep-web/grid/NewIndex/coldefs/entity/created-at';
import { colDefEntityEntityUsers } from 'labstep-web/grid/NewIndex/coldefs/entity/entity-users';
import { getColDefEntityName } from 'labstep-web/grid/NewIndex/coldefs/entity/name';
import { getColDefEntityTemplate } from 'labstep-web/grid/NewIndex/coldefs/entity/template';
import { getMetadataColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata';
import { getMetadataMoleculePubchemColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/molecule/pubchem';
import { getMetadataMoleculeSafetyColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/molecule/safety';
import { getMetadataMoleculeStructureColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata/molecule/structure';
import { colDefOrderRequestActionMenu } from 'labstep-web/grid/NewIndex/coldefs/order-request/action-menu';
import { colDefOrderRequestAmount } from 'labstep-web/grid/NewIndex/coldefs/order-request/amount';
import { colDefOrderRequestIsUrgent } from 'labstep-web/grid/NewIndex/coldefs/order-request/is-urgent';
import { getColDefOrderRequestLogAuthor } from 'labstep-web/grid/NewIndex/coldefs/order-request/log-author';
import { getColDefOrderRequestLogCreatedAt } from 'labstep-web/grid/NewIndex/coldefs/order-request/log-created-at';
import { colDefOrderRequestQuantity } from 'labstep-web/grid/NewIndex/coldefs/order-request/quantity';
import { colDefOrderRequestStatus } from 'labstep-web/grid/NewIndex/coldefs/order-request/status';
import { colDefResourceItemActionMenu } from 'labstep-web/grid/NewIndex/coldefs/resource-item/action-menu';
import { colDefResourceItemAmount } from 'labstep-web/grid/NewIndex/coldefs/resource-item/amount';
import { colDefResourceItemResource } from 'labstep-web/grid/NewIndex/coldefs/resource-item/resource';
import { colDefResourceItemResourceLocation } from 'labstep-web/grid/NewIndex/coldefs/resource-item/resource-location';
import { colDefResourceItemStatus } from 'labstep-web/grid/NewIndex/coldefs/resource-item/status';
import { colDefResourceActionMenu } from 'labstep-web/grid/NewIndex/coldefs/resource/action-menu';
import { colDefResourceAvailableItems } from 'labstep-web/grid/NewIndex/coldefs/resource/available-items';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { IndexTemplatableEntity } from 'labstep-web/models';
import { Device } from 'labstep-web/models/device.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import {
  OrderRequest,
  OrderRequestStatus,
} from 'labstep-web/models/order-request.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { metadataThread } from 'labstep-web/services/schema/schema';

export const NEW_INDEX_COL_DEFS: Record<
  string,
  Record<string, NewIndexColDef>
> = {
  [Device.entityName]: {
    action_menu: colDefDeviceActionMenu,
    bulk_select: colDefBulkSelect,
    name: getColDefEntityName(Device.entityName),
    entity_users: colDefEntityEntityUsers,
    created_at: colDefEntityCreatedAt,
  },
  [OrderRequest.entityName]: {
    action_menu: colDefOrderRequestActionMenu,
    bulk_select: colDefBulkSelect,
    name: getColDefEntityName(OrderRequest.entityName, false),
    is_urgent: colDefOrderRequestIsUrgent,
    quantity: colDefOrderRequestQuantity,
    status: colDefOrderRequestStatus,
    price: colDefOrderRequestAmount,
    approved_log_created_at: getColDefOrderRequestLogCreatedAt(
      OrderRequestStatus.approved,
    ),
    ordered_log_created_at: getColDefOrderRequestLogCreatedAt(
      OrderRequestStatus.ordered,
    ),
    received_log_created_at: getColDefOrderRequestLogCreatedAt(
      OrderRequestStatus.received,
    ),
    approved_log_author: getColDefOrderRequestLogAuthor(
      OrderRequestStatus.approved,
    ),
    ordered_log_author: getColDefOrderRequestLogAuthor(
      OrderRequestStatus.ordered,
    ),
    received_log_author: getColDefOrderRequestLogAuthor(
      OrderRequestStatus.received,
    ),
    entity_users: colDefEntityEntityUsers,
    created_at: colDefEntityCreatedAt,
  },
  [Resource.entityName]: {
    action_menu: colDefResourceActionMenu,
    bulk_select: colDefBulkSelect,
    name: getColDefEntityName(Resource.entityName),
    available_items: colDefResourceAvailableItems,
    entity_users: colDefEntityEntityUsers,
    created_at: colDefEntityCreatedAt,
  },
  [ResourceItem.entityName]: {
    action_menu: colDefResourceItemActionMenu,
    bulk_select: colDefBulkSelect,
    name: getColDefEntityName(ResourceItem.entityName),
    status: colDefResourceItemStatus,
    amount: colDefResourceItemAmount,
    resource_location: colDefResourceItemResourceLocation,
    resource: colDefResourceItemResource,
    entity_users: colDefEntityEntityUsers,
    created_at: colDefEntityCreatedAt,
  },
};

export const getMetadataMoleculeColDefs = (
  metadata: Metadata,
): NewIndexColDef[] => [
  getMetadataMoleculeStructureColDef(
    metadata.parent_entity_class,
    metadata.type,
    metadataThread.label,
  ),
  getMetadataMoleculePubchemColDef(
    metadata.parent_entity_class,
    metadata.type,
    metadataThread.label,
    'Name',
    fieldName,
  ),
  getMetadataMoleculePubchemColDef(
    metadata.parent_entity_class,
    metadata.type,
    metadataThread.label,
    'CAS Number',
    fieldCAS,
  ),
  getMetadataMoleculePubchemColDef(
    metadata.parent_entity_class,
    metadata.type,
    metadataThread.label,
    'Molecular Formula',
    fieldMolecularFormula,
  ),
  getMetadataMoleculePubchemColDef(
    metadata.parent_entity_class,
    metadata.type,
    metadataThread.label,
    'Molecular Weight',
    fieldMolecularWeight,
  ),
  getMetadataMoleculeSafetyColDef(
    metadata.parent_entity_class,
    metadata.type,
    metadataThread.label,
  ),
];

export const getMetadataMoleculeColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
  field: string,
): NewIndexColDef | null => {
  switch (field) {
    case 'structure':
      return getMetadataMoleculeStructureColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
    case fieldName.name:
      return getMetadataMoleculePubchemColDef(
        parentEntityClass,
        type,
        metadataLabel,
        'Name',
        fieldName,
      );
    case fieldCAS.name:
      return getMetadataMoleculePubchemColDef(
        parentEntityClass,
        type,
        metadataLabel,
        'CAS Number',
        fieldCAS,
      );
    case fieldMolecularFormula.name:
      return getMetadataMoleculePubchemColDef(
        parentEntityClass,
        type,
        metadataLabel,
        'Molecular Formula',
        fieldMolecularFormula,
      );
    case fieldMolecularWeight.name:
      return getMetadataMoleculePubchemColDef(
        parentEntityClass,
        type,
        metadataLabel,
        'Molecular Weight',
        fieldMolecularWeight,
      );
    case 'safety':
      return getMetadataMoleculeSafetyColDef(
        parentEntityClass,
        type,
        metadataLabel,
      );
    default:
      return null;
  }
};

export const getDefaultColDefs = (
  context: string,
  entityName: string,
  defaultTemplate?: IndexTemplatableEntity,
  addCategoryColDef: boolean = false,
) => {
  const colDefs: NewIndexColDef[] = [];

  if (addCategoryColDef) {
    const categoryColDef = getColDefEntityTemplate(entityName);
    if (categoryColDef) {
      colDefs.push(categoryColDef);
    }
  }

  for (const colDef of Object.values(
    NEW_INDEX_COL_DEFS[entityName],
  ).filter(
    (colDef) =>
      !colDef.pinned &&
      colDef.colId !== 'entity_users' &&
      colDef.colId !== 'created_at',
  )) {
    colDefs.push(colDef);
  }

  if (defaultTemplate) {
    for (const metadata of defaultTemplate.metadata_thread
      .metadatas) {
      if (!metadata.label) {
        continue;
      }
      if (metadata.type === MetadataType.molecule) {
        colDefs.push(
          getMetadataMoleculeStructureColDef(
            metadata.parent_entity_class,
            metadata.type,
            metadata.label,
          ),
        );
        colDefs.push(
          getMetadataMoleculePubchemColDef(
            metadata.parent_entity_class,
            metadata.type,
            metadata.label,
            'Name',
            fieldName,
          ),
        );
        colDefs.push(
          getMetadataMoleculePubchemColDef(
            metadata.parent_entity_class,
            metadata.type,
            metadata.label,
            'CAS Number',
            fieldCAS,
          ),
        );
        colDefs.push(
          getMetadataMoleculePubchemColDef(
            metadata.parent_entity_class,
            metadata.type,
            metadata.label,
            'Molecular Formula',
            fieldMolecularFormula,
          ),
        );
        colDefs.push(
          getMetadataMoleculePubchemColDef(
            metadata.parent_entity_class,
            metadata.type,
            metadata.label,
            'Molecular Weight',
            fieldMolecularWeight,
          ),
        );
        colDefs.push(
          getMetadataMoleculeSafetyColDef(
            metadata.parent_entity_class,
            metadata.type,
            metadata.label,
          ),
        );
      } else {
        colDefs.push(
          getMetadataColDef(
            metadata.parent_entity_class,
            metadata.type,
            metadata.label,
          ),
        );
      }
    }
  }

  for (const colDef of Object.values(
    NEW_INDEX_COL_DEFS[entityName],
  ).filter(
    (colDef) =>
      colDef.colId === 'entity_users' ||
      colDef.colId === 'created_at',
  )) {
    colDefs.push(colDef);
  }

  if (context === 'resource_show') {
    return colDefs.filter(
      (colDef) =>
        colDef.colId !== 'status' &&
        colDef.colId !== 'resource' &&
        colDef.colId !== 'entity_users' &&
        colDef.colId !== 'created_at',
    );
  }

  return colDefs;
};

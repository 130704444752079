/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/entity/created-at
 * @desc Column Definition for entity created_at
 */

import { ValueGetterParams } from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { Entity } from 'labstep-web/models/entity.model';
import { humanReadableDate } from 'labstep-web/services/date.service';

export const colDefEntityCreatedAt: NewIndexColDef = {
  colId: 'created_at',
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.date,
    field: 'created_at',
  },
  headerName: 'Creation Date',
  headerComponentParams: {
    icon: ICONS.metadata.type.datetime,
  },
  editable: false,
  valueGetter: (params: ValueGetterParams) => params.data.created_at,
  valueGetterExport: (params: ValueGetterParams) =>
    params.node?.data?.created_at
      ? String(params.node?.data?.created_at)
      : '',
  cellRenderer: (params: CellRendererParams<Entity>) => {
    if (!params.data) {
      return '';
    }
    return humanReadableDate(params.data.created_at);
  },
};

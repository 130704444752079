/**
 * Labstep
 *
 * @module screens/Master/Index
 * @desc Generic master screen
 */

import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { Switch as ReactRouterSwitch, Route } from 'react-router-dom';
import { ISwitchProps } from './types';

export const Switch: React.FC<ISwitchProps> = ({ links }) => (
  <ReactRouterSwitch>
    {links.map(({ render, route }, index) => (
      <Route
        key={route.to}
        exact
        path={navigation.get(route.to)}
        render={render}
      />
    ))}
  </ReactRouterSwitch>
);

export default Switch;

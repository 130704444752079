/**
 * Labstep
 *
 * @module models/user-share-link
 * @desc Typescript export class for UserShareLink
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { PermissionType } from 'labstep-web/models/permission.model';
import { ShareLink } from 'labstep-web/models/share-link.model';
import { User } from 'labstep-web/models/user.model';

export class UserShareLink extends Entity {
  static readonly entityName = 'user_share_link';

  get entityName(): string {
    return UserShareLink.entityName;
  }

  constructor(data: Partial<UserShareLink> = {}) {
    super();
    Object.assign(this, data);
  }

  id!: number;

  @Type(() => User)
  user!: User;

  share_link!: ShareLink;

  type!: PermissionType;
}

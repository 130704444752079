/**
 * Labstep
 *
 * @module prosemirror/nodes/commands
 * @desc ProseMirror commands for nodes
 */

import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';

export const replaceWithNode = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  from: number,
  to: number,
  key: string,
  attrs: Record<string, unknown> = {},
  content?: ProsemirrorNode,
) => {
  const { tr, schema } = state;
  const $from = tr.doc.resolve(from);
  const $to = tr.doc.resolve(to);
  const node = schema.nodes[key].create(attrs, content);

  tr.setStoredMarks([]);

  const isEmptyListItem =
    $from.parent.type === schema.nodes.paragraph &&
    $to.parent.type === schema.nodes.list_item;
  if (isEmptyListItem && !content) {
    const fragment = schema.nodes.paragraph.createAndFill();
    if (fragment) {
      tr.insert(to, fragment);
    }
  }

  tr.replaceRangeWith(from, to, node);

  if (content) {
    let pos = from + 1;
    // step placed under paragraph
    if ($from.parentOffset > 0) {
      pos += 2;
    }
    tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
  }
  dispatch?.(tr);
};

export const insertNode = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  key: string,
  pos?: number,
  attrs?: Record<string, unknown>,
) => {
  const { tr } = state;
  const node = state.schema.nodes[key].create(attrs);
  tr.setStoredMarks([]);
  tr.insert(pos || state.selection.$from.pos, node);
  dispatch?.(tr);
};

export const replaceWithMultipleNodes = (
  state: IStateDispatchProps['state'],
  dispatch: IStateDispatchProps['dispatch'],
  from: number,
  to: number,
  nodeKey: string,
  fields: number[],
  content?: ProsemirrorNode,
) => {
  const { tr, schema } = state;

  // Create the node and replace the trigger text
  const lastField = fields[fields.length - 1];
  const attrs = { [getIdAttribute(nodeKey)]: lastField };
  const lastNode = schema.nodes[nodeKey].create(attrs, content);

  tr.setStoredMarks([]);
  tr.replaceRangeWith(from, to, lastNode);

  // Insert the rest of the field nodes
  let currentPosition = tr.selection.$to.pos;
  fields.slice(0, fields.length - 1).forEach((field) => {
    const node = schema.nodes[nodeKey].create(
      { [getIdAttribute(nodeKey)]: field },
      content,
    );
    tr.insert(currentPosition, node);
    currentPosition = tr.selection.$to.pos;
  });

  if (content) {
    const pos = from + 1;
    tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
  }

  dispatch?.(tr);
};

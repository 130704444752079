/**
 * Labstep
 *
 * @module components/Metadata/Form/Create
 * @desc Form to create metadata
 */

import { getMetadataFormFields } from 'labstep-web/components/Metadata/Form/fields';
import { preSubmit } from 'labstep-web/components/Metadata/Form/preSubmit';
import {
  addMoleculeToBody,
  getOptionByType,
} from 'labstep-web/components/Metadata/Form/utils';
import FormCreateContainer, {
  FormCreate,
} from 'labstep-web/core/Form/Create';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import React, { useState } from 'react';
import { MetadataFormCreateProps } from './types';

export const MetadataFormCreate: React.FC<
  MetadataFormCreateProps
> = ({
  entity,
  isTemplate,
  body = {},
  metadataType,
  onSubmit,
  defaultValues: defaultValuesProp,
  noValue,
  hideLinkedTypes,
  ...rest
}) => {
  const { activeGroup } = useActiveGroup();

  const [type, setType] = useState(
    getOptionByType(metadataType || MetadataType.default),
  );

  const [defaultValues, setDefaultValues] = useState({
    ...defaultValuesProp,
    type,
    label: metadataType === MetadataType.molecule ? 'Structure' : '',
    current_entity: entity,
  });

  const props = {
    body: addMoleculeToBody(
      { ...body, group_id: activeGroup!.guid },
      type.value,
    ),
    autoFocus: true,
    entityName: Metadata.entityName,
    preSubmit,
    onFormChange: (values: any): void => {
      if (
        values.type !== type.value ||
        (metadataType === MetadataType.entity_relation &&
          JSON.stringify(defaultValues) !== JSON.stringify(values))
      ) {
        setType(getOptionByType(values.type));
        setDefaultValues(values);
      }
    },
    defaultValues,
    fields: getMetadataFormFields(
      type.value,
      isTemplate,
      undefined,
      noValue,
      hideLinkedTypes,
      defaultValues,
    ),
    ...rest,
  };

  return !entity ? (
    <FormCreate
      {...props}
      onSubmit={(values: any, reset): void => {
        onSubmit?.({ ...values, ...props.body }, reset);
      }}
    />
  ) : (
    <FormCreateContainer
      {...props}
      parentId={entity.metadata_thread.idAttr}
      parentName={entity.metadata_thread.entityName}
    />
  );
};

export default MetadataFormCreate;

/**
 * Labstep
 *
 * @module components/FilterPost/Report/ExperimentAuthor/Active/Post
 * @desc Active node component for experiment author filter
 */

import FilterPostReportExperimentAuthor from 'labstep-web/components/FilterPost/Report/ExperimentAuthor';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { User } from 'labstep-web/models/user.model';
import { PostFilterExperimentAuthor } from 'labstep-web/services/postFilter/filters/author';
import React from 'react';
import { FilterPostReportExperimentAuthorActivePostProps } from './types';

const FilterPostReportExperimentAuthorActivePost: React.FC<
  FilterPostReportExperimentAuthorActivePostProps
> = ({ node, onRemoveClick }) => {
  const { addPostFilter } = useParamsContext();
  const filterActive = PostFilterExperimentAuthor.isNodeActive(node);

  if (!filterActive) {
    return null;
  }

  const attributes =
    PostFilterExperimentAuthor.getAttributeValues(node);

  return (
    <FilterPostReportExperimentAuthor
      addPostFilter={addPostFilter}
      viewComponent={({ toggleModal }) => (
        <EntityReadEntityContainer
          entityName={User.entityName}
          guid={attributes.guid.value}
        >
          {({ entity }) => (
            <RemoveLabel
              name={`Created by : ${entity?.name}`}
              icon="user circle"
              onRemoveClick={onRemoveClick}
              onNameClick={toggleModal}
            />
          )}
        </EntityReadEntityContainer>
      )}
    />
  );
};

export default FilterPostReportExperimentAuthorActivePost;

/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/metadata/sequence
 * @desc Column Definition for metadata sequence
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import ActionComponent from 'labstep-web/core/Action/Component';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import {
  createOrUpdateValue,
  setValue,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import SequenceEditor from 'labstep-web/core/SequenceEditor';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridColumnDefinitionService } from 'labstep-web/grid/services/grid-column-definition.service';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { generateNewDateString } from 'labstep-web/services/date.service';
import rules from 'labstep-web/services/validation/rules';

export const getMetadataSequenceColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
): NewIndexColDef => {
  return {
    colId: `metadata:${parentEntityClass}:${type}:${metadataLabel}`,
    postFilterSettings: {
      path: 'metadatas',
    },
    sortable: true,
    headerName: metadataLabel,
    headerComponent: GridNewIndexColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.type.sequence,
    },
    editable: (params: EditableCallbackParams) =>
      GridNewIndexService.getEditable(params),
    valueGetter: (params: ValueGetterParams) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return null;
      }
      return metadata.value;
    },
    valueSetter: (params: ValueSetterParams) => {
      if (!params.node) {
        return false;
      }
      return false;
    },
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return (
          <TextPlaceholder
            onClick={() => {
              createOrUpdateValue({
                entityName: Metadata.entityName,
                body: {
                  type,
                  label: metadataLabel,
                  metadata_thread_id:
                    params.node.data.metadata_thread.guid,
                  sequence: { name: 'Untitled sequence' },
                },
                createProps: {
                  parentName:
                    params.node.data.metadata_thread.entityName,
                  parentId: params.node.data.metadata_thread.idAttr,
                },
                options:
                  GridColumnDefinitionService.getOptions(params),
              });
              return true;
            }}
          >
            Create sequence
          </TextPlaceholder>
        );
      }
      return metadata.sequence?.name;
    },
    cellEditorPopup: true,
    cellEditor: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return '';
      }
      return (
        <SequenceEditor
          sequenceData={metadata.sequence.sequenceData}
          onClose={(): void => {
            stopEditingAndFocus(params);
          }}
          readOnly={false}
          onSave={(sequenceData) => {
            const { name, ...data } = sequenceData;
            const newValue = { name, data: JSON.stringify(data) };
            setValue(
              'sequence',
              newValue,
              {
                entityName: metadata.entityName,
                id: metadata.idAttr,
              },
              rules.metadata.sequence,
              GridColumnDefinitionService.getOptions(params),
            );
          }}
        />
      );
    },
    suppressKeyboardEvent: suppressKeyboardEventClearCells,
    valueClearer: (params: any) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (metadata) {
        setValue(
          'deleted_at',
          generateNewDateString(),
          {
            entityName: metadata.entityName,
            id: metadata.idAttr,
          },
          rules.metadata.date,
          GridColumnDefinitionService.getOptions(params),
        );
      }
      return null;
    },
  };
};

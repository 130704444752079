/**
 * Labstep
 *
 * @module screens/Resource/Index/Breadcrumb
 * @desc Resource Index Breadcrumb
 */

import EntityTemplateBreadcrumb from 'labstep-web/components/Entity/Template/Breadcrumb/Index';
import EntityImportActionPending from 'labstep-web/components/EntityImport/Action/Pending';
import ResourceTemplatePreview from 'labstep-web/components/ResourceTemplate/Preview';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';

export const ScreensResourceIndexBreadcrumb: React.FC = () => (
  <>
    <EntityTemplateBreadcrumb
      entityName={Resource.entityName}
      entityPreview={({ entity, ...rest }): React.ReactElement => (
        <ResourceTemplatePreview
          resource={entity as Resource}
          {...rest}
        />
      )}
      indexRoute="group_resources"
    />
    <EntityImportActionPending
      targetEntityName={Resource.entityName}
    />
  </>
);

export default ScreensResourceIndexBreadcrumb;

/**
 * Labstep
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import {
  OrderRequest,
  OrderRequestStatusValues,
} from 'labstep-web/models/order-request.model';
import {
  ResourceItem,
  ResourceItemStatusValues,
} from 'labstep-web/models/resource-item.model';
import { mixed, number } from 'yup';

export const fieldNumber: FieldWithoutActionProps = {
  fieldLabel: 'Value',
  name: 'value',
  placeholder: 'Enter value',
  fieldType: FieldType.Text,
  validation: number().required(),
};

export const getOptionsFields = (
  entityName: string,
): FieldWithoutActionProps => {
  const options = [];

  if (entityName === ResourceItem.entityName) {
    options.push(
      ...Object.entries(ResourceItemStatusValues).map(
        ([key, value]) => ({
          label: value,
          value: key,
        }),
      ),
    );
  }

  if (entityName === OrderRequest.entityName) {
    options.push(
      ...Object.entries(OrderRequestStatusValues).map(
        ([key, value]) => ({
          label: value,
          value: key,
        }),
      ),
    );
  }

  const fieldLabel =
    entityName === ResourceItem.entityName
      ? 'Availability'
      : 'Status';

  return {
    fieldLabel: fieldLabel,
    name: 'option',
    fieldType: FieldType.ReactSelect,
    elementProps: {
      options,
    },
    validation: mixed().required(),
  };
};

export const getOptionsFilterFields = (
  entityName: string,
): FieldWithoutActionProps[] => {
  return [getOptionsFields(entityName)];
};

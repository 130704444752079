/**
 * Labstep
 *
 * @module components/Entity/Action/TemplateCreate
 * @desc Create a template into the list of templates
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { EntityActionTemplateCreateProps } from './types';

export const EntityActionTemplateCreate: React.FC<
  EntityActionTemplateCreateProps
> = ({
  groupId,
  entityName,
  setActiveEditModal,
  history,
  indexRoute,
}) => (
  <EntityActionCreate
    entityName={entityName}
    actionComponentProps={{
      type: 'text',
      icon: ICONS.entity.actions.create,
    }}
    body={{ is_template: true }}
    options={{
      onSuccess: ({ response }) => {
        if (!response) {
          return;
        }
        const templateId = response.result as number;
        const templateGuid =
          response.entities[entityName][templateId].guid;
        history.push(
          navigation.get(
            indexRoute,
            {
              id: groupId,
            },
            false,
            {
              template_id: templateGuid,
            },
          ),
        );
        setActiveEditModal({
          entityName,
          id: templateId,
        });
      },
    }}
  />
);

export default withRouter(
  withActiveEditModal(EntityActionTemplateCreate),
);

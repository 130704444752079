/**
 * Labstep
 */

import {
  FieldType,
  FieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { mixed } from 'yup';

export const OPTIONS = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
];

export const fieldBoolean: FieldWithoutActionProps = {
  fieldLabel: 'Is',
  name: 'bool',
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: OPTIONS.map((option) => ({
      value: option.value,
      label: option.label,
    })),
  },
  validation: mixed().required(),
};

/**
 * Labstep
 *
 * @module component/Metadata/Action/Create/DataModal
 * @desc Data modal component
 */

import MetadataActionCreate from 'labstep-web/components/Metadata/Action/Create';
import { getChildRoute } from 'labstep-web/containers/Router/ProtocolChild';
import Loadable from 'labstep-web/core/Loadable';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { Metadata } from 'labstep-web/models/metadata';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import { MetadataActionCreateDataModalProps } from './types';

export const MetadataFormCreateBulk = React.lazy(
  () => import('labstep-web/components/Metadata/Form/Create/Bulk'),
);

export const MetadataActionCreateDataModal: React.FC<
  MetadataActionCreateDataModalProps
> = ({ entity, history, isInput }) => {
  const childRoute = isInput
    ? getChildRoute('metadata_show')
    : getChildRoute('metadata_show_output');

  return (
    <ModalDefault
      size="small"
      header={`Add ${Metadata.getHumanReadableEntityName(
        true,
        true,
        entity,
      )}`}
      viewComponent={({ toggleModal }) => (
        <MetadataActionCreate
          entity={entity}
          body={{
            type: 'file',
            is_input: isInput,
            is_output: !isInput,
          }}
          options={{
            onSuccess: ({ response }) => {
              history.push(
                navigation.get(childRoute!.name, {
                  ...childRoute!.params,
                  childId: response!.result,
                }),
                { expanded: true },
              );
            },
          }}
          handleOnClickBulkOption={toggleModal}
        />
      )}
      content={({ toggleModal }) => (
        <Loadable>
          <MetadataFormCreateBulk
            entity={entity}
            isTemplate={entity.is_template}
            isProtocolOrExperiment
            options={{
              toast: true,
              onSuccess: () => {
                toggleModal();
              },
            }}
          />
        </Loadable>
      )}
    />
  );
};

export default withRouter(MetadataActionCreateDataModal);

/**
 * Labstep
 *
 * @module grid/NewIndex/components/ColumnHeader
 * @desc Protocol Condition DataGrid Header
 */

import GridNewIndexColumnHeaderContextMenu from 'labstep-web/grid/NewIndex/components/ColumnHeader/ContextMenu';
import GridNewIndexColumnHeaderTrigger from 'labstep-web/grid/NewIndex/components/ColumnHeader/Trigger';
import React from 'react';
import { GridNewIndexColumnHeaderProps } from './types';

export const GridNewIndexColumnHeader: React.FC<
  GridNewIndexColumnHeaderProps
> = ({ sort, filter, ...rest }) => {
  const { column, displayName, icon, cornerIcon, action, ...params } =
    rest;
  const trigger = <GridNewIndexColumnHeaderTrigger {...rest} />;
  return (
    <GridNewIndexColumnHeaderContextMenu
      trigger={trigger}
      sort={sort}
      filter={filter}
      {...params}
      {...rest}
    />
  );
};

export default GridNewIndexColumnHeader;

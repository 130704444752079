/**
 * Labstep
 *
 * @module core/InformationCard
 * @desc InformationCard
 */

import classnames from 'classnames';
import Container from 'labstep-web/components/Layout/Container';
import { HorizontalDivider } from 'labstep-web/core/Divider';
import React from 'react';
import styles from './styles.module.scss';
import { IInformationProps, InformationCardProps } from './types';

export const Information: React.FC<IInformationProps> = ({
  state,
  actionMenu,
  primaryInfo,
  secondaryInfo,
  breadcrumb,
  bottom,
  additionalActions,
  noBottomMargin,
}) => (
  <>
    {state ? (
      <div className={styles.top}>
        <div className={styles.state}>
          <div data-testid="state">{state}</div>
          <div className={styles.topRight}>
            {additionalActions}
            {actionMenu}
          </div>
        </div>
        <div>
          {breadcrumb}
          {primaryInfo}
        </div>
      </div>
    ) : (
      <div className={styles.top}>
        <div className={styles.topTop}>
          <div>
            {breadcrumb}
            {primaryInfo}
          </div>
          <div className={styles.topRight}>
            {additionalActions}
            {actionMenu}
          </div>
        </div>
      </div>
    )}
    {secondaryInfo && (
      <>
        <Container>{secondaryInfo}</Container>
        {bottom && (
          <Container>
            <HorizontalDivider />
          </Container>
        )}
      </>
    )}
    <div className={classnames(styles.bottom, { noBottomMargin })}>
      {bottom}
    </div>
  </>
);

const InformationCard: React.FC<InformationCardProps> = ({
  className,
  ...rest
}) => (
  <div
    className={classnames(styles.card, className)}
    data-cy="core/Card/Information"
    id="information-card"
    data-testid="information-card"
  >
    <Information {...rest} />
  </div>
);

export default InformationCard;

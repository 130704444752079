/**
 * Labstep
 *
 * @module grid/NewIndex/components/ToolPanel/ListItem
 * @desc List item
 */

import classNames from 'classnames';
import Icon from 'labstep-web/core/Icon';
import React from 'react';
import styles from './styles.module.scss';
import { IEntityDataGridToolPanelListItemProps } from './types';

export const EntityDataGridToolPanelListItem: React.FC<
  IEntityDataGridToolPanelListItemProps
> = ({ icon, actionIcon, text, onClick, disabled }) => (
  <div
    onClick={onClick}
    className={classNames({
      [styles.container]: true,
      [styles.disabled]: disabled,
    })}
  >
    <Icon name={icon} />
    <span className={styles.text}>{text}</span>
    {actionIcon && !disabled && <Icon name={actionIcon} />}
    {disabled && <span>Showing</span>}
  </div>
);

export default EntityDataGridToolPanelListItem;

/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/ResourceItem/Input
 * @desc Search and select a ResourceItem for a ProtocolValue
 */

import { IEntitySearchContainerProps } from 'labstep-web/components/Entity/Search/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import ResourceItemActionCreate from 'labstep-web/components/ResourceItem/Action/Create';
import ResourceItemPreview from 'labstep-web/components/ResourceItem/Preview';
import { IFormShowEditSearchSelectModalProps } from 'labstep-web/core/Form/ShowEdit/SearchSelect/Modal/types';
import { IFormShowEditSearchSelectContainerProps } from 'labstep-web/core/Form/ShowEdit/SearchSelect/types';
import { ISearchSelectModalProps } from 'labstep-web/core/Select/Search/Modal/types';
import columnDefs from 'labstep-web/grid/SearchModal/coldefs/resource-item';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { PostFilterProtocolValueOriginNull } from 'labstep-web/services/postFilter/filters/protocolValueOrigin';
import { PostFilterResource } from 'labstep-web/services/postFilter/filters/resource';
import { PostFilterResourceTemplate } from 'labstep-web/services/postFilter/filters/resource/template';
import { PostFilterStatus } from 'labstep-web/services/postFilter/filters/status';
import { PostFilterOperator } from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { IProtocolValueShowEditResourceItemInputProps } from './types';

export const getSearchProps = (
  protocolValue: ProtocolValue,
  isOutput = false,
): Partial<IEntitySearchContainerProps> => {
  const filter = [
    {
      type: PostFilterOperator.and,
      predicates: [PostFilterStatus.getNode(['available'])],
    },
    {
      type: PostFilterOperator.and,
      predicates: [],
    },
  ];
  if (protocolValue.resource) {
    filter[1].predicates.push(
      PostFilterResource.getNode([protocolValue.resource.guid]),
    );
  }
  if (protocolValue.resource_template) {
    filter[1].predicates.push(
      PostFilterResourceTemplate.getNode([
        protocolValue.resource_template.guid,
      ]),
    );
  }
  if (isOutput) {
    filter[1].predicates.push(
      PostFilterProtocolValueOriginNull.getNode(),
    );
  }
  return {
    filters: [
      Filter.metadata,
      Filter.resource_metadata,
      Filter.resource_item_status,
      Filter.resource_location,
    ],
    initialSearchParams: {
      filter,
    },
    usePostFilter: true,
  };
};

export const getContainerProps = (
  protocolValue: ProtocolValue,
): Omit<
  IFormShowEditSearchSelectContainerProps<ProtocolValue>,
  'children'
> => ({
  childEntityName: ResourceItem.entityName,
  parentEntity: protocolValue,
  optimistic: false, // update resource and resource template
});

export const getSearchSelectProps = (
  protocolValue: ProtocolValue,
  children?: React.ReactNode,
  type?: IProtocolValueShowEditResourceItemInputProps['type'],
): Omit<ISearchSelectModalProps, 'onChange' | 'entityName'> & {
  children: React.ReactNode;
} => ({
  children:
    children ||
    (protocolValue.resource_item ? (
      <ResourceItemPreview
        resourceItem={protocolValue.resource_item}
        showIcon
        showResource={!protocolValue.resource}
      />
    ) : null),
  createBody: protocolValue.resource
    ? {
        resource_id: protocolValue.resource.id,
      }
    : undefined,
  createAction: !protocolValue.resource && (
    <ResourceItemActionCreate
      withRedirect={false}
      resourceTemplate={protocolValue.resource_template || undefined}
    />
  ),
  creatable: true,
  searchProps: getSearchProps(protocolValue),
  actionComponentProps:
    type === 'button'
      ? {
          type: 'button',
          icon: 'search',
          showIcon: true,
          elementProps: {
            size: 'large',
            primary: true,
            basic: true,
            fluid: true,
          },
          text: 'Search for Item',
        }
      : undefined,
  columnDefs: columnDefs(
    protocolValue.resource,
    protocolValue.resource_template,
  ),
});

export const getProps = (
  protocolValue: ProtocolValue,
  children?: React.ReactNode,
  type?: IProtocolValueShowEditResourceItemInputProps['type'],
): IFormShowEditSearchSelectModalProps<ProtocolValue> => ({
  ...getContainerProps(protocolValue),
  ...getSearchSelectProps(protocolValue, children, type),
});

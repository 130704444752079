/**
 * Labstep
 *
 * @module components/Entity/Detail/Table
 * @desc Table for entity detail
 */

import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import React from 'react';
import { EntityDetailTableProps } from './types';

export const EntityDetailTable: React.FC<EntityDetailTableProps> = ({
  rows,
  columns,
  dataCy,
}) => (
  <HorizontalTable
    dataCy={dataCy}
    padded
    borderless
    headerProps={{ width: 6 }}
    rows={rows}
    columns={columns}
  />
);

export default EntityDetailTable;

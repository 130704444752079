/**
 * Labstep
 *
 * @module screens/ResourceItem/Index
 * @desc Resource Item Index
 */

import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import ResourceItemActionCreate from 'labstep-web/components/ResourceItem/Action/Create';
import ResourceItemEmptyState from 'labstep-web/components/ResourceItem/Card/EmptyState';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { ParamsStateContextType } from 'labstep-web/contexts/params-state/types';
import GridNewIndex from 'labstep-web/grid/NewIndex';
import {
  getSystemFilterTemplateGuid,
  systemFilterNoTemplate,
} from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridFilterService } from 'labstep-web/grid/services/grid-filter.service';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import React, { useMemo } from 'react';
import ResourceItemIndexBreadcrumb from '../Breadcrumb';
import ResourceItemIndexLeftPane from '../LeftPane';
import { bulkActions, filters, sortOptions } from './constants';
import { ScreensResourceItemIndexMasterProps } from './types';

export const ScreensResourceItemIndexMaster: React.FC<
  ScreensResourceItemIndexMasterProps
> = ({ entityView, resourceTemplate, group, links }) => {
  const breadcrumb = useMemo(
    () => <ResourceItemIndexBreadcrumb />,
    [],
  );

  const action = (
    <ParamsStateContextProvider value={{}}>
      <ResourceItemActionCreate
        withRedirect={false}
        resourceTemplate={resourceTemplate}
        extraOptions={
          <EntityImportModal
            targetEntityName={ResourceItem.entityName}
            templateId={resourceTemplate?.id}
          />
        }
      />
    </ParamsStateContextProvider>
  );

  const noResultsMessage = (
    <ParamsStateContextProvider value={{}}>
      <ResourceItemEmptyState
        action={
          <ResourceItemActionCreate
            withRedirect={false}
            resourceTemplate={resourceTemplate}
          />
        }
        secondaryAction={
          <EntityImportModal
            targetEntityName={ResourceItem.entityName}
            templateId={entityView.templateId}
            actionComponentProps={{
              type: 'button',
              elementProps: { basic: true },
            }}
          />
        }
      />
    </ParamsStateContextProvider>
  );

  const leftPane = useMemo(
    () => <ResourceItemIndexLeftPane links={links} />,
    [links],
  );

  const globalParams: Record<
    string,
    string | number | Record<string, unknown>[]
  > = {
    group_id: group.id,
  };

  const paramsStateContext: ParamsStateContextType = {
    name: 'resource_item_index',
    globalParams,
    entityView,
    loadedEntityView: new EntityView({
      ...entityView,
    }),
  };
  if (entityView.templateId) {
    paramsStateContext.initialSearchParams =
      GridFilterService.injectSystemFilter(
        getSystemFilterTemplateGuid(entityView.templateId),
        entityView.parameters || {},
      );
  } else {
    paramsStateContext.initialSearchParams =
      GridFilterService.injectSystemFilter(
        systemFilterNoTemplate,
        entityView.parameters || {},
      );
  }
  if (paramsStateContext.loadedEntityView) {
    paramsStateContext.loadedEntityView.parameters =
      paramsStateContext.initialSearchParams;
  }

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <MasterIndex
        entityName={ResourceItem.entityName}
        breadcrumb={breadcrumb}
        leftPane={leftPane}
        filters={filters}
        noResultsMessage={noResultsMessage}
        action={action}
        bulkActions={bulkActions}
        sortOptions={sortOptions}
        useAllPages
        usePostFilter
      >
        {({
          entities,
          readNextPage,
          status,
        }: EntitySearchContainerChildrenProps) => (
          <GridNewIndex
            entityName={ResourceItem.entityName}
            group={group}
            entities={entities}
            entityView={entityView}
            status={status}
            loadMore={readNextPage}
          />
        )}
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

export default ScreensResourceItemIndexMaster;

/**
 * Labstep
 *
 * @module screens/Device/Index
 * @desc Device Index
 */

import DeviceCalendar from 'labstep-web/components/Device/Calendar';
import DeviceEmptyState from 'labstep-web/components/Device/Card/EmptyState';
import EntityActionCreateMenu from 'labstep-web/components/Entity/Action/Create/Menu';
import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { ParamsStateContextType } from 'labstep-web/contexts/params-state/types';
import GridNewIndex from 'labstep-web/grid/NewIndex';
import {
  getSystemFilterTemplateGuid,
  systemFilterNoTemplate,
} from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridFilterService } from 'labstep-web/grid/services/grid-filter.service';
import { Device } from 'labstep-web/models/device.model';
import { EntityView } from 'labstep-web/models/entity-view.model';
import DeviceIndexBreadcrumb from 'labstep-web/screens/Device/Index//Breadcrumb';
import DeviceIndexLeftPane from 'labstep-web/screens/Device/Index/LeftPane';
import MasterIndex from 'labstep-web/screens/Master/Index';
import React, { useMemo } from 'react';
import { bulkActions, filters, sortOptions } from './constants';
import { ScreensDeviceIndexMasterProps } from './types';

export const ScreensDeviceIndexMaster: React.FC<
  ScreensDeviceIndexMasterProps
> = ({ entityView, group }) => {
  const [activeView, setActiveView] = React.useState<
    'grid' | 'calendar'
  >('grid');

  const action = (
    <EntityActionCreateMenu
      entityName={Device.entityName}
      extraOptions={
        <EntityImportModal
          targetEntityName={Device.entityName}
          templateId={entityView.templateId}
        />
      }
      templateId={
        entityView.templateId && entityView.templateId !== 'none'
          ? entityView.templateId
          : undefined
      }
    />
  );
  const breadcrumb = (
    <DeviceIndexBreadcrumb
      setActiveView={setActiveView}
      activeView={activeView}
    />
  );
  const leftPane = useMemo(() => <DeviceIndexLeftPane />, []);
  const noResultsMessage = () => <DeviceEmptyState action={action} />;

  const globalParams: Record<
    string,
    string | number | Record<string, unknown>[]
  > = {
    group_id: group.id,
  };

  const paramsStateContext: ParamsStateContextType = {
    name: 'device_index',
    globalParams,
    entityView,
    loadedEntityView: new EntityView({
      ...entityView,
    }),
  };
  if (entityView.templateId === 'none') {
    paramsStateContext.initialSearchParams =
      GridFilterService.injectSystemFilter(
        systemFilterNoTemplate,
        entityView.parameters || {},
      );
  } else if (entityView.templateId) {
    paramsStateContext.initialSearchParams =
      GridFilterService.injectSystemFilter(
        getSystemFilterTemplateGuid(entityView.templateId),
        entityView.parameters || {},
      );
  }
  if (paramsStateContext.loadedEntityView) {
    paramsStateContext.loadedEntityView.parameters =
      paramsStateContext.initialSearchParams;
  }

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <MasterIndex
        entityName={Device.entityName}
        breadcrumb={breadcrumb}
        leftPane={leftPane}
        filters={filters}
        noResultsMessage={noResultsMessage}
        action={action}
        bulkActions={bulkActions}
        sortOptions={sortOptions}
        useAllPages
        usePostFilter
      >
        {({
          entities,
          readNextPage,
          status,
        }: EntitySearchContainerChildrenProps) =>
          activeView === 'grid' ? (
            <GridNewIndex
              entityName={Device.entityName}
              group={group}
              entities={entities}
              entityView={entityView}
              status={status}
              loadMore={readNextPage}
            />
          ) : (
            <DeviceCalendar devices={entities} />
          )
        }
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

export default ScreensDeviceIndexMaster;

/**
 * Labstep
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import EntityLinkComponent from 'labstep-web/components/Entity/Link/Component';
import OrderRequestStatusModalAddItems from 'labstep-web/components/OrderRequest/Status/Modal/AddItems';
import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import DataGrid from 'labstep-web/core/DataGrid';
import { suppressKeyboardEventDisableEnterOnEdit } from 'labstep-web/core/DataGrid/CellEditor/utils';
import {
  CellEditorParams,
  CellRendererParams,
} from 'labstep-web/core/DataGrid/types';
import { setValue } from 'labstep-web/core/DataGrid/utils';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import TextWithIcon from 'labstep-web/core/Text/WithIcon';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexActionToggleColumnOption from 'labstep-web/grid/NewIndex/components/ToggleColumnOption';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridColumnDefinitionService } from 'labstep-web/grid/services/grid-column-definition.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { Entity } from 'labstep-web/models/entity.model';
import {
  OrderRequest,
  OrderRequestStatus,
  OrderRequestStatusValues,
} from 'labstep-web/models/order-request.model';
import { dateOnly } from 'labstep-web/services/date.service';
import { setEntityValue } from 'labstep-web/state/actions/entity';
import store from 'labstep-web/state/store';
import styles from './styles.module.scss';

const setStatusValue = (
  params: CellEditorParams<Entity> | ValueSetterParams,
  value: OrderRequestStatus,
) => {
  const isImportResourceItemsDisplayed = (
    params.data as OrderRequest
  ).shouldImportResourceItems(value);
  store.dispatch(
    setEntityValue(
      params.data.entityName,
      params.data.id,
      'is_import_resource_items_displayed',
      isImportResourceItemsDisplayed,
    ),
  );
  setValue('status', value, {
    entityName: params.data.entityName,
    id: params.data.id,
  });
};

const closeImportResourceItems = (
  params: CellRendererParams<Entity>,
) => {
  store.dispatch(
    setEntityValue(
      params.data.entityName,
      params.data.id,
      'is_import_resource_items_displayed',
      false,
    ),
  );
  setTimeout(() => {
    GridColumnDefinitionService.refreshCell(params);
  }, 300);
};

export const colDefOrderRequestStatus: NewIndexColDef = {
  colId: 'status',
  postFilterSettings: {
    fieldType: PostFilterFieldTypes.options,
    field: 'status',
    entityName: OrderRequest.entityName,
  },
  headerName: 'Status',
  headerComponentParams: {
    icon: 'info circle',
  },
  autoHeight: true,
  columnOptions: (
    <GridNewIndexActionToggleColumnOption
      colId="status"
      columnOption="showLog"
      columnName="Status Info"
    />
  ),
  editable: (params: EditableCallbackParams) =>
    GridNewIndexService.getEditable(params),
  cellRenderer: (params: CellRendererParams<Entity>) => {
    const { entityView } = useParamsStateContext();
    if (!entityView) {
      return null;
    }
    if (!params.data) {
      return '';
    }
    if (params.data.is_import_resource_items_displayed) {
      return (
        <OrderRequestStatusModalAddItems
          orderRequest={params.data}
          open
          onCancel={() => closeImportResourceItems(params)}
          onClose={() => closeImportResourceItems(params)}
          onImport={() => closeImportResourceItems(params)}
        />
      );
    }
    return (
      <div>
        <Flex className={styles.status}>
          <TextWithIcon
            icon="circle"
            iconProps={{ className: styles[params.data.status] }}
            text={
              OrderRequestStatusValues[
                params.data
                  .status as keyof typeof OrderRequestStatusValues
              ]
            }
          />
          <Icon
            name="sort down"
            onClick={() =>
              params.api.startEditingCell({
                rowIndex: params.rowIndex,
                colKey: 'status',
              })
            }
          />
        </Flex>
        {entityView.column_options.status.showLog &&
          params.data.lastDateAndUser && (
            <div className={styles.showLog}>
              <div>
                on {dateOnly(params.data.lastDateAndUser.date)}
              </div>
              <div>
                by{' '}
                <EntityLinkComponent
                  entity={params.data.lastDateAndUser.user}
                  secondary
                  disabled
                />
              </div>
            </div>
          )}
      </div>
    );
  },
  cellEditor: DataGrid.CellEditor.Select,
  cellEditorPopup: true,
  cellEditorParams: (params: CellEditorParams<Entity>) => ({
    props: {
      value: {
        label:
          OrderRequestStatusValues[
            (params.data as OrderRequest)
              .status as keyof typeof OrderRequestStatusValues
          ],
        value: (params.data as OrderRequest).status,
      },
      options: Object.keys(OrderRequestStatusValues).map(
        (status) => ({
          label:
            OrderRequestStatusValues[
              status as keyof typeof OrderRequestStatusValues
            ],
          value: status,
        }),
      ),
      onChange: (option: {
        value: typeof OrderRequestStatusValues;
      }) => {
        setStatusValue(
          params,
          option.value as unknown as OrderRequestStatus,
        );
      },
    },
  }),
  suppressKeyboardEvent: suppressKeyboardEventDisableEnterOnEdit,
  valueGetterExport: (params: ValueGetterParams) =>
    String(params.node?.data?.status),
  // processCellFromClipboard: (params: ProcessCellForExportParams) => {
  //   const { value } = params;
  //   const validStatusValues = Object.keys(OrderRequestStatus);

  //   if (validStatusValues.includes(value)) {
  //     return value;
  //   }
  //   return null
  // },
  valueGetter: (params: ValueGetterParams) => params.data.status,
  valueSetter: (params: ValueSetterParams) => {
    if (params.newValue === '') {
      return false;
    }
    setStatusValue(params, params.newValue as OrderRequestStatus);
    return true;
  },
};

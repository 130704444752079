/**
 * Labstep
 *
 * @module components/Resource/Filter/Pane
 * @desc Filter pane for resource
 */

import FilterAssignedToList from 'labstep-web/components/Filter/AssignedTo/List';
import FilterCreatedByList from 'labstep-web/components/Filter/CreatedBy/List';
import { FilterList } from 'labstep-web/components/Filter/List';
import FilterPane from 'labstep-web/components/Filter/Pane';
import FilterTagList from 'labstep-web/components/Filter/Tag/List';
import { getPostFilterNumeric } from 'labstep-web/components/FilterPost/NewIndex/Numeric/Form';
import { ICONS } from 'labstep-web/constants/icons';
import { Resource } from 'labstep-web/models/resource.model';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React from 'react';

const ResourceFilterPane: React.FC = () => (
  <FilterPane
    entityName={Resource.entityName}
    text={'Resources'}
    icon={ICONS.resource.primary}
    filters={[]}
    extraFilters={
      <>
        <FilterList
          filters={[
            {
              icon: ICONS.resource.info.available,
              text: 'Items available',
              params: { available_resource_item_count: 1 },
              postFilterValues: [
                getPostFilterNumeric(
                  'available_resource_item_count',
                  PostFilterComparison.gte,
                ),
                ['1'],
              ],
            },
            {
              icon: ICONS.resource.info.unavailable,
              text: 'No items available',
              params: { available_resource_item_count: 0 },
              postFilterValues: [
                getPostFilterNumeric(
                  'available_resource_item_count',
                  PostFilterComparison.eq,
                ),
                ['0'],
              ],
            },
          ]}
        />
        <FilterCreatedByList
          entityName={Resource.entityName}
          usePostFilter
        />
        <FilterAssignedToList
          entityName={Resource.entityName}
          usePostFilter
        />
        <FilterTagList
          entityName={Resource.entityName}
          usePostFilter
        />
      </>
    }
  />
);

export default ResourceFilterPane;

/**
 * Labstep
 *
 * @module models/entity-view
 * @desc Typescript export class for Entity View
 */

import type { ColumnState } from 'ag-grid-community';
import { Type } from 'class-transformer';
import { Device } from 'labstep-web/models/device.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { Tag } from 'labstep-web/models/tag.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import {
  IPostFilterSettings,
  PostFilterOperator,
} from 'labstep-web/services/query-parameter.service';

export type EntityViewParameters = {
  search_query?: string;
  filter?: IPostFilterSettings['filter'];
  sort?: string;
};

export type EntityViewColumnOptions = {
  name: {
    showMetadataPreview: boolean;
  };
  resource: {
    showMetadataPreview: boolean;
  };
  status: {
    showLog: boolean;
  };
};

export const ENTITY_VIEW_DEFAULT_COLUMN_OPTIONS: Record<
  string,
  EntityViewColumnOptions
> = {
  default: {
    name: {
      showMetadataPreview: false,
    },
    resource: {
      showMetadataPreview: false,
    },
    status: {
      showLog: false,
    },
  },
  order_request_index: {
    name: {
      showMetadataPreview: true,
    },
    resource: {
      showMetadataPreview: false,
    },
    status: {
      showLog: true,
    },
  },
};

export const ENTITY_VIEW_DEFAULT_PARAMETERS: EntityViewParameters = {
  filter: [
    {
      type: PostFilterOperator.and,
      predicates: [],
    },
    {
      type: PostFilterOperator.and,
      predicates: [],
    },
  ],
};

export class EntityView extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityName = 'entity_view' as const;

  public get entityName(): string {
    return EntityView.entityName;
  }

  public constructor(data: Partial<EntityView> = {}) {
    super();
    Object.assign(this, data);
  }

  public is_template: boolean = false;

  public id!: string;

  public guid!: string;

  public name!: string;

  public entity_name!: string;

  public context!: string;

  public state?: ColumnState[] | null;

  public column_definition_ids?: string[] | null;

  public parameters?: EntityViewParameters | null;

  private _column_options?: EntityViewColumnOptions | null;

  public get column_options(): EntityViewColumnOptions {
    return (
      this._column_options ||
      ENTITY_VIEW_DEFAULT_COLUMN_OPTIONS[
        this
          .context as keyof typeof ENTITY_VIEW_DEFAULT_COLUMN_OPTIONS
      ] ||
      ENTITY_VIEW_DEFAULT_COLUMN_OPTIONS.default
    );
  }

  public set column_options(value: EntityViewColumnOptions | null) {
    this._column_options = value;
  }

  @Type(() => Group)
  group!: Group;

  @Type(() => Tag)
  tags!: Tag[];

  @Type(() => Device)
  device_template!: Device;

  @Type(() => Resource)
  resource_template!: Resource;

  private _templateId!: string | undefined;

  set templateId(value: string | undefined) {
    this._templateId = value;
  }

  get templateId(): string | undefined {
    if (this.device_template) {
      return this.device_template.guid;
    }
    if (this.resource_template) {
      return this.resource_template.guid;
    }
    return this._templateId;
  }

  get templateEntityName(): string | undefined {
    if (this.entity_name === Device.entityName) {
      return Device.entityName;
    }
    if (this.entity_name === Resource.entityName) {
      return Resource.entityName;
    }
    if (this.entity_name === ResourceItem.entityName) {
      return Resource.entityName;
    }
    return undefined;
  }

  get parametersSystemFields(): string[] {
    if (this.parameters?.filter?.length !== 2) {
      return [];
    }

    const systemFields = [];
    for (const predicate of this.parameters.filter[1].predicates) {
      const path = predicate.path;
      const subPredicates = predicate.predicates || [];
      for (const subpredicate of subPredicates) {
        if (path && path !== '') {
          systemFields.push(`${path}:${subpredicate.attribute}`);
        } else {
          systemFields.push(subpredicate.attribute);
        }
      }
    }
    return systemFields;
  }

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}

/**
 * Labstep
 *
 * @module components/Entity/Template/Breadcrumb/Index
 * @desc Breadcrumb for Template Entity on Index page
 */

import EntityFormShowEditName from 'labstep-web/components/Entity/Form/ShowEdit/Name';
import { EntityPreviewProps } from 'labstep-web/components/Entity/Preview/types';
import EntityTemplatePaneManager from 'labstep-web/components/Entity/Template/Pane/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveEditModal } from 'labstep-web/containers/ActiveEditModal';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import ActionComponent from 'labstep-web/core/Action/Component';
import Desktop from 'labstep-web/core/MediaQuery/Desktop';
import ScreensIndexBreadcrumb from 'labstep-web/screens/Breadcrumb';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { LocationService } from 'labstep-web/services/location.service';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import { EntityTemplateBreadcrumbIndexProps } from './types';

export const EntityTemplateBreadcrumbIndex: React.FC<
  EntityTemplateBreadcrumbIndexProps
> = ({
  location,
  entityName,
  activeEditModal,
  setActiveEditModal,
  showUncategorised = true,
  entityPreview,
  text,
  history,
  activeGroup,
  indexRoute,
  icon,
}) => (
  <>
    <ScreensIndexBreadcrumb
      text={
        text ||
        `All ${getHumanReadableEntityName(entityName, true, true)}`
      }
      onClick={() => {
        if (activeGroup) {
          history.push(
            navigation.get(indexRoute, {
              id: activeGroup.id,
            }),
          );
        }
      }}
      dropdownContent={({ header }): React.ReactElement => (
        <div className={styles.contentContainer}>
          {header}
          <EntityTemplatePaneManager
            entityPreview={entityPreview}
            entityName={entityName}
            showUncategorised={showUncategorised}
            searchBarProps={{
              // Prevent mobile breadcrumb closing
              onClick: (e): void => {
                e.preventDefault();
                e.stopPropagation();
              },
            }}
            indexRoute={indexRoute}
          />
        </div>
      )}
      icon={icon ? icon : ICONS[entityName].primary}
      rightMobileIcon={ICONS.resource_template.primary}
      right={(): React.ReactElement | null => {
        const templateId = LocationService.getQueryParameter(
          location,
          'template_id',
        );
        if (templateId === 'none') {
          return (
            <Desktop>
              {({ match }): React.ReactElement =>
                match ? (
                  <div className={styles.desktopContainer}>
                    <span>Uncategorised</span>
                  </div>
                ) : (
                  <span>Uncategorised</span>
                )
              }
            </Desktop>
          );
        } else if (templateId) {
          return (
            <EntityReadEntityContainer
              entityName={entityName}
              guid={templateId}
            >
              {({ entity }): React.ReactElement | null =>
                entity ? (
                  <div className={styles.container}>
                    <EntityFormShowEditName entity={entity} />
                    <ActionComponent
                      type="icon"
                      icon={ICONS.navigation.settings}
                      onClick={(e): void => {
                        e.preventDefault();
                        e.stopPropagation();
                        setActiveEditModal({
                          entityName,
                          id: entity.id,
                        });
                      }}
                      elementProps={{
                        popup: { content: 'Settings' },
                      }}
                    />
                  </div>
                ) : null
              }
            </EntityReadEntityContainer>
          );
        }
        return null;
      }}
    />
    {activeEditModal && activeEditModal.entityName === entityName && (
      <EntityReadEntityContainer
        entityName={entityName}
        id={activeEditModal.id}
      >
        {({
          entity,
        }: {
          entity: EntityPreviewProps['entity'];
        }): React.ReactElement =>
          entityPreview({
            entity,
            modalProps: {
              open: true,
              viewComponent: null,
              onClose: (): void => {
                setActiveEditModal(null);
              },
            },
          }) as React.ReactElement
        }
      </EntityReadEntityContainer>
    )}
  </>
);

export default withActiveGroup(
  withRouter(withActiveEditModal(EntityTemplateBreadcrumbIndex)),
);

/**
 * Labstep
 *
 * @module components/FilterPost/NewIndex/Text/Form
 * @desc Filter Metadata form
 */

import {
  COMPARISON_OPTIONS,
  fieldComparison,
  fieldText,
} from 'labstep-web/components/FilterPost/NewIndex/Text/Form/fields';
import { IFilterTextFormProps } from 'labstep-web/components/FilterPost/NewIndex/Text/Form/types';
import ReusableFormGroup from 'labstep-web/core/Form/Reusable/Group';
import { PostFilter } from 'labstep-web/services/postFilter';
import { PostFilterComparison } from 'labstep-web/services/query-parameter.service';
import React, { useCallback, useState } from 'react';

export const getPostFilterText = (
  field: string,
  comparison: PostFilterComparison,
  path?: string,
) => {
  return new PostFilter([[field, comparison]], path);
};

const FilterTextForm: React.FC<IFilterTextFormProps> = ({
  addPostFilter,
  onDone,
  path,
  field,
  defaultValues,
  replace,
}) => {
  const [fields, setFields] = useState([fieldComparison]);

  const onSubmit = useCallback(
    (values): void => {
      const { comparison } = values;
      let { value } = values;
      if (comparison.value === PostFilterComparison.not_null) {
        value = undefined;
      }
      addPostFilter(
        getPostFilterText(field, comparison.value, path).getNode([
          value,
        ]),
        true,
      );
      onDone();
    },
    [path, addPostFilter],
  );

  return (
    <ReusableFormGroup
      fields={fields}
      onSubmit={onSubmit}
      onDone={onDone}
      defaultValues={{
        comparison: COMPARISON_OPTIONS[0],
        ...defaultValues,
      }}
      onFormChange={({ comparison }): void => {
        if (!comparison) {
          return;
        }
        const newFields =
          comparison.value === PostFilterComparison.not_null
            ? [fieldComparison]
            : [fieldComparison, fieldText];
        if (fields.length !== newFields.length) {
          setFields(newFields);
        }
      }}
      addAnother={false}
    />
  );
};

export default FilterTextForm;

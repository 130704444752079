/**
 * Labstep
 *
 * @module screens/Overview
 * @desc Workspace overview
 */

import ExperimentWorkflowCardOverview from 'labstep-web/components/ExperimentWorkflow/Card/Overview';
import GroupAboutCard from 'labstep-web/components/Group/Card/About';
import { GroupUserLimitReached } from 'labstep-web/components/Group/UserLimitReached';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import OrderRequestCardOverview from 'labstep-web/components/OrderRequest/Card/Overview';
import ProtocolCollectionCardOverview from 'labstep-web/components/ProtocolCollection/Card/Overview';
import Flex from 'labstep-web/core/Flex';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import LogBoard from 'labstep-web/screens/Overview/LogBoard';
import MessageBoard from 'labstep-web/screens/Overview/MessageBoard';
import React from 'react';
import styles from './styles.module.scss';
import { IOverviewProps } from './types';

const Overview: React.FC<IOverviewProps> = ({ group }) => {
  const params = {
    group_id: group.id,
  };
  // TODO: Review with Barney if needed or not
  const showUserLimitWarning = false;
  // const showUserLimitWarning = useHasExceededLimit(
  //   group.user_count,
  //   LimitTypes.MAX_USERS_PER_GROUP,
  // );

  return (
    <LayoutMainContentCenter>
      <div className={styles.container}>
        {showUserLimitWarning ? (
          <GroupUserLimitReached group={group} />
        ) : (
          <>
            <GroupAboutCard group={group} />
            <Flex wrap style={{ gap: '20px' }}>
              <div className={styles.item}>
                <ExperimentWorkflowCardOverview params={params} />
              </div>
              <div className={styles.item}>
                <ProtocolCollectionCardOverview params={params} />
              </div>
              {useHasFeatureFlagEnabled('order_management') && (
                <div className={styles.item}>
                  <OrderRequestCardOverview params={params} />
                </div>
              )}
              <div className={styles.item}>
                <MessageBoard group={group} />
              </div>
              {useHasFeatureFlagEnabled('log_overview') && (
                <div className={styles.item}>
                  <LogBoard group={group} />
                </div>
              )}
            </Flex>
          </>
        )}
      </div>
    </LayoutMainContentCenter>
  );
};

export default Overview;

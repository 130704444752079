/**
 * Labstep
 *
 * @module screens/Device/Index/LeftPane
 * @desc Device Index LeftPane
 */

import DeviceFilterPane from 'labstep-web/components/Device/Filter/Pane';
import DevicePreview from 'labstep-web/components/Device/Preview';
import EntityTemplatePane from 'labstep-web/components/Entity/Template/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { Device } from 'labstep-web/models/device.model';
import React from 'react';

export const DeviceIndexLeftPane: React.FC = () => (
  <LayoutLeftPane>
    <ParamsStateContextProvider value={{}}>
      <EntityTemplatePane
        entityPreview={({ entity, ...rest }): React.ReactElement => (
          <DevicePreview device={entity as Device} {...rest} />
        )}
        entityName={Device.entityName}
        indexRoute="group_devices"
      />
    </ParamsStateContextProvider>
    <ParamsStateContextProvider
      value={{ destination: 'device_index' }}
    >
      <DeviceFilterPane />
    </ParamsStateContextProvider>
  </LayoutLeftPane>
);

export default DeviceIndexLeftPane;

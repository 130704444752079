/**
 * Labstep
 *
 * @module screens/EntityStateWorkflow/Show/Right
 * @desc EntityStateWorkflow Show Right
 */

import ExperimentWorkflowLinkedList from 'labstep-web/components/ExperimentWorkflow/LinkedList';
import RightPane from 'labstep-web/components/Layout/RightPane';
import LogList from 'labstep-web/components/Log/List';
import { ICONS } from 'labstep-web/constants/icons';
import React from 'react';
import { IScreensEntityStateWorkflowShowRightProps } from './types';

const ScreensEntityStateWorkflowShowRight: React.FC<
  IScreensEntityStateWorkflowShowRightProps
> = ({ entityStateWorkflow }) => (
  <RightPane
    route={{
      to: 'entity_state_workflow_show',
      params: { id: entityStateWorkflow.id },
    }}
    subPages={[
      {
        name: 'Linked Templates',
        icon: ICONS.experiment_workflow.template,
        route: {
          to: 'entity_state_workflow_show_experiment_workflow_template',
          params: { id: entityStateWorkflow.id },
        },
        component: (
          <ExperimentWorkflowLinkedList
            params={{
              is_template: 1,
              entity_state_workflow_id: entityStateWorkflow.id,
            }}
          />
        ),
      },
      {
        name: 'Linked Experiments',
        icon: ICONS.experiment_workflow.primary,
        route: {
          to: 'entity_state_workflow_show_experiment_workflow',
          params: { id: entityStateWorkflow.id },
        },
        component: (
          <ExperimentWorkflowLinkedList
            params={{
              entity_state_workflow_id: entityStateWorkflow.id,
            }}
          />
        ),
      },
      {
        name: 'Activity',
        icon: 'history',
        route: {
          to: 'entity_state_workflow_show_activity',
          params: { id: entityStateWorkflow.id },
        },
        component: (
          <LogList
            entityName={entityStateWorkflow.entityName}
            entityId={entityStateWorkflow.id}
          />
        ),
      },
    ]}
  />
);

export default ScreensEntityStateWorkflowShowRight;

/**
 * Labstep
 *
 * @desc utils for processing batches
 */

import { createEntity } from 'labstep-web/state/actions/entity';
import type { Dispatch } from 'redux';
import { IBatch, IOwnProps } from './types';

export const DEFAULT_MAX_BATCH_SIZE = 10;
const DEFAULT_CONCURRENCY_LIMIT = 3;
const DEFAULT_DELAY_BETWEEN_CALLS = 50;

export const processBatches = (
  dispatch: Dispatch,
  ownProps: IOwnProps,
  batches: IBatch[],
  options: any,
) => {
  const {
    concurrencyLimit = DEFAULT_CONCURRENCY_LIMIT,
    delayBetweenCalls = DEFAULT_DELAY_BETWEEN_CALLS,
  } = ownProps;
  // Step 2: Process batches with delay and concurrency control
  const processBatch = async (batch: IBatch) => {
    const { batchUuid, items, parentId, parentName } = batch;
    return new Promise<void>((resolve) => {
      dispatch(
        createEntity(
          ownProps.entityName,
          items as any,
          parentName,
          parentId,
          batchUuid,
          options,
          ownProps.childKeyName,
        ),
      );
      setTimeout(resolve, delayBetweenCalls);
    });
  };

  // Step 3: Execute requests with concurrency control
  const executeBatches = async () => {
    let runningRequests = 0;
    let index = 0;

    return new Promise<void>((resolve) => {
      const processNext = async () => {
        if (index >= batches.length) {
          if (runningRequests === 0) {
            resolve();
          }
          return;
        }
        if (runningRequests >= concurrencyLimit) {
          return;
        }

        runningRequests++;
        const batch = batches[index];
        processBatch(batch).finally(() => {
          runningRequests--;
          processNext();
        });
        index++;
        setTimeout(processNext, delayBetweenCalls);
      };

      // Start initial concurrent requests
      for (let i = 0; i < concurrencyLimit; i++) {
        processNext();
      }
    });
  };

  executeBatches();
};

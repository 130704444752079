/**
 * Labstep
 *
 * @module components/Log/List
 * @desc List of logs
 */

import LogListItem from 'labstep-web/components/Log/ListItem';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Log } from 'labstep-web/models/log.model';
import React from 'react';
import styles from './styles.module.scss';
import { LogListContainerProps, LogListProps } from './types';
/**test@labstep.com test@labstep.com edited the protocol
 */
export const LogList: React.FC<LogListProps> = ({
  logs,
  entityName,
  selectedEntitySnapshot,
  setSelectedEntitySnapshot,
}) => {
  return (
    <div className={styles.list} data-cy="components/Log/List">
      {logs.map((log: Log) => {
        return (
          <LogListItem
            log={log}
            entityName={entityName}
            selectedEntitySnapshot={selectedEntitySnapshot}
            setSelectedEntitySnapshot={setSelectedEntitySnapshot}
          />
        );
      })}
    </div>
  );
};

export const LogListContainer: React.FC<LogListContainerProps> = ({
  entityName,
  entityId,
  objectId,
  objectClass,
  extraParams,
  count = 10,
  ...rest
}) => (
  <ReadOnMountHOC
    type="cursor"
    pagination={{
      infinite: false,
    }}
    entityName="log"
    params={
      entityName
        ? {
            [`${entityName}_id`]: entityId,
            count,
            has_request_trace: true,
            ...extraParams,
          }
        : {
            object_id: objectId,
            object_class: objectClass,
            count,
            has_request_trace: true,
            ...extraParams,
          }
    }
    children={({ entities }) => (
      <LogList logs={entities} entityName={entityName} {...rest} />
    )}
  />
);

export default LogListContainer;

/**
 * Labstep
 *
 * @module screens/Experiment/Edit/Right/Metadatas
 * @desc Experiment Value Screens
 */

import { MetadataCardList } from 'labstep-web/components/Metadata/CardList';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import { IExperimentWorkflowEditRightMetadatasProps } from './types';

const ExperimentWorkflowEditRightMetadatas: React.FC<
  IExperimentWorkflowEditRightMetadatasProps
> = ({ experimentWorkflow }) => (
  <Flex grow>
    <MetadataCardList
      entity={experimentWorkflow.root_experiment}
      experimentWorkflow={experimentWorkflow}
    />
  </Flex>
);

export default ExperimentWorkflowEditRightMetadatas;

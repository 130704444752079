/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/metadata/molecule/structure
 * @desc Column Definition for metadata molecule structure
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
} from 'ag-grid-community';
import MoleculeActionEdit from 'labstep-web/components/Molecule/Action/Edit';
import MoleculeStructure from 'labstep-web/components/Molecule/Structure';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { CellEditorParams } from 'labstep-web/core/DataGrid/types';
import {
  createOrUpdateValue,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import GridNewIndexColumnHeader from 'labstep-web/grid/NewIndex/components/ColumnHeader';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { GridColumnDefinitionService } from 'labstep-web/grid/services/grid-column-definition.service';
import { Entity } from 'labstep-web/models/entity.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';

export const getMetadataMoleculeStructureColDef = (
  parentEntityClass: string,
  type: MetadataType,
  metadataLabel: string,
): NewIndexColDef => {
  return {
    colId: `metadata:${parentEntityClass}:${type}:${metadataLabel}:structure`,
    postFilterSettings: {
      path: 'metadatas',
    },
    sortable: true,
    headerName: 'Structure',
    headerComponent: GridNewIndexColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.type.molecule,
    },
    editable: (params: EditableCallbackParams) =>
      GridNewIndexService.getEditable(params),
    valueGetter: (params: ValueGetterParams): string | null => {
      // const entity = getEntity(params);
      // if (entity instanceof Molecule) {
      //   return entity.svg;
      // }
      return null;
    },
    valueSetter: (params: any): boolean => {
      // const entity = getEntity(params);
      // if (!entity) {
      //   return false;
      // }
      // return setValue('svg', params.newValue, {
      //   entityName: entity.entityName,
      //   id: entity.idAttr,
      // });
      return false;
    },
    cellRenderer: (params: CellRendererParams<Entity>) => {
      if (!params.node) {
        return null;
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return (
          <TextPlaceholder
            onClick={() => {
              createOrUpdateValue({
                entityName: Metadata.entityName,
                body: {
                  type,
                  label: metadataLabel,
                  metadata_thread_id:
                    params.node.data.metadata_thread.guid,
                  molecule: { name: 'Untitled structure' },
                },
                createProps: {
                  parentName:
                    params.node.data.metadata_thread.entityName,
                  parentId: params.node.data.metadata_thread.idAttr,
                },
                options:
                  GridColumnDefinitionService.getOptions(params),
              });
              return true;
            }}
          >
            Create structure
          </TextPlaceholder>
        );
      }
      // if (createProps) {
      //   return (
      //     <EntityActionCreate
      //       parentName={createProps.parentName}
      //       parentId={createProps.parentId}
      //       entityName={Metadata.entityName}
      //       actionComponentProps={{
      //         type: 'text',
      //         text: 'Draw structure',
      //         elementProps: { placeholder: true, gridCell: true },
      //       }}
      //       body={createProps.createBody}
      //       options={{
      //         onSuccess: () =>
      //           params.api.startEditingCell({
      //             rowIndex: params.rowIndex,
      //             colKey: params.column,
      //           }),
      //       }}
      //     />
      //   );
      // }
      return (
        <MoleculeStructure
          molecule={metadata.molecule}
          fallback={
            <DataGridPlaceholder
              params={params}
              children="Draw structure"
              editable={true}
            />
          }
        />
      );
    },
    cellEditorPopup: true,
    cellEditor: (params: CellEditorParams<Entity>) => {
      if (!params.node) {
        return '';
      }
      const metadata =
        params.node.data.metadata_thread.metadatas.find(
          (m: Metadata) =>
            m.type === type && m.label === metadataLabel,
        );
      if (!metadata) {
        return null;
      }
      return (
        <MoleculeActionEdit
          molecule={metadata.molecule}
          canEdit
          viewComponent={null}
          open
          onDone={(): void => stopEditingAndFocus(params)}
        />
      );
    },
  };
};

/**
 * Labstep
 *
 * @module core/Form/ShowEdit/Select
 * @desc A showedit component for search select
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import { ShowEditToggleComponent } from 'labstep-web/core/Form/ShowEdit';
import Select from 'labstep-web/core/Select';
import React from 'react';
import Creatable from 'react-select/creatable';
import { FieldTypeReactSelect } from '../../Reusable/types';
import styles from './styles.module.scss';
import {
  IFormShowEditSelectProps,
  ISelectViewComponentProps,
} from './types';

export const SelectViewComponent: React.FC<
  ISelectViewComponentProps
> = ({ children, onExpand }) => (
  <div className={styles.container}>
    <div data-testid="show-edit-toggle">{children}</div>
    <ActionComponent
      type="icon"
      icon="chevron down"
      onClick={onExpand}
    />
  </div>
);

const FormShowEditSelect: React.FC<IFormShowEditSelectProps> = ({
  dataCy,
  entity,
  children,
  optimistic = true,
  isClearable = true,
  setOnce,
  field,
  disabled,
  childKeyName,
  creatable,
  preSubmit,
  ...rest
}) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.idAttr,
    Action.edit,
    field.name,
  );

  return (
    <EntityUpdateContainer
      entityName={entity.entityName}
      id={entity.idAttr}
      optimistic={optimistic}
    >
      {({ update }) => {
        const childKey = (childKeyName ||
          field.name) as keyof typeof entity;

        return (
          <ShowEditToggleComponent
            dataCy={dataCy}
            hasValue={Boolean(entity[childKey])}
            canEdit={!disabled && canEdit}
            children={children}
            customShow={({ toggle }) =>
              setOnce ? (
                children
              ) : (
                <SelectViewComponent onExpand={toggle}>
                  {children}
                </SelectViewComponent>
              )
            }
            toggledComponent={({ close }) => {
              const Component = creatable ? Creatable : Select;
              return (
                <div
                  data-cy={dataCy}
                  className={styles.searchSelectContainer}
                >
                  <Component
                    value={entity[childKey] || null}
                    autoFocus
                    onBlur={close}
                    isClearable={isClearable}
                    openMenuOnFocus
                    options={
                      (field as FieldTypeReactSelect).elementProps
                        ?.options
                    }
                    backspaceRemovesValue={isClearable}
                    {...creatable}
                    onChange={(option: any) => {
                      const value = option ? option.value : null;
                      let values = {
                        [field.name]: value,
                      };
                      if (preSubmit) {
                        values = preSubmit(values);
                      }
                      update(values);
                      close();
                    }}
                  />
                </div>
              );
            }}
            name={field.name}
            {...rest}
          />
        );
      }}
    </EntityUpdateContainer>
  );
};

export default FormShowEditSelect;

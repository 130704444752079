/**
 * Labstep
 *
 * @module components/FilterPost
 * @desc Main Filter Post component
 */

import EntityViewActionSave from 'labstep-web/components/EntityView/Action/Save';
import FilterMenu from 'labstep-web/components/Filter/Menu';
import SearchBarFilter from 'labstep-web/components/Filter/Search';
import { PostFilterActiveWithHooks } from 'labstep-web/components/FilterPost/Active';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import { ICONS } from 'labstep-web/constants/icons';
import Button from 'labstep-web/core/Button';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import EntityDataGridActionShowSideBar from 'labstep-web/grid/Index/components/ShowSideBar';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';
import styles from './styles.module.scss';
import { FilterPostProps } from './types';

export const FilterPost: React.FC<FilterPostProps> = ({
  action,
  entityName,
  searchBarProps,
  isTemplate,
  searchParams,
  filters,
  hideSearch = false,
  searchKey = 'search_query',
}) => {
  const filterButton = filters && filters.length > 0 && (
    <FilterMenu
      trigger={
        <Button className={styles.searchBarAction}>
          <Icon name={ICONS.filter.primary} />
          Filter
        </Button>
      }
      entityName={entityName}
      filters={filters}
      clearAll={() => {}}
    />
  );
  return (
    <div data-testid="filter">
      <div className={styles.searchBarContainer}>
        <div
          data-testid="search-bar"
          className={styles.searchBar}
          data-cy="components/Filter:searchBar"
        >
          <SearchBarFilter
            hideSearch={hideSearch}
            searchBarProps={{
              label: (
                <div className={styles.searchBarActionContainer}>
                  {filterButton}
                  <EntityDataGridActionShowSideBar
                    className={styles.searchBarAction}
                  />
                </div>
              ),
              placeholder: `Search for ${getHumanReadableEntityName(
                entityName,
                true,
                false,
                isTemplate,
              )}`,
              ...searchBarProps,
            }}
            searchKey={searchKey}
          />
          <Flex>
            <EntityViewActionSave />
            {objectOrFunction(action, {
              searchParams,
            })}
          </Flex>
        </div>
      </div>
      <Flex grow>
        <PostFilterActiveWithHooks searchParams={searchParams} />
      </Flex>
    </div>
  );
};

export default FilterPost;

/**
 * Labstep
 *
 * @module models/user
 * @desc Typescript export class for User
 */

import { Type } from 'class-transformer';
import { PermissionActions } from 'labstep-web/models';
import { EntityUserRoleRequirement } from 'labstep-web/models/entity-user-role-requirement.model';
import { EntityUserRole } from 'labstep-web/models/entity-user-role.model';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import {
  Organization,
  PremiumFeature,
} from 'labstep-web/models/organization.model';
import { Profile } from 'labstep-web/models/profile.model';
import { Thread } from 'labstep-web/models/thread.model';
import { UserOrganization } from 'labstep-web/models/user-organization.model';

// eslint-disable-next-line no-shadow
export enum ContentStateTemplate {
  default = 'default',
  revised = 'revised',
}

// eslint-disable-next-line no-shadow
export enum DefaultEntityCreationProseMirrorExperiments {
  default = 'experiment',
  revised = 'protocol',
}

// eslint-disable-next-line no-shadow
export enum IntercomSupportGroup {
  none = 'none',
  default = 'default',
}

// eslint-disable-next-line no-shadow
export enum TestingState {
  disabled = 'disabled',
  enabled = 'enabled',
}

// eslint-disable-next-line no-shadow
export enum Testing {
  contentStateTemplate = 'content_state_template',
  defaultEntityCreationProseMirrorExperiments = 'default_entity_creation_prose_mirror_experiments',
  intercomSupportGopup = 'intercom_support_popup',
  emailReEngagement = 'email_re_engagement',
}

interface ITesting {
  content_state_template?: ContentStateTemplate;
  default_entity_creation_prose_mirror_experiments?: DefaultEntityCreationProseMirrorExperiments;
  intercom_support_popup?: IntercomSupportGroup;
  email_re_engagement?: TestingState;
  default_entity_creation: unknown;
}

// eslint-disable-next-line no-shadow
enum Sort {
  last_login = 'last_login',
}

export const ACADEMIC_PREMIUM_FEATURES: PremiumFeature[] = [
  'inventory',
  'order_management',
  'devices',
  'chemistry',
  'molecular_biology',
  'auto_sharing',
  'completion_checklist',
  'multiplexing',
  'custom_identifier',
];

export class User extends Entity {
  public static readonly entityName = 'user';

  public get entityName(): typeof User.entityName {
    return User.entityName;
  }

  public static sort = Sort;

  public constructor(data: Partial<User> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public username!: string;

  public email!: string;

  public enabled!: boolean;

  public first_name!: string;

  public last_name!: string;

  public saml_id!: string;

  public token!: string;

  public refresh_token!: string;

  public roles?: string[];

  public consented_at!: string;

  public monthly_report_optout_at!: string;

  // TODO Remove later
  public authenticated!: boolean;

  public name!: string;

  public experiment_workflow_count!: number;

  public protocol_collection_count!: number;

  public resource_count!: number;

  public group_count!: number;

  public biography!: string;

  public testing!: ITesting;

  public enabled_db!: boolean;

  /**
   * @deprecated
   */
  public is_enterprise!: boolean;

  public is_external!: boolean;

  public is_two_factor_enabled!: boolean;

  public is_signup_enterprise!: boolean;

  public stripe_subscription_id!: string;

  public is_organization_setup_completed!: boolean;

  public allowed_actions!: PermissionActions[];

  @Type(() => MetadataThread)
  public metadata_thread!: MetadataThread;

  @Type(() => Thread)
  public thread!: Thread;

  @Type(() => Profile)
  public profile!: Profile;

  @Type(() => Group)
  public group!: Group;

  @Type(() => Organization)
  public organization!: Organization | null;

  @Type(() => UserOrganization)
  public user_organizations!: UserOrganization[];

  @Type(() => EntityUserRole)
  public entity_user_roles!: EntityUserRole[];

  @Type(() => EntityUserRoleRequirement)
  public entity_user_role_requirements!: EntityUserRoleRequirement[];

  public get userOrganization(): null | UserOrganization {
    if (this.user_organizations && this.user_organizations[0]) {
      return this.user_organizations[0];
    }
    return null;
  }

  public get shouldEnableMFA(): boolean {
    const organization =
      this.user_organizations &&
      this.user_organizations[0] &&
      this.user_organizations[0].organization;
    if (organization) {
      return false;
    }
    return false;
  }

  public getTestingByKey(key: keyof ITesting): boolean | string {
    if (!this.testing) {
      return false;
    }

    return this.testing[key] as string | boolean;
  }

  public get isLabstepAdmin(): boolean {
    return !!(
      this.userOrganization &&
      this.userOrganization.type === 'admin' &&
      this.userOrganization.organization.identifier === 'labstep'
    );
  }

  public get isLabstepMember(): boolean {
    return !!(
      this.userOrganization &&
      this.userOrganization.organization.identifier === 'labstep'
    );
  }

  public get isOrganizationAdmin(): boolean {
    return this.userOrganization
      ? this.userOrganization.type === 'admin'
      : false;
  }

  public get userOrganizationOrganization(): Organization | null {
    const organization =
      this.user_organizations &&
      this.user_organizations[0] &&
      this.user_organizations[0].organization;
    return organization ?? null;
  }

  /** When workspace gets deleted, this will return false */
  public get hasGroupNotDeleted(): boolean {
    return !!(this.group && this.group.deleted_at === null);
  }

  public hasFeatureEnabled(feature: PremiumFeature): boolean {
    return this.premiumFeatures
      ? this.premiumFeatures.indexOf(feature) > -1
      : false;
  }

  public get premiumFeatures(): PremiumFeature[] {
    const organization = this.userOrganizationOrganization;
    if (organization) {
      return organization.premium_features || [];
    }
    return ACADEMIC_PREMIUM_FEATURES;
  }

  public get isPaymentOverdue(): boolean {
    const organization = this.userOrganizationOrganization;
    if (organization) {
      return organization.is_payment_overdue;
    }
    return false;
  }
}

/**
 * Labstep
 *
 * @module components/BatchRequest/Progress/ErrorTable
 * @desc Table listing all errors from batch processed requests
 */

import Button from 'labstep-web/core/Button';
import Checkbox from 'labstep-web/core/Checkbox';
import Icon from 'labstep-web/core/Icon';
import TableSimple from 'labstep-web/core/Table/Simple';
import React from 'react';
import styles from './styles.module.scss';
import { BatchError, IErrorsTableProps } from './types';
import { getErrors } from './utils';

export const ErrorTable: React.FC<IErrorsTableProps> = ({
  requests,
  failedRequests,
  retryRequests,
  cancelRequests,
  toggleModal,
}) => {
  const errors = getErrors(requests, failedRequests);
  const allIds = errors.map((error) => error.id);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [unselected, setUnselected] =
    React.useState<string[]>(allIds);

  const columns = [
    {
      header: (
        <Checkbox
          indeterminate={
            (selectedAll &&
              unselected.length > 0 &&
              unselected.length < errors.length) ||
            (selected.length > 0 && selected.length < errors.length)
          }
          checked={
            (selectedAll && unselected.length === 0) ||
            selected.length === errors.length
          }
          onClick={() => {
            if (selectedAll) {
              setSelected([]);
              setUnselected(errors.map((error) => error.id));
            } else {
              setSelected(errors.map((error) => error.id));
              setUnselected([]);
            }
            setSelectedAll(!selectedAll);
          }}
        />
      ),
      content: (error: BatchError) => (
        <Checkbox
          value={error.id}
          onClick={() => {
            if (selected.includes(error.id)) {
              setSelected(selected.filter((id) => id !== error.id));
              setUnselected([...unselected, error.id]);
            } else {
              setSelected([...selected, error.id]);
              setUnselected(
                unselected.filter((id) => id !== error.id),
              );
            }
          }}
          checked={selected.includes(error.id)}
        />
      ),
      cellProps: { style: { width: 60 } },
    },
    {
      header: '',
      content: (error: BatchError) => {
        return (
          <>
            {error.itemNames.map((itemName, index) => (
              <div key={index}>{itemName}</div>
            ))}
          </>
        );
      },
      cellProps: {
        style: { width: 200, color: '#404E5B', fontWeight: '600' },
      },
    },
    {
      header: '',
      content: (error: BatchError) => (
        <>
          {error.fieldErrors.map((fieldError, index) => (
            <div key={index}>
              {fieldError.field} - {fieldError.errorMessage}
            </div>
          ))}
        </>
      ),
      cellProps: { style: { color: '#DD0000' } },
    },
  ];

  return (
    <>
      <div className={styles.content}>
        <div>
          <Button
            className={styles.noBackGround}
            icon
            onClick={() => {
              if (selected.length > 0) {
                retryRequests(selected);
                setSelected([]);
              }
            }}
          >
            <Icon name="redo" /> Retry
          </Button>
          <Button
            className={styles.noBackGround}
            icon
            onClick={() => {
              if (selected.length > 0) {
                cancelRequests(selected);
                setSelected([]);
              }
            }}
          >
            <Icon name="ban" /> Cancel
          </Button>
        </div>
        <TableSimple rows={errors} columns={columns} />
      </div>
      <hr className={styles.hr} />
      <div className={styles.footer}>
        <Button
          className={styles.retry}
          onClick={() => retryRequests(allIds)}
        >
          Retry all
        </Button>
        <Button
          className={styles.cancel}
          onClick={() => cancelRequests(allIds)}
        >
          Cancel all
        </Button>
        <Button className={styles.done} primary onClick={toggleModal}>
          Done
        </Button>
      </div>
    </>
  );
};

/**
 * Labstep
 *
 * @module components/Metadata/Manager/List
 * @desc Metadata Manager in list form
 */

import { MetadataCardList } from 'labstep-web/components/Metadata/CardList';
import Flex from 'labstep-web/core/Flex';
import React from 'react';
import { MetadataManagerListProps } from './types';

const MetadataManagerList: React.FC<MetadataManagerListProps> = ({
  entity,
  draggable,
  hideActions,
}) => (
  <Flex grow>
    <MetadataCardList
      entity={entity}
      draggable={draggable}
      hideActions={hideActions}
    />
  </Flex>
);

export default MetadataManagerList;

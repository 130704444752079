/**
 * Labstep
 *
 * @module screens/OrderRequest/Index
 * @desc OrderRequest Index
 */

import GridNewIndexEntityViewLoader from 'labstep-web/grid/NewIndex/components/EntityViewLoader';
import GridNewIndexTemplateLoader from 'labstep-web/grid/NewIndex/components/TemplateLoader';
import { IndexTemplatableEntity } from 'labstep-web/models';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import ScreensOrderRequestIndexMaster from 'labstep-web/screens/OrderRequest/Index/Master';
import React from 'react';
import { ScreensOrderRequestIndexProps } from './types';

export const ScreensOrderRequestIndex: React.FC<
  ScreensOrderRequestIndexProps
> = ({ group, links }) => (
  <GridNewIndexTemplateLoader
    entityName={OrderRequest.entityName}
    templateId={group.default_order_request_template?.guid}
  >
    {({ template }: { template?: IndexTemplatableEntity }) => (
      <GridNewIndexEntityViewLoader
        group={group}
        context="order_request_index"
        entityName={OrderRequest.entityName}
        defaultTemplate={template}
      >
        {({ entityView }: { entityView: EntityView }) => (
          <ScreensOrderRequestIndexMaster
            entityView={entityView}
            group={group}
            links={links}
          />
        )}
      </GridNewIndexEntityViewLoader>
    )}
  </GridNewIndexTemplateLoader>
);

export default ScreensOrderRequestIndex;

/**
 * Labstep
 *
 * @module components/Entity/Template/SectionItem
 * @desc An item to display a template in a section header
 */

import { EntityPreviewContent } from 'labstep-web/components/Entity/Preview';
import ResourceTemplateLayoutLinks from 'labstep-web/components/ResourceTemplate/LayoutLinks';
import Icon from 'labstep-web/core/Icon';
import Modal from 'labstep-web/core/Modal/Default';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import styles from './styles.module.scss';
import { EntityTemplateSectionItemProps } from './types';

const getTemplatePreviewComponent = (template: any) => {
  switch (template.entityName) {
    case Resource.entityName:
      return (
        <EntityPreviewContent
          entity={template}
          bottom={
            <ResourceTemplateLayoutLinks
              resourceTemplate={template}
              withRoute={false}
            />
          }
        />
      );
    default:
      return null;
  }
};

const EntityTemplateSectionItem: React.FC<
  EntityTemplateSectionItemProps
> = ({ template }) => {
  const component = getTemplatePreviewComponent(template);
  if (!component) {
    return null;
  }

  return (
    <>
      {template.name}
      <Modal
        size="large"
        content={getTemplatePreviewComponent(template)}
        viewComponent={({ toggleModal }) => (
          <span
            className={styles.iconContainer}
            onClick={toggleModal}
          >
            <Icon name="cog" />
          </span>
        )}
      />
    </>
  );
};

export default EntityTemplateSectionItem;

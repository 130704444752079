/**
 * Labstep
 *
 * @module grid/NewIndex/coldefs/resource-item/resource-location
 * @desc Column Definition for resource item resource location
 */

import {
  EditableCallbackParams,
  ValueGetterParams,
} from 'ag-grid-community';
import ResourceItemFilterResourceLocation from 'labstep-web/components/ResourceItem/Filter/ResourceLocation';
import ResourceItemResourceLocationModal from 'labstep-web/components/ResourceItem/ResourceLocationModal';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import {
  ParamsStateContext,
  ParamsStateContextProvider,
} from 'labstep-web/contexts/params-state';
import { suppressKeyboardEventClearCells } from 'labstep-web/core/DataGrid/CellEditor/utils';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import {
  CellEditorParams,
  CellRendererParams,
  ColDefParams,
} from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
  stopEditingAndFocus,
} from 'labstep-web/core/DataGrid/utils';
import { GridIndexContextMenuFilterProps } from 'labstep-web/grid/Index/components/ContextMenu/types';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import { Entity } from 'labstep-web/models/entity.model';
import { ResourceLocation } from 'labstep-web/models/resource-location.model';
import React from 'react';

export const getResourceLocationModal = (
  params: ColDefParams<Entity> & { rowIndex: number },
  editing: boolean,
): React.ReactElement => (
  <EntityReadEntityContainer
    entityName={params.data.entityName}
    id={params.data.id}
  >
    {({ entity: resourceItem }): JSX.Element => {
      const props = editing
        ? {
            hideMap: true,
            modalProps: {
              open: true,
              viewComponent: null,
              onClose: (): void => stopEditingAndFocus(params),
            },
          }
        : {};
      return (
        <ParamsStateContext.Provider value={{}}>
          <ResourceItemResourceLocationModal
            resourceItem={resourceItem}
            {...props}
          />
        </ParamsStateContext.Provider>
      );
    }}
  </EntityReadEntityContainer>
);

/**
 * Column definition for resource location
 * @returns Column definition
 */
export const colDefResourceItemResourceLocation = {
  colId: 'resource_location',
  postFilterSettings: {
    path: 'resource_location',
    field: 'name',
    fieldType: PostFilterFieldTypes.custom,
  },
  headerName: 'Location',
  headerComponentParams: {
    icon: ICONS.resource_location.primary,
    filter: (props: GridIndexContextMenuFilterProps) => (
      <ResourceItemFilterResourceLocation {...props} />
    ),
  },
  editable: (params: EditableCallbackParams) =>
    GridNewIndexService.getEditable(params),
  cellStyle: { display: 'flex' },
  valueGetter: (params: ValueGetterParams): number | null =>
    params.data?.resource_location?.guid || null,
  valueGetterExport: (params: ValueGetterParams) =>
    params.node?.data?.resource_location?.location_path || '',
  valueSetter: (params: any): boolean =>
    setValue('resource_location_guid', params.newValue, {
      entityName: params.data.entityName,
      id: params.data.idAttr,
    }),
  cellRenderer: (
    params: CellRendererParams<Entity>,
  ): React.ReactElement => {
    return params.data?.resource_location ? (
      <EntityReadEntityContainer
        entityName={params.data.entityName}
        id={params.data.id}
      >
        {({ entity: resourceItem }): JSX.Element => {
          return (
            <ParamsStateContextProvider value={{}}>
              <ResourceItemResourceLocationModal
                resourceItem={resourceItem}
              />
            </ParamsStateContextProvider>
          );
        }}
      </EntityReadEntityContainer>
    ) : (
      <DataGridPlaceholder
        params={params}
        children={`Specify ${ResourceLocation.getHumanReadableEntityName()}`}
        editable={getEditable()}
        placeholder={`No ${ResourceLocation.getHumanReadableEntityName()}`}
      />
    );
  },
  cellEditorPopup: true,
  suppressKeyboardEvent: suppressKeyboardEventClearCells,
  valueClearer: () => null,
  cellEditor: (params: CellEditorParams<Entity>) => (
    <EntityReadEntityContainer
      entityName={params.data.entityName}
      id={params.data.id}
    >
      {({ entity: resourceItem }): JSX.Element => {
        return (
          <ParamsStateContextProvider value={{}}>
            <ResourceItemResourceLocationModal
              resourceItem={resourceItem}
              hideMap
              modalProps={{
                open: true,
                viewComponent: null,
                onClose: (): void => stopEditingAndFocus(params),
              }}
            />
          </ParamsStateContextProvider>
        );
      }}
    </EntityReadEntityContainer>
  ),
};

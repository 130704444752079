/**
 * Labstep
 *
 * @module report/services/grid-new-index
 * @desc AGGrid Service NewIndex
 */

import { arrayMove } from '@dnd-kit/sortable';
import {
  Column,
  EditableCallbackParams,
  HeaderClassParams,
} from 'ag-grid-community';
import { selectHasAccess } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ISortableProps } from 'labstep-web/core/Sortable/types';
import {
  NEW_INDEX_COL_DEFS,
  getMetadataMoleculeColDef,
  getMetadataMoleculeColDefs,
} from 'labstep-web/grid/NewIndex/coldefs';
import { getColDefEntityTemplate } from 'labstep-web/grid/NewIndex/coldefs/entity/template';
import { getMetadataColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata';
import { NewIndexColDef } from 'labstep-web/grid/NewIndex/coldefs/types';
import { GridService } from 'labstep-web/grid/services/grid.service';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import {
  PostFilterComparison,
  PostFilterOperator,
} from 'labstep-web/services/query-parameter.service';
import store from 'labstep-web/state/store';
import { forEach, get } from 'lodash';

export const PINNED_COL_IDS = ['action_menu', 'bulk_select', 'name'];

export const userFilteStatusAvailable = {
  type: PostFilterOperator.and,
  predicates: [
    {
      value: 'available',
      attribute: 'status',
      comparison: PostFilterComparison.eq,
    },
  ],
};

export const getSystemFilterResourceGuid = (
  resourceGuid: string,
) => ({
  path: 'resource',
  type: PostFilterOperator.and,
  predicates: [
    {
      value: resourceGuid,
      attribute: 'guid',
      comparison: PostFilterComparison.eq,
    },
  ],
});

export const getSystemFilterTemplateGuid = (
  templateGuid: string,
) => ({
  path: 'template',
  type: PostFilterOperator.and,
  predicates: [
    {
      value: templateGuid,
      attribute: 'guid',
      comparison: PostFilterComparison.eq,
    },
  ],
});

export const systemFilterNoTemplate = {
  path: 'template',
  type: PostFilterOperator.and,
  predicates: [
    {
      attribute: 'guid',
      comparison: PostFilterComparison.not_exists,
    },
  ],
};

export interface IEntityDataGridToolPanelSectionItemProps {
  column: ColumnWithId;
  section: SectionType;
  onToggleColumnVisible: (colId?: string) => void;
}

// eslint-disable-next-line no-shadow
export enum SectionType {
  visible = 'visible',
  hidden = 'hidden',
}
export interface IEntityDataGridToolPanelSectionProps {
  columns: Column[];
  section: SectionType;
  onToggleColumnVisible: IEntityDataGridToolPanelSectionItemProps['onToggleColumnVisible'];
  onSortColumns: (columns: Column[]) => void;
}

export type ColumnWithId = Column & {
  getId: () => string;
};

export type ItemWithColumn = {
  id: string;
  column: ColumnWithId;
};

export const PROTOCOL_VALUE_FIELDS = [
  'amount',
  'resource_item_name',
  'resource_name',
  'resource_location_path',
];

export class GridNewIndexService extends GridService {
  /**
   * Returns a function bound to the onSortEnd prop of dnd-kit sortable
   * @param items ItemWithColumn array
   * @param fn Function to call when sorting is complete
   * @returns Column array
   */
  public static onSortEndColumns(
    items: ItemWithColumn[],
    fn: (columns: Column[]) => void,
  ): ISortableProps<ItemWithColumn>['onSortEnd'] {
    return ({ oldIndex, newIndex }) => {
      const newItems = arrayMove(items, oldIndex, newIndex);
      const newColumns = GridNewIndexService.itemsToColumns(newItems);
      fn(newColumns);
    };
  }

  /**
   * Creates an array of objects with the column and its id
   * For use with dnd-kit sortable
   * @param columns Column array
   * @returns ItemWithColumn array
   */
  public static columnsToItems(columns: Column[]): ItemWithColumn[] {
    return columns
      .filter((column) => column.getId()) // sanity type check
      .map((column) => ({
        id: column.getId(),
        column,
      }));
  }

  /**
   * Creates an array of columns from an array of objects with the column and its id
   * @param items ItemWithColumn array
   * @returns Column array
   */
  public static itemsToColumns(items: ItemWithColumn[]): Column[] {
    return items.map((item) => item.column);
  }

  public static isHideable(column: HeaderClassParams['column']) {
    if (!column) {
      return false;
    }
    return (
      !column.isPinned() &&
      !column.getColDef().lockPinned &&
      !column.getColDef().lockVisible
    );
  }

  public static showListSection = (
    labels: string[],
    searchQuery: string | null | undefined,
  ) =>
    !searchQuery ||
    searchQuery.length === 0 ||
    labels
      .map((label) => label.includes(searchQuery.toLowerCase()))
      .includes(true);

  public static showListItem = (
    label: string,
    searchQuery: string | null | undefined,
  ) =>
    !searchQuery ||
    searchQuery.length === 0 ||
    label.includes(searchQuery.toLowerCase());

  public static metadataCellRenderer = (
    metadatas: Metadata[],
    label: string,
  ) => {
    const foundMetadata = metadatas
      ? metadatas.find(
          (dataMetadata: Metadata) => dataMetadata.label === label,
        )
      : null;
    if (!foundMetadata) {
      return '';
    }
    return foundMetadata.display_value || '';
  };

  public static protocolValueCellRenderer = (
    protocolValues: ProtocolValue[],
    name: string,
    path: string,
  ) => {
    const foundProtocolValue = GridNewIndexService.getProtocolValue(
      protocolValues,
      name,
    );
    if (!foundProtocolValue) {
      return '';
    }
    return get(foundProtocolValue, path) || '';
  };

  public static getProtocolValue = (
    protocolValues: ProtocolValue[],
    name: string,
  ) =>
    protocolValues
      ? protocolValues.find(
          (dataProtocolValue: ProtocolValue) =>
            dataProtocolValue.name === name,
        )
      : null;

  public static getColDefs = (
    entityView: EntityView,
  ): NewIndexColDef[] => {
    const colDefs: NewIndexColDef[] = [
      NEW_INDEX_COL_DEFS[entityView.entity_name].action_menu,
      NEW_INDEX_COL_DEFS[entityView.entity_name].bulk_select,
      NEW_INDEX_COL_DEFS[entityView.entity_name].name,
    ];

    if (!entityView.column_definition_ids) {
      return colDefs;
    }

    forEach(entityView.column_definition_ids, (colId: string) => {
      if (colId.startsWith('metadata:')) {
        const parts = colId.split(':');
        if (parts.length === 4) {
          const parentEntityClass = parts[1];
          const type = parts[2];
          const metadataLabel = parts[3];
          colDefs.push(
            getMetadataColDef(
              parentEntityClass,
              type as MetadataType,
              metadataLabel,
            ),
          );
        } else if (parts.length === 5) {
          const parentEntityClass = parts[1];
          const type = parts[2];
          const metadataLabel = parts[3];
          const field = parts[4];
          const moleculeColDef = getMetadataMoleculeColDef(
            parentEntityClass,
            type as MetadataType,
            metadataLabel,
            field,
          );
          if (moleculeColDef) {
            colDefs.push(moleculeColDef);
          }
        }
      } else if (NEW_INDEX_COL_DEFS[entityView.entity_name][colId]) {
        colDefs.push(
          NEW_INDEX_COL_DEFS[entityView.entity_name][colId],
        );
      } else if (colId === 'template') {
        colDefs.push(getColDefEntityTemplate(entityView.entity_name));
      }
    });

    return colDefs;
  };

  public addColumnDef(colDef: NewIndexColDef): void {
    const currentColDefs = this.getColumnDefs();

    if (currentColDefs.find((col) => col.colId === colDef.colId)) {
      this.setColumnHide(colDef.colId, false);

      return;
    }

    this.setColumnDefs([...this.getColumnDefs(), colDef]);
  }

  public static getEditable = (
    params: EditableCallbackParams,
  ): boolean => {
    if (!params.node?.data) {
      return true;
    }
    return !!selectHasAccess(
      store.getState(),
      params.node.data.entityName,
      params.node.data.idAttr,
      Action.edit,
    );
  };
}

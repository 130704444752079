/**
 * Labstep
 *
 * @module screens/Resource/Index/LeftPane
 * @desc Resource Index LeftPane
 */

import EntityTemplatePane from 'labstep-web/components/Entity/Template/Pane';
import LayoutLeftPane from 'labstep-web/components/Layout/LeftPane';
import ResourceFilterPane from 'labstep-web/components/Resource/Filter/Pane';
import ResourceTemplatePreview from 'labstep-web/components/ResourceTemplate/Preview';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { ScreensResourceIndexLeftPaneProps } from './types';

export const ScreensResourceIndexLeftPane: React.FC<
  ScreensResourceIndexLeftPaneProps
> = ({ links }) => (
  <LayoutLeftPane links={links}>
    <ParamsStateContextProvider value={{}}>
      <EntityTemplatePane
        entityPreview={({ entity, ...rest }): React.ReactElement => (
          <ResourceTemplatePreview
            resource={entity as Resource}
            {...rest}
          />
        )}
        entityName={Resource.entityName}
        indexRoute="group_resources"
      />
    </ParamsStateContextProvider>
    <ParamsStateContextProvider
      value={{ destination: 'resource_index' }}
    >
      <ResourceFilterPane />
    </ParamsStateContextProvider>
  </LayoutLeftPane>
);

export default ScreensResourceIndexLeftPane;

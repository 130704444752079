/**
 * Labstep
 *
 * @module core/Dropdown/Portal
 * @desc Dropdown Portal component (e.g. when parent overflow hidden)
 */

import Dropdown from 'labstep-web/core/Dropdown';
import { useToggle } from 'labstep-web/hooks/toggle';
import { windowService } from 'labstep-web/services/window.service';
import React, { useEffect, useRef, useState } from 'react';
import { Portal } from 'semantic-ui-react';
import { IDropdownProps } from '../types';
import { IDropdownPortalProps } from './types';

export const DROPDOWN_MARGIN = 15;
export const DROPDOWN_MIN_WIDTH = 75;
export const MODAL_SELECTOR = '.modal';
export const DATEPICKER_SELECTOR = '.flatpickr-calendar';

export const DropdownPortal: React.FC<IDropdownPortalProps> = ({
  trigger,
  onOpen,
  closeOnChange,
  xOffset = 0,
  yOffset = 0,
  ...rest
}) => {
  const triggerRef = useRef<HTMLDivElement>();
  const menuRef = useRef<HTMLDivElement>(null);
  const [coords, setCoords] = useState<React.CSSProperties>({});
  const [direction, setDirection] =
    useState<IDropdownProps['direction']>('right');
  const [isOpen, toggleIsOpen] = useToggle();

  /**
   * Toggle isOpen and set coords for dropdown
   */
  const toggleIsOpenAndSetCoords: IDropdownPortalProps['onOpen'] = (
    event,
  ) => {
    if (triggerRef.current) {
      const { x, y } = triggerRef.current.getBoundingClientRect();
      setCoords({
        left: x + xOffset,
        top: y + window.scrollY + DROPDOWN_MARGIN + yOffset,
      });
      toggleIsOpen();
      onOpen?.(event);
      event.stopPropagation();
    }
  };

  useEffect(() => {
    // Set dropdown height and direction
    if (menuRef.current && windowService.viewportSize) {
      const { height: viewHeight, width: viewWidth } =
        windowService.viewportSize;
      const { height, width, top, left } =
        menuRef.current.getBoundingClientRect();
      const maxHeight = viewHeight - top - DROPDOWN_MARGIN;
      if (height > maxHeight) {
        menuRef.current.style.maxHeight = `${maxHeight}px`;
        menuRef.current.style.overflow = 'auto';
      }
      const maxWidth = viewWidth - left - DROPDOWN_MARGIN;
      if (width > maxWidth) {
        setDirection('left');
      } else {
        setDirection('right');
      }
    }
    // Show dropdown after height and direction are set
    setCoords({
      ...coords,
      display: 'block',
      position: 'fixed', // Need to do this after bounding rect calculation
    });
  }, [isOpen, menuRef.current]);

  const onClose: React.ComponentProps<typeof Portal>['onClose'] = (
    e,
  ) => {
    // a dropdown item that opens a modal will not close the dropdown
    // but a dropdown inside a modal will be closed when clicked outside
    const modalElement =
      e.target instanceof Element
        ? e.target.closest(MODAL_SELECTOR)
        : null;
    const datePickerElement =
      e.target instanceof Element &&
      e.target.closest(DATEPICKER_SELECTOR);
    const triggerElement = triggerRef.current;
    if (
      (modalElement &&
        triggerElement &&
        !modalElement.contains(triggerElement)) ||
      datePickerElement
    ) {
      return;
    }
    toggleIsOpen();
  };

  return (
    <Portal
      openOnTriggerClick
      closeOnDocumentClick
      onOpen={toggleIsOpenAndSetCoords}
      onClose={onClose}
      open={isOpen}
      trigger={trigger}
      triggerRef={triggerRef}
    >
      <div
        style={{
          display: 'none', // Hide until we have the correct height
          zIndex: 1000,
          minWidth: DROPDOWN_MIN_WIDTH,
          ...coords,
        }}
      >
        <Dropdown
          menuRef={menuRef}
          isOpen
          icon={null}
          direction={direction}
          onClick={closeOnChange ? toggleIsOpen : undefined}
          {...rest}
        />
      </div>
    </Portal>
  );
};

export default DropdownPortal;

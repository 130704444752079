/**
 * Labstep
 *
 * @module components/Entity/TimeMachine
 * @desc Entity time machine
 */

import EntityTimeMachineList from 'labstep-web/components/Entity/TimeMachine/List';
import LogLegacyList from 'labstep-web/components/Log/Legacy/List';
import { TIME_MACHINE_ENTITIES } from 'labstep-web/constants/time-machine';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { Log } from 'labstep-web/models/log.model';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { EntityTimeMachineProps } from './types';

export const EntityTimeMachine: React.FC<EntityTimeMachineProps> = ({
  logEntity,
}) => {
  const [activeLog, setActiveLog] = useState<Log | null>(null);
  return (
    <>
      <ModalDefault
        header="Time Machine"
        size="fullscreen"
        open={!!activeLog}
        onClose={(): void => setActiveLog(null)}
        content={() =>
          activeLog ? (
            <div className={styles.container}>
              <div>
                <EntityTimeMachineList
                  key={activeLog.ended_at || activeLog.created_at}
                  entityName={activeLog.log_entry_object_class}
                  id={activeLog.log_entry_object_id}
                  timestamp={
                    activeLog.ended_at || activeLog.created_at
                  }
                />
              </div>
              <LogLegacyList
                showExternalIcon={false}
                entity={logEntity}
                extraParams={{
                  log_entry_object_id: activeLog.log_entry_object_id,
                  log_entry_object_class:
                    activeLog.log_entry_object_class,
                }}
                getOnClickFunction={(log) => (): void =>
                  setActiveLog(log)
                }
                activeLogId={activeLog && activeLog.id}
              />
            </div>
          ) : null
        }
        viewComponent={() => (
          <LogLegacyList
            entity={logEntity}
            getOnClickFunction={(log): (() => void) | undefined => {
              if (
                TIME_MACHINE_ENTITIES.includes(
                  log.log_entry_object_class,
                )
              ) {
                return (): void => setActiveLog(log);
              }
              return undefined;
            }}
          />
        )}
      />
    </>
  );
};

export default withActiveGroup(EntityTimeMachine);

/**
 * Labstep
 *
 * @module components/Entity/Create/Can
 * @desc Entity create container with Can permission check
 */

import { CanCreate } from 'labstep-web/components/Entity/Can';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import React from 'react';
import { IEntityCanCreateContainerProps } from './types';

export const EntityCanCreateContainer: React.FC<
  IEntityCanCreateContainerProps
> = ({
  entityName,
  parentName,
  parentId,
  overwriteCanCreateProps = {},
  skipPermissionCheck,
  showLoaderWhileCreating,
  isTemplate,
  batch,
  children,
}) => {
  const component = (
    <EntityCreateContainer
      entityName={entityName}
      parentName={parentName}
      parentId={parentId}
      showLoaderWhileCreating={showLoaderWhileCreating}
      batch={batch}
      isTemplate={isTemplate}
    >
      {(props): React.ReactElement => children(props)}
    </EntityCreateContainer>
  );
  // Temp fix for UserGroup creation that doesn't really have
  // a parent
  if (skipPermissionCheck) {
    return component;
  }
  return (
    <CanCreate
      parentName={parentName}
      parentId={parentId}
      entityName={entityName}
      {...overwriteCanCreateProps}
    >
      {component}
    </CanCreate>
  );
};

export default EntityCanCreateContainer;

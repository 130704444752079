/**
 * Labstep
 *
 * @module components/File/Action/Create
 * @desc Action to create file
 */

import FileActionAttach from 'labstep-web/components/File/Action/Attach';
import Picker from 'labstep-web/components/File/Action/Create/Picker';
import FileActionUpload from 'labstep-web/components/File/Action/Upload';
import ActionComponent from 'labstep-web/core/Action/Component';
import BoxPicker from 'labstep-web/core/BoxPicker';
import DropboxPicker from 'labstep-web/core/DropboxPicker';
import GoogleDrivePicker from 'labstep-web/core/GooglePicker';
import ModalDefault from 'labstep-web/core/Modal/Default';
import OneDrivePicker from 'labstep-web/core/OneDrivePicker';
import { configService } from 'labstep-web/services/config.service';
import React from 'react';
import styles from './styles.module.scss';
import { FileActionCreateProps } from './types';

export const FileActionCreate: React.FC<FileActionCreateProps> = ({
  children,
  options,
  onFolderWatcherSelect,
  multiple,
  entityType,
  entityId,
  showOnly,
  modalProps,
  viewComponent,
  ...rest
}) => (
  <ModalDefault
    usePortal
    id="file-create-modal"
    header="Add file / image"
    content={({ toggleModal }) => {
      let enhancedOptions = {};
      if (options) {
        enhancedOptions = {
          onSuccess: options.onSuccess,
          onFail: options.onFail,
          toast: true,
        };
      }
      const onSelect = () => {
        toggleModal();
      };
      return (
        <div className={styles.container}>
          {showOnly !== 'cloud' && (
            <FileActionUpload
              options={enhancedOptions}
              onDropAccepted={onSelect}
              multiple={multiple}
              showDragEnter
              entityType={entityType}
              entityId={entityId}
              {...rest}
            >
              <div className={styles.dropzone}>
                <Picker text="My Device" icon="computer" />
                <div>or Drag and Drop</div>
                <div>(Up to 100MB)</div>
              </div>
            </FileActionUpload>
          )}
          {/* {!showOnly && !!onFolderWatcherSelect && (
            <FileActionAttach
              viewComponent={({
                toggleModal: toggleFolderWatcherModal,
              }) => (
                <Picker
                  onClick={toggleFolderWatcherModal}
                  text="From Folder Watcher"
                  icon="hdd"
                />
              )}
              onSubmit={(values) => {
                onFolderWatcherSelect(values);
                toggleModal();
              }}
            />
          )} */}
          {showOnly !== 'device' && !configService.isUsAwsRegion && (
            <>
              <GoogleDrivePicker
                multiple={multiple}
                onSelect={onSelect}
                options={enhancedOptions}
                parentName={entityType}
                parentId={entityId}
              />
              <OneDrivePicker
                multiple={multiple}
                onSelect={onSelect}
                options={enhancedOptions}
                parentName={entityType}
                parentId={entityId}
              />
              <DropboxPicker
                multiple={multiple}
                onSelect={onSelect}
                options={enhancedOptions}
                parentName={entityType}
                parentId={entityId}
              />
              <BoxPicker
                multiple={multiple}
                onSelect={onSelect}
                options={enhancedOptions}
                parentName={entityType}
                parentId={entityId}
              />
            </>
          )}
        </div>
      );
    }}
    viewComponent={
      viewComponent ||
      (({ toggleModal }) =>
        children ? (
          children({ toggleModal })
        ) : (
          <ActionComponent
            onClick={toggleModal}
            type="button"
            text="Upload File"
          />
        ))
    }
    {...modalProps}
  />
);

export default FileActionCreate;

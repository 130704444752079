/**
 * Labstep
 *
 * @module core/DataGrid/ColumnHeader/EntityImport/Metadata/Action/Delete
 * @desc DataGrid Header for EntityImport
 */

import { useGridEntityImportServiceFromContext } from 'labstep-web/contexts/grid/hook';
import ActionComponent from 'labstep-web/core/Action/Component';
import React from 'react';
import { IDataGridColumnHeaderEntityImportMetadataActionDeleteProps } from './types';

export const DataGridColumnHeaderEntityImportMetadataActionDelete: React.FC<
  IDataGridColumnHeaderEntityImportMetadataActionDeleteProps
> = ({ column }) => {
  const gridEntityImportService =
    useGridEntityImportServiceFromContext();
  if (!gridEntityImportService) {
    return null;
  }
  return (
    <ActionComponent
      type="option"
      text="Delete"
      icon="trash"
      modalConfirmationProps={{
        header: 'Delete Column',
        message: 'Are you sure you want to delete this column?',
        positive: false,
      }}
      onClick={(): void => {
        const colId = column.getColId();
        gridEntityImportService.removeColumn(colId);
      }}
    />
  );
};

export default DataGridColumnHeaderEntityImportMetadataActionDelete;

/**
 * Labstep
 *
 * @module grid/NewIndex/components/ToolPanel
 * @desc Entity DataGrid dropdown to set column options
 */

import { Column } from 'ag-grid-community';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import {
  useDataGridContext,
  useGridNewIndexServiceFromContext,
} from 'labstep-web/contexts/grid/hook';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { toggleColumnVisible } from 'labstep-web/core/DataGrid/utils';
import Header from 'labstep-web/core/Header';
import { getMetadataColDef } from 'labstep-web/grid/NewIndex/coldefs/metadata';
import EntityDataGridToolPanelAddColumn from 'labstep-web/grid/NewIndex/components/ToolPanel/AddColumn';
import EntityDataGridToolPanelMetadataMolecule from 'labstep-web/grid/NewIndex/components/ToolPanel/MetadataMolecule';
import EntityDataGridToolPanelSection from 'labstep-web/grid/NewIndex/components/ToolPanel/Section';
import { SectionType } from 'labstep-web/grid/NewIndex/components/ToolPanel/Section/types';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { IEntityDataGridToolPanelProps } from './types';
import {
  filterHiddenColumnDefs,
  filterPinnedLeftColumnDefs,
  filterPinnedRightColumnDefs,
  filterShownAndNotPinnedPositionColumnDefs,
  getColIdArr,
} from './utils';

// eslint-disable-next-line no-shadow
export enum ToolPanelState {
  default = 'default',
  add_column = 'add_column',
  metadata_molecule = 'metadata_molecule',
}

export const EntityDataGridToolPanel: React.FC<
  IEntityDataGridToolPanelProps
> = ({ entityView, authenticatedUserEntity }) => {
  const [metadataMolecule, setMetadataMolecule] =
    useState<Metadata | null>(null);

  const gridNewIndexService = useGridNewIndexServiceFromContext();

  const { agGrid, columns, columnState } = useDataGridContext();
  if (!columns) {
    return null;
  }

  const colIds = gridNewIndexService?.getColumnDefinitionIds();

  const [toolPanelState, setToolPanelState] = useState(
    ToolPanelState.default,
  );

  /**
   * Columns by type (pinned left, pinned right, shown, hidden)
   */
  const columnsByType: Record<
    'pinnedLeft' | 'pinnedRight' | 'shown' | 'hidden',
    Column[]
  > = useMemo(
    () => ({
      pinnedLeft: filterPinnedLeftColumnDefs(columns, columnState),
      pinnedRight: filterPinnedRightColumnDefs(columns, columnState),
      shown: filterShownAndNotPinnedPositionColumnDefs(
        columns,
        columnState,
      ),
      hidden: filterHiddenColumnDefs(columns, columnState),
    }),
    [columns, columnState],
  );

  const onSortEnd = useCallback(
    (columnsSorted: Column[]) => {
      if (agGrid) {
        const allColumns = [
          ...columnsByType.pinnedLeft,
          ...columnsSorted,
          ...columnsByType.pinnedRight,
          ...columnsByType.hidden,
        ];
        const allColumnsColIdArr = getColIdArr(allColumns);
        agGrid.columnApi.applyColumnState({
          state: allColumnsColIdArr,
          applyOrder: true,
        });
      }
    },
    [agGrid],
  );

  const toggleColumnVisibleByColId = useCallback(
    (colId) => {
      if (agGrid) {
        toggleColumnVisible(agGrid, colId);
      }
    },
    [agGrid],
  );

  const selectMetadata = (
    metadata: Metadata,
    parentEntityClass: string,
  ) => {
    if (metadata.type === MetadataType.molecule) {
      setMetadataMolecule(metadata);
      setToolPanelState(ToolPanelState.metadata_molecule);
      return;
    }
    if (gridNewIndexService) {
      const colDef = getMetadataColDef(
        parentEntityClass,
        metadata.type,
        metadata.label || '',
      );
      gridNewIndexService.addColumnDef(colDef);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.close}>
        <ActionComponent
          type="icon"
          icon="times"
          onClick={() => agGrid?.api?.setSideBarVisible(false)}
        />
      </div>
      {toolPanelState === ToolPanelState.default && (
        <>
          <Header size="tiny" noMargin>
            Columns
          </Header>
          <EntityDataGridToolPanelSection
            section={SectionType.visible}
            columns={columnsByType.shown}
            onToggleColumnVisible={toggleColumnVisibleByColId}
            onSortColumns={onSortEnd}
          />
          <ActionComponent
            icon="plus"
            type="text"
            text="Add Column"
            onClick={() =>
              setToolPanelState(ToolPanelState.add_column)
            }
          />
        </>
      )}
      {gridNewIndexService &&
        toolPanelState === ToolPanelState.add_column &&
        colIds && (
          <EntityDataGridToolPanelAddColumn
            authenticatedUserEntity={authenticatedUserEntity}
            colIds={colIds}
            entityView={entityView}
            gridNewIndexService={gridNewIndexService}
            setToolPanelState={setToolPanelState}
            selectMetadata={selectMetadata}
          />
        )}
      {gridNewIndexService &&
        toolPanelState === ToolPanelState.metadata_molecule &&
        metadataMolecule &&
        colIds && (
          <EntityDataGridToolPanelMetadataMolecule
            authenticatedUserEntity={authenticatedUserEntity}
            colIds={colIds}
            entityView={entityView}
            gridNewIndexService={gridNewIndexService}
            setToolPanelState={setToolPanelState}
            metadata={metadataMolecule}
          />
        )}
    </div>
  );
};

export default withAuthenticatedUserEntity(EntityDataGridToolPanel);

/**
 * Labstep
 *
 * @module models/amount-update
 * @desc Typescript export class for AmountUpdate
 */

import { Type } from 'class-transformer';
import { UNITS } from 'labstep-web/constants/unit';
import { Entity } from 'labstep-web/models/entity.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { User } from 'labstep-web/models/user.model';
import { getAmountUnitText } from 'labstep-web/services/amount-unit.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export type AmountUpdateEntityNameType = 'amount_update';

export const getLabel = (
  name: string,
  amount: string | null,
  unit: string | null,
): string => {
  const amountUnitText = getAmountUnitText(amount, unit);

  if (!amountUnitText) {
    return name;
  }

  return `${name} (${amountUnitText})`;
};

export class AmountUpdate extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityName: AmountUpdateEntityNameType =
    'amount_update';

  public get entityName(): string {
    return AmountUpdate.entityName;
  }

  public constructor(data: Partial<AmountUpdate> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public name!: string | null;

  public amount!: string | null;

  public unit!: string | null;

  @Type(() => User)
  public author!: User | null;

  @Type(() => ProtocolValue)
  public protocolValue: ProtocolValue | null | undefined;

  @Type(() => ResourceItem)
  public resource_item: ResourceItem | null | undefined;

  public get unitDisplayValue(): string | null | undefined {
    if (!this.unit) {
      return null;
    }

    if (UNITS[this.unit]) {
      return UNITS[this.unit].displayValue;
    }
    return this.unit;
  }

  public get amountUnitText(): string | null {
    return getAmountUnitText(this.amount, this.unitDisplayValue);
  }

  public get amountUnitTextNoUnitOnly(): string | null {
    return getAmountUnitText(
      this.amount,
      this.unitDisplayValue,
      true,
    );
  }

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}

/**
 * Labstep
 *
 * @module components/ExperimentWorkflowTemplate/Warning
 * @desc Display a warning for the user that they are viewing / editing a template.
 */

import Message from 'labstep-web/core/Message';
import React from 'react';
import { ExperimentWorkflowTemplateWarningProps } from './types';

const ExperimentWorkflowTemplateWarning: React.FC<
  ExperimentWorkflowTemplateWarningProps
> = ({ experimentWorkflow }) =>
  experimentWorkflow?.is_template ? (
    <Message
      data-cy="components/ExperimentWorkflowTemplate/Warning"
      color="yellow"
    >
      This is a template.
    </Message>
  ) : null;

export default ExperimentWorkflowTemplateWarning;

/**
 * Labstep
 *
 * @module grid/Report/components/ColumnHeader/ContextMenu
 * @desc Entity DataGrid Context Menu
 */

import { FilterMetadataType } from 'labstep-web/components/Filter/Metadata/Form/types';
import GridReportFilterDate from 'labstep-web/components/FilterPost/Report/Date';
import FilterPostReportExperimentAuthor from 'labstep-web/components/FilterPost/Report/ExperimentAuthor';
import GridReportFilterMetadata from 'labstep-web/components/FilterPost/Report/Metadata';
import GridReportFilterMetadataHasValue from 'labstep-web/components/FilterPost/Report/MetadataHasValue';
import { useGridReportServiceFromContext } from 'labstep-web/contexts/grid/hook';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import Dropdown from 'labstep-web/core/Dropdown';
import DropdownPortal from 'labstep-web/core/Dropdown/Portal';
import Icon from 'labstep-web/core/Icon';
import { GridNewIndexService } from 'labstep-web/grid/NewIndex/services/grid-new-index.service';
import { ReportingColDef } from 'labstep-web/grid/Report/coldefs/types';
import { PostFilterFieldTypes } from 'labstep-web/grid/services/grid-filter.service';
import {
  MetadataType,
  MetadataTypeValues,
} from 'labstep-web/models/metadata/types';
import {
  dateOnlyAtUTC,
  formatDateOnly,
} from 'labstep-web/services/date.service';
import React from 'react';
import { GridReportColumnHeaderContextMenuProps } from './types';

export const SUPPORTED_METADATA_TYPES = [
  MetadataType.default,
  MetadataType.numeric,
  MetadataType.date,
  MetadataType.options,
];

export const SUPPORTED_HAS_VALUE_METADATA_TYPES = [
  MetadataType.default,
  MetadataType.numeric,
  MetadataType.date,
  MetadataType.options,
  MetadataType.file,
];

export const GridReportColumnHeaderContextMenu: React.FC<
  GridReportColumnHeaderContextMenuProps
> = ({
  columnApi,
  column,
  firstItems,
  lastItems,
  columnOptions,
  trigger,
}) => {
  const { addPostFilter } = useParamsContext();
  const gridReportService = useGridReportServiceFromContext();
  const dropdownItems: React.ReactNode[] = firstItems
    ? [firstItems]
    : [];

  dropdownItems.push(
    <Dropdown.Item
      onClick={(): void => {
        if (gridReportService) {
          gridReportService.sortColumn(
            trigger.props.column.colId,
            'asc',
          );
        }
      }}
    >
      <Icon name="sort amount up" /> Sort ASC by this field
    </Dropdown.Item>,
  );
  dropdownItems.push(
    <Dropdown.Item
      onClick={(): void => {
        if (gridReportService) {
          gridReportService.sortColumn(
            trigger.props.column.colId,
            'desc',
          );
        }
      }}
    >
      <Icon name="sort amount down" /> Sort DESC by this field
    </Dropdown.Item>,
  );

  const colDef = column.getColDef() as ReportingColDef;

  if (colDef.colId === 'experiment_author') {
    dropdownItems.push(
      <FilterPostReportExperimentAuthor
        addPostFilter={addPostFilter}
      />,
    );
  }
  if (
    colDef.cellRendererParams?.metadata &&
    colDef.cellRendererParams?.postFilterPath &&
    SUPPORTED_HAS_VALUE_METADATA_TYPES.includes(
      colDef.cellRendererParams?.metadata.type,
    )
  ) {
    dropdownItems.push(
      <GridReportFilterMetadataHasValue
        metadataLabel={colDef.cellRendererParams.metadata.label}
        path={colDef.cellRendererParams.postFilterPath}
        addPostFilter={addPostFilter}
      />,
    );
  }
  if (
    colDef.cellRendererParams?.metadata &&
    colDef.cellRendererParams?.postFilterPath &&
    SUPPORTED_METADATA_TYPES.includes(
      colDef.cellRendererParams?.metadata.type,
    )
  ) {
    dropdownItems.push(
      <GridReportFilterMetadata
        path={colDef.cellRendererParams.postFilterPath}
        defaultValues={{
          label: colDef.cellRendererParams.metadata.label,
          type: {
            value: colDef.cellRendererParams.metadata
              .type as FilterMetadataType,
            label:
              MetadataTypeValues[
                colDef.cellRendererParams.metadata
                  .type as MetadataType
              ],
          },
        }}
        addPostFilter={addPostFilter}
      />,
    );
  }
  if (
    colDef.postFilterSettings?.fieldType ===
      PostFilterFieldTypes.date &&
    colDef.headerName &&
    colDef.postFilterSettings.field
  ) {
    dropdownItems.push(
      <GridReportFilterDate
        label={colDef.headerName}
        path={colDef.postFilterSettings.path}
        field={colDef.postFilterSettings.field}
        addPostFilter={addPostFilter}
        defaultValues={{
          value: formatDateOnly(dateOnlyAtUTC(new Date())),
        }}
      />,
    );
  }

  const afterDividerItems = [];
  if (GridNewIndexService.isHideable(column)) {
    afterDividerItems.push(
      <Dropdown.Item
        onClick={(): void =>
          columnApi.setColumnVisible(column, false)
        }
      >
        <Icon name="eye slash" /> Hide from View
      </Dropdown.Item>,
    );
  }
  if (lastItems) {
    afterDividerItems.push(lastItems);
  }
  if (dropdownItems.length && afterDividerItems.length) {
    dropdownItems.push(<Dropdown.Divider />);
  }
  dropdownItems.push(...afterDividerItems);
  if (columnOptions) {
    dropdownItems.push(<Dropdown.Divider />);
    dropdownItems.push(columnOptions);
  }
  return dropdownItems.length ? (
    <DropdownPortal trigger={trigger}>{dropdownItems}</DropdownPortal>
  ) : (
    trigger
  );
};

export default GridReportColumnHeaderContextMenu;
